<template>
    <div class="news" @click="openLink">
        <div class="dateTime">
            <font-awesome-layers class="fa-2x">
                <font-awesome-icon :icon="icons.calendar"></font-awesome-icon>
                <font-awesome-layers-text transform="down-6" class="iconDate" :value="newsDate"></font-awesome-layers-text>
            </font-awesome-layers>
            <div class="month">{{ newsMonth }}</div>
        </div>
        <div class="newsBody">
            <div class="newsTitle">
                <a :href="news.link" target="_blank">{{ news.title }}</a>
            </div>
            <div class="related">
                <Badges :badges="badges" @badgeClicked="badgeClicked" size="xs"></Badges>
            </div>
            <div class="desc">{{ news.content }}</div>
        </div>
    </div>
</template>

<script>
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import DateHelper from '../../infra/DateHelper';
import Badges from '../controls/Badges.vue';

export default {
    components: { Badges },
    props: ['news'],
    data() {
        return {
            icons: {
                calendar: faCalendar
            }
        }
    },
    computed: {
        newsDate() {
            return new Date(this.news.date).getDate();
        },
        newsMonth() {
            return DateHelper.toShortMonth({ stringDate: this.news.date, locale: this.$i18n.locale });
        },
        badges() {
            if (!this.news.symbols) return [];
            return this.news.symbols.map(s => {
                const lp = s.split('.')
                const badge = {
                    label: ['US', 'CN'].includes(lp[1]) ? lp[0] : s,
                    value: s
                };
                return badge
            });
        }
    },
    methods: {
        badgeClicked(badge) {
            console.log('badge clicked', badge);
            //this.$emit('badgeClicked', badge);
        },
        openLink() {
            window.open(this.news.link, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.news {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: var(--v-hover1-base);
        .newsBody .newsTitle a {
            text-decoration: underline;
        }
    }
    .dateTime {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        // font-size: 0.7em;
        .iconDate {
            font-size: 0.5em;
        }
        .month {
            font-size: 0.8em;
        }
    }
    .newsBody {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .newsTitle {
            font-size: 0.9em;
            font-weight: bold;
            a {
                color: var(--v-darkBG-base);
                text-decoration: none;
            }
        }
        .related {
            display: flex;
            gap: 5px;
        }
        .desc {
            font-size: 0.75em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

}
</style>