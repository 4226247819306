<template>
    <div class="stockCardPriceInfos">
        <div class="scpiLastPrice">
            <div class="scpiCurrency">{{ currency }}</div>
            <div class="scpiPrice">{{ price }}</div>
        </div>
        <div class="scpiPriceChange">
            <ValueAndChange v-bind="dayGain" :hideValue="$vuetify.breakpoint.smAndDown" class="scChange"></ValueAndChange>
        </div>
    </div>
</template>

<script>
import StockPriceInfos from '../../models/StockPriceInfos';
import StockHtmlHelper from '../stocks/StockHtmlHelper';
import ValueAndChange from './ValueAndChange.vue';

export default {
	components: { ValueAndChange },
    props: {
        stockPriceInfos: { type: StockPriceInfos }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        currency() {
            const currency = this.stockPriceInfos.currency || ""
            return currency.toUpperCase()
        },
        price() {
            return this.stockHtmlHelper.formatPriceWithDigit(this.stockPriceInfos.price, 2)
        },
        dayGain() {
            return this.stockPriceInfos.dayGain
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.stockCardPriceInfos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .scpiLastPrice {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 0.9em;
        .scpiCurrency {
            font-size: 0.7em;
            color: #777;
        }
        .scpiPrice {
            font-weight: bold;
        }
    }
    .scpiPriceChange {
        .scChange {
            font-size: 0.9em;
        }
        .scIcon {
            position: absolute;
            bottom: 3px;
            right: 3px;
            font-size: 16px;
            rotate: -15deg;
        }
    }
    @media screen and (max-width: $small) {
        .scpiLastPrice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9em;
            gap: 2px;
            .scpiPrice {
                flex: 1;
            }
        }
        .scpiPriceChange {
        }
    }
}
</style>