<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="900"
    >
        <template v-slot:activator="{ on }">
            <v-btn v-if="loggedIn"
                color="primary"
                icon
                v-on="on">
                <font-awesome-icon :icon="icons.help" class="fa-2x" />
            </v-btn>
        </template>
        <div class="helpBox">
            <v-card>
                <v-toolbar dark class="dialog-toolbar-color">
                    <v-toolbar-title><font-awesome-icon :icon="icons.help" class="fa-lg mr-2" />{{$t('help.title')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="dialog=false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="px-1 px-sm-4">
                    <div class="helpExample">
                        <div class="helpTitle">{{$t('help.example.title')}}</div>
                        <div class="sscInfos">
                            <div class="ssci-side">
                                <div class="ssci-part">1</div>
                                <div class="ssci-part">3</div>
                            </div>
                            <div class="ssci-center">
                                <ScoredStockCard v-if="stock" :key="`${stock.id}-help`" :entry="stock" :demo="true"></ScoredStockCard>
                            </div>
                            <div class="ssci-side">
                                <div class="ssci-part">2</div>
                                <div class="ssci-part">4</div>
                            </div>
                        </div>
                        <div class="helpLegend">
                            <div class="le-section"></div>
                            <div class="le-section">
                                <div class="le-sectionLabel">1</div>
                                <div class="le-sectionEntries">
                                    <div class="legendEntry">{{$t('help.example.1')}}</div>
                                </div>
                            </div>
                            <div class="le-section">
                                <div class="le-sectionLabel">2</div>
                                <div class="le-sectionEntries">
                                    <div class="legendEntry">{{$t('help.example.2')}}</div>
                                    <div class="legendEntry">
                                        <div class="le-split2col">
                                            <div class="le-col">
                                                <div class="le-col-header">{{$t('help.example.buyDesc')}}</div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t800 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t800.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t800.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t600 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t600.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t600.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t000 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t000.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t000.score')}}</div>
                                                </div>
                                            </div>
                                            <div class="le-col">
                                                <div class="le-col-header">{{$t('help.example.sellDesc')}}</div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t800 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t800sell.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t800.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t600 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t600sell.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t600.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t000 fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t000sell.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t000.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t400-sell fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t400sell.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t400sell.score')}}</div>
                                                </div>
                                                <div class="le-col-subinfo">
                                                    <div class="le-ssub1">
                                                        <font-awesome-icon :icon="icons.circle" class="t200-sell fa-lg"></font-awesome-icon><span class="le-subinfo-label">{{$t('help.example.t200sell.text')}}</span>
                                                    </div>
                                                    <div class="le-ssub2">{{$t('help.example.t200sell.score')}}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="le-section">
                                <div class="le-sectionLabel">3</div>
                                <div class="le-sectionEntries">
                                    <div class="legendEntry">{{$t('help.example.3')}}</div>
                                </div>
                            </div>
                            <div class="le-section">
                                <div class="le-sectionLabel">4</div>
                                <div class="le-sectionEntries">
                                    <div class="legendEntry">
                                        <div class="le-subinfo">{{$t('help.example.4')}}</div>
                                        <div class="le-subinfo"><font-awesome-icon :icon="icons.intraday"></font-awesome-icon><span class="le-subinfo-label">{{$t('entry.intraday')}}</span></div>
                                        <div class="le-subinfo"><font-awesome-icon :icon="icons.eod"></font-awesome-icon><span class="le-subinfo-label">{{$t('entry.eod')}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="about">
                        <div class="poweredby" @click="clickSynaxy">
                            <span>{{$t('Home.welcome.footer.poweredBy')}}</span>
                            <v-img :src="require('@/assets/coloredLogo_blackText.png')" position="left center" contain width="138" height="45" alt="Synaxy"/>
                        </div>
                        <div class="aboutText">{{$t('help.about.text')}}</div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div class="versionInfos">
                        <AppVersion></AppVersion>
                    </div>
                    <v-row align="center" justify="end">
                        <v-btn
                            color="secondary"
                            fab
                            small
                            dark
                            @click="dialog = false"
                        >
                            <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
                        </v-btn>
                        <!-- <v-btn @click="dialog = false">{{$t('help.buttons.close')}}</v-btn> -->
                    </v-row>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import ScoredStockCard from '../stocks/ScoredStockCard.vue'
import { topComputed } from "@/store/helpers/TopPicksHelper"
import { authComputed } from '@/store/helpers/AuthenticationHelper'
import AppVersion from '@/components/controls/AppVersion'
import StockService from '@/services/StockService'
import StockHtmlHelper from '../stocks/StockHtmlHelper'
import { faMoon, faSun, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
export default {
    name: 'HelpDialog',
    data: () => ({
        dialog: undefined,
        stock: undefined,
        icons: {
            eod: faMoon,
            intraday: faSun,
            circle: faCircle,
            help: faInfoCircle,
            close: faTimes
        }
    }),
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    components: {
        ScoredStockCard,
        AppVersion
    },
    computed: {
        ...topComputed,
        ...authComputed
    },
    methods: {
        getCleanScore() {
            return this.stockHtmlHelper.cleanScore(this.stock.highScore);
        },
        clickSynaxy() {
            window.open('https://www.synaxy.com/', '_blank')
        }
    },
    watch: {
        dialog(val) {
            if (val && !this.stock) {
                const example = this.top1Buy;
                this.stock = example || StockService.getStock({ symbol: 'AAPL', plateformCustomExchange: 'US' }).then((stock) => {
                    if (stock !== undefined) {
                        this.stock = stock;
                    }
                }).catch(err => { console.error(err); });
            }
        }
    }
}
</script>

<style scoped>
.helpDialog {
    max-width: 900px;
}
.helpBox .theme--light.v-card > .v-card__text {
    color: rgba(0,0,0,0.8) !important;
}
.helpExample {
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.helpTitle {
    font-size: larger;
    padding-bottom: 10px;
}
.sscInfos {
    width: 100%;
    display: flex;
}
.ssci-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 5px;
}
.ssci-center {
    flex: 1;
    border: solid 1px #CCC;
}
.ssci-part {
    border: solid 2px var(--agora);
    color: var(--agora);
    border-radius: 50%;
    text-align: center;
    padding: 3px 10px;
    font-weight: bold;
}
.helpLegend {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
.le-section {
    display: flex;
    padding: 5px 0;
    border-bottom: solid 1px #CCC;
}
.le-sectionLabel{
    color: var(--agora);
    display: flex;
    align-items: center;
    width: 25px;
    font-weight: bold;
}
.le-sectionEntries {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.legendEntry {
    display: flex;
    align-items: center;
    padding: 5px;
}
.legendEntry.flexcol {
    flex-direction: column;
    align-items: flex-start;
}
.le-split2col {
    display: flex;
    flex-grow: 1;
}
.le-col {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.le-col:not(:last-of-type) {
    margin-right: 20px;
}
.le-col-header {
    font-weight: 500;
    background-color: var(--lightBG);
    padding: 8px;
    margin-bottom: 8px;
}
.le-col-subinfo {
    display: flex;
    align-items: center;
}
.le-col-subinfo:not(:last-of-type) {
    margin-bottom: 5px;
}
.le-subinfo {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.legendEntry.flexcol .le-subinfo {
    margin-bottom: 5px;
}
.le-subinfo-label{
    margin-left: 5px;
}
.le-ssub1 {
    width: 250px;
}
.le-ssub2 {
    flex:1;
}
.about {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.poweredby {
    font-size: small;
    display: flex;
    align-items: center;
    gap: 5px;
}
.poweredby:hover {
    cursor: pointer;
}
.versionInfos {
    position: absolute;
    bottom: 10;
}
@media screen and (max-width: 750px) {
    .helpDialog {
        width: 100%;
    }
    .helpExample {
        padding: 0 0 15px;
    }
    .helpTitle {
        padding: 10px;
    }
    .helpLegend {
        margin-top: 5px;
    }
    .ssci-part {
        border-width: 1px;
        padding: 3px 5px;
    }
    .ssci-center {
        width: 0px;
    }
    .legendEntry {
        flex-direction: column;
        align-items: flex-start;
    }
    .le-split2col {
        flex-direction: column;
        width: 100%;
    }
    .le-col:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .le-ssub1 {
        width: 220px;
        font-size: smaller;
    }
    .le-ssub2 {
        font-size: smaller;
    }
}
</style>
