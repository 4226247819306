<template>
  <div class="emptyList">{{$t("Home.dashboard.summary.emptyList")}}</div>
</template>

<script>
export default {
    name: 'EmptySummaryList'
}
</script>

<style lang="scss" scoped>
.emptyList{
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: var(--midGray);;
    background-color: #F7F7F7;
    @media screen and (max-width: 750px) {
      & {
        min-height: 100px;
      }
    }
}
</style>
