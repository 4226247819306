<template>
    <div class="simplePage">
        <v-card class="simplePageCard" outlined>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon x-large color="orange darken-4">mdi-email-arrow-right</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="headline mb-1">{{$t('register.activationEmailSent.title')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-card-subtitle v-html="$t('register.activationEmailSent.text', [this.email])"></v-card-subtitle>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="goHome">{{$t('register.accountActivation.success.goHomeButton')}}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ['email'],
    methods: {
        goHome() {
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style scoped src="@/assets/css/simplePage.css">
</style>
<style scoped>
>>>.email {
    font-weight: bold;
}
</style>
