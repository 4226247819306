<template>
    <div class="stockMetas">
        <div class="metasCol">
            <div class="meta">
                <div class="metaName">{{ $t('entry.previousClose') }}</div>
                <div class="metaValue">{{prevClose}}</div>
            </div>
            <div class="meta">
                <div class="metaName">{{ $t('entry.open') }}</div>
                <div class="metaValue">{{openPrice}}</div>
            </div>
        </div>
        <div class="metasCol">
            <div class="meta">
                <div class="metaName">{{ $t('entry.oneDayRange') }}</div>
                <div class="metaValue">{{dayRange}}</div>
            </div>
            <div class="meta">
                <div class="metaName">{{ $t('entry.oneYearRange') }}</div>
                <div class="metaValue">{{oneYearRange}}</div>
            </div>
        </div>
        <div class="metasCol">
            <div class="meta">
                <div class="metaName">{{ $t('entry.marketCap') }}</div>
                <div class="metaValue">{{marketCap}}</div>
            </div>
            <div class="meta">
                <div class="metaName">{{ $t('entry.dividend') }}</div>
                <div class="metaValue">{{dividend}}</div>
            </div>
        </div>
        <div class="metasCol">
            <div class="meta">
                <div class="metaName">{{ $t('entry.oneYearTargetEst') }}</div>
                <div class="metaValue">{{oneYearTargetEst}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import StockHtmlHelper from './StockHtmlHelper';

export default {
    props: ['stock'],
    data() {
        return {
            stockHtmlHelper: undefined
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        prevClose() {
            return this.stock && this.stock.dayStockEvolution.previousClose ? this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.previousClose) : this.$t('dictio.na');
        },
        openPrice() {
            return this.stock && this.stock.dayStockEvolution.dayOpen ? this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayOpen) : this.$t('dictio.na');
        },
        dayRange() {
            if (this.stock && this.stock.dayStockEvolution.dayLow && this.stock.dayStockEvolution.dayHigh) {
                return `${this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayLow)} - ${this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayHigh)}`;
            }
            else {
                return this.$t('dictio.na');
            }
        },
        oneYearRange() {
            if (this.stock && this.stock.mainFundamentals.the52WeekLow && this.stock.mainFundamentals.the52WeekHigh) {
                return `${this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.the52WeekLow)} - ${this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.the52WeekHigh)}`;
            }
            else {
                return this.$t('dictio.na');
            }
        },
        marketCap() {
            if (this.stock) {
                const mcValUnit = this.stockHtmlHelper.simplifyUnit(this.stock.marketCap);
                return `${mcValUnit.val}${mcValUnit.unit ? this.$t(`units.${mcValUnit.unit}`) : ''}`;
            } else {
                return this.$t('dictio.na');
            }
        },
        dividend() {
            return this.stock ? this.stock.dividendInfo.dividendYield ? this.stock.dividendInfo.dividendYield : this.$t('dictio.na') : this.$t('dictio.na');
        },
        oneYearTargetEst() {
            return this.stock ? this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.wallStreetTargetPrice) : this.$t('dictio.na');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.stockMetas {
    flex: 1;
    display: flex;
    padding: 15px;
    font-size: 0.8em;
    .metasCol {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .meta {
            flex: 1;
            .metaName {
                text-transform: uppercase;
            }
            .metaValue {
                font-weight: bold;
            }
        }
    }
    @media screen and (max-width: $small) {
        flex-direction: column;
        gap: 10px;
        .metasCol {
            flex-direction: row;
        }
    }
}
</style>