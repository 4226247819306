var StockMixin = {
    computed: {
        stockId() {
            return this.stock ? this.stock.stockId : ''
        },
        stock() {
            return this.$store.getters.completeStockInfo;
        },
        stockName() {
            return this.stock ? this.stock.companyName : ''
        },
        stockSymbol() {
            return this.stock ? this.stock.symbol : ''
        },
        lastPrice() {
            return this.stock ? this.stock.lastPrice : ''
        },
        stockChangeInfo() {
            if (!this.stock) return {}
            return {
                changeAmount: this.stock.change,
                changePercent: this.stock.changePercent
            }
        }
    }
}

export default StockMixin;