<template>
    <div :class="`featureCard${even ? ' even' : ''}`">
        <div class="featureCardImg">
            <div class="fcImg">
                <v-img :src="imgSrc"></v-img>
            </div>
        </div>
        <div class="featureCardDesc">
            <div class="fcTitle">{{$t(`${resKey}.title`)}}</div>
            <div class="fcText">{{ $t(`${resKey}.text`) }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        img: {
            type: String,
            default: '/images/target.png'
        },
        resKey: {
            type: String
        },
        even: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            marketingImg: '/images/buylist1-fr.png'
        }
    },
    computed: {
        imgSrc() {
            return `${this.img}-${this.$i18n.locale}.png`
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.featureCard {
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 30px 50px;
    background-color: rgba(0, 0, 0, 0.4);
    height: 400px;
    width: 70vw;
    &.even {
        flex-direction: row-reverse;
        .featureCardImg {
            .fcImg {
                left: unset;
                right: 50%;
                transform: translate(50%, -50%);
                &:hover {
                    transform: scale(1.4) translate(50%, -40%);
                }
            }
        }
    }
    .featureCardImg {
        flex: 1;
        position: relative;
        height: 100%;
        .fcImg {
            max-width: 700px;
            background-color: rgba(255, 255, 255, 0.3);
            padding: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.6s ease-in-out;
            &:hover {
                transform: scale(1.4) translate(-50%, -40%);
            }
        }
    }
    .featureCardDesc {
        font-size: 1.5rem;
        max-width: 750px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .fcTitle {
            font-size: 2rem;
        }
        .fcText {
            font-size: 1.5rem;
        }
    }
    @media screen and (max-width: 750px) {
        padding: 20px;
        flex-direction: column;
        width: 100%;
        &.even {
            flex-direction: column;
            .featureCardImg {
                .fcImg {
                    left: unset;
                    right: unset;
                    transform: unset;
                    &:hover {
                        transform: scale(1.4);
                    }
                }
            }
        }
        .featureCardImg {
            width: 100%;
            .fcImg {
                bottom: -30px;
                top: unset;
                left: unset;
                transform: unset;
                padding: 5px;
                background-color: #FFF;
                max-width: 100%;
                &:hover {
                    transform: scale(1.4);
                }
            }
        }
        .featureCardDesc {
            font-size: 1rem;
            .fcTitle {
                font-size: 1.3rem;
            }
            .fcText {
                font-size: 1rem;
            }
        }
    }
}
</style>