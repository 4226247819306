<template>
    <v-dialog v-model="dialog" max-width="1000px">
        <div class="stockFullCardCont">
            <div class="dialogHeader">{{ $t('UserStockWatchingList.portfolio.addSymbol') }}</div>
            <div class="dialogBody">
                <StockSearcher v-if="!showStockInfos" @symbolSelected="symbolSelected" @symbolCleared="symbolCleared"></StockSearcher>
                <div v-else class="infoStockCard">
                    <v-skeleton-loader v-if="showStockLoader" type="list-item-two-line"></v-skeleton-loader>
                    <PricedStockCard v-if="!showStockLoader && showStockInfos" :entry="selectedStock" :closable="true" @close="closePSC"></PricedStockCard>
                </div>
                <div v-if="showStockInfos">
                    <PortfolioStockLotWidget v-if="$vuetify.breakpoint.smAndUp" :portfolioId="portfolioId" :stockLots="[]" :stock="selectedStock" @portfolioUpdated="portfolioUpdated"></PortfolioStockLotWidget>
                    <PortfolioStockLotWidgetMobile v-else :portfolioId="portfolioId" :stockId="selectedStock.stockId" :stockinfo="selectedStock"></PortfolioStockLotWidgetMobile>
                </div>
            </div>
            <div class="dialogFooter">
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="close"
                    class="ml-3"
                >
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg" color="primary"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import StockService from '../../services/StockService';
import StockSearcher from '../controls/StockSearcher.vue'
import PricedStockCard from '../stocks/PricedStockCard.vue';
import PortfolioStockLotWidget from './PortfolioStockLotWidget.vue';
import PortfolioStockLotWidgetMobile from './PortfolioStockLotWidgetMobile.vue';
export default {
    props: ['portfolioId'],
    components: { StockSearcher, PricedStockCard, PortfolioStockLotWidget, PortfolioStockLotWidgetMobile },
    data() {
        return {
            dialog: false,
            icons: {
                close: faTimes
            },
            showStockLoader: false,
            showStockInfos: false,
            foundSymbol: undefined,
            selectedStock: undefined
        }
    },
    computed: {
        stockSelected() {
            return this.selectedStock
        }
    },
    methods: {
        symbolSelected({ normalizedTicker, exchange }) {
            this.foundSymbol = normalizedTicker;
            this.showStockLoader = true;
            StockService.getStock({ symbol: this.foundSymbol, plateformCustomExchange: exchange }).then((stock) => {
                if (stock !== undefined) {
                    this.showStockLoader = false;
                    this.showStockInfos = true;
                    this.selectedStock = stock;
                }
            }).catch(err => { console.error(err); });
        },
        symbolCleared() {
            // this.showStockInfos = false;
            // this.editedItem = {};
        },
        portfolioUpdated({ updatedPortfolio }) {
            if (updatedPortfolio.stocks.length === 0) {
                this.close()
            }
        },
        show() {
            this.showStockInfos = false
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        closePSC() {
            this.showStockInfos = false
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    margin: 5px;
}
.stockFullCardCont {
    background-color: #FFF;
    border-radius: 10px;
    >* {
        padding: 15px;
    }
    .dialogHeader {
        font-size: 1.5rem;
    }
    .dialogBody {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .dialogFooter {
        display: flex;
    }
    @media screen and (max-width: 750px) {
        .dialogHeader {
            padding: 12px;
        }
        .dialogBody {
            padding: 0 12px;
        }
        .dialogFooter {
            padding: 12px;
        }
    }
}
</style>
