import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.confirmationTitle))]),_vm._l((_vm.confirmationText),function(txt,i){return _c('div',{key:i,staticClass:"confirmText",domProps:{"innerHTML":_vm._s(txt)}})}),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"mr-5",attrs:{"color":"primary","fab":""},on:{"click":_vm.confirm}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-2x",attrs:{"icon":_vm.icons.confirm}})],1),_c(VBtn,{attrs:{"color":"primary","fab":""},on:{"click":_vm.deny}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-2x",attrs:{"icon":_vm.icons.deny}})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }