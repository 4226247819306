import serverService from './ApiWrapper'
import UserListEntryConverter from '../infra/converters/UserListEntryConverter'
import ServerHelper from './ServerHelper'

export function getAllListWithEntries({ userId, listType }) {
    return new Promise((resolve) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        serverService.post(`/api/${ctrl}/V2/GetAllListWithEntries`, { userId }).then((response) => {
            response.lists.forEach(list => {
                list.type = listType;
                const entriesConv = list.entries.map(entry => {
                    const userListEntry = UserListEntryConverter.convert(entry);
                    userListEntry.listId = list.id;
                    return userListEntry;
                });
                list.entries = entriesConv;
            });
            resolve(response);
        });
    });
}

export function createNewList({ userId, listName, listDescription, listType }) {
    return new Promise((resolve, reject) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        serverService.post(`/api/${ctrl}/V2/CreateNewList`, { userId, listName, listDescription }, true).then((response) => {
            response.data.list.type = listType;
            const convertedResponse = {
                list: response.data.list,
                usage: response.additionalData.subscriptionInfo.usage
            };
            resolve(convertedResponse);
        }).catch(error => {
            reject(error);
        });
    });
}

export function updateListInfo({ listId, listName, listDescription, listType }) {
    const ctrl = ServerHelper.getStockListInfos(listType);
    return serverService.post(`/api/${ctrl}/V2/UpdateListInfo`, { listId, newListName: listName, newListDescription: listDescription })
}

export function deleteList({ listId, listType }) {
    return new Promise((resolve, reject) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        return serverService.post(`/api/${ctrl}/V2/DeleteList`, { listId }, true).then((response) => {
            const convertedResponse = {
                usage: response.additionalData.subscriptionInfo.usage
            };
            resolve(convertedResponse);
        }).catch(error => {
            reject(error);
        });
    });
}
