<template>
    <v-card-text class="pa-0 inviteRow">
        <div class="singleLineForm">
            <v-text-field name="firstname"
                v-model="firstname"
                :label="$t('admin.body.invitations.newInvitation.firstname')"
                type="text"
                outlined
                dense
                hide-details="auto"
                autocomplete="new-password">
            </v-text-field>
            <v-text-field name="lastname"
                v-model="lastname"
                :label="$t('admin.body.invitations.newInvitation.lastname')"
                type="text"
                outlined
                dense
                hide-details="auto"
                autocomplete="new-password">
            </v-text-field>
            <v-text-field name="email"
                v-model="email"
                :label="$t('admin.body.invitations.newInvitation.email')"
                type="text"
                outlined
                dense
                hide-details="auto"
                autocomplete="new-password">
            </v-text-field>
            <div class="formBtns">
                <div class="d-flex align-center">
                    <div class="d-none d-sm-flex mr-2">{{this.$t('admin.body.invitations.newInvitation.plan')}}</div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="blue-grey"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                            >
                                <font-awesome-icon :icon="icons[selectedPlan]" class="mr-2"></font-awesome-icon>
                                <span>{{$t(`subscriptions.plans.${selectedPlan}.title`)}}</span>
                                <font-awesome-icon :icon="icons.down" class="ml-2"></font-awesome-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(plan, index) in plans"
                                :key="index"
                                @click="setPlan(plan)">
                                <v-list-item-icon>
                                    <font-awesome-icon :icon="icons[plan]"></font-awesome-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(`subscriptions.plans.${plan}.title`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div class="d-none d-sm-flex mr-2">{{this.$t('locale.lang')}}</div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                dark
                                color="blue-grey"
                            >
                                <font-awesome-icon :icon="icons.lang" class="mr-2"/>
                                {{selectedLangLabel}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group v-model="selectedLang" color="primary">
                                <v-list-item
                                    v-for="key in locales"
                                    :key="`lang${key}`"
                                >
                                    <v-list-item-title>{{ key }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
                <v-btn
                    color="primary"
                    fab
                    small
                    dark
                    :loading="sendingInvitation"
                    @click="sendInvitation()"
                >
                    <font-awesome-icon :icon="icons.send" class="fa-lg"></font-awesome-icon>
                </v-btn>
            </div>
            <SySnackbar ref="iSySnackbar"></SySnackbar>
        </div>
    </v-card-text>
</template>

<script>
import {
    faPaperPlane,
    faCaretDown
} from "@fortawesome/pro-solid-svg-icons";
import { faLanguage } from "@fortawesome/pro-light-svg-icons";
import InvitationService from '../../services/InvitationService'
import planHelperMixin from "../../helpers/planHelperMixin";
import SySnackbar from '../controls/SySnackbar.vue';
export default {
  components: { SySnackbar },
    mixins: [planHelperMixin],
    data() {
        return {
            firstname: "",
            lastname: "",
            email: "",
            icons: {
                send: faPaperPlane,
                down: faCaretDown,
                lang: faLanguage
            },
            selectedPlan: "base",
            plans: [],
            invitationService: undefined,
            selectedLang: undefined,
            sendingInvitation: false,
            locales: ["FR", "EN"]
        };
    },
    created() {
        this.invitationService = new InvitationService();
        this.invitationService.getPlans().then((response) => {
            this.plans = response;
            this.selectedPlan = this.plans[0]
        });
    },
    computed: {
        selectedLangLabel() {
            return this.selectedLang !== undefined ? this.locales[this.selectedLang] : this.$i18n.locale;
        }
    },
    methods: {
        setPlan(plan) {
            this.selectedPlan = plan
        },
        sendInvitation() {
            this.sendingInvitation = true
            const data = { firstname: this.firstname, lastname: this.lastname, email: this.email, plan: this.selectedPlan, twoLettersPreferredLanguage: this.selectedLangLabel };
            this.invitationService.send(data).then(invitations => {
                this.$refs.iSySnackbar.success({ text: this.$t('admin.body.invitations.newInvitation.success'), duration: 3000 })
                this.clearForm();
                this.$emit("invitationSent", { invitations });
            }).catch(errors => {
                const text = errors.map(e => this.$t(`alerts.codes.${e.errorCode}`)).join(' ')
                this.$refs.iSySnackbar.error({ text, duration: 3000 })
            }).finally(() => {
                this.sendingInvitation = false
            });
        },
        clearForm() {
            this.firstname = ""
            this.lastname = ""
            this.email = ""
            this.selectedPlan = "base"
        }
    }
}
</script>

<style>
.inviteRow {
    display: flex;
}
.singleLineForm {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: auto;
}
.singleLineForm > *:not(:last-of-type){
    margin-bottom: 10px;
}
.formBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.formBtns>*:not(:last-child) {
    margin-right: 10px;
}
@media screen and (max-width: 750px) {
    .singleLineForm {
        width: 100%;
    }
}
</style>
