<template>
    <div class="dashboardOverview">
        <div class="doCont">
            <div class="doPortfolios">
                <div class="doTotal">
                    <div class="doTitleCont">
                        <div class="doTitle">{{ $t('Home.dashboard.overview.allPortfolios') }}</div>
                    </div>
                    <div class="doValueCont">
                        <div class="doValueInfos">
                            <div class="doCurrency">{{ preferredCurrency }}</div>
                            <div class="doValue">{{ allPortfoliosTotal }}</div>
                        </div>
                        <ValueAndChange v-bind="totalChange" class="doChange"></ValueAndChange>
                    </div>
                </div>
                <div class="doMovers">
                    <div class="doSubtitle">{{ $t('Home.dashboard.overview.topMovers') }}</div>
                    <div class="overviewPortfolioCardList">
                        <OverviewPortfolioCard v-for="(s, i) in topPortfolioMovers" :stock="s" type="portfolio" :key="`tp${i}`" @viewStockLot="viewStockLot"></OverviewPortfolioCard>
                    </div>
                </div>
            </div>
            <div class="doLists" v-if="hasBestSuggestions">
                <div class="doTitle">{{ $t('Home.dashboard.overview.bestSuggestions') }}</div>
                <div class="doBests">
                    <OverviewListCard v-for="(s, i) in getTopBuys" :stock="s" type="buy" :key="`tb${i}`" @viewStock="viewStock"></OverviewListCard>
                    <OverviewListCard v-for="(s, i) in getTopSells" :stock="s" type="sell" :key="`ts${i}`" @viewStock="viewStock"></OverviewListCard>
                </div>
            </div>
        </div>
        <ViewStockDialogMobile ref="viewStockDialog" @notify="showNotification"></ViewStockDialogMobile>
        <PortfolioEditStockDialog ref="portfolioEditStockDialog" :portfolioId="selectedPortfolioId" :stock="selectedPortfolioStock"></PortfolioEditStockDialog>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
    </div>
</template>

<script>
import SySnackbar from '../../controls/SySnackbar.vue';
import ViewStockDialogMobile from '../../stocks/ViewStockDialogMobile.vue';
import DashboardOverviewMixin from './DashboardOverviewMixin'
import OverviewListCard from './OverviewListCard.vue';
export default {
    mixins: [DashboardOverviewMixin],
    components: { OverviewListCard, ViewStockDialogMobile, SySnackbar }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/breakpoints.scss";
.dashboardOverview {
    font-family: "Roboto", sans-serif;
    padding: 10px;
    .doCont {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: #FFF;
        &>* {
            flex: 1;
        }
        .doLists {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .doTitle {
                padding: 5px 10px;
            }
            .doBests {
                display: flex;
                gap: 10px;
                &>* {
                    flex: 1;
                }
            }
        }
        .doPortfolios {
            display: flex;
            flex-direction: column;
            color: #000;
            background-color: #FFF;
            padding: 5px;
            &>* {
                flex: 1;
            }
            .doTotal {
                display: flex;
                gap: 10px;
                justify-content: space-between;
                .doTitleCont {
                    font-size: 1em;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .doTitle {
                        font-weight: 600;
                    }
                }
                .doValueCont {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .doValueInfos {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .doValue {
                            font-size: 1.8em;
                            font-weight: bold;
                        }
                        .doCurrency {
                            font-size: 0.8em;
                            color: #777;
                        }
                    }
                }
            }
            .doMovers {
                display: flex;
                flex-direction: column;
                gap: 5px;
                .doSubtitle {
                    font-size: 0.8em;
                    color: #777;
                }
                .overviewPortfolioCardList {
                    display: flex;
                    gap: 5px;
                    &>* {
                        flex: 1;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $small) {}
}
</style>