import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import vuetify from './plugins/vuetify'
import { i18n } from './i18n'
import storageService from './services/StorageService'
import PreferencesService from './services/PreferencesService'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import AuthenticatedUser from './models/AuthenticatedUser'
import titleMixin from './views/titleMixin'
import AuthUserJWT from "./infra/AuthUserJWT"
import { StripePlugin } from '@vue-stripe/vue-stripe';
import VueApexCharts from 'vue-apexcharts'
import apiWrapper from './services/ApiWrapper'

apiWrapper.post('/api/Subscription/getPublicKey').then(response => {
    const options = {
        pk: response.publicKey,
        stripeAccount: process.env.STRIPE_ACCOUNT,
        apiVersion: process.env.API_VERSION,
        locale: process.env.LOCALE
    };
    Vue.use(StripePlugin, options)
})

Vue.use(VueApexCharts)
//Vue.use(require('vue-script2'))

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('apexchart', VueApexCharts)
Vue.mixin(titleMixin)
Vue.mixin({
    computed: {
        isPhone() {
            return this.$vuetify.breakpoint.xs;
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    }
})
Vue.use(VueReCaptcha, { siteKey: '6Ld_09AZAAAAACeeLilid2feOtfQ0WpMyWN1fnwK' })

Vue.config.productionTip = false

new Vue({
    router,
    store,
    created() {
        this.setUserInfoIfNeeded();
        this.setUserExpirationTokenTimer();
        const preferencesService = new PreferencesService();
        this.$i18n.locale = preferencesService.UserLang;
    },
    vuetify,
    i18n,
    render: function (h) { return h(App) },
    computed: {
        userLists() {
            return this.$store.state.list.lists;
        }
    },
    methods: {
        setUserExpirationTokenTimer() {
            const storageAuthUser = storageService.AuthUser;
            if (storageAuthUser) {
                const authUser = new AuthenticatedUser(storageAuthUser);
                if (authUser.expiration) {
                    var delta = new Date(authUser.expiration) - (new Date());
                    var timer = setTimeout(() => {
                        //storageService.deleteAuthUser();
                        this.$store.dispatch('logoff');
                        //this.$router.push({ name: 'Home' });
                    }, delta);
                    this.$store.dispatch('setAuthTokenExpirationTimer', { timer });
                }
            }
        },
        setUserInfoIfNeeded() {
            const storageAuthUser = storageService.AuthUser;
            if (storageAuthUser) {
                const authUser = new AuthenticatedUser(storageAuthUser);
                if (authUser.isExpired()) {
                    storageService.deleteAuthUser();
                }
                else {
                    this.$store.commit('setUser', authUser);
                }
                const authUserJWT = new AuthUserJWT(authUser.token);
                this.$store.commit('setUserRoles', authUserJWT.userRoles);
            }
            this.$store.dispatch('setAllowCookies', { allowCookies: storageService.allowCookies });
        }
    },
    mounted() {
        this.$store.watch(
            (state) => { return state.list.lists.buyLists },
            (newValue, oldValue) => {
                this.$store.dispatch('updateBuysTopList', { list: newValue });
            }
        );
        this.$store.watch(
            (state) => { return state.list.lists.sellLists },
            (newValue, oldValue) => {
                this.$store.dispatch('updateSellsTopList', { list: newValue });
            }
        );
    }
}).$mount('#app')
