<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
        <div class="editCard">
            <div class="title">{{ title }}</div>
            <div class="cardFormCont">
                <v-form ref="cardForm" v-model="valid">
                    <v-text-field
                        name="listName"
                        v-model="listName"
                        hide-details="auto"
                        :label="listNameLabel"
                        counter="50"
                        required
                        :rules="listNameRules"
                    ></v-text-field>
                    <v-text-field
                        name="listDescription"
                        v-model="listDescription"
                        :label="listDescLabel"
                        value=""
                    ></v-text-field>
                    <div v-if="isPortfolio" class="currencySelector">
                        <div class="currencyLabel">{{$t('Home.dashboard.lists.currency')}}</div>
                        <v-select
                            v-model="currencySelected"
                            :items="currencyList"
                            label=""
                            rounded
                            dense
                            outlined
                            :hide-details="true"
                            class="currencyDrop"
                        ></v-select>
                    </div>
                </v-form>
            </div>
            <div class="cardFooter">
                <SaveCancelButtons align="end" :enableSave="valid" @saveAsked="save" @cancelAsked="cancel"></SaveCancelButtons>
            </div>
        </div>
        <Alert ref="iAlert"></Alert>
    </v-dialog>
</template>

<script>
import Alert from '../../../controls/Alert.vue';
import SaveCancelButtons from '../../../controls/SaveCancelButtons.vue'
export default {
    components: { SaveCancelButtons, Alert },
    data() {
        return {
            icons: {},
            list: null,
            type: null,
            dialog: false,
            valid: false,
            listName: null,
            listDescription: null,
            listNameRules: [
                (v) => !!v || this.$t("rules.required"),
                (v) => (v && v.length <= 50) || this.$t("rules.maxXCharacters", 50, { nb: 50 })
            ],
            currencyList: ['CAD', 'USD'],
            currencySelected: 'CAD'
        };
    },
    computed: {
        title() {
            const type = this.type === 'portfolio' ? 'Portfolio' : 'List'
            const action = this.list ? 'edit' : 'addNew'
            return this.$t(`Home.dashboard.lists.${action}${type}`)
        },
        listNameLabel() {
            const name = this.type === "portfolio" ? "portfolio" : "list";
            return this.$t(`Home.dashboard.lists.${name}NamePlaceholder`);
        },
        listDescLabel() {
            const name = this.type === "portfolio" ? "portfolio" : "list";
            return this.$t(`Home.dashboard.lists.${name}DescPlaceholder`);
        },
        isPortfolio() {
            return this.type === 'portfolio'
        }
    },
    methods: {
        show({ list, type }) {
            if (list) {
                this.list = list
                this.listName = list.name
                this.listDescription = list.description
                this.currencySelected = list.currency ? list.currency.toUpperCase() : null
            }
            this.type = type;
            this.dialog = true;
        },
        close() {
            this.$refs.cardForm.reset()
            this.dialog = false;
        },
        save() {
            if (this.type === 'portfolio') {
                this.savePortfolio()
            }
            else {
                this.saveList()
            }
        },
        cancel() {
            this.close()
        },
        savePortfolio() {
            const data = {
                ...(this.list && { portfolioId: this.list.id }),
                portfolioName: this.listName,
                portfolioDescription: this.listDescription,
                currency: this.currencySelected
            }
            const action = this.list ? 'updatePortfolioInfos' : 'createNewPortfolio'
            this.$store.dispatch(action, data).then(result => {
                this.cancel()
            }).catch(error => {
                const code = error.response.status
                const errorCode = code === 401 ? 'listLimitReached' : code
                this.$refs.iAlert.error({ errorCode })
            })
        },
        saveList() {
            const data = {
                ...(this.list && { listId: this.list.id }),
                userId: this.$store.getters.user.userId,
                listName: this.listName,
                listDescription: this.listDescription,
                listType: this.type
            }
            const action = this.list ? 'updateListInfos' : 'createNewList'
            this.$store.dispatch(action, data).then(result => {
                this.cancel()
            }).catch(error => {
                const code = error.response.status
                const errorCode = code === 401 ? 'listLimitReached' : code
                this.$refs.iAlert.error({ errorCode })
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.editCard {
    background-color: #FFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .currencySelector {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        .currencyDrop {
            max-width: min-content;
        }
    }
}
</style>