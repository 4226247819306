<template>
    <v-card outlined class="registerCont">
        <div class="sp-header">
            <div class="sp-header-icon">
                <font-awesome-icon :icon="icons.addUser" class="fa-2x" style="--fa-primary-color: #03A9F4; --fa-secondary-color: #0277BD;" />
            </div>
            <div class="sp-header-title">{{$t('register.notInvited.title')}}</div>
        </div>
        <div class="sp-desc">{{$t('register.notInvited.registerOnInviteOnly')}}</div>
        <div class="sp-desc">{{$t('register.notInvited.askForInvite')}}</div>

        <div>
            <div>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <div class="d-flex flex-column flex-md-row">
                        <v-text-field name="firstname"
                            v-model="input.firstname"
                            clearable
                            :label="$t('register.form.fields.firstname')"
                            type="text"
                            outlined
                            :rules="[rules.required]"
                            :dense="$vuetify.breakpoint.mobile"
                            prepend-inner-icon="mdi-account"
                            class="firstField"
                            tabindex="1"
                            autocomplete="new-password">
                        </v-text-field>
                        <v-text-field name="lastname"
                            v-model="input.lastname"
                            clearable
                            :label="$t('register.form.fields.lastname')"
                            type="text"
                            outlined
                            :rules="[rules.required]"
                            :dense="$vuetify.breakpoint.mobile"
                            prepend-inner-icon="mdi-account"
                            tabindex="2"
                            autocomplete="new-password">
                        </v-text-field>
                    </div>
                    <v-text-field name="email"
                        v-model="input.email"
                        clearable
                        :label="$t('register.form.fields.email')"
                        type="email"
                        outlined
                        :rules="[rules.required, rules.email, uniqueEmail]"
                        :dense="$vuetify.breakpoint.mobile"
                        prepend-inner-icon="mdi-email"
                        tabindex="3"
                        autocomplete="new-password">
                    </v-text-field>

                    <v-textarea
                        outlined
                        :label="$t('register.form.fields.motivation')"
                        :rules="[rules.required]"
                        :dense="$vuetify.breakpoint.mobile"
                        rows="3"
                        tabindex="4"
                        v-model="input.motivation"
                    ></v-textarea>
                    <RecaptchaText></RecaptchaText>
                    <v-btn block color="primary" x-large :dark="!loading" @click="requestInvitationAsked" :loading="loading" :disabled="loading" class="mt-3">{{$t('register.notInvited.buttons.askForInvite')}}</v-btn>
                </v-form>
            </div>
            <div class="signin">{{$t('register.form.footer.alreadyHaveAccount.text')}} <a href="#" @click="loginAsked">{{$t('register.form.footer.alreadyHaveAccount.signIn')}}</a></div>
        </div>
    </v-card>
</template>

<script>
import InvitationService from '../../services/InvitationService';
import RegisterFormMixin from './RegisterFormMixin';
import AutomatedAbuseService from '../../services/AutomatedAbuseService'
export default {
    mixins: [RegisterFormMixin],
    data() {
        return {
            input: {
                motivation: ''
            },
            loading: false
        }
    },
    methods: {
        loginAsked() {
            this.$router.push({ name: 'Login' });
        },
        requestInvitationAsked() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                AutomatedAbuseService.validate({ vueComp: this, action: 'askInvitation' }).then(token => {
                    this.invitationService = new InvitationService();
                    const data = {
                        firstName: this.input.firstname,
                        lastName: this.input.lastname,
                        email: this.input.email,
                        motivation: this.input.motivation,
                        humanActionToken: token,
                        twoLettersPreferredLanguage: this.$i18n.locale
                    };
                    this.invitationService.askForInvitation(data).then(() => {
                        this.$router.push({ name: 'InvitationinRequestReceived', params: { email: this.input.email } });
                    });
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
}
</script>
<style scoped src="@/assets/css/simplePage.css">
</style>
<style scoped>
.registerCont {
    max-width: 700px;
    margin: auto;
    border: none;
}

.signin {
    margin-top: 25px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px 0;
}
.firstField{
    margin-right: 12px;
}

@media only screen and (device-width: 768px) {
/* For general iPad layouts */
    .registerCont {
        padding: 25px;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
}

@media screen and (max-width: 750px) {
    .registerCont {
        padding: 0 15px;
    }
    .firstField{
        margin-right: 0;
    }
    .signin {
        font-size: smaller;
    }
}
</style>
