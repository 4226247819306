import { render, staticRenderFns } from "./VerticalTags.vue?vue&type=template&id=f187334c&scoped=true&"
import script from "./VerticalTags.vue?vue&type=script&lang=js&"
export * from "./VerticalTags.vue?vue&type=script&lang=js&"
import style0 from "./VerticalTags.vue?vue&type=style&index=0&id=f187334c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f187334c",
  null
  
)

export default component.exports