<template>
    <div>
        <div v-if="$vuetify.breakpoint.mobile" class="subscriptionPlanPanel">
            <SubscriptionCardMobile v-for="plan in publicPlans" :key="`sc-${plan.name}`" :plan="plan" :selectedPlan="selectedPlan" @newPlanSelected="newPlanSelected"></SubscriptionCardMobile>
        </div>
        <div v-else class="subscriptionPlanPanel">
            <SubscriptionCard v-for="plan in publicPlans" :key="`sc-${plan.name}`" :plan="plan" :selectedPlan="selectedPlan" @newPlanSelected="newPlanSelected"></SubscriptionCard>
        </div>
    </div>
</template>

<script>
import SubscriptionCard from './SubscriptionCard.vue'
import SubscriptionCardMobile from './SubscriptionCardMobile.vue'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
export default {
    data() {
        return {
            publicPlans: []
        }
    },
    components: {
        SubscriptionCard,
        SubscriptionCardMobile
    },
    created() {
        this.buildPlanList()
    },
    computed: {
        ...authComputed,
        allPlans() {
            return this.$store.getters.getPlans
        },
        myPlan() {
            return this.$store.getters.getMyPlan
        },
        selectedPlan() {
            return this.$store.getters.getSelectedPlan
        }
    },
    methods: {
        newPlanSelected({ plan }) {
            this.$store.dispatch('setSelectedPlan', { plan })
        },
        setAndSortplans(plans) {
            this.publicPlans = plans.sort((plan1, plan2) => {
                if (plan1.displayOrder < plan2.displayOrder) {
                    return -1;
                }
                else {
                    return 1;
                }
            });
        },
        buildPlanList() {
            if (this.allPlans.length === 0) {
                this.$store.dispatch('fetchAllPlans').then((plans) => {
                    this.setAndSortplans(plans)
                })
            }
            else {
                this.setAndSortplans(this.allPlans)
            }
        },
        buildPlan({ plan, currentPlanName }) {
            if (plan.planName === 'base' && ['base', 'pioneer', 'vip'].includes(currentPlanName)) {
                plan.planName = currentPlanName;
                plan.icon = this.$t(`subscriptions.plans.${currentPlanName}.icon`);
                plan.cost = { free: this.$t('subscriptions.plans.price.free') };
            } else if (this.$te(`subscriptions.plans.${plan.planName}`)) {
                plan.icon = this.$t(`subscriptions.plans.${plan.planName}.icon`);
                const p = plan.price.toString().split('.');
                plan.cost = { d: p[0], c: p[1] };
            }
            return plan;
        }
    },
    watch: {
        myPlan() {
            this.buildPlanList()
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/breakpoints.scss";
.subscriptionPlanPanel {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0;
    gap: 30px;
}
@media screen and (max-width: $tablet) {
    .subscriptionPlanPanel {
        padding: 0;
        gap: 10px;
    }
}
</style>