import { i18n } from '@/i18n'
class AlertAdaptor {
    adapt({ type = 'error', code, data }) {
        let alert;
        switch (type) {
            case 'error':
                switch (code) {
                    case 'DemonstrationAccountNotAllowed':
                        alert = new WarningAlert({ code });
                        break;
                    case 'operationsLimitReached':
                        alert = new LimitReachedWarning({ code, data });
                        break;
                    default:
                        alert = new Alert({ code });
                        break;
                }
                break;
            case 'warning':
                alert = new WarningAlert({ code });
                break;
            case 'info':
                alert = new InfoAlert({ code });
                break;
            default:
                alert = new Alert({ code });
                break;
        }
        return alert;
    }
}

class Alert {
    constructor({ type = 'error', code }) {
        this._errorResx = `alerts.codes.${code}`;
        this._type = type;
    }

    _getMsg() {
        // if (this.alert.code) {
        //     return this.$t(`alerts.codes.${this.alert.code}`);
        // }

        return i18n.t(this._errorResx);
    }

    getInfos() {
        return {
            type: this._type,
            errorMsg: this._getMsg()
        };
    }
}

class WarningAlert extends Alert {
    constructor({ code }) {
        super({ type: 'warning', code });
    }
}

class LimitReachedWarning extends WarningAlert {
    constructor({ code, data }) {
        super({ code });
        this._data = data;
    }

    _getMsg() {
        const unit = i18n.t(`units.${this._data.maxUnit}`);
        return i18n.t(this._errorResx, { n: this._data.maxAllowed, u: unit })
    }
}

class InfoAlert extends Alert {
    constructor({ code }) {
        super({ type: 'info', code });
    }
}

export default AlertAdaptor
