<template>
    <div class="listCard" @click="viewList">
        <div class="lcHeader">
            <div class="lcName">{{list.name}}</div>
            <div class="lcDesc">{{list.description}}</div>
        </div>
        <div class="lcBody">
            <div class="lcPart" v-for="(b, i) in sortedBadges" :key="i">
                <v-chip :class="getBadgeBGColor(b)">{{getBadgeLabel(badges[b])}}</v-chip>
            </div>
        </div>
        <font-awesome-icon :icon="icons[listType]" class="fa-fw bgIcon" color="#DDD"></font-awesome-icon>
    </div>
</template>

<script>
import listCardMixin from './ListCardMixin'
export default {
    props: ['list', 'listType'],
    data() {
        return {
        }
    },
    mixins: [listCardMixin],
    computed: {
        sortedBadges() {
            return Object.keys(this.badges).sort(function(a, b) { return a - b; })
        }
    }
}
</script>

<style lang="scss" scoped>
.listCard {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #FFF;
    .lcHeader {
        display: flex;
        flex-direction: column;
        flex: 1;
        z-index: 1;

        .lcName {
            font-size: 1em;
            font-weight: bold;
        }
        .lcDesc {
            font-size: 0.7em;
            color: #777;
        }
    }
    .lcBody {
        width: 150px;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        z-index: 1;
        .lcPart {
        }
    }
    .bgIcon {
        position: absolute;
        bottom: -10px;
        right: 160px;
        font-size: 50px;
        rotate: -15deg;
    }
}
</style>