import {
    faArrowToBottom,
    faArrowToTop,
    faCircle
} from "@fortawesome/pro-solid-svg-icons";
import StockHtmlHelper from '../../stocks/StockHtmlHelper'

var SellEditionPanelMixin = {
    props: ['thresholds'],
    data() {
        return {
            valid: false,
            icons: {
                bottom: faArrowToBottom,
                top: faArrowToTop,
                circle: faCircle
            },
            stockHtmlHelper: undefined,
            rules: {
                required: value => !!value || this.$t('EditorPanel.errors.required'),
                isPrice: value => {
                    const pattern = /^(\d+(?:[.,]\d{1,4})?)$/;
                    return (
                        pattern.test(value) || !value || this.$t('EditorPanel.errors.invalidPrice')
                    );
                }
            },
            thresholdFieldType: this.$vuetify.breakpoint.mobile ? 'number' : 'text'
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    methods: {
        getThresholdColor(score) {
            return this.stockHtmlHelper.getBadgeSellColor(score);
        },
        validateForm() {
            return this.$refs.sellEditForm.validate();
        },
        isFormValid() {
            return this.valid;
        },
        resetForm() {
            this.$refs.sellEditForm.reset();
        },
        inputChanged() {
            this.$emit('inputChanged', { valid: this.isFormValid() });
        },
        keyUp(e) {
            if (e.keyCode === 13) {
                this.$emit('submitAsked', { valid: this.isFormValid() });
            }
        }
    },
    watch: {
        valid(val) {
            this.$emit('isFormValid', { valid: val });
        }
    }
}

export default SellEditionPanelMixin
