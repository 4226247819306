<template>
    <div class="subscription cancel">CANCEL!!!</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
