<template>
    <div @click="selectPlan()" :class="`subscriptionCard${loggedIn && isSelected ? ' selected' : ''}${isCurrentPlan ? ' current' : ''}`">
        <div class="sc-header">
            <div v-if="isCurrentPlan" class="sc-header-userSelected-icon">
                {{$t(`subscriptions.plans.current`)}}
            </div>
            <div class="sc-header-icon">
                <font-awesome-icon :icon="icons[plan.name]"></font-awesome-icon>
            </div>
            <div class="sc-header-title">
                {{$t(`subscriptions.plans.${plan.name}.title`)}}
            </div>
            <div v-if="isCurrentPlanCancelled" class="sc-header-subtitle">Annulé*</div>
        </div>
        <div class="sc-body">
            <div class="sc-desc">
                <div v-for="(item, i) in descriptionKeys" :key="i">
                    <div class="sc-desc-item" v-if="isKeyActive(item)">
                        <div class="sc-desc-part"><font-awesome-icon :icon="icons.check" style="color:dodgerblue;margin-right:5px"></font-awesome-icon></div>
                        <div class="sc-desc-part">{{$tc(`subscriptions.plans.description.${item}`, plural(item), { nb: plan.limit[item] })}}</div>
                    </div>
                </div>
            </div>
            <SubscriptionEnd v-if="isCurrentPlan" class="mb-3"></SubscriptionEnd>
            <div class="sc-pricing">
                <div v-if="isFreePlan" class="sc-price"><span class="dollars">{{$t('subscriptions.plans.price.free')}}</span></div>
                <div v-else class="sc-price"><span class="dollars">{{plan.cost.d}}</span><span class="cents">{{$t(`subscriptions.plans.price.separator`)}}{{plan.cost.c}} {{$t(`subscriptions.plans.price.currency`)}}</span></div>
                <div v-if="isFreePlan" class="sc-reccuring">{{$t(`subscriptions.plans.reccuring.always`)}}</div>
                <div v-else class="sc-reccuring">{{$t(`subscriptions.plans.reccuring.perMonth`)}}</div>
            </div>
        </div>
        <div class="sc-footer"></div>
    </div>
</template>

<script>
import SubscriptionCardMixin from './SubscriptionCardMixin'
import SubscriptionEnd from './SubscriptionEnd.vue'
export default {
    mixins: [SubscriptionCardMixin],
    components: { SubscriptionEnd }
}
</script>

<style scoped src="@/assets/css/subscriptionCardMobile.css">
</style>
