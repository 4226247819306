<template>
    <div :class="`priceChanges ${getChangeColor}`">
        <div class="change">{{formatChange}}</div>
        <font-awesome-icon :icon="getChangeIcon" ></font-awesome-icon>
        <div class="changePrct">{{formatChangePrct}}</div>
    </div>
</template>

<script>
import StockHtmlHelper from './StockHtmlHelper'
import { faArrowUp, faArrowDown, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
export default {
    props: ['stockChangeInfo'],
    data () {
        return {
            up: faArrowUp,
            down: faArrowDown,
            null: faArrowRight,
            stockHtmlHelper: undefined
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        getChangeColor() {
            return this.stockChangeInfo ? this.stockHtmlHelper.getChangeColor(this.stockChangeInfo.changePercent) : this.$t('dictio.na');
        },
        formatChange() {
            return this.stockChangeInfo && this.stockChangeInfo.changeAmount ? this.stockChangeInfo.changeAmount.toFixed(this.stockChangeInfo.price < 5 ? 4 : 2) : this.$t('dictio.na');
        },
        getChangeIcon() {
            if (this.stockChangeInfo) {
                return this.stockChangeInfo.changeAmount > 0 ? this.up : this.stockChangeInfo.changeAmount < 0 ? this.down : this.null;
            }
            return this.null
        },
        formatChangePrct() {
            return this.stockChangeInfo && this.stockChangeInfo.changePercent ? this.stockChangeInfo.changePercent.toFixed(2) : this.$t('dictio.na');
        }
    }
}
</script>

<style scoped>
.priceChanges{
    display: flex;
    justify-content: flex-end;
    font-size: smaller;
    align-items: center;
    gap: 5px;
}
.priceChanges.up{
    color: var(--v-greenUp-base);
}
.priceChanges.down{
    color: var(--v-redDown-base);
}
.changePrct::before {
    content: "(";
}
.priceChanges.up .changePrct::before {
    content: "(+";
}
.changePrct::after {
    content: "%)";
}
.priceChanges.up .change::before {
    content: "+";
}
</style>
