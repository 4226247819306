import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanels,{attrs:{"focusable":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.$t('UserStockWatchingList.portfolio.summary.title')))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.stockValues,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.row",fn:function({ item }){return [_c('strong',[_vm._v(_vm._s(item.row))])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }