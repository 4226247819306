import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr'
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                agora: '#1e90ff',
                synaxy: '#e6620a',
                warning: '#e6620a',
                error: '#cc0000',
                agorabg: '#e6f2ff',
                success: '#29a329',
                lightBorder: '#CCC',
                lightGrayBG: '#EFEFEF',
                superLightGrayBG: '#F5F5F5',
                darkBG: '#374049',
                hover1: '#F2F2F2',
                greenUp: '#388E3C',
                lightGreenUp: '#7fcc83',
                redDown: '#C62828',
                lightRedDown: '#e16b6b'
            }
        }
    }

    // theme: {
    //     themes: {
    //         light: {
    //             follow: '#EF6C00',
    //             consider: '#FBC02D',
    //             buy: '#43A047',
    //             deal: '#1B5E20',
    //             invalid: '#333',
    //             soon: 'transparent'
    //         }
    //     }
    // }
})
