import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registerActivation"},[(_vm.emailConfirmationPending)?_c(VProgressLinear,{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}):_c('div',[(_vm.emailConfirmed)?_c(VCard,{staticClass:"registerActivationCard",attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":"","color":"green darken-1"}},[_vm._v("mdi-account-check")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.$t('register.accountActivation.success.title')))])],1)],1),_c(VCardSubtitle,{domProps:{"innerHTML":_vm._s(_vm.$t('register.accountActivation.success.text', [this.email]))}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.goHome}},[_vm._v(_vm._s(_vm.$t('register.accountActivation.success.goHomeButton')))])],1)],1):_c(VCard,{staticClass:"registerActivationCard",attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":"","color":"red darken-1"}},[_vm._v("mdi-account-remove")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.$t('register.accountActivation.fail.title')))])],1)],1),_c(VCardSubtitle,{domProps:{"innerHTML":_vm._s(_vm.$t('register.accountActivation.fail.text', [this.email]))}}),_c(VCardActions,[_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"primary"},on:{"click":_vm.resend}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t('register.accountActivation.fail.resendButton.text')))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('register.accountActivation.fail.resendButton.tooltip')))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }