<template>
    <div>
        <v-stepper v-model="step" non-linear>
            <v-stepper-header>
                <v-stepper-step step="1" editable>
                    {{stepTitle(1)}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                    {{stepTitle(2)}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">
                {{$t('subscriptions.stepper.3.title')}}
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <div class="steptitle">{{stepTitle(1)}}</div>
                    <div class="stepPanel">
                        <SubscriptionPlanPanel ref="subscriptionPlanPanel"></SubscriptionPlanPanel>
                        <div class="stepper-actions">
                            <v-btn
                                color="primary"
                                :small="isMobile"
                                :disabled="!continueToCheckoutBtnEnabled"
                                @click="newPlanSelectionConfirm"
                            >
                                {{step1Action}}
                            </v-btn>
                            <v-btn text :small="isMobile" @click="cancel">
                                {{$t('subscriptions.plans.buttons.cancel')}}
                            </v-btn>
                        </div>
                    </div>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                    <div class="steptitle">{{stepTitle(2)}}</div>
                    <div class="stepPanel">
                        <div class="orderDetailsStep" v-if="isNewOrder">
                            <div class="cardSelector">
                                <div v-if="yourCreditCards.length" class="ccTitles">
                                    <span>{{$t('subscriptions.stepper.2.panel.yourCreditCards')}}</span>
                                    <span>{{$t('subscriptions.stepper.2.panel.expirationDate')}}</span>
                                </div>
                                <v-radio-group v-model="creditCardSelection" class="mt-0" hide-details>
                                    <v-radio
                                        v-for="cc in yourCreditCards"
                                        :key="cc.id"
                                        :value="cc.id"
                                        active-class="ccActive"
                                        class="ccInactive"
                                    >
                                        <template v-slot:label>
                                            <div class="card">
                                                <div class="cardId">
                                                    <div class="brand"><font-awesome-icon :icon="icons.cc[cc.brand]" class="fa-lg mr-3"></font-awesome-icon>{{cc.brand}}</div>
                                                    <div class="endsWith">{{$t('subscriptions.stepper.2.panel.endingWithMobile')}} {{cc.last4}}</div>
                                                </div>
                                                <div class="expiration">
                                                    <div class="expDate">{{cc.expiration.year}}/{{cc.expiration.month.toString().padStart(2, '0')}}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="new" active-class="ccActive" class="ccInactive">
                                        <template v-slot:label>
                                            <font-awesome-icon :icon="icons.cc.unk" class="fa-lg mr-3"></font-awesome-icon> <span>{{$t('subscriptions.stepper.2.panel.addNewCard')}}</span>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </div>
                            <!-- <OrderDetailsPanel :order="invoice"></OrderDetailsPanel> -->
                            <StripeCheckoutPanel ref="stripeCheckoutPanel" v-if="creditCardSelection==='new'" :subscriptionInfo="subscriptionInfo" @paymentInfosCompleted="paymentInfosCompleted"></StripeCheckoutPanel>
                            <!-- <div class="disclaimer">{{$t('subscriptions.plans.disclaimer')}}</div> -->
                        </div>
                        <div class="orderDetailsStep" v-else>
                            <SubscriptionChangeValidation ref="subscriptionChangeValidation"></SubscriptionChangeValidation>
                        </div>
                        <div class="stepper-actions">
                            <v-btn
                                color="primary"
                                small
                                :disabled="!paymentInfosContinueBtnEnabled"
                                @click="confirmSubscription"
                            >
                                {{step2Action}}
                            </v-btn>
                            <v-btn text :small="isMobile" @click="cancel">
                                {{$t('subscriptions.plans.buttons.cancel')}}
                            </v-btn>
                        </div>
                    </div>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                </v-stepper-content>
                <v-stepper-content step="3" class="pa-0">
                    <div class="steptitle">{{stepTitle(3)}}</div>
                    <div class="stepPanel">
                        <SubscriptionConfirmationPanel v-if="isNewOrder" ref="subscriptionConfirmationPanel" :order="invoice"></SubscriptionConfirmationPanel>
                        <SubscriptionChangeConfirmation v-else></SubscriptionChangeConfirmation>
                        <div class="stepper-actions">
                            <v-btn
                                color="primary"
                                small
                                @click="closeAsked"
                            >
                                {{$t('subscriptions.plans.buttons.close')}}
                            </v-btn>
                        </div>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
import SubscriptionStepperMixin from './SubscriptionStepperMixin'
export default {
    mixins: [SubscriptionStepperMixin, UserDataHelperMixin]
}
</script>

<style scoped>
.subscriptionStepper.theme--light.v-stepper {
    background: none;
    background-color: var(--v-lightGrayBG-base);
}
.orderDetailsStep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderDetailsStep>*:not(:last-of-type) {
    margin-bottom: 15px;
}
.stepper-actions{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.stepper-actions>*{
    margin: 0 5px;
}

.steptitle{
    text-align: center;
    padding: 10px;
    border-bottom: solid 1px #BBB;
    background-color: #1976d2;
    color: #FFF;
}
.stepPanel {
    padding: 20px 10px;
    background-color: var(--lightBG);
}
.disclaimer {
    font-size: x-small;
}
.cardSelector {
    padding: 20px;
    background-color: #FFF;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	border-radius: 10px;
}
.ccInactive {
    border: solid 1px transparent;
    padding: 5px;
}
.ccActive {
    border: solid 1px var(--v-agora-base);
    border-radius: 5px;
    padding: 5px;
    background-color: var(--hoverBG);
}
.ccTitles {
    display: flex;
    justify-content: space-between;
    font-size: small;
}
.expiration {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.brand {
    display: flex;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 10px;
}
.card {
    display: flex;
    justify-content: space-between;
    flex: 1;
}
.cardId {
    display: flex;
}
</style>
