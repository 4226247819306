import {
    faRedoAlt
} from "@fortawesome/pro-regular-svg-icons";
import BasicFeedbackService from '@/services/FeedbackService'
import DateHelper from "../../../infra/DateHelper";
var CommentSectionMixin = {
    data() {
        return {
            search: '',
            expanded: [],
            comments: undefined,
            globalRating: '?',
            icons: {
                refresh: faRedoAlt
            }
        }
    },
    methods: {
        refreshFeedbackList() {
            BasicFeedbackService.getUserFeedbackList().then(response => {
                this.comments = this.transform(response);
            })
                .catch(error => {
                    console.error(error);
                });
        },
        transform(data) {
            return data.map(d => {
                return {
                    id: d.id,
                    name: `${d.author.firstname} ${d.author.lastname}`,
                    date: d.date,
                    simpleDate: DateHelper.toSimpleDate(d.date),
                    email: d.author.email,
                    rating: d.rating,
                    comment: d.comment
                };
            })
        },
        calculateGlobalRating() {
            if (this.comments && this.comments.length > 0) {
                this.globalRating = (this.comments.reduce((acc, cur) => { return acc + cur.rating }, 0) / this.comments.length).toFixed(2);
            }
            else {
                this.globalRating = "?";
            }
        },
        commentRowClass() {
            return 'commentRow';
        }
    },
    created() {
        this.refreshFeedbackList();
    },
    computed: {
        headers() {
            return [
                { text: '', value: 'id', width: '5%' },
                { text: this.$t('admin.body.comment.table.name'), value: 'name', width: '20%' },
                { text: this.$t('admin.body.comment.table.date'), value: 'simpleDate', width: '15%' },
                { text: this.$t('admin.body.comment.table.rating'), value: 'rating', width: '10%' },
                { text: this.$t(`admin.body.comment.table.comment${this.$vuetify.breakpoint.mobile ? 'Abbrev' : ''}`), value: 'comment', width: '50%' },
                { text: '', value: 'data-table-expand' }
            ];
        }
    },
    watch: {
        comments(val) {
            this.calculateGlobalRating();
        }
    }
}

export default CommentSectionMixin
