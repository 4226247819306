<template>
    <div class="changeNameDialog">
        <v-dialog
        v-model="dialog"
        width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue-grey"
                    fab
                    small
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <font-awesome-icon :icon="icons.edit" class="fa-lg"></font-awesome-icon>
                </v-btn>
            </template>

            <v-card>
                <v-toolbar
                    color="blue darken-2"
                    dark
                >{{$t('settings.body.modifyNameDialog.title')}}</v-toolbar>
                <v-card-text class="py-2">
                    <v-container class="px-0">
                        <v-text-field name="firstname"
                            v-model="input.firstname"
                            :label="$t('settings.body.firstname')"
                            type="text"
                            outlined
                            :rules="[rules.required]"
                            prepend-inner-icon="mdi-account"
                            autocomplete="new-password">
                        </v-text-field>
                        <v-text-field name="lastname"
                            v-model="input.lastname"
                            :label="$t('settings.body.lastname')"
                            type="text"
                            outlined
                            :rules="[rules.required]"
                            prepend-inner-icon="mdi-account"
                            autocomplete="new-password">
                        </v-text-field>
                        <!-- <v-text-field name="oldPassword"
                            v-model="input.oldPassword"
                            :label="$t('settings.body.changePasswordDialog.oldPassword')"
                            type="password"
                            outlined
                            autocomplete="new-password">
                        </v-text-field>
                        <v-text-field name="newPassword"
                            v-model="input.newPassword"
                            :label="$t('settings.body.changePasswordDialog.newPassword')"
                            type="password"
                            outlined
                            autocomplete="new-password">
                        </v-text-field>
                        <v-text-field name="confirmPassword"
                            v-model="input.confirmPassword"
                            :label="$t('settings.body.changePasswordDialog.confirmPassword')"
                            type="password"
                            outlined
                            hide-details="auto"
                            autocomplete="new-password">
                        </v-text-field> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        fab
                        small
                        dark
                        @click="confirmChangeFullname()"
                    >
                        <font-awesome-icon :icon="icons.check" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        fab
                        small
                        dark
                        @click="closeFullnameEdition()"
                    >
                        <font-awesome-icon :icon="icons.times" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { authComputed } from '../../../store/helpers/AuthenticationHelper';
import Alert from '../../../components/controls/Alert.vue';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
export default {
    data() {
        return {
            dialog: false,
            input: {
                firstname: '',
                lastname: ''
            },
            icons: {
                check: faCheck,
                times: faTimes,
                edit: faPen
            },
            rules: {
                required: value => !!value || this.$t('settings.errors.required')
            }
        }
    },
    computed: {
        ...authComputed
    },
    created() {
        this.setFullName();
    },
    components: {
        Alert
    },
    methods: {
        setFullName() {
            this.input.firstname = this.user.firstname;
            this.input.lastname = this.user.lastname;
        },
        confirmChangeFullname() {
            this.$store.dispatch('setUserFullname', { firstname: this.input.firstname, lastname: this.input.lastname }).then((response) => {
                this.closeFullnameEdition();
                this.setFullName();
            }).catch((error) => {
                this.$refs.iAlert.error(error[0]);
            });
        },
        closeFullnameEdition() {
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
.changeNameDialog {
    margin: auto;
}
@media screen and (max-width: 768px) {
    .changeNameDialog {
        width: 100%;
    }
}

</style>
