<template>
    <div :class="`suggestionCard ${getCardClass}`" @click="viewStockLot">
        <div class="scStockInfos">
            <div class="scSymbol">{{ symbol }}</div>
            <div class="scScore">{{ score }}</div>
        </div>
        <StockCardPriceInfos :stockPriceInfos="stockPriceInfos"></StockCardPriceInfos>
    </div>
</template>

<script>
import StockPriceInfoConverter from '../../infra/converters/StockPriceInfoConverter';
import StockCardPriceInfos from '../controls/StockCardPriceInfos.vue';
import StockHtmlHelper from '../stocks/StockHtmlHelper';

export default {
    props: ["stock"],
    components: { StockCardPriceInfos },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        getCardClass() {
            const badge = this.stockHtmlHelper.getBadge(this.stock.highScore);
            return this.stockHtmlHelper.getBadgeCssClass(badge);
        },
        symbol() {
            return this.stock.symbol
        },
        score() {
            if (!this.stock) return 0;
            const scoreInfos = this.stock.getScoreInfos()[0];
            return this.stockHtmlHelper.cleanScore(scoreInfos.score);
        },
        stockPriceInfos() {
            return StockPriceInfoConverter.convertSuggestionStock(this.stock);
        }
    },
    methods: {
        viewStockLot() {
            this.$emit('viewStock', { stock: this.stock });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.suggestionCard {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    padding: 10px;
    border: solid 1px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-top: solid 6px var(--t000);
    &.deal {
        border-color: var(--t800);
        background-color: #dcefdc;
        .scStockInfos .scScore{
            color: var(--t800);
        }
    }
    &.buy {
        border-color: var(--t600);
        background-color: #dcefdc;
        .scStockInfos .scScore{
            color: var(--t600);
        }
    }
    &.sell-trigger {
        border-color: var(--t400-sell);
        background-color: #ffe6e6;
        .scStockInfos .scScore{
            color: var(--t400-sell);
        }
    }
    &.sell-loss{
        border-color: var(--t200-sell);
        background-color: #ffe6e6;
        .scStockInfos .scScore{
            color: var(--t200-sell);
        }
    }
    .scStockInfos {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2em;
        .scSymbol {
            color: #000;
            font-weight: bold;
        }
        .scScore {
            display: flex;
            align-items: center;
            font-size: 1.5em;
            font-weight: bold;
            color: var(--t000);
            &::after {
                margin-left: 5px;
                content: "pts";
                font-size: 1rem;
                font-weight: normal;
            }
        }
    }
    .scPriceInfos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        .scLastPrice {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 0.9em;
            .scCurrency {
                font-size: 0.7em;
                color: #777;
            }
            .scPrice {
                font-weight: bold;
            }
        }
        .scPriceChange {
            .scChange {
                font-size: 0.9em;
            }
            .scIcon {
                position: absolute;
                bottom: 3px;
                right: 3px;
                font-size: 16px;
                rotate: -15deg;
            }
        }
    }
    @media screen and (max-width: $small) {
        padding: 4px;
        font-size: 0.7em;
        gap: 5px;
        .scStockInfos {
            flex-direction: column;
            align-items: center;
        }
        .scPriceInfos {
            .scLastPrice {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.7em;
                line-height: 1;
                gap: 2px;
                .scPrice {
                    flex: 1;
                }
            }
            .scPriceChange {
                line-height: 1.1;
            }
        }
    }
}
</style>