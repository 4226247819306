<template>
    <div class="currencySelector">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :dark="dark"
                    :color="btnColor"
                    :small="$vuetify.breakpoint.xs"
                    :large="large !== undefined ? large : false"
                >
                    <font-awesome-icon :icon="icons[baseCurrencyIcon]" style="--fa-secondary-color: #000;" class="mr-2"/>
                    {{baseCurrencyText}}
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(c, index) in currencies" :key="index" @click="setBaseCurrency(c)">
                    <v-list-item-title>
                        <font-awesome-icon :icon="icons[c.icon]" class="mr-2"/>
                        {{ c.text }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import CurrencyHelperMixin from '../../helpers/CurrencyHelperMixin'
export default {
    props: {
        dark: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: undefined
        }
    },
    mixins: [CurrencyHelperMixin],
    computed: {
        btnColor() {
            let col = this.dark !== undefined ? 'secondary' : '';
            if (this.color) {
                col = this.color;
            }
            return col;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>