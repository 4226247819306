<template>
    <v-expand-transition>
        <div v-show="show" :class="`appMessage ${type}`">
            <div class="messageCont">
                <font-awesome-icon :icon="icon(type)" class="fa-fw fa-2x mr-5"></font-awesome-icon>
                <div class="message">
                    <div v-html="$t(`alerts.codes.${code}`)"></div>
                    <div v-if="subcode" class="submessage">{{$t(`alerts.codes.${subcode}`)}}</div>
                </div>
            </div>
            <v-btn
                color="secondary"
                fab
                small
                @click="dismiss"
            >
                <font-awesome-icon :icon="icons.cancel" class="fa-fw fa-lg"></font-awesome-icon>
            </v-btn>
        </div>
    </v-expand-transition>
</template>

<script>
import { faExclamationTriangle, faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
export default {
    props: { code: String, subcode: String, type: { type: String, default: 'info' }, dismissedMsg: { type: Boolean, default: false } },
    data() {
        return {
            icons: {
                info: faInfoCircle,
                warning: faExclamationTriangle,
                error: faTimesCircle,
                cancel: faTimes
            },
            dismissed: false
        }
    },
    computed: {
        show() {
            return !this.dismissedMsg && !this.dismissed
        }
    },
    methods: {
        icon(type) {
            switch (type) {
                case 'warning':
                    return this.icons.warning;
                case 'error':
                    return this.icons.error;
                default:
                    return this.icons.info;
            }
        },
        dismiss() {
            this.$emit('dismissed', { code: this.code })
            this.dismissed = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.appMessage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: #FFF;
    ::v-deep a {
        color: #FFF
    }
    &.info {
        background-color: var(--v-agora-base);
    }
    &.error {
        background-color: var(--v-error-base);
    }
    .messageCont {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        .submessage {
            font-size: 0.8em;
            &:empty {
                display: none;
            }
        }
    }
}

</style>
