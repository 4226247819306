import PlanConverter from '../../infra/converters/PlanConverter';
import SubscriptionService from '../../services/SubscriptionService';
var planStore = {
    state: () => ({
        plans: [],
        plansLoaded: false,
        myPlan: undefined,
        selectedPlan: undefined,
        nextPlanIsDowngrade: false
    }),

    mutations: {
        setMyPlan(state, { plan }) {
            state.myPlan = plan
        },
        setSelectedPlan(state, { plan }) {
            state.selectedPlan = plan
        },
        resetSelectedPlan(state) {
            state.selectedPlan = state.myPlan
        },
        setNextPlanIsDowngrade(state, { isDowngrade }) {
            state.nextPlanIsDowngrade = isDowngrade
        },
        setAllPans(state, { plans }) {
            state.plans = plans
            state.plansLoaded = true
        }
    },
    actions: {
        fetchAllPlans(context) {
            return new Promise((resolve, reject) => {
                if (context.state.plansLoaded) {
                    resolve(context.state.plans)
                }
                else {
                    SubscriptionService.getPublicSubscriptionList().then(plans => {
                        context.commit('setAllPans', { plans })
                        resolve(plans)
                    }).catch((error) => {
                        reject(error)
                    })
                }
            })
        },
        setMyPlan(context, { planDefinition }) {
            context.commit('setMyPlan', { plan: PlanConverter.convert(planDefinition) })
            context.commit('resetSelectedPlan')
        },
        setSelectedPlan(context, { plan }) {
            context.commit('setSelectedPlan', { plan })
        },
        resetSelectedPlan(context) {
            context.commit('resetSelectedPlan')
        },
        setNextPlanIsDowngrade(context, { isDowngrade }) {
            context.commit('setNextPlanIsDowngrade', { isDowngrade })
        }
    },
    getters: {
        getPlans(state) {
            return state.plans
        },
        getMyPlan(state) {
            return state.myPlan
        },
        getSelectedPlan(state) {
            return state.selectedPlan
        },
        selectedPlanName(state) {
            return state.selectedPlan ? state.selectedPlan.name : 'null'
        },
        differentPlanSelected(state) {
            return state.selectedPlan.priceId !== state.myPlan.priceId
        },
        nextPlanIsDowngrade(state) {
            return state.nextPlanIsDowngrade
        },
        myPlanHasPortfolio(state) {
            return state.myPlan ? state.myPlan.limit.portfolioListCount > 0 : false
        },
        minPlanWithPortfolio(state) {
            return state.plans.find(p => p.limit.portfolioListCount > 0)
        }
    }
}
export default planStore