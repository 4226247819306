<template>
    <div class="inviteActionButtons">
        <v-btn
            v-if="!sent"
            color="primary"
            fab
            x-small
            :disabled="loading"
            :loading="loading"
            @click="resend()"
        >
            <font-awesome-icon :icon="icons.resend" class="fa-lg"></font-awesome-icon>
        </v-btn>
        <font-awesome-icon v-else :icon="icons.sent" class="fa-2x" style="color:green"></font-awesome-icon>
    </div>
</template>

<script>
import { faRedo } from '@fortawesome/pro-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import planHelperMixin from '../../helpers/planHelperMixin';
import InvitationService from "../../services/InvitationService";
export default {
    props: ['guest'],
    mixins: [planHelperMixin],
    data() {
        return {
            icons: {
                resend: faRedo,
                sent: faCheckCircle
            },
            loading: false,
            sent: false
        }
    },
    created() {
        this.invitationService = new InvitationService();
    },
    methods: {
        resend() {
            this.loading = true
            this.invitationService.resend({ email: this.guest.email }).then(response => {
                this.sent = true
            }).catch(errors => {
                console.error(errors)
            }).finally(() => {
                this.loading = false
            });
        }
    }
}
</script>

<style scoped>
.inviteActionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
</style>
