import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adminPanel"},[_c('div',{staticClass:"adminPanelTitle"},[_vm._v(_vm._s(_vm.$t('admin.body.usage.title')))]),_c('div',{staticClass:"adminPanelPart"},[_c('div',{staticClass:"ap-subtitle"},[_c('div',{staticClass:"ap-subtitle-lpart"},[_vm._v(_vm._s(_vm.$t('admin.body.usage.title')))]),_c('div',{staticClass:"ap-subtitle-rpart"})]),_c('div',{staticClass:"ap-row"},[_c('div',{staticClass:"ap-section"},[_c('div',{staticClass:"ap-subSectionTitle"},[_vm._v(_vm._s(_vm.$t('admin.body.usage.timespan.title')))]),_c('div',{staticClass:"ap-body-part"},[_c(VRadioGroup,{model:{value:(_vm.since),callback:function ($$v) {_vm.since=$$v},expression:"since"}},_vm._l((_vm.timespan),function(n){return _c(VRadio,{key:n.key,attrs:{"label":_vm.$t(`admin.body.usage.timespan.${n.since}`),"value":n.delta},on:{"change":function($event){return _vm.timeSpanChanged(n.delta)}}})}),1)],1)]),_c(VDivider,{attrs:{"vertical":""}}),_c(VList,{staticClass:"px-5 flex-grow-1"},[_c(VListItem,[_c(VListItemAvatar),_c('div',{staticClass:"li-title"}),_c('div',{staticClass:"li-stats"},[_c('div',{staticClass:"li-stat"},[_vm._v("Total")]),_c('div',{staticClass:"li-stat"},[_vm._v("Unique")])])],1),_c(VDivider),_vm._l((_vm.stats),function(s){return _c(VListItem,{key:s.statisticName},[_c(VListItemAvatar,[(s.statisticName)?_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons[s.statisticName]}}):_vm._e()],1),(s.statisticName)?_c('div',{staticClass:"li-title"},[_vm._v(_vm._s(_vm.$t(`admin.body.usage.keys.${s.statisticName}`)))]):_vm._e(),_c('div',{staticClass:"li-stats"},[_c('div',{staticClass:"li-stat"},[_vm._v(_vm._s(s.totalCount))]),_c('div',{staticClass:"li-stat"},[_vm._v(_vm._s(s.uniqueUserCount))])])],1)})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }