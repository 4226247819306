<template>
    <div class="loginWidgetContainer">
        <div id="login" class="loginWidget">
            <div class="loginWidgetHeader">
                <img src="~@/assets/Invagora.png" class="homeLogo"/>
            </div>
            <div class="loginPanelContainer">
                <div class="loginPanel">
                    <div class="container">
                        <h1>{{ $t("login.sections.signIn") }}</h1>
                    </div>
                    <div class="container lpBody">
                        <v-form  ref="form" class="animate" v-model="valid">
                            <v-text-field
                                name="email"
                                v-model="input.email"
                                clearable
                                :label="$t('login.fields.email.label')"
                                type="email"
                                outlined
                                prepend-inner-icon="mdi-account"
                                :rules="[rules.required, rules.email]"
                                @keyup="keyUp"
                                autocomplete="new-password"
                                class="mb-3">
                            </v-text-field>
                            <v-text-field
                                name="password"
                                v-model="input.password"
                                :label="$t('login.fields.password.label')"
                                :type="showPassword ? 'text' : 'password'"
                                outlined
                                prepend-inner-icon="mdi-key"
                                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                :rules="[rules.required]"
                                hide-details="auto"
                                @keyup="keyUp"
                                @click:append="showPasswordIcon_clicked"
                                autocomplete="new-password">
                            </v-text-field>
                        </v-form>
                        <RecaptchaText></RecaptchaText>
                    </div>
                    <div class="container lpActions">
                        <v-btn
                            @click="login"
                            x-large
                            ref="loginBtn"
                            color="primary"
                            style="flex:1"
                            :disabled="!valid"
                        >{{$t("login.buttons.login")}}</v-btn>
                    </div>
                    <div class="container cont-flex cont-forget justify-space-between">
                        <div class="rememberMe">
                            <v-checkbox
                                v-model="input.rememberMe"
                                :label="$t('login.buttons.rememberMe')"
                                color="info"
                                dense
                                hide-details
                                class="mt-0"
                            ></v-checkbox>
                        </div>
                        <div class="psw">
                            <v-btn text small color="primary" @click="forgetPassword">{{ $t("login.buttons.forgetPassword") }}</v-btn>
                        </div>
                    </div>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                </div>
                <div class="signup">{{$t('login.dontHaveAccount.text')}} <router-link to="register/form">{{$t('login.dontHaveAccount.signUp')}}</router-link></div>
            </div>
        </div>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
    </div>
</template>

<script>
import validationMixin from '../../helpers/validationMixin';
import {
    faUserCircle,
    faLock,
    faLanguage
} from "@fortawesome/pro-light-svg-icons";
import AutomatedAbuseService from '../../services/AutomatedAbuseService'
import RecaptchaText from '../controls/RecaptchaText'
import storageService from "../../services/StorageService";
import SySnackbar from '../controls/SySnackbar.vue';
import terms from '../../views/Policies/terms.json'
export default {
    name: "Login",
    mixins: [validationMixin],
    data() {
        return {
            valid: false,
            overlay: false,
            availableLocales: this.$i18n.availableLocales.map(l => l.toUpperCase()),
            icon: {
                lang: faLanguage
            },
            input: {
                email: "",
                password: undefined,
                rememberMe: false
            },
            showPassword: false
        };
    },
    components: {
        RecaptchaText,
        SySnackbar
    },
    mounted() {
        const userAccess = storageService.UserAccess;
        if (userAccess && userAccess.user) {
            this.input.email = userAccess.user;
            this.input.rememberMe = true;
        }
        this.focusLogin();
    },
    computed: {
        appIcon() {
            return faUserCircle;
        },
        lockIcon() {
            return faLock;
        }
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.overlay = true;
                AutomatedAbuseService.validate({ vueComp: this, action: 'login' }).then(token => {
                    this.$store.dispatch('login', { email: this.input.email, password: this.input.password, rememberMe: this.input.rememberMe, token }).then(response => {
                        if (storageService.acceptedTermsVersion !== terms.version) {
                            this.$router.push({ name: 'TermsAndConditions', query: { v: terms.version } });
                        }
                        else {
                            this.$router.push({ name: 'Home' });
                        }
                    }).catch(errors => {
                        this.overlay = false;
                        let errMsg = this.$t('login.errors.default');
                        if (errors !== undefined) {
                            if (typeof errors === 'string') {
                                errMsg = errors;
                            }
                            else if (Array.isArray(errors)) {
                                errMsg = errors.map(e => this.$t(`alerts.codes.${e.errorCode}`)).join("<br>");
                            }
                        }
                        this.showError(errMsg);
                    });
                });
            }
        },
        focusLogin() {
            this.$refs.loginBtn.$el.focus();
        },
        showPasswordIcon_clicked() {
            this.showPassword = !this.showPassword;
        },
        forgetPassword() {
            this.$router.push({ name: 'ResetPasswordRequest' });
        },
        showError(errors) {
            this.$refs.iSySnackbar.error({ text: errors })
        },
        keyUp(e) {
            if (e.keyCode === 13) {
                this.login();
            }
        }
    }
};
</script>

<style scoped>
.loginWidgetContainer {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.loginWidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
    border-radius: 10px;
}
.loginWidgetHeader{
    padding: 10px 60px 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.homeLogo {
    height: 90px;
}
.loginWidgetFooter {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
}
.lpBody {
    min-height: 180px;
    flex: 1;
}
.lpActions {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loginPanelContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin: auto;
}
.loginPanelContainer > * {
    flex: 1;
}

.loginPanel {
    /* border-bottom: solid 1px dodgerblue;
    padding-bottom: 10px; */
}
.rememberMe >>> .v-label {
    font-size: 0.75rem;
    text-transform: uppercase;
}
.createPanel {
    display: flex;
    flex-direction: column;
}

/* Set a style for all buttons */
.loginWidgetContainer>>>button {
    padding: 14px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.loginWidgetContainer>>>button[type="submit"] {
    color: white !important;
    background-color: #4caf50;
}

.loginWidgetContainer>>>button[type="submit"]:hover {
    opacity: 0.8;
}

.loginFooter {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.signup {
    margin-top: 25px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px 0;
}

/* Extra styles for the cancel button */
.cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
    position: relative;
}

/* .mobileLangSwitcher {
    align-self: flex-end;
    padding: 0 40px;
} */

img.avatar {
    width: 40%;
    border-radius: 50%;
}

.container {
    padding: 16px;
    width: 500px;
}

.container > input {
    margin-bottom: 15px;
}

.cont-flex {
    display: flex;
    justify-content: flex-end;
}

.cont-space {
    display: flex;
    flex-direction: column;
}
.cont-space > *:not(:last-of-type) {
    margin-bottom: 20px;
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: red;
    cursor: pointer;
}

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
    }

    to {
        -webkit-transform: scale(1);
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 1050px) {
    .loginPanelContainer {
        padding: 5px;
        max-width: 600px;
    }

    .loginPanel {
        width: 100%;
        padding-bottom: 10px;
    }

    .container {
        width: 100%;
    }

    .cancelbtn {
        width: 100%;
    }
}

@media only screen and (device-width: 768px) {
/* For general iPad layouts */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
/* For landscape layouts only */
    .loginWidget {
        flex-direction: row;
    }
    .loginWidgetHeader{
        padding: 10px 40px;
    }

    .homeLogo{
        height: 70px;
    }
    .signup {
        margin: 20px 16px 0;
    }
}

@media screen and (max-width: 750px) {
    .loginWidgetContainer {
        padding: 10px 5px;
    }

    .cont-space {
        font-size: small;
    }
    .cont-space > *:not(:last-of-type) {
        margin-bottom: 5px;
    }
    .v-text-field__details {
        display: flex;
    }
    .loginWidget {
        padding: 0;
        border-radius: 5px;
    }
    .loginWidgetHeader{
        padding: 10px 30px;
    }
    .mobileLangSwitcher {
        padding: 0 10px;
    }
    h1 {
        font-size: 1.5em;
    }
    .lpBody {
        min-height: 0;
    }
    .homeLogo {
        height: 70px;
    }
    .loginFooter {
        flex-direction: column;
    }
    .psw {
        align-self: flex-end;
    }
    .signup {
        margin-top: 0;
        font-size: smaller;
    }
}
</style>
