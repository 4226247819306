<template>
    <div class="orderDetails">
        <div class="orderDetailsTitle" v-if="!$vuetify.breakpoint.mobile">{{$t('subscriptions.confirmation.summary')}}</div>
        <div class="orderDetailsBody">
            <div class="scc-orderDetails">
                <div class="scc-orderDetails-title" v-if="!$vuetify.breakpoint.mobile">{{$t('subscriptions.confirmation.orderDetails')}}</div>
                <OrderItem v-for="item in orderItems" :key="`${item.name}-item`" :item="item" :date="renewalDate"></OrderItem>
            </div>
            <div class="scc-summary">
                <div class="scc-summary-title">{{$t('subscriptions.confirmation.orderCost')}}</div>
                <div class="scc-subtotal">
                    <div class="scc-label">{{$t('subscriptions.confirmation.subtotal')}}</div>
                    <div class="scc-amount">{{subtotal}}</div>
                </div>
                <div v-for="tax in orderTaxes" :key="`tax-${tax.invagoraId}`" class="scc-taxes">
                    <div class="scc-label">{{$t(`subscriptions.confirmation.${tax.invagoraId}`)}}</div>
                    <div class="scc-amount">{{NormalizeAmount(tax.amount)}}</div>
                </div>
                <div class="scc-total">
                    <div class="scc-label">{{$t('subscriptions.confirmation.total')}}</div>
                    <div class="scc-amount">{{total}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderItem from './OrderItem.vue'
import planHelperMixin from '../../helpers/planHelperMixin';
export default {
    props: ['order', 'renewalDate'],
    mixins: [planHelperMixin],
    components: { OrderItem },
    computed: {
        subtotal() {
            return this.order && this.order.subtotal ? this.order.subtotal.toFixed(2) : '';
        },
        total() {
            return this.order && this.order.total ? this.order.total.toFixed(2) : ''
        },
        orderItems() {
            return this.order ? this.order.items : []
        },
        orderTaxes() {
            return this.order ? this.order.taxes : []
        }
    },
    methods: {
        NormalizeAmount(amount) {
            return amount.toFixed(2);
        }
    }
}
</script>

<style scoped>
.orderDetails {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	border-radius: 10px;
	padding: 25px;
}
.orderDetailsTitle {
    flex: 1;
    font-size: larger;
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
.orderDetailsBody {
    flex: 1;
    display: flex;
    justify-content: center;
}
@media print {
    .orderDetailsPanel {
        box-shadow: none;
    }
}
</style>
<style scoped src="@/assets/css/subscriptionConfirmation.css">
</style>
