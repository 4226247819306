<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        content-class="upgradeDialog"
    >
        <v-card class="upgradeDialogCard">
            <div class="upgradeDialogIcon">
                <font-awesome-icon :icon="icons.warning" class="fa-fw fa-3x"></font-awesome-icon>
            </div>
            <v-card-title class="text-h5">
                {{$t('Home.dashboard.summary.listLimitReachedTitle')}}
            </v-card-title>
            <v-card-text class="upgradeDialogText">{{text}}</v-card-text>
            <div class="actions">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    dark
                    rounded
                    @click="goToPlan"
                >
                    {{$t('Home.dashboard.summary.upgradePlan')}}
                </v-btn>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="close"
                >
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
export default {
    data () {
        return {
            icons: {
                close: faTimes,
                warning: faExclamationTriangle
            },
            dialog: false,
            typeLimitReached: undefined
        }
    },
    created() {
    },
    computed: {
        title() {
            return this.$t(`alerts.title.${this.type}`);
        },
        text() {
            const key = `Home.dashboard.summary.listLimitReached.${this.typeLimitReached}`;
            if (this.$te(key)) {
                return this.$t(key);
            } else {
                return ''
            }
        },
        msg() {
            return this.alert.errorMsg || this.$t('alerts.error');
        }
    },
    methods: {
        show({ type }) {
            this.typeLimitReached = type;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        goToPlan() {
            this.$router.push({ name: 'Subscription' })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .upgradeDialog {
    overflow-y: unset;
}
.upgradeDialogCard {
    border-left: solid 10px var(--v-synaxy-base);
    .upgradeDialogText {
        color: rgba(0, 0, 0, 0.87) !important;
        font-size: medium;
    }
    .upgradeDialogIcon {
        display: flex;
        justify-content: center;
        font-size: x-large;
        position: relative;
        top: -36px;
        &> svg {
            --fa-primary-color: #FFF;
            --fa-secondary-color: var(--v-synaxy-base);
            --fa-secondary-opacity: 1.0;
        }
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 0 24px 20px;
    }
}
</style>