import { getTopList } from "../helpers/ListHelper";

var analyticDataStore = {
  state: () => ({
    loaded: {
      buys: false,
      sells: false
    },
    top: {
      buy: undefined,
      sell: undefined
    },
    topLists: {
      buys: [],
      sells: []
    }
  }),

  mutations: {
    setBuysTopList(state, { list }) {
      state.topLists.buys = list;
      state.top.buy = list[0];
      state.loaded.buys = true;
    },
    setSellsTopList(state, { list }) {
      state.topLists.sells = list;
      state.top.sell = list[0];
      state.loaded.sells = true;
    }
  },

  actions: {
    updateBuysTopList(context, { list }) {
      return new Promise((resolve, reject) => {
        context.commit('setBuysTopList', { list: getTopList(list, 5) });
        resolve();
      });
    },
    updateSellsTopList(context, { list }) {
      return new Promise((resolve, reject) => {
        context.commit('setSellsTopList', { list: getTopList(list, 5) });
        resolve();
      });
    }
  },

  getters: {
    topBuyLoaded(state) {
      return state.loaded.buys;
    },
    topSellLoaded(state) {
      return state.loaded.sells;
    },
    top1Buy(state) {
      return state.top.buy;
    },
    top3Buy(state) {
      return state.topLists.buys.slice(0, 3);
    },
    top1Sell(state) {
      return state.top.sell;
    },
    top3Sell(state) {
      return state.topLists.sells.slice(0, 3);
    }
  }
}

export default analyticDataStore;
