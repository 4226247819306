<template>
    <div class="partnership">
        <div class="pBody">
            <div class="pTitle">{{ partnership.companyName }}</div>
            <div class="pDate">
                <font-awesome-icon :icon="icons.calendar"></font-awesome-icon>
                <div>
                    {{ partnershipDate }}
                </div>
            </div>
            <div class="desc">{{ partnership.description }}</div>
            <div class="links">
                <PartnershipCardLink v-for="(link, i) in partnership.extraLinks" :key="i" :link="link"></PartnershipCardLink>
            </div>
        </div>
    </div>
</template>

<script>
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import DateHelper from '../../infra/DateHelper';
import Partnership from '../../models/Partnership';
import PartnershipCardLink from './PartnershipCardLink.vue';

export default {
	components: { PartnershipCardLink },
    props: {
        partnership: {
            type: Partnership
        }
    },
    data() {
        return {
            icons: {
                calendar: faCalendar
            }
        }
    },
    computed: {
        partnershipDate() {
            return DateHelper.toSimpleDate(this.partnership.dateTime)
        }
    }
}
</script>

<style lang="scss" scoped>
.partnership {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    .pBody {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .pTitle {
            font-size: 1.2em;
            font-weight: bold;
        }
        .pDate {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 0.9em;
        }
        .desc {
            font-size: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .links {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
</style>