<template>
  <div class="registerActivation">
              <v-progress-linear v-if="emailConfirmationPending"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
    <v-card v-if="emailConfirmed" class="registerActivationCard" outlined>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon x-large color="green darken-1">mdi-account-check</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">{{$t('register.accountActivation.success.title')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-subtitle v-html="$t('register.accountActivation.success.text', [this.email])"></v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="goHome">{{$t('register.accountActivation.success.goHomeButton')}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="registerActivationCard" outlined>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon x-large color="red darken-1">mdi-account-remove</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">{{$t('register.accountActivation.fail.title')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-subtitle v-html="$t('register.accountActivation.fail.text', [this.email])"></v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" v-bind="attrs" v-on="on" @click="resend">{{$t('register.accountActivation.fail.resendButton.text')}}</v-btn>
          </template>
          <span>{{$t('register.accountActivation.fail.resendButton.tooltip')}}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    </div>
  </div>
</template>

<script>
import AccountActivationMixin from './AccountActivationMixin'
export default {
  mixins: [AccountActivationMixin]
}
</script>

<style scoped>
.registerActivation {
  padding-top: 100px;
}
.v-sheet.v-card.registerActivationCard {
  max-width: 500px;
  margin: auto;
  border: none;
}
</style>
