import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"langSwitcher"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"dark":_vm.isDark,"color":_vm.btnColor,"small":_vm.$vuetify.breakpoint.xs,"large":_vm.large !== undefined ? _vm.large : false}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-2x mr-3",staticStyle:{"--fa-secondary-color":"#000"},attrs:{"icon":_vm.icon.lang}}),_vm._v(" "+_vm._s(_vm.label)+" ")],1)]}}])},[_c(VList,_vm._l((_vm.availableLocales),function(key){return _c(VListItem,{key:`lang${key}`,on:{"click":function($event){return _vm.setLocale(key)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.locales[key]))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }