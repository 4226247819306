class Partnership {
    constructor({ companyName, dateTime, description, extraLinks, googleSearchKeyWords, srcUrl }) {
        this._companyName = companyName;
        this._dateTime = dateTime;
        this._description = description;
        this._extraLinks = extraLinks;
        this._googleSearchKeyWords = googleSearchKeyWords;
        this._srcUrl = srcUrl;
    }

    get companyName() { return this._companyName; }
    get dateTime() { return this._dateTime; }
    get description() { return this._description; }
    get extraLinks() { return this._extraLinks; }
    get googleSearchKeyWords() { return this._googleSearchKeyWords; }
    get srcUrl() { return this._srcUrl; }
}

export default Partnership;