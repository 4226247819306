<template>
    <div>
        <div v-if="!loaded" class="loading">
            <v-data-table
            item-key="name"
            class="elevation-1"
            loading
            loading-text="Loading... Please wait"
            ></v-data-table>
        </div>
        <div v-else>
            <div class="listOverview">
                <div class="loTitle">{{ getListName }}</div>
                <v-btn @click="editList" icon x-small color="white" class="editBtn">
                    <font-awesome-icon :icon="icons.edit" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
                <font-awesome-icon :icon="icons[this.listType]" class="fa-fw bgIcon" color="#DDD"></font-awesome-icon>
            </div>
            <div v-if="orderedStockList.length > 0" class="entryList">
                <div class="entriesHeader">
                    <div class="entryHeader"></div>
                    <div class="entryHeader eh-center aright">
                        <div>{{ $t('UserStockWatchingList.watchTable.headers.score') }}</div>
                    </div>
                    <div class="entryHeader eh-actions">
                        <v-btn
                            color="blue darken-4"
                            fab
                            dark
                            small
                            absolute
                            top
                            class="addButtonMobile"
                            @click="newItemBtn_Clicked"
                        >
                            <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                        </v-btn>
                    </div>
                </div>
                <div v-for="entry in orderedStockList" :key="`${entry.id}-entry`" class="entry" @click="editItem(entry)">
                    <div class="entryPart ep-left">
                        <div class="entrySymbol">{{entry.symbol}}</div>
                        <div class="entryName">{{entry.companyName}}</div>
                    </div>
                    <div class="entryPart ep-center">
                        <div class="entryScore"><div :class="`entryScoreBadge ${getColorForScore(entry.score)}`" dark>{{ cleanScore(entry.score) }}</div></div>
                    </div>
                    <div class="entryPart ep-right">
                        <StockPriceInfos :entry="entry"></StockPriceInfos>
                        <StockPriceChange :stockChangeInfo="stockChangeInfo(entry)"></StockPriceChange>
                    </div>
                </div>
            </div>
            <EmptyListWidget v-else @addAsked="newItemBtn_Clicked"></EmptyListWidget>
            <ConfirmDialog ref="deleteItemConfirmDialog" @answered="deleteItemDialogAnswered"></ConfirmDialog>
        </div>
        <EditorPanel ref="editorPanel" @removeSymbol="deleteItem"></EditorPanel>
        <EditStockDialogMobile ref="editStockPanel" @removeSymbol="deleteItem"></EditStockDialogMobile>
        <Alert ref="iAlert"></Alert>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
        <EditListCard ref="editCard"></EditListCard>
    </div>
</template>

<script>
import userStockListMixin from './UserStockListMixin'
import UserDataHelperMixin from '../../infra/UserDataHelperMixin'
import listCardMixin from '../home/dashboard/boards/ListCardMixin'
import StockPriceInfos from '../stocks/StockPriceInfos.vue'
import StockPriceChange from '../stocks/StockPriceChange.vue'
import EditStockDialogMobile from './edition/EditStockDialogMobile.vue'
import SySnackbar from '../controls/SySnackbar.vue'
import EditListCard from '../home/dashboard/boards/EditListCard.vue'
import EmptyListWidget from '../list/EmptyListWidget.vue'
export default {
    components: { StockPriceInfos, StockPriceChange, EditStockDialogMobile, SySnackbar, EditListCard, EmptyListWidget },
    data: () => ({
        expanded: [],
        singleExpand: false,
        dialog: false,
        stocks: [],
        editedIndex: -1,
        editedItem: {
            symbol: '',
            name: '',
            threashold200: 0,
            threashold400: 0,
            threashold600: 0,
            threashold800: 0
        },
        defaultItem: {
            symbol: '',
            name: '',
            threashold200: 0,
            threashold400: 0,
            threashold600: 0,
            threashold800: 0
        }
    }),
    mixins: [userStockListMixin, listCardMixin, UserDataHelperMixin],
    computed: {
        getListName() {
            return this.listNames.find(n => n.id === this.listSelected).name
        }
    }
}
</script>

<style lang="scss" scoped>
.listOverview {
    height: 100%;
    padding: 15px;
    display: flex;
    gap: 15px;
    background-color: var(--darkBG);
    border-radius: 10px;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
    .bgIcon {
        position: absolute;
        top: 5px;
        right: 0px;
        font-size: 50px;
        rotate: -15deg;
        opacity: 0.3;
    }
}
.entryList {
    display: flex;
    flex-direction: column;
    .entriesHeader {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 0.6em;
        .entryHeader {
            flex: 3;
            position: relative;
            &.eh-center {
                flex: 2;
            }
            &.aright {
                text-align: right;
            }
            &.eh-actions {
                text-align: right;
                .addButtonMobile {
                    position: relative;
                    right: 0;
                    top: -15px;
                }
            }
        }
    }
    .entry {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-bottom: solid 1px #EEE;
        .entryPart {
            font-size: 0.8em;
            flex: 2;
            &.ep-left {
                flex: 3;
            }
            &.ep-center {
                flex: none;
                font-size: 0.7em;
                text-align: right;
            }
            &.ep-right {
                text-align: right;
            }
            .entrySymbol {
                font-weight: bold;
            }
            .entryName {
                font-size: 0.8em;
                color: var(--midGray);
            }
            .sdcLabel {
                font-size: 0.8em;
            }
            ::v-deep .priceInfos {
                justify-content: flex-end;
                .currency{
                    font-size: 0.7em;
                }
            }
            .entryScore {
                font-size: smaller;
            }

            .entryScoreBadge {
                line-height: 20px;
                border-radius: 12px;
                padding: 2px 8px;
            }
        }
    }
}
</style>
<style scoped src="@/assets/css/stockInfos.css">
</style>
