<template>
    <div :class="`card${selected ? ' selected' : ''}`" @click="changeCardSelection">
        <div class="cardInfos">
            <div class="cardLogo">
                <font-awesome-icon :icon="icons.cc[card.brand]" class="fa-3x"></font-awesome-icon>
            </div>
            <div class="cardDescription">
                <div class="cardId">{{card.brand}} •••• {{card.last4}}</div>
                <div class="expiration">
                    <div class="expDate">{{$t('account.sections.subscriptionBilling.managePaymentMethods.expiryDate')}} {{card.expiration.month.toString().padStart(2, '0')}}/{{card.expiration.year}}</div>
                </div>
            </div>
            <div class="extra">
                <div v-if="isDefaultPaymentMethod" class="extraIcon">
                    <font-awesome-icon v-if="$vuetify.breakpoint.xs" :icon="icons.defaultCard" class="fa-fw" color="#1e90ff"></font-awesome-icon>
                    <v-chip v-else dark color="agora">{{$t('account.sections.subscriptionBilling.managePaymentMethods.defaultPaymentMethod')}}</v-chip>
                </div>
                <v-menu bottom left v-model="showMenu">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link @click="editCard">
                            <v-list-item-title>
                                <font-awesome-icon :icon="icons.edit" class="fa-fw mr-1"></font-awesome-icon>
                                Modifier
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="!isDefaultPaymentMethod" link @click="makeDefault">
                            <v-list-item-title>
                                <font-awesome-icon :icon="icons.defaultCard" class="fa-fw mr-1"></font-awesome-icon>
                                Par défaut
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider v-if="canDeleteCard"></v-divider>
                        <v-list-item v-if="canDeleteCard" link>
                            <v-list-item-title @click="askForDelete">
                                <font-awesome-icon :icon="icons.remove" class="fa-fw mr-1"></font-awesome-icon>
                                Supprimer
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <ConfirmDialog ref="confirmDelete" @answered="deleteAnswered"></ConfirmDialog>
        <EditPaymentMethod ref="editPaymentMethod" :card="card" @updated="updated"></EditPaymentMethod>
    </div>
</template>

<script>
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt, faCreditCardBlank, faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import ConfirmDialog from '../controls/ConfirmDialog.vue';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import EditPaymentMethod from './EditPaymentMethod.vue';
export default {
    props: ['card', 'selected'],
    components: { ConfirmDialog, EditPaymentMethod },
    data() {
        return {
            showMenu: false,
            icons: {
                cc: {
                    unk: faCreditCardBlank,
                    mastercard: faCcMastercard,
                    visa: faCcVisa,
                    amex: faCcAmex
                },
                edit: faPencilAlt,
                defaultCard: faShieldCheck,
                remove: faTrashAlt
            }
        }
    },
    computed: {
        isDefaultPaymentMethod() {
            return this.card.isDefault
        },
        canDeleteCard() {
            return !this.$store.getters.userSubscriptionInfo.currentCycleInfo || !this.card.isDefault
        }
    },
    methods: {
        changeCardSelection() {
            this.$emit('clicked', this.card.id)
        },
        makeDefault() {
            this.$emit('isDefault', this.card.id)
        },
        editCard() {
            this.$refs.editPaymentMethod.show()
        },
        askForDelete() {
            this.$refs.confirmDelete.open({
                title: this.$t('account.sections.subscriptionBilling.managePaymentMethods.deletePaymentMethod.title'),
                text: this.$t('account.sections.subscriptionBilling.managePaymentMethods.deletePaymentMethod.message', [this.card.brand, this.card.last4])
            })
        },
        deleteAnswered({ response }) {
            if (response) this.$emit('deleted', this.card.id)
        },
        updated({ paymentMethodId, expirationMonth, expirationYear }) {
            this.$emit('updated', { paymentMethodId, expirationMonth, expirationYear })
        }
    },
    watch: {
        showMenu(val) {
            if (val) this.changeCardSelection()
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    cursor: pointer;
    border-left: solid 6px transparent;
    &.selected {
        border-left: solid 6px var(--agora);
        background-color: var(--agoraBG);
    }
    &:hover {
        background-color: var(--hoverBG);
    }
    .cardInfos {
        display: flex;
        align-items: center;
        gap: 15px;
        .cardLogo {
            width: 55px;
        }
        .cardDescription {
            flex: 1;
            .cardId {
                display: flex;
                font-weight: bold;
                text-transform: capitalize;
                width: 150px;
            }
            .expiration {
                font-size: 0.9em;
            }
        }
        .extra {
            display: flex;
            gap: 5px;
            .extraIcon{
                display: flex;
                align-items: center;
            }
        }
    }
    .cardActions {
        display: flex;
        justify-content: space-between;
    }
}
</style>