<template>
    <div class="dashboardLists">
        <div class="dlHeader">
            <div class="dlTitle">{{$t("Home.dashboard.summary.buyLists")}}</div>
            <div class="dlAction">
                <v-btn icon color="agora" @click="addBuyList">
                    <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
        <div class="dlCard" v-for="(l, i) in buyLists" :key="`bl-${i}`">
            <ListCardMobile :list="l" listType="buy"></ListCardMobile>
        </div>
        <div class="dlHeader">
            <div class="dlTitle">{{$t("Home.dashboard.summary.sellLists")}}</div>
            <div class="dlAction">
                <v-btn icon color="agora" @click="addSellList">
                    <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
        <div class="dlCard" v-for="(l, i) in sellLists" :key="`sl-${i}`">
            <ListCardMobile :list="l" listType="sell"></ListCardMobile>
        </div>
        <div class="dlPF">
            <div class="dlHeader">
                <div class="dlTitle">{{$t("Home.dashboard.summary.portfolios")}}</div>
                <div class="dlAction">
                    <v-btn icon color="agora" @click="addPortfolio">
                        <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
            <div class="dlCard" v-for="(p, i) in portfolios" :key="`pl-${i}`">
                <PortfolioCardMobile :portfolio="p"></PortfolioCardMobile>
            </div>
        </div>
        <EditListCard ref="editCard"></EditListCard>
    </div>
</template>

<script>
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import ListCardMobile from './boards/ListCardMobile.vue'
import PortfolioCardMobile from './boards/PortfolioCardMobile.vue'
import { authorizationComputed } from '../../../store/helpers/AuthorizationHelper'
import EditListCard from './boards/EditListCard.vue'
export default {
    components: { ListCardMobile, PortfolioCardMobile, EditListCard },
    data() {
        return {
            icons: {
                add: faPlusCircle
            },
            list: null,
            type: null
        }
    },
    computed: {
        ...authorizationComputed,
        buyLists() {
            return this.$store.getters.userLists.buyLists
        },
        sellLists() {
            return this.$store.getters.userLists.sellLists
        },
        portfolios() {
            return this.$store.getters.portfolioList
        }
    },
    methods: {
        addBuyList() {
            this.$refs.editCard.show({ list: null, type: 'buy' })
        },
        addSellList() {
            this.$refs.editCard.show({ list: null, type: 'sell' })
        },
        addPortfolio() {
            this.$refs.editCard.show({ list: null, type: 'portfolio' })
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboardLists {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .dlHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dlTitle {
            color: #FFF;
            padding: 0 10px;
        }
    }
    .dlPF {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
</style>