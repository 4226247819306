<template>
    <div class="loginPage">
        <SimplePage>
            <Login></Login>
            <template v-slot:widgetFooterLeftPart>
                <LoginAsGuest></LoginAsGuest>
                <SubscriptionButtons></SubscriptionButtons>
            </template>
        </SimplePage>
    </div>
</template>

<script>
import SimplePage from '../SimplePage.vue'
import Login from '../../components/login/Login.vue'
import LoginAsGuest from '../../components/controls/LoginAsGuest.vue';
import SubscriptionButtons from '../../components/subscriptions/SubscriptionButtons.vue';
export default {
    components: { SimplePage, Login, LoginAsGuest, SubscriptionButtons }
}
</script>

<style scoped>
.loginPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
