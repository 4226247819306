class UserListEntry {
    constructor({ id, stockId, symbol, companyName, score, threasholds = {}, isPriceIntraDay, marketRegularPrice = {}, marketCap, oneYearLow, oneYearHigh, change, changePercent, currency, plateformCustomExchange }) {
        this._id = id;
        this._stockId = stockId;
        this._symbol = symbol || '';
        this._companyName = companyName;
        this._score = score;
        this._absoluteScore = this._score === -9999 ? this._score : Math.abs(this._score);
        this._threashold200 = threasholds.t200;
        this._threashold400 = threasholds.t400;
        this._threashold600 = threasholds.t600;
        this._threashold800 = threasholds.t800;
        this._isPriceIntraDay = isPriceIntraDay;
        this._lastPriceDateTime = marketRegularPrice.dateTime || Date.now;
        this._lastPrice = marketRegularPrice.value || 0.0;
        this._marketCap = marketCap;
        this._oneYearLow = oneYearLow;
        this._oneYearHigh = oneYearHigh;
        this._change = change || { value: 0.0 };
        this._changePercent = changePercent || { value: 0.0 };
        this._currency = currency;
        this._plateformCustomExchange = plateformCustomExchange;
        this._listId = undefined;
    }

    get id() { return this._id; }
    get stockId() { return this._stockId; }
    get symbol() { return this._symbol; }
    get companyName() { return this._companyName; }
    get score() { return this._score; }
    get absoluteScore() { return this._absoluteScore; }
    get threashold200() { return this._threashold200; }
    get threashold400() { return this._threashold400; }
    get threashold600() { return this._threashold600; }
    get threashold800() { return this._threashold800; }
    get isPriceIntraDay() { return this._isPriceIntraDay; }
    get lastPriceDateTime() { return this._lastPriceDateTime; }
    get lastPrice() { return this._lastPrice; }
    get marketCap() { return this._marketCap; }
    get oneYearLow() { return this._oneYearLow; }
    get oneYearHigh() { return this._oneYearHigh; }
    get change() { return this._change.value; }
    get changePercent() { return this._changePercent.value; }
    get currency() { return this._currency; }
    get plateformCustomExchange() { return this._plateformCustomExchange; }
    get listId() { return this._listId; }

    getThresholds() {
        return {
            t200: this._threashold200 || '',
            t400: this._threashold400 || '',
            t600: this._threashold600 || '',
            t800: this._threashold800 || ''
        }
    }

    toNumber(value) {
        const normalizeValue = typeof value === 'string' ? value.replace(',', '.') : value;
        return Number(normalizeValue);
    }

    set id(value) { this._id = value; }
    set stockId(value) { this._stockId = value; }
    set symbol(value) { this._symbol = value; }
    set companyName(value) { this._companyName = value; }
    set score(value) { this._score = value; }
    set absoluteScore(value) { this._absoluteScore = value; }
    set threashold200(value) { this._threashold200 = value; }
    set threashold400(value) { this._threashold400 = value; }
    set threashold600(value) { this._threashold600 = value; }
    set threashold800(value) { this._threashold800 = value; }
    set isPriceIntraDay(value) { this._isPriceIntraDay = value; }
    set lastPriceDateTime(value) { this._lastPriceDateTime = value; }
    set lastPrice(value) { this._lastPrice = value; }
    set marketCap(value) { this._marketCap = value; }
    set oneYearLow(value) { this._oneYearLow = value; }
    set oneYearHigh(value) { this._oneYearHigh = value; }
    set change(value) { this._change.value = value; }
    set changePercent(value) { this._changePercent.value = value; }
    set currency(value) { this._currency = value; }
    set plateformCustomExchange(value) { this._plateformCustomExchange = value; }
    set listId(value) { this._listId = value; }

    setThresholds({ t200, t400, t600, t800 }) {
        this._threashold200 = this.toNumber(t200);
        this._threashold400 = this.toNumber(t400);
        this._threashold600 = this.toNumber(t600);
        this._threashold800 = this.toNumber(t800);
    }

    get rawData() {
        return {
            id: this._id,
            stockId: this._stockId,
            symbol: this._symbol,
            companyName: this._companyName,
            score: parseFloat(this._score || 0),
            absoluteScore: this._absoluteScore,
            currency: this.currency,
            threasholds: {
                T200: parseFloat(this._threashold200 || 0),
                T400: parseFloat(this._threashold400 || 0),
                T600: parseFloat(this._threashold600 || 0),
                T800: parseFloat(this._threashold800 || 0)
            },
            isPriceIntraDay: this._isPriceIntraDay,
            marketRegularDateTime: this._lastPriceDateTime,
            marketRegularPrice: parseFloat(this._lastPrice || 0),
            marketCap: parseFloat(this._marketCap || 0),
            oneYearLow: parseFloat(this._oneYearLow || 0),
            oneYearHigh: parseFloat(this._oneYearHigh || 0),
            change: parseFloat(this._change.value || 0),
            changePercent: parseFloat(this._changePercent.value || 0),
            plateformCustomExchange: this._plateformCustomExchange
        };
    }

    hasThresholds() {
        return this._threashold200 || this._threashold400 || this._threashold600 || this._threashold800;
    }
}

export default UserListEntry
