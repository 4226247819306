<template>
    <div class="changePasswordDialog">
        <v-dialog v-model="dialog" width="600">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue-grey"
                    fab
                    small
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <font-awesome-icon :icon="icons.edit" class="fa-lg"></font-awesome-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    color="blue darken-2"
                    dark
                >{{$t('settings.body.managePaymentMethodsDialog.title')}}</v-toolbar>
                <div class="paymentMethods">
                    <CreditCardSelector ref="creditCardSelector" :editable="true" @changed="ccChanged" @askForNewCard="askForNewCard" @defaultPayementMethodChanged="defaultPayementMethodChanged"></CreditCardSelector>
                    <StripePaymentInfoPanel v-if="showNewCardForm" ref="paymentInfoPanel"></StripePaymentInfoPanel>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        fab
                        small
                        dark
                        @click="confirmButtonClicked"
                    >
                        <font-awesome-icon :icon="icons.check" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        fab
                        small
                        dark
                        @click="closeChangePaymentMethod"
                    >
                        <font-awesome-icon :icon="icons.times" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-dialog>
        <ConfirmDialog ref="renewConfirm" @answered="renewConfirmationAnsered"></ConfirmDialog>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import StripePaymentInfoPanel from "../../../components/payment/StripePaymentInfoPanel.vue";
import storageService from "../../../services/StorageService";
import CreditCardSelector from "../../../components/payment/CreditCardSelector.vue";
import ConfirmDialog from "../../../components/controls/ConfirmDialog.vue";
import Alert from "../../../components/controls/Alert.vue";
export default {
    name: 'ManagePaymentMethods',
    data() {
        return {
            dialog: false,
            overlay: false,
            selectedCardId: undefined,
            icons: {
                check: faCheck,
                times: faTimes,
                edit: faPen
            }
        }
    },
    components: { StripePaymentInfoPanel, CreditCardSelector, ConfirmDialog, Alert },
    computed: {
        showNewCardForm() {
            return this.selectedCardId === 'new'
        }
    },
    methods: {
        confirmButtonClicked() {
            if (this.$store.getters.isInDefaultOfPayment) {
                this.askForRenewConfirmation()
            }
            else {
                this.confirmChangePaymentMethod()
            }
        },
        confirmChangePaymentMethod() {
            this.overlay = true
            if (this.showNewCardForm) {
                this.$refs.paymentInfoPanel.addNewPaymentMethod().then((response) => {
                    this.newPaymentMethodAdded({ paymentMethod: response.setupIntent.payment_method });
                    storageService.deleteDismissAppMessageKey({ key: 'ExpiredCard' })
                    this.closeChangePaymentMethod()
                }).catch(error => {
                    this.$refs.iAlert.error({ errorCode: error.code })
                }).finally(() => {
                    this.overlay = false
                })
            }
            else {
                this.closeChangePaymentMethod()
            }
        },
        newPaymentMethodAdded({ paymentMethod }) {
            this.$refs.paymentInfoPanel.clearForm()
            this.$emit('newPaymentMethodAdded', { paymentMethod })
        },
        closeChangePaymentMethod() {
            this.dialog = false;
            this.selectedCardId = undefined
            this.overlay = false
        },
        ccChanged({ card }) {
            this.selectedCardId = card.id
        },
        askForNewCard() {
            this.selectedCardId = 'new'
        },
        defaultPayementMethodChanged({ defaultCardId }) {
            this.$emit('defaultPayementMethodChanged', { defaultCardId })
        },
        askForRenewConfirmation() {
            this.$refs.renewConfirm.open({
                title: 'Renew',
                text: 'Voulez-vous?'
            })
        },
        renewConfirmationAnsered({ response }) {
            if (response) {
                this.confirmChangePaymentMethod()
            }
            else {
                console.log('renewConfirmationAnsered=false')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.paymentMethods {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
</style>
