import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tagsNavigator"},[_c(VToolbar,{staticClass:"flex-grow-0"},[_c(VToolbarTitle,{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mobile)?_c('div',[_c('font-awesome-icon',{staticClass:"fa-fw",attrs:{"icon":_vm.icons.tags}})],1):_c('div',[_vm._v(_vm._s(_vm.$t('search.tags.header.title')))]),_c('Tag',{staticClass:"ml-2",attrs:{"tag":_vm.refTag}})],1),_c(VSpacer),(_vm.$vuetify.breakpoint.mobile)?_c('TagFilter',{attrs:{"tags":_vm.facetedSearchTags},on:{"tagFilterRequested":_vm.tagFilterRequested}}):_vm._e()],1),_c('div',{staticClass:"navigationBody"},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"navigationFilter",attrs:{"icon":""}},[_c('VerticalTags',{attrs:{"tags":_vm.facetedSearchTags,"selectedTags":_vm.searchedTags,"selectable":""},on:{"tagClicked":_vm.filterTagClicked}})],1):_vm._e(),_c('div',{staticClass:"taggedStockCardList"},[(_vm.stockIds.length === 0)?_c('div',[_c('div',{staticClass:"emptyTagList"},[_vm._v(_vm._s(_vm.$t('search.tags.results.empty')))]),_c('TagsDisplayer',{attrs:{"tags":_vm.tagsToDisplay},on:{"tagClicked":_vm.searchNewTag}})],1):_c(VCarousel,{attrs:{"light":"","height":_vm.$vuetify.breakpoint.mobile ? '100%' : 700,"hide-delimiters":_vm.$vuetify.breakpoint.mobile}},_vm._l((_vm.stockIds),function(stockId,i){return _c(VCarouselItem,{key:i,attrs:{"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c(VSheet,{staticClass:"carouselSheet",attrs:{"tile":""}},[(_vm.$vuetify.breakpoint.mobile)?_c('CompleteStockCardMobile',{attrs:{"stockId":stockId},on:{"tagClicked":_vm.searchNewTag}}):_c('CompleteStockCard',{staticClass:"mx-auto",attrs:{"stockId":stockId},on:{"tagClicked":_vm.searchNewTag}})],1)],1)}),1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }