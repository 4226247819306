<template>
    <v-chip @click="clicked" :outlined="!selected" color="var(--agora)" :text-color="selected ? 'white' : ''" close :close-icon="icon">{{tag.value}}</v-chip>
</template>

<script>
export default {
    props: ['tag'],
    data () {
        return {
            selected: false,
            icon: 'mdi-plus-circle'
        }
    },
    methods: {
        clicked() {
            this.selected = !this.selected;
            this.icon = this.selected ? undefined : 'mdi-plus-circle';
            this.$emit('clicked', { tag: this.tag, selected: this.selected });
        }
    }
}
</script>

<style scoped>

</style>
