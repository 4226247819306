import { confirmEmail, resendActivationToken } from "../../services/AuthenticationService";
var AccountActivationMixin = {
    data() {
        return {
            emailConfirmationPending: true,
            emailConfirmed: undefined
        };
    },
    created() {
        confirmEmail({ token: this.$route.query.token })
            .then(data => {
                this.emailConfirmationPending = false;
                this.emailConfirmed = true;
            })
            .catch(errors => {
                this.emailConfirmationPending = false;
                this.emailConfirmed = false;
            });
    },
    methods: {
        goHome() {
            this.$router.push({ name: 'Home' });
        },
        resend() {
            resendActivationToken({ oldToken: this.$route.query.token })
                .then(response => {
                    this.$router.push({
                        name: 'ActivationEmailSent',
                        params: { email: response }
                    });
                });
        }
    }
};

export default AccountActivationMixin;
