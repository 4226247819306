import StockHtmlHelper from '../../../stocks/StockHtmlHelper'
import ConfirmDialog from '../../../controls/ConfirmDialog.vue'
import { faHandPointLeft } from '@fortawesome/pro-light-svg-icons';
import Alert from '@/components/controls/Alert.vue'
import ListRegularIconMixin from '../../../help/icons/ListRegularIconMixin';
var listCardMixin = {
    mixins: [ListRegularIconMixin],
    data: () => ({
        fab: false,
        stockHtmlHelper: undefined,
        icons: {
            target: faHandPointLeft
        }
    }),
    components: { ConfirmDialog, Alert },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    methods: {
        viewList() {
            this.$router.push({ name: 'List', params: { id: this.list.id } });
        },

        editListInfo() {
            this.$emit('askEditBoardInfos');
        },

        deleteList() {
            this.$refs.deleteLListConfirmDialog.open({
                data: { listId: this.list.id, listType: this.listType },
                title: this.$t('Home.dashboard.lists.actions.deleteList.title'),
                text: this.$t('Home.dashboard.lists.actions.deleteList.text', [this.list.name])
            })
        },

        deleteListDialogAnswered(answer) {
            if (answer.response === true) {
                this.$store.dispatch('deleteList', answer.data).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                });
            }
        },

        getBadgeLabel(value) {
            const size = value.length;
            return this.$vuetify.breakpoint.mobile ? size : size < 3 ? value.join(" | ") : `${size} ${this.$t(this.stockHtmlHelper.getSymbolRessource(size))}`;
        },

        getBadgeBGColor(badge) {
            return this.stockHtmlHelper.getBadgeBGColor(badge);
        },

        getTooltip({ badge, type }) {
            switch (badge) {
                case '1':
                    return this.$t(`thresholds.${type}.t800`);
                case '2':
                    return this.$t(`thresholds.${type}.t600`);
                case '3':
                    return this.$t('thresholds.t0');
                case '4':
                    return this.$t(`thresholds.sell.t400`);
                case '5':
                    return this.$t(`thresholds.sell.t200`);
                default:
                    return this.$t('thresholds.t0');
            }
        }
    },
    computed: {
        showTargetedList() {
            return this.$store.state.list.focusedLists.some(l => l === this.list.id);
        },
        badges() {
            const badges = this.list.entries.reduce((acc, curr) => {
                const badge = this.stockHtmlHelper.getReleventBadge(curr.score);
                acc[badge] ? acc[badge].push(curr.symbol) : acc[badge] = [curr.symbol];
                return acc;
            }, {});
            return badges;
        }
    }
}

export default listCardMixin