<template>
    <div class="not-found">
        <h1>404 - {{ $t('error.404.title') }}</h1>
        <p>{{ $t('error.404.text') }}</p>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
.not-found {
    text-align: center;
    margin-top: 100px;
}
</style>
