import { faBiking, faCarSide, faHelicopter, faHiking, faPlane, faRocket, faStarshipFreighter, faWalking } from "@fortawesome/pro-light-svg-icons"

var planHelperMixin = {
    data() {
        return {
            icons: {
                pioneer: faHiking,
                base: faWalking,
                frugal: faBiking,
                standard: faCarSide,
                vip: faHelicopter,
                pro: faPlane,
                elite: faRocket,
                admin: faStarshipFreighter
            }
        }
    }
}

export default planHelperMixin