import StockPriceInfos from "../../models/StockPriceInfos";

class StockPriceInfoConverter {
    static convertPortfolioStock(portfolioStock) {
        const data = {
            price: portfolioStock.stockDateTimeSnapshot.price,
            change: portfolioStock.stockDateTimeSnapshot.change,
            changePercent: portfolioStock.stockDateTimeSnapshot.changePercent,
            currency: portfolioStock.stockDateTimeSnapshot.currency
        }
        return new StockPriceInfos(data);
    }

    static convertSuggestionStock(suggestionStock) {
        const data = {
            price: suggestionStock.lastPrice,
            change: suggestionStock.change,
            changePercent: suggestionStock.changePercent,
            currency: suggestionStock.currency
        }
        return new StockPriceInfos(data);
    }
}

export default StockPriceInfoConverter
