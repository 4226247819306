<template>
    <div class="listCard" @click="viewList">
        <div class="speedDialCont" @click.stop="">
            <v-speed-dial
                v-model="fab"
                direction="bottom"
                :open-on-hover=false
                transition="slide-y-transition"
            >
                <template v-slot:activator>
                    <v-btn
                        color="#1e90ff"
                        small
                        dark
                        fab
                        class="floatBtn"
                    >
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-btn
                    fab
                    dark
                    small
                    color="blue darken-2"
                    @click.native.stop="editListInfo"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    small
                    color="red darken-4"
                    @click="deleteList"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-speed-dial>
            <confirm-dialog ref="deleteLListConfirmDialog" @answered="deleteListDialogAnswered"></confirm-dialog>
        </div>
        <v-card-title class="cardTitle">
            <v-list-item class="px-0 listItem">
                <v-list-item-content class="cardtitleContent">
                    <div class="listItemTitle">{{list.name}}</div>
                    <v-toolbar-title class="grey--text text--darken-1 title-sm d-inline">{{list.description}}</v-toolbar-title>
                    <div :class="`targetedList${showTargetedList ? ' show' : ''}`">
                        <font-awesome-icon :icon="icons.target" class="fa-fw fa-lg" color="dodgerblue"></font-awesome-icon>
                    </div>
                </v-list-item-content>
            </v-list-item>
        </v-card-title>

        <div class="listInfos">{{list.entries.length}} {{$t(`Home.dashboard.lists.symbol${list.entries.length > 1 ? 's' : ''}`)}}</div>

        <div class="listScoreSummary">
            <v-tooltip v-for="key in Object.keys(badges).sort(function(a, b) { return a - b; })"
                :key="key"
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        v-if="$vuetify.breakpoint.mobile"
                        :class="[getBadgeBGColor(key), 'mr-2']"
                        size="30"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span class="white--text">{{getBadgeLabel(badges[key])}}</span>
                    </v-avatar>
                    <v-chip v-else v-bind="attrs" v-on="on" :class="[getBadgeBGColor(key), 'mr-2']">{{getBadgeLabel(badges[key])}}</v-chip>
                </template>
                <span>{{getTooltip({ badge: key, type: list.type })}}</span>
            </v-tooltip>
        </div>
        <Alert ref="iAlert"></Alert>
        <font-awesome-icon :icon="getListIconFromType(listType)" class="fa-fw bgIcon" color="#EEE"></font-awesome-icon>
    </div>
</template>

<script>
import listCardMixin from './ListCardMixin'
export default {
    props: ['list', 'listType'],
    name: 'ListCard',
    data () {
        return {
            input: {
                listName: "",
                listDescription: ""
            }
        }
    },
    mixins: [listCardMixin]
}
</script>

<style>
.actionSection{
    flex: none;
}
.v-btn--fab.v-size--small.floatBtn {
    opacity: 0;
}
.listCard {
    width: 100%;
    height: 100%;
    transition: background-color 0.5s ease;
    background-color: #FFF;
    position: relative;
    overflow: hidden;
}
.v-sheet.v-card.listCard {
    border-radius: 0;
}
.listCard:hover .v-btn--fab.v-size--small.floatBtn {
    opacity: 0.6;
}

.listCard:hover .v-btn--fab.v-size--small.floatBtn:hover {
    opacity: 1;
}

.bgIcon {
    position: absolute;
    bottom: -10px;
    right: 0;
    font-size: 130px;
    rotate: -15deg;
}

.cardTitle{
    padding: 15px;
}
.listItemTitle {
    font-size: x-large;
}
.cardtitleContent{
    padding: 0;
    position: relative;
}
.listItem {
    width: 100%;
}
.listInfos {
    padding: 5px 15px;
    color: #AAA;
    font-size: smaller;
    z-index: 10;
}
.title-sm {
    font-size: small;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.listScoreSummary {
    padding: 0 15px;
    z-index: 10;
    position: relative;
}
.targetedList {
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 0.5s;
    opacity: 0;
    animation: beat 1s ease-in-out infinite;
}
@keyframes beat {
    0% { transform: translateX(0px); }
    50% { transform: translateX(-10px); }
    100% { transform: translateX(0px); }
}
.targetedList.show {
    opacity: 1;
}
.speedDialCont {
    position: absolute;
    top: 30px;
    right: 15px;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .listCard {
        max-width: none;
    }
    .listItem {
        min-height: 20px;
    }
    .v-btn--fab.v-size--small.floatBtn {
        opacity: 1;
    }
    .cardtitleContent{
        padding: 0 !important;
    }
    .listItemTitle {
        font-size: large;
    }
}
@media screen and (max-width: 768px) {
    .listCard {
        max-width: none;
    }
    .listItem {
        min-height: 20px;
    }
    .v-btn--fab.v-size--small.floatBtn {
        opacity: 1;
    }
    .cardtitleContent{
        padding: 0 !important;
    }
    .listItemTitle {
        font-size: large;
    }
}
@media only screen and (max-device-width: 1200px) and (orientation:landscape) {
    .v-btn--fab.v-size--small.floatBtn {
        opacity: 1;
    }
}

</style>
