<template>
    <div class="invagoraPage">
        <div v-if="canceledSubscription" class="spd-message">
            {{$t('subscriptions.cancellation.waitUntilEnd')}}
        </div>
        <div class="sp-program">
            <v-img :src="require('@/assets/Stripe Climate Badge - Small.png')" position="center center" contain width="30" height="30" class="flex-grow-0"/>
            <div>{{$t('program.stripeClimate')}}</div>
        </div>
        <div v-if="showReadOnly">
            <SubscriptionPlanPanel></SubscriptionPlanPanel>
        </div>
        <div v-else>
            <div v-if="userSubscriptionInfoLoaded">
                <SubscriptionStepperMobile v-if="$vuetify.breakpoint.mobile" @notification="notify"></SubscriptionStepperMobile>
                <SubscriptionStepper v-else @notification="notify"></SubscriptionStepper>
            </div>
            <div v-else>
                <v-skeleton-loader type="card, list-item-two-line" width="500" height="320"></v-skeleton-loader>
            </div>
        </div>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
    </div>
</template>

<script>
import { authComputed } from '../../store/helpers/AuthenticationHelper'
import { faDollarSign, faSyncAlt } from "@fortawesome/pro-duotone-svg-icons";
import DateHelper from '../../infra/DateHelper'
import SubscriptionPlanPanel from '../../components/subscriptions/SubscriptionPlanPanel.vue';
import SubscriptionStepper from '../../components/subscriptions/SubscriptionStepper.vue';
import SubscriptionStepperMobile from '../../components/subscriptions/SubscriptionStepperMobile.vue';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import SySnackbar from '../../components/controls/SySnackbar.vue';
export default {
        data: () => ({
        showPlansButton: true,
        icons: {
            dollar: faDollarSign,
            sync: faSyncAlt,
            close: faTimes
        }
    }),
    computed: {
        ...authComputed,
        getUserSubscriptionInfo() {
            return this.userSubscriptionInfo;
        },
        userSubscriptionInfoLoaded() {
            return this.userSubscriptionInfo.planName !== undefined;
        },
        showReadOnly() {
            return !this.loggedIn || this.canceledSubscription || this.$store.getters.hasRole('demonstration');
        },
        subscriptionDaysLeft() {
            return DateHelper.daysBetween(new Date().toDateString(), this.userSubscriptionInfo.terminationInfo.terminationDate)
        }
    },
    components: {
        SubscriptionPlanPanel,
        SubscriptionStepper,
        SubscriptionStepperMobile,
        SySnackbar
    },
    methods: {
        calculatePlansButtonVisibility(userSubscriptionInfo) {
            if (Object.prototype.hasOwnProperty.call(userSubscriptionInfo, "planDefinition")) {
                this.showPlansButton = userSubscriptionInfo.planDefinition.planName === 'base';
            }
        },
        notify({ type, text }) {
            this.$refs.iSySnackbar.show({ type, text })
        }
    },
    watch: {
        getUserSubscriptionInfo: {
            handler: function (val) {
                this.calculatePlansButtonVisibility(val);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/breakpoints.scss";
.invagoraPage {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--lightBG);
    overflow: auto;
    .sp-program {
        display: flex;
        gap: 20px;
        font-size: smaller;
        padding: 15px;
        justify-content: center;
        align-items: center;
        background-color: #e6ffe9;
        color: #00660f;
    }
    .spd-message {
        background-color: #F2F2F2;
        border-bottom: solid 1px;
        padding: 30px;
        font-size: larger;
        text-align: center;
    }
    @media screen and (max-width: $small) {
        position: static;
        .sp-program {
            font-size: x-small;
        }
        .spd-message{
            font-size: 1rem;
            margin-bottom: 10px;
        }
    }
}
</style>