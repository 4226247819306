<template>
    <div class="appMessages">
        <AppMessage v-for="(message, i) in messages" :key="i" v-bind="message" @dismissed="dismiss"></AppMessage>
    </div>
</template>

<script>
import storageService from '../../services/StorageService';
import AppMessage from './AppMessage.vue';
export default {
    components: { AppMessage },
    data() {
        return {
            storage: undefined
        }
    },
    computed: {
        messages() {
            return this.$store.getters.messages
        }
    },
    methods: {
        dismiss({ code }) {
            storageService.addDismissAppMessageKey({ key: code, info: new Date() })
        }
    }
}
</script>

<style lang="scss" scoped>
.appMessages {
    display: flex;
    flex-direction: column;
    gap: 1px;
}
</style>
