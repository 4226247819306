import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changeNameDialog"},[_c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue-grey","fab":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.edit}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue darken-2","dark":""}},[_vm._v(_vm._s(_vm.$t('settings.body.modifyNameDialog.title')))]),_c(VCardText,{staticClass:"py-2"},[_c(VContainer,{staticClass:"px-0"},[_c(VTextField,{attrs:{"name":"firstname","label":_vm.$t('settings.body.firstname'),"type":"text","outlined":"","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-account","autocomplete":"new-password"},model:{value:(_vm.input.firstname),callback:function ($$v) {_vm.$set(_vm.input, "firstname", $$v)},expression:"input.firstname"}}),_c(VTextField,{attrs:{"name":"lastname","label":_vm.$t('settings.body.lastname'),"type":"text","outlined":"","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-account","autocomplete":"new-password"},model:{value:(_vm.input.lastname),callback:function ($$v) {_vm.$set(_vm.input, "lastname", $$v)},expression:"input.lastname"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.confirmChangeFullname()}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.check}})],1),_c(VBtn,{attrs:{"color":"secondary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.closeFullnameEdition()}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.times}})],1)],1)],1)],1),_c('Alert',{ref:"iAlert"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }