<template>
    <div class="stockList">
        <div class="listHeader">
            <div class="listTitle">{{ $t(title) }}</div>
        </div>
        <div v-if="listLoaded" class="cardList">
            <SwivleCard v-for="(list, index) in getLists" :key="index" :list="list" :listType="type"></SwivleCard>
            <SwivelAddCard :listType="type"></SwivelAddCard>
        </div>
        <v-skeleton-loader v-else type="card, list-item-two-line" height="320"></v-skeleton-loader>
    </div>
</template>

<script>
import SwivelAddCard from '../home/dashboard/boards/SwivelAddCard.vue';
import SwivleCard from '../home/dashboard/boards/SwivleCard.vue';
//import UserSubscriptionHelper from '../plan/UserSubscriptionHelper';

export default {
    props: ['type'],
    components: { SwivleCard, SwivelAddCard },
    data() {
        return {
            userSubscriptionHelper: undefined,
            canAddAList: false
        }
    },
    computed: {
        title() {
            return `Home.dashboard.summary.${this.type}Lists`
        },
        // userSubscriptionInfo() {
        //     return this.$store.getters.userSubscriptionInfo;
        // },
        listLoaded() {
            return this.$store.getters.allListAreLoaded
        },
        getLists() {
            const listType = `${this.type}Lists`
            return this.$store.getters.userLists[listType]
        }
    }//,
    // watch: {
    //     userSubscriptionInfo: {
    //         handler(val) {
    //             if (Object.prototype.hasOwnProperty.call(val, "usage")) {
    //                 this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: val });
    //                 switch (this.type) {
    //                     case "buy":
    //                         this.canAddAList = this.userSubscriptionHelper.canAddBuyList();
    //                         break;
    //                     case "sell":
    //                         this.canAddAList = this.userSubscriptionHelper.canAddSellList();
    //                         break;
    //                     default:
    //                         this.canAddAList = false;
    //                 }
    //             }
    //         },
    //         deep: true
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.stockList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .listHeader {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        align-items: center;
        .listTitle {
            font-size: large;
            display: flex;
            align-items: center;
        }
    }
    .cardList {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
</style>