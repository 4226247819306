<template>
    <v-expansion-panels focusable>
        <v-expansion-panel>
            <v-expansion-panel-header>{{ $t('UserStockWatchingList.portfolio.summary.title') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-data-table
                    :headers="headers"
                    :items="stockValues"
                    dense
                    disable-sort
                    hide-default-footer
                >
                    <template v-slot:item.row="{ item }">
                        <strong>{{ item.row }}</strong>
                    </template>
                </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import StockMixin from '../stocks/StockMixin'

export default {
    props: ['portfolioId'],
    mixins: [StockMixin],
    data() {
        return {
            headers: [
                { text: '', value: 'row' },
                { text: this.$t('UserStockWatchingList.portfolio.summary.Unmaterialized'), value: 'nonMate', align: 'right' },
                { text: this.$t('UserStockWatchingList.portfolio.summary.materialized'), value: 'mate', align: 'right' },
                { text: this.$t('UserStockWatchingList.portfolio.summary.combined'), value: 'combined', align: 'right' }
            ]
        }
    },
    computed: {
        portfolioStockInfo() {
            return this.stockId ? this.$store.getters.portfolioStock({ portfolioId: this.portfolioId, stockId: this.stockId }) : {}
        },
        stockValues() {
            const stats = this.portfolioStockInfo.statistics
            if (!stats) return []
            const formattedStats = {};
            Object.entries(stats).forEach(([key, value]) => {
                if (key.includes('Share')) {
                    formattedStats[key] = value;
                } else {
                    formattedStats[key] = parseFloat(value).toFixed(2);
                }
            });
            return [
                { row: 'Nb. stock', nonMate: formattedStats.remainingStockShareCount, mate: formattedStats.totalShareSoldCount, combined: formattedStats.totalPurchasedShareCount },
                { row: 'Valeur $', nonMate: formattedStats.totalRemainingStockValue, mate: formattedStats.totalMaterializedGrossGains, combined: formattedStats.totalMaterializedGrossValueIncludingUnrealized },
                { row: 'Gain $', nonMate: formattedStats.totalUnmaterializedProfit, mate: formattedStats.totalMaterializedProfit, combined: formattedStats.totalProfitIncludingUnrealized },
                { row: 'Gain %', nonMate: formattedStats.totalPercentageUnmaterializedProfit, mate: formattedStats.percentageOfMaterializedProfit, combined: formattedStats.percentageOfProfitIncludingUnrealized }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
}
::v-deep .v-expansion-panel > .v-expansion-panel-header {
    min-height: 0;
    padding: 10px;
    font-weight: bold;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 0;
}
</style>