<template>
    <div v-if="isDisplayPC" class="priceInfos">
        <div class="currency">{{currency}}</div>
        <div class="price">{{formatPrice(lastPrice)}}</div>
    </div>
    <div v-else class="priceInfos">
        <div class="currency">{{currency}}</div>
        <div class="price">{{formatPrice(lastPrice)}}</div>
    </div>
</template>

<script>
import StockHtmlHelper from './StockHtmlHelper'
export default {
    name: 'StockPriceInfos',
    props: ['entry', 'config'],
    data () {
        return {
            display: this.config || "PC"
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        isDisplayPC() {
            return this.display === "PC"; // Price-Currency
        },
        currency() {
            return this.entry ? this.entry.currency : '';
        },
        lastPrice() {
            return this.entry ? this.entry.lastPrice : 0;
        }
    },
    methods: {
        formatPrice(price) {
            return this.stockHtmlHelper.formatPrice(price);
        }
    }
}
</script>

<style scoped>
.priceInfos{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.currency{
    color:var(--midGray);
    font-size: 0.8rem;
}
.price {
    font-weight: bold;
}
</style>
