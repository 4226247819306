import PropertyMetas from '../../models/PropertyMetas'

class PropertyMetasConverter {
    static convert({ propertiesMetas = [] }) {
        const propertyMetasList = {};
        propertiesMetas.forEach(propertyMetas => {
            propertyMetasList[propertyMetas.name] = new PropertyMetas({ name: propertyMetas.name, value: propertyMetas.value, dateTime: propertyMetas.dateTime, metas: propertyMetas.metas });
        });
        return propertyMetasList;
    }
}

export default PropertyMetasConverter
