<template>
    <v-dialog v-model="dialog" scrollable content-class="stockFullCardDialog" @click:outside="close">
        <div class="stockFullCardCont">
            <div class="dialogBody">
                <StockFullCard ref="stockFullCard" :stock="stock"></StockFullCard>
            </div>
            <div class="dialogFooter">
                <v-btn
                    color="red"
                    fab
                    small
                    dark
                    @click="remove"
                >
                    <font-awesome-icon :icon="icons.delete" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="close"
                    class="ml-3"
                >
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg" color="primary"></font-awesome-icon>
                </v-btn>
            </div>
            <ConfirmDialog ref="dirtyConfirmation" @answered="dirtyConfirmationAnswered"></ConfirmDialog>
        </div>
    </v-dialog>
</template>

<script>
import EditStockDialogMixin from './EditStockDialogMixin'
export default {
    mixins: [EditStockDialogMixin]
}
</script>

<style scoped>
>>>.stockFullCardDialog {
    max-width: 1200px;
    max-height: 100%;
}
.stockFullCardCont {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
}
.dialogFooter {
    border-top: solid 1px #DDD;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    background-color: #FFF;
}
</style>
