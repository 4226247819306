<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card class="d-flex flex-column">
            <v-toolbar
                dark
                color="primary"
                class="flex-grow-0"
            >
                <v-toolbar-title>{{$t('search.title')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="dialogBody">
                <slot></slot>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        show() {
            this.dialog = true;
        }
    }
}
</script>

<style scoped>
.dialogBody{
    flex: 1;
    display: flex;
    flex-direction: column;
}
</style>
