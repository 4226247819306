import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changePasswordDialog"},[_c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue-grey","fab":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.edit}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue darken-2","dark":""}},[_vm._v(_vm._s(_vm.$t('settings.body.managePaymentMethodsDialog.title')))]),_c('div',{staticClass:"paymentMethods"},[_c('CreditCardSelector',{ref:"creditCardSelector",attrs:{"editable":true},on:{"changed":_vm.ccChanged,"askForNewCard":_vm.askForNewCard,"defaultPayementMethodChanged":_vm.defaultPayementMethodChanged}}),(_vm.showNewCardForm)?_c('StripePaymentInfoPanel',{ref:"paymentInfoPanel"}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":_vm.confirmButtonClicked}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.check}})],1),_c(VBtn,{attrs:{"color":"secondary","fab":"","small":"","dark":""},on:{"click":_vm.closeChangePaymentMethod}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.times}})],1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1),_c('ConfirmDialog',{ref:"renewConfirm",on:{"answered":_vm.renewConfirmationAnsered}}),_c('Alert',{ref:"iAlert"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }