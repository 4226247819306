<template>
    <div class="dashboard">
        <div class="dSection dark">
            <div class="dsHeader">{{ $t('Home.dashboard.overview.allPortfolios') }}</div>
            <font-awesome-icon :icon="icons.portfolio" class="fa-fw sectionIcon" color="#EEE"></font-awesome-icon>
            <DashboardOverview2></DashboardOverview2>
            <PortfolioWidget></PortfolioWidget>
        </div>
        <div class="dSection">
            <div class="dsHeader">{{ $t('Home.dashboard.overview.allBuyLists') }}</div>
            <font-awesome-icon :icon="icons.buy" class="fa-fw sectionIcon" color="#333"></font-awesome-icon>
            <BestSuggestionsWidget type="buy"></BestSuggestionsWidget>
            <ListWidget type="buy"></ListWidget>
        </div>
        <div class="dSection">
            <div class="dsHeader">{{ $t('Home.dashboard.overview.allSellLists') }}</div>
            <font-awesome-icon :icon="icons.sell" class="fa-fw sectionIcon" color="#333"></font-awesome-icon>
            <BestSuggestionsWidget type="sell"></BestSuggestionsWidget>
            <ListWidget type="sell"></ListWidget>
        </div>
    </div>
</template>

<script>
import ListRegularIconMixin from '../../components/help/icons/ListRegularIconMixin';
import DashboardOverview2 from '../../components/home/dashboard/DashboardOverview2.vue';
import BestSuggestionsWidget from '../../components/list/BestSuggestionsWidget.vue';
import ListWidget from '../../components/list/ListWidget.vue';
import PortfolioWidget from '../../components/portfolio/PortfolioWidget.vue';
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
export default {
    mixins: [UserDataHelperMixin, ListRegularIconMixin],
	components: { DashboardOverview2, PortfolioWidget, BestSuggestionsWidget, ListWidget }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.dashboard {
    height: 100%;
    background-color: #FFF;
    display: flex;
    gap: 25px;
    padding: 20px;
    .dSection {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        background-color: var(--lightBG);
        border-radius: 10px;
        padding: 20px;
        position: relative;
        overflow: hidden;
        .sectionIcon {
            opacity: 0.2;
            position: absolute;
            top: -5px;
            right: 20px;
            font-size: 90px;
            rotate: -15deg;
        }
        &.dark {
            background-color: var(--darkBG);
            .dsHeader {
                color: #FFF;
            }
        }
        .dsHeader {
            font-size: 1.5em;
            font-weight: bold;
            border-radius: 10px 10px 0 0;
        }
    }
    @media screen and (max-width: $large) {
        padding: 20px 10px;
        gap: 10px;
    }
}
</style>