<template>
    <div :class="`invTicker ${extraClasses}`">
        <div class="tName">{{ name }}</div>
        <div class="tValue">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 0
        },
        extraClasses: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/breakpoints.scss";
.invTicker {
    display: flex;
    align-items: center;
    gap: 5px;
    .tName {
    }
    .tValue {
        font-weight: bold;
        color: var(--agora);
    }
    @media screen and (max-width: $small) {
        font-size: 0.75em;
    }
}
</style>