<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="500"
    >
        <v-card>
            <v-card-title class="headline">{{confirmationTitle}}</v-card-title>
            <div v-for="(txt, i) in confirmationText" :key="i" class="confirmText" v-html="txt"></div>
            <v-card-actions class="justify-center">
                <v-btn
                    color="primary"
                    @click="confirm"
                    fab
                    class="mr-5"
                >
                    <font-awesome-icon :icon="icons.confirm" class="fa-fw fa-2x"></font-awesome-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    fab
                    @click="deny"
                >
                    <font-awesome-icon :icon="icons.deny" class="fa-fw fa-2x"></font-awesome-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
export default {
    name: 'ConfirmDialog',
    data() {
        return {
            dialog: false,
            icons: {
                confirm: faThumbsUp,
                deny: faThumbsDown
            },
            confirmationTitle: undefined,
            confirmationText: undefined,
            data: undefined
        }
    },
    methods: {
        open ({ title, text, data }) {
            this.confirmationTitle = title;
            this.confirmationText = Array.isArray(text) ? text : [text];
            this.data = data;
            this.dialog = true
        },
        confirm() {
            this.sendAnswer({ response: true });
        },
        deny() {
            this.sendAnswer({ response: false });
        },
        sendAnswer({ response }) {
            this.$emit('answered', { response, data: this.data });
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
.headline{
    word-break: keep-all;
}
.confirmText {
    padding: 0px 25px 20px;
    font-size: large;
}
>>>.bold{
    font-weight: bold;
}
>>>.capBold {
    text-transform: capitalize;
    font-weight: bold;
}
>>>.v-dialog > .v-card > .v-card__actions{
    padding: 8px 24px 20px;
}
</style>
