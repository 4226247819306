<template>
    <div class="stockPage">
        <StockPageHeader></StockPageHeader>
        <v-tabs :vertical="verticalTabs">
            <v-tab class="flex-start">
                <v-icon left>mdi-finance</v-icon>
                {{ $t('stockPage.tabs.quote') }}
            </v-tab>
            <v-tab class="flex-start" @click="loadAiAnalysis">
                <v-icon left>mdi-memory</v-icon>
                {{ $t('stockPage.tabs.ai_analysis') }}
            </v-tab>
            <v-tab-item>
                <StockQuote></StockQuote>
            </v-tab-item>
            <v-tab-item>
                <StockAiAnalysis></StockAiAnalysis>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import StockAiAnalysis from '../../components/stocks/StockAiAnalysis.vue';
import StockQuote from '../../components/stocks/StockQuote.vue';
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
import StockPageHeader from './StockPageHeader.vue';

export default {
    mixins: [UserDataHelperMixin],
    components: { StockQuote, StockAiAnalysis, StockPageHeader },
    data() {
        return {
            stockId: this.$route.params.id
        }
    },
    beforeMount() {
        this.init();
    },
    computed: {
        verticalTabs() {
            return this.$vuetify.breakpoint.width > 1440;
        }
    },
    methods: {
        init() {
            const loadedStockId = this.$store.getters.completeStockInfo ? this.$store.getters.completeStockInfo.stockId : null;
            if (this.stockId !== loadedStockId) {
                this.$store.dispatch('clearStockInfo');
                this.$store.dispatch('getQuoteContextualInfo', { stockId: this.stockId, locale: this.$i18n.locale });
                this.$store.dispatch('getStockCompleteInfo', { stockId: this.stockId });
            }
        },
        loadAiAnalysis() {
            this.$store.dispatch('getAiContextualInfo', { stockId: this.stockId, locale: this.$i18n.locale });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.stockPage {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .v-tab.flex-start {
        justify-content: flex-start;
    }
    .v-tab.v-tab--active {
        background-color: var(--darkBG);
    }
    ::v-deep .v-tabs-items {
        padding: 20px;
        background-color: var(--darkBG);
    }
    ::v-deep .v-tab.v-tab--active {
        color: #FFF;
    }
    @media screen and (min-width: $xlarge) {
        padding: 20px 0;
    }
    @media screen and (max-width: $small) {
        ::v-deep .v-tabs-items {
            padding: 10px;
        }
    }
}
</style>