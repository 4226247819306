<template>
    <div class="emptyList">
        <div class="elTitle">{{ $t('UserStockWatchingList.emptyList.title') }}</div>
        <div class="elDesc">{{ $t('UserStockWatchingList.emptyList.text') }}</div>
        <v-btn color="blue darken-4" dark fab @click="addClicked">
            <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
        </v-btn>
    </div>
</template>

<script>
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

export default {
    data() {
        return {
            icons: {
                add: faPlus
            }
        }
    },
    methods: {
        addClicked() {
            this.$emit('addAsked')
        }
    }
}
</script>

<style lang="scss" scoped>
.emptyList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 15px;
    background-color: var(--v-superLightGrayBG-base);
    .elTitle {
        font-size: 1.2em;
        font-weight: bold;
    }
    .elDesc {
        text-align: center;
        font-size: 0.8em;
        color: var(--midGray);
    }
}
</style>