import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"currencySelector"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"dark":_vm.dark,"color":_vm.btnColor,"small":_vm.$vuetify.breakpoint.xs,"large":_vm.large !== undefined ? _vm.large : false}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"--fa-secondary-color":"#000"},attrs:{"icon":_vm.icons[_vm.baseCurrencyIcon]}}),_vm._v(" "+_vm._s(_vm.baseCurrencyText)+" ")],1)]}}])},[_c(VList,_vm._l((_vm.currencies),function(c,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.setBaseCurrency(c)}}},[_c(VListItemTitle,[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":_vm.icons[c.icon]}}),_vm._v(" "+_vm._s(c.text)+" ")],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }