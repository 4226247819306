import serverService from './ApiWrapper'

class InfosService {
    getAppVersion() {
        return serverService.post('/api/Basics/AppVersion')
    }

    getSystemDateTime() {
        return serverService.get('/api/Basics/GetSystemDateTime')
    }

    getPublicMainInfos() {
        return serverService.get('/api/Infos/publicMainInfos')
    }
}

export default InfosService
