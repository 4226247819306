var StockPriceHelperMixin = {
    data() {
        return {
        }
    },
    methods: {
        convertValue(value, currentCurrency, targetCurrency) {
            if (currentCurrency && targetCurrency) {
                const forexKey = `${currentCurrency.toUpperCase()}${targetCurrency.toUpperCase()}`
                const rate = this.exchangeRate(forexKey)
                return (value || 0) * rate
            }
            return this.$t('dictio.na')
        },
        exchangeRate(forexKey) {
            if (forexKey) {
                const key = forexKey.toUpperCase()
                return this.$store.getters.getCurrencyExchangeRates ? this.$store.getters.getCurrencyExchangeRates[key] : null
            }
            return null
        }
    }
}

export default StockPriceHelperMixin