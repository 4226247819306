import { render, staticRenderFns } from "./ResetPasswordRequestSent.vue?vue&type=template&id=349cf4d9&scoped=true&"
import script from "./ResetPasswordRequestSent.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordRequestSent.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/password.css?vue&type=style&index=0&id=349cf4d9&prod&scoped=true&lang=css&"
import style1 from "./ResetPasswordRequestSent.vue?vue&type=style&index=1&id=349cf4d9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349cf4d9",
  null
  
)

export default component.exports