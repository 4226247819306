import { register } from '../../services/AuthenticationService'
import AutomatedAbuseService from '../../services/AutomatedAbuseService'
import RecaptchaText from '../../components/controls/RecaptchaText'
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons'
import JWT from '../../infra/JWT'
import storageService from '../../services/StorageService'
import termsData from '../Policies/terms.json'
var registerMixin = {
    data() {
        return {
            valid: true,
            icons: {
                addUser: faUserPlus
            },
            input: {
                email: "",
                uniqueEmail: true,
                firstname: "",
                lastname: "",
                password: '',
                passwordValidation: true,
                passwordConfirmation: ''
            },
            showPassword: false,
            isTermsAndConditionAccepted: false,
            termsAndConditionDialog: false,
            rules: {
                required: value => !!value || this.$t('register.form.rules.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('register.form.rules.invalidEmail')
                },
                pswLength: value => {
                    const pattern = /^(?=.{8,})/;
                    return (
                        pattern.test(value) || this.$t('register.form.errors.NotEnoughCharacters')
                    );
                },
                pswNumber: value => {
                    const pattern = /^(?=.*[0-9])/;
                    return (
                        pattern.test(value) || this.$t('register.form.errors.NotEnoughNumber')
                    );
                },
                pswLowercase: value => {
                    const pattern = /^(?=.*[a-z])/;
                    return (
                        pattern.test(value) || this.$t('register.form.errors.NotEnoughLowerLetters')
                    );
                },
                pswCapital: value => {
                    const pattern = /^(?=.*[A-Z])/;
                    return (
                        pattern.test(value) || this.$t('register.form.errors.NotEnoughCapitalLetters')
                    );
                },
                pswPonct: value => {
                    const pattern = /^(?=.*[!@#$%^&*])/;
                    return (
                        pattern.test(value) || this.$t('register.form.errors.NotEnoughSpecialCharacters')
                    );
                }
            }
        }
    },
    components: {
        RecaptchaText
    },
    computed: {
        passwordConfirmationRule() {
            return (this.input.password === this.input.passwordConfirmation) || this.$t('register.form.errors.passwordsMustMatch');
        },
        uniqueEmail() {
            return this.input.uniqueEmail || this.$t('register.form.errors.emailAlreadyUsed');
        },
        passwordValidation() {
            return this.input.passwordValidation;
        },
        createDisabled() {
            return !(this.input.password !== '' && this.input.passwordConfirmation !== '' && this.valid && this.isTermsAndConditionAccepted)
        }
    },
    mounted() {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6Ld_09AZAAAAACeeLilid2feOtfQ0WpMyWN1fnwK');
        recaptchaScript.async = true;
        recaptchaScript.defer = true;
        document.head.appendChild(recaptchaScript);
        if (storageService.inviteToken) {
            const jwt = new JWT(storageService.inviteToken);
            const payload = jwt.payload;
            this.input.email = payload.email;
            this.input.firstname = payload.firstname;
            this.input.lastname = payload.lastname;
        }
    },
    methods: {
        gotologin() {
            this.$router.push({ name: 'Login' });
        },
        showPasswordIcon_clicked() {
            this.showPassword = !this.showPassword;
        },
        createAccountAsked() {
            if (this.$refs.form.validate()) {
                AutomatedAbuseService.validate({ vueComp: this, action: 'register' }).then(token => {
                    this.registerAsked({ token });
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        emailInput() {
            this.input.uniqueEmail = true
        },
        deleteInvitationToken() {
            if (storageService.inviteToken) {
                storageService.deleteInviteToken();
            }
        },
        registerAsked({ token }) {
            const termsAndConditionsAnswer = {
                version: parseInt(termsData.version),
                areTermsAndContionsAccepted: this.isTermsAndConditionAccepted
            }
            const data = {
                email: this.input.email,
                firstname: this.input.firstname,
                lastname: this.input.lastname,
                password: this.input.password,
                passwordConfirmation: this.input.passwordConfirmation,
                twoLettersPreferredLanguage: this.$i18n.locale,
                token,
                inviteToken: undefined,
                termsAndConditionsAnswer
            };
            if (storageService.inviteToken) {
                data.inviteToken = storageService.inviteToken;
            }
            register(data).then((response) => {
                window.gtag_report_conversion();
                this.deleteInvitationToken();
                switch (response.emailConfirmationStatus) {
                    case 'confirmed':
                        this.$router.push({ name: 'AccountCreated' });
                        break;
                    case 'notConfirmed':
                        this.$router.push({ name: 'ActivationEmailSent', params: { email: this.input.email } });
                        break;
                }
            }).catch(errors => {
                if (Array.isArray(errors)) {
                    errors.forEach(err => {
                        switch (err.errorCode) {
                            case 'EmailAlreadyUsed':
                                this.input.uniqueEmail = false
                                break
                            case 'InvitationValidationProcessFailed':
                                this.$refs.iAlert.error({ errorCode: err.errorCode })
                                break
                            default:
                                this.input.passwordValidation = this.$t(`register.form.errors.${err.errorCode}`)
                                break
                        }
                    });
                }
                else {
                    this.$refs.sySnackbar.error({ text: this.$t('register.form.errors.default') })
                    console.error(errors)
                }
            });
        }
    }
}

export default registerMixin
