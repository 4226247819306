class PortfolioStock {
    constructor({ portfolioId, stockId, note, addedDate, stockDateTimeSnapshot, buyLots, sellLots, statistics }) {
        this._portfolioId = portfolioId
        this._stockId = stockId
        this._note = note
        this._addedDate = addedDate
        this._stockDateTimeSnapshot = stockDateTimeSnapshot
        const b = buyLots || []
        const s = sellLots || []
        this._lots = this.mergeLots({ buyLots: b, sellLots: s })
        this._statistics = statistics
    }

    mergeLots({ buyLots, sellLots }) {
        const lots = buyLots.map(l => this.adaptLot({ ...l }))
        Array.prototype.push.apply(lots, sellLots.map(l => this.adaptLot({ ...l })))
        return lots
    }

    adaptLot({
        lotId,
        averageTransactionPrice,
        dateOfTransaction,
        quantity,
        note,
        portfolioStockTransationType,
        standardizedTotalGain,
        standardizedTotalGainPourcentage
    }) {
        return {
            lotId,
            averageTransactionPrice,
            dateOfTransaction,
            quantity,
            note,
            portfolioStockTransationType,
            standardizedTotalGain,
            standardizedTotalGainPourcentage
        }
    }

    addLot(lot) {
        this._lots.push(lot)
    }

    updateLot(lotUpdated) {
        this._lots = this._lots.map(l => l.lotId === lotUpdated.lotId ? lotUpdated : l)
    }

    removeLot(lotId) {
        this._lots = this._lots.filter(l => l.lotId !== lotId)
    }

    get portfolioId() { return this._portfolioId }
    get stockId() { return this._stockId }
    get note() { return this._note }
    get addedDate() { return this._addedDate }
    get stockDateTimeSnapshot() { return this._stockDateTimeSnapshot }
    get lots() { return this._lots }
    get statistics() { return this._statistics }
    get dateInfos() {
        return {
            priceDateTime: this._stockDateTimeSnapshot.dateTime,
            isPriceIntraDay: this._stockDateTimeSnapshot.isPriceIntraDay
        }
    }
}

export default PortfolioStock