class StockPriceInfos {
    constructor({ price, change, changePercent, currency }) {
        this._price = price;
        this._change = change;
        this._changePercent = changePercent;
        this._currency = currency;
    }

    get price() {
        return this._price;
    }

    set price(value) {
        this._price = value;
    }

    get change() {
        return this._change;
    }

    set change(value) {
        this._change = value;
    }

    get changePercent() {
        return this._changePercent;
    }

    set changePercent(value) {
        this._changePercent = value;
    }

    get currency() {
        return this._currency;
    }

    set currency(value) {
        this._currency = value;
    }

    get dayGain() {
        if (!this._change || !this._changePercent) return {};
        return {
            val: this._change,
            chg: this._changePercent,
            chgSymbol: "%"
        }
    }
}

export default StockPriceInfos;