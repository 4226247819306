import { authorizationComputed } from "../store/helpers/AuthorizationHelper";

var UserDataHelperMixin = {
    data() {
        return {
            refreshInterval: 60000,
            loaded: false,
            timer: ''
        }
    },
    created() {
        this.fetchUserDataIfNeeded().then(() => {
            this.loaded = true;
        }).catch(error => { console.error(error) });
    },
    computed: {
        ...authorizationComputed
    },
    methods: {
        fetchUserData() {
            return new Promise((resolve) => {
                const allPromises = [
                    this.$store.dispatch('getAllBuyListWithEntries'),
                    this.$store.dispatch('getAllSellListWithEntries'),
                    this.$store.dispatch('getUserSubscriptionInfo'),
                    this.$store.dispatch('getUserAllPortfolios'),
                    this.$store.dispatch('fetchAllPlans'),
                    this.$store.dispatch('initPreferences')
                ];
                Promise.all(allPromises)
                    .then(([allBuyListResult, allSellListResult, subscriptionResult, allPortfoliosResult]) => {
                        if (subscriptionResult) {
                            this.$store.dispatch('setMyPlan', { planDefinition: subscriptionResult.subscription.planDefinition })
                        }
                        resolve();
                    })
                    .catch(error => {
                        console.error(error)
                    })
            });
        },
        fetchUserDataIfNeeded() {
            return new Promise((resolve) => {
                if (!this.$store.getters.allListAreLoaded) {
                    this.loaded = false;
                    this.fetchUserData().then(() => {
                        this.loaded = true;
                        resolve();
                    });
                }
                else {
                    resolve();
                }
            });
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        }
    },
    beforeDestroy() {
        this.cancelAutoUpdate();
    }
}

export default UserDataHelperMixin
