<template>
    <div class="stockInfos">
        <div v-if="closable" class="pscClose">
            <v-btn icon small @click="close">
                <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
            </v-btn>
        </div>
        <div class="stockProfile">
            <div class="companyName">{{entry.companyName}}</div>
            <div class="symbol">({{entry.symbol}})</div>
        </div>
        <div class="stockPrice">
            <div class="pricing-left">
                <StockPriceInfos :entry="entry" :key="entry.id + '-spi'"></StockPriceInfos>
                <StockPriceChange :stockChangeInfo="stockChangeInfo" :key="entry.id + '-spc'"></StockPriceChange>
            </div>
            <div class="pricing-right">
                <stock-price-date :entry="entry"></stock-price-date>
            </div>
        </div>
    </div>
</template>

<script>
import StockHtmlHelper from './StockHtmlHelper'
import StockPriceInfos from './StockPriceInfos'
import StockPriceChange from './StockPriceChange'
import StockPriceDate from './StockPriceDate.vue'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
export default {
    props: { entry: Object, closable: { type: Boolean, default: false } },
    data() {
        return {
            icons: {
                close: faTimes
            },
            stockHtmlHelper: undefined,
            color: "",
            badge: ""
        }
    },
    components: {
        StockPriceInfos,
        StockPriceChange,
        StockPriceDate
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        this.badge = this.stockHtmlHelper.getBadge(this.entry.highScore);
        this.color = this.stockHtmlHelper.getBadgeColor(this.badge);
    },
    computed: {
        stockChangeInfo() {
            return this.stockHtmlHelper.getStockChangeInfo(this.entry);
        }
    },
    methods: {
        getChangeColor(changePrct) {
            return this.stockHtmlHelper.getChangeColor(changePrct);
        },
        formatChange(change, price) {
            return change !== undefined ? change.toFixed(price < 5 ? 4 : 2) : 'N/A';
        },
        getChangeIcon(change) {
            return change > 0 ? this.up : change < 0 ? this.down : this.null;
        },
        formatPrice(price) {
            return this.stockHtmlHelper.formatPrice(price);
        },
        formatChangePrct(changePrct) {
            return changePrct !== undefined ? changePrct.toFixed(2) : 'N/A';
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.stockInfos{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}
.pscClose {
    position: absolute;
    top: -5px;
    right: -5px;
}
.stockProfile{
    flex: 1;
    display: flex;
    font-weight: bold;
    font-size: large;
    margin-bottom: 5px;
}
.companyName{
    margin-right: 10px;
}
.stockPrice{
    display: flex;
    justify-content: space-between;
    font-size: smaller;
}
.pricing-left, .pricing-right {
    display: flex;
    align-items: center;
}
.pricing-left>*{
    margin-right: 15px;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .pricing-left{
        flex-direction: column;
    }
}
@media screen and (max-width: 750px) {
    .stockProfile{
        font-size: smaller;
    }
    .stockPrice{
        flex-direction: column;
    }
    .pricing-right{
        justify-content: flex-end;
    }
}
</style>
