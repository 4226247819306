import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"agora"}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg mr-2",attrs:{"icon":_vm.icons.move}}),_vm._v(" "+_vm._s(_vm.$t('UserStockWatchingList.portfolio.actions.move.title'))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"portfolioMoveStockDialog"},[_c('div',{staticClass:"dialogHeader"},[_vm._v(_vm._s(_vm.$t('UserStockWatchingList.portfolio.actions.move.text')))]),_c('div',{staticClass:"dialogBody"},[_c('div',{staticClass:"portfolios"},[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('UserStockWatchingList.portfolio.actions.move.actual')))]),_c('div',{staticClass:"actualPortofolio"},[_vm._v(_vm._s(_vm.actualProfolio.name))])],1),_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('UserStockWatchingList.portfolio.actions.move.to')))]),_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedPortfolio),callback:function ($$v) {_vm.selectedPortfolio=$$v},expression:"selectedPortfolio"}},_vm._l((_vm.portfoliosAvailable),function(p,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(p.name)}})],1)],1)}),1)],1)],1)]),_c('div',{staticClass:"dialogFooter"},[_c(VSpacer),_c(VBtn,{attrs:{"fab":"","small":"","color":"primary","disabled":_vm.confirmMoveDisabled},on:{"click":_vm.confirm}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.ok}})],1),_c(VBtn,{attrs:{"fab":"","small":"","dark":""},on:{"click":_vm.cancel}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.cancel}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }