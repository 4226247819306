import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simplePage"},[_c(VCard,{staticClass:"simplePageCard",attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":"","color":"orange darken-4"}},[_vm._v("mdi-email-arrow-right")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.$t('register.activationEmailSent.title')))])],1)],1),_c(VCardSubtitle,{domProps:{"innerHTML":_vm._s(_vm.$t('register.activationEmailSent.text', [this.email]))}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.goHome}},[_vm._v(_vm._s(_vm.$t('register.accountActivation.success.goHomeButton')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }