<template>
    <div class="portfolioStockLotWidget">
        <div class="pslList elevation-2" v-if="hasLot">
            <v-data-table
                :headers="headers"
                :items="portfolioStockLots"
                item-key="lotId"
                :expanded.sync="expanded"
                :search="search"
                sort-by="dateOfTransaction"
                :sort-desc=false
                locale="fr-CA"
                @click:row="editLot"
                class="stockLotTable"
                :footer-props="footerProps"
                hide-default-footer
                :items-per-page=-1
                :disable-sort=true
                dense>
                <template v-slot:top>
                    <v-toolbar flat height="72" color="white">
                        <div class="listName">
                            <v-btn-toggle v-model="lotType" mandatory color="agora">
                                <v-btn>
                                    <font-awesome-layers class="mr-5">
                                        <font-awesome-icon :icon="getListIconFromType('sell')" class="fa-fw" transform="up-5 right-12 grow-2"></font-awesome-icon>
                                        <font-awesome-icon :icon="getListIconFromType('buy')" class="fa-fw" transform="down-5 left-4 grow-2"></font-awesome-icon>
                                    </font-awesome-layers>
                                    {{ $t('UserStockWatchingList.buttons.all') }}
                                </v-btn>
                                <v-btn>
                                    <font-awesome-icon :icon="getListIconFromType('buy')" class="fa-fw fa-lg mr-2"></font-awesome-icon>{{ $t('UserStockWatchingList.buttons.buy') }}
                                </v-btn>
                                <v-btn>
                                    <font-awesome-icon :icon="getListIconFromType('sell')" class="fa-fw fa-lg mr-2"></font-awesome-icon>{{ $t('UserStockWatchingList.buttons.sell') }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                        <v-spacer></v-spacer>
                        <v-switch v-model="expandAll" inset hide-details :label="$t('UserStockWatchingList.buttons.showDetails')"></v-switch>
                    </v-toolbar>
                </template>
                <template v-slot:item.dateOfTransaction="{ item }">
                    <span>{{ formattedDate(item.dateOfTransaction) }}</span>
                </template>
                <template v-slot:item.portfolioStockTransationType="{ item }">
                    <span class="transationType">{{ $t(`UserStockWatchingList.buttons.${item.portfolioStockTransationType}`) }}</span>
                </template>
                <template v-slot:item.quantity="{ item }">
                    <span>{{ item.portfolioStockTransationType === 'buy' ? item.quantity : `(${item.quantity})` }}</span>
                </template>
                <template v-slot:item.averageTransactionPrice="{ item }">
                    <span>{{ priceByShare(item.averageTransactionPrice) }}</span>
                </template>
                <template v-slot:item.cost="{ item }">
                    <span>{{ lotCost({...item}) }}</span>
                </template>
                <template v-slot:item.value="{ item }">
                    <span>{{ lotValue({...item}) }}</span>
                </template>
                <template v-slot:item.changePrct="{ item }">
                    <span :class="gainClass({...item})">{{ gainPrct({...item}) }}</span>
                </template>
                <template v-slot:item.change="{ item }">
                    <span :class="gainClass({...item})">{{ gain({...item}) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn color="error" icon small dark @click.native.stop="deleteLotAsked(item)">
                        <font-awesome-icon :icon="icons.delete" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                </template>
                <template v-slot:footer="{ }">
                    <PortfolioStockLotFooter :lastPrice="stock.lastPrice" :statistics="portfolioStock.statistics"></PortfolioStockLotFooter>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <strong>Note :</strong> {{ item.note }}
                    </td>
                </template>
            </v-data-table>
        </div>
        <div class="portfolioActions">
            <PortfolioEditStockLotDialog ref="editStockLotDialog" :stock="stock" @saveLot="saveLotAsked" @delete="deleteLotAsked" @move="moveLotAsked"></PortfolioEditStockLotDialog>
            <PortfolioMoveStockDialog ref="moveStockDialog" :portfolioId="portfolioId" :stockId="stock.stockId" @move="moveStock"></PortfolioMoveStockDialog>
        </div>
        <PortfolioStockSummary :portfolioId="portfolioId"></PortfolioStockSummary>
        <Alert ref="iAlert"></Alert>
        <ConfirmDialog ref="confirmDelete" @answered="deleteLotAnswered"></ConfirmDialog>
    </div>
</template>

<script>
import Alert from '../controls/Alert.vue'
import PortfolioEditStockLotDialog from './PortfolioEditStockLotDialog.vue'
import PortfolioStockLotFooter from './PortfolioStockLotFooter.vue'
import ListLightIconMixin from '../help/icons/ListLightIconMixin'
import PortfolioStockLotWidgetMixin from './PortfolioStockLotWidgetMixin'
import ConfirmDialog from '../controls/ConfirmDialog.vue'
import PortfolioMoveStockDialog from './PortfolioMoveStockDialog.vue'
import PortfolioStockSummary from './PortfolioStockSummary.vue'
export default {
    components: { Alert, PortfolioEditStockLotDialog, PortfolioStockLotFooter, ConfirmDialog, PortfolioMoveStockDialog, PortfolioStockSummary },
    mixins: [ListLightIconMixin, PortfolioStockLotWidgetMixin],
    props: ['portfolioId', 'stockLots', 'stock'],
    computed: {
        headers() {
            const qty = this.$tc('UserStockWatchingList.portfolio.quantity', this.$vuetify.breakpoint.lgAndDown ? 1 : 2)
            const pbs = this.$tc('UserStockWatchingList.portfolio.priceByShare', this.$vuetify.breakpoint.lgAndDown ? 1 : 2)
            return [
                { text: this.$t('UserStockWatchingList.portfolio.date'), value: 'dateOfTransaction', class: 'colHeader', width: '15%' },
                { text: this.$t('UserStockWatchingList.portfolio.type'), value: 'portfolioStockTransationType', class: 'colHeader', width: '10%' },
                { text: qty, value: 'quantity', class: 'colHeader', align: 'end', width: '10%' },
                { text: pbs, value: 'averageTransactionPrice', class: 'colHeader', align: 'end', width: '10%' },
                { text: this.$t('UserStockWatchingList.portfolio.cost'), value: 'cost', class: 'colHeader', align: 'end', width: '12%' },
                { text: this.$t('UserStockWatchingList.portfolio.value'), value: 'value', class: 'colHeader', align: 'end', width: '12%' },
                { text: this.$t('UserStockWatchingList.portfolio.changePrct'), value: 'changePrct', class: 'colHeader', align: 'end', width: '10%' },
                { text: this.$t('UserStockWatchingList.portfolio.change'), value: 'change', class: 'colHeader', align: 'end', width: '10%' },
                { value: 'actions', class: 'colHeader', align: 'right', width: '11%' }
            ]
        },
        footerProps() {
            return {
                itemsPerPageText: '',
                showCurrentPage: false,
                hideDefaultFooter: true
            }
        },
        search() {
            switch (this.lotType) {
                case 1:
                    return 'buy'
                case 2:
                    return 'sell'
                default:
                    return ''
            }
        },
        addDisabled() {
            return this.addAsked
        }
    },
    watch: {
        expandAll(val) {
            if (val) {
                this.expanded.push(...this.portfolioStockLots)
            } else {
                this.expanded = []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.portfolioStockLotWidget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--v-lightGrayBG-base);
    .pslList {
        font-size: 0.875em;
        .stockLotTable {
            ::v-deep tbody tr :hover {
                cursor: pointer;
            }
            .transationType {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .positive {
            color: var(--v-greenUp-base);
        }
        .negative {
            color: var(--v-redDown-base);
        }
        ::v-deep .v-data-table .v-data-table__expanded.v-data-table__expanded__content {
            box-shadow: none;
            background-color: var(--superLightBG);
        }
    }
    .portfolioStockLotHeader {
        display: flex;
        gap: 10px;
        border-bottom: solid 1px var(--v-lightBorder-base);
        .pslheader {
            flex: 1;
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.8rem;
            font-weight: 700;
            padding: 5px;
            &.pslh3 {
                flex: 3;
            }
            &.pslhActions {
                flex: none;
                width: 80px;
            }
        }
    }
    .portfolioStockLotCont {
        >*:nth-child(even) {
            background-color: var(--v-hover1-base);
        }
    }
    .portfolioActions {
        display: flex;
        justify-content: space-between;
    }
}
</style>