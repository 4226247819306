<template>
    <div>
        <v-form ref="buyEditForm" v-model="valid">
            <v-row>
                <v-col cols="6" md="3">
                    <div class="stp-threshold">
                        <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t200" key="bt200" @input="inputChanged()" @keyup="keyUp" :rules="[rules.isPrice]" :label="$t(`thresholds.buy.t200`)"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="6" md="3">
                    <div class="stp-threshold">
                        <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t400" key="bt400" @input="inputChanged()" @keyup="keyUp" :rules="[rules.isPrice]" :label="$t(`thresholds.buy.t400`)"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="6" md="3">
                    <div class="stp-threshold">
                        <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t600" key="bt600" @input="inputChanged()" @keyup="keyUp" :rules="[rules.required, rules.isPrice]" :label="$t(`thresholds.buy.t600`)">
                            <template slot="append"><font-awesome-icon :class="getthresholdColor(2)" :icon="icon" size="xs"></font-awesome-icon></template>
                        </v-text-field>
                    </div>
                </v-col>
                <v-col cols="6" md="3">
                    <div class="stp-threshold">
                        <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t800" key="bt800" @input="inputChanged()" @keyup="keyUp" :rules="[rules.isPrice]" :label="$t(`thresholds.buy.t800`)">
                            <template slot="append"><font-awesome-icon :class="getthresholdColor(1)" :icon="icon" size="xs"></font-awesome-icon></template>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import BuyEditionPanelMixin from './BuyEditionPanelMixin'
export default {
    mixins: [BuyEditionPanelMixin]
}
</script>

<style scoped>
.v-card__actions {
	padding: 0 24px 20px;
}
.symbolInput input{
    text-transform: uppercase;
}

@media screen and (max-width: 750px) {
    .v-text-field__details {
        display: none;
    }
    .container{
        padding: 0;
        padding-bottom: 10px;
    }
    .v-subheader{
        padding: 0;
        height: 25px;
        font-size: large;
    }
    .col-6 {
        padding: 0 10px;
    }
    .stp-threshold {
        padding: 10px 0;
    }
}
</style>
