<template>
    <div class="resetPasswordForm">
        <v-card outlined class="resetPasswordCard">
            <div class="rpf-header">
                <div class="rpf-header-icon">
                    <font-awesome-layers class="fa-2x">
                        <font-awesome-icon :icon="icon.sync" style="color:#F57C00" />
                        <font-awesome-icon :icon="icon.key" transform="shrink-8 flip-h" style="color:#E65100" />
                    </font-awesome-layers>
                </div>
                <div class="rpf-header-title">{{$t('resetPassword.form.title')}}</div>
            </div>
            <div class="rpf-desc">{{$t('resetPassword.form.text')}}</div>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                        <v-text-field
                            name="newPassowrd"
                            v-model="password"
                            autocomplete="off"
                            outlined
                            hide-details="auto"
                            type="password"
                            :label="$t('resetPassword.form.passwordLabel')"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                        <v-text-field
                            name="confirmNewPassowrd"
                            v-model="confirmPassword"
                            autocomplete="new-password"
                            outlined
                            hide-details="auto"
                            type="password"
                            :label="$t('resetPassword.form.confirmPasswordLabel')"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="resetPassword">{{$t('resetPassword.form.resetButton')}}</v-btn>
            </v-card-actions>
        </v-card>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faKey } from "@fortawesome/pro-solid-svg-icons";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { resetPasswordRequest } from '../../services/AuthenticationService'
import Alert from "../../components/controls/Alert.vue";
export default {
    data() {
        return {
            icon: {
                key: faKey,
                sync: faSync
            },
            password: "",
            confirmPassword: ""
        };
    },
    methods: {
        resetPassword() {
            resetPasswordRequest({ token: this.$route.query.token, password: this.password, confirmPassword: this.confirmPassword }).then((response) => {
                this.$router.push({ name: "ResetPasswordDone" });
            }).catch(error => {
                console.log("ERROR resetPasswordRequest", error);
                this.$refs.iAlert.error(error[0]);
            });
        }
    },
    components: { Alert }
}
</script>

<style scoped src="@/assets/css/password.css">
</style>
