<template>
    <div class="stripePanel">
        <div class="sp-title">{{$t('subscriptions.payment.title')}}</div>
        <div class="sp-info"><font-awesome-icon :icon="icons.warning" class="mr-2"></font-awesome-icon> {{$t('subscriptions.payment.newCardAsDefault')}}</div>
        <div class="stripePanelInfos">
            <label for="cardName">{{$t('subscriptions.payment.cardName')}}</label>
            <input
                id="cardName"
                type="text"
                title="Name"
                class="stripe-input sp-cardname"
                :value="cardName"
                @input="changeName"
                data-card-field
                autocomplete="off"
            />
            <label>{{$t('subscriptions.payment.cardNumber')}}</label>
            <div id="card-number" class="stripe-input"></div>
            <div class="stripe-expcvc">
                <div class="stripe-exp">
                    <label>{{$t('subscriptions.payment.cardExpires')}}</label>
                    <div id="card-expiry" class="stripe-input"></div>
                </div>
                <div class="stripe-cvc">
                    <label>{{$t('subscriptions.payment.cardCVC')}}</label>
                    <div id="card-cvc" class="stripe-input"></div>
                </div>
            </div>
            <div id="card-error"></div>
        </div>
    </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import SubscriptionService from "../../services/SubscriptionService";
import PaymentServiceMixin from '../subscriptions/PaymentServiceMixin';
export default {
    mixins: [PaymentServiceMixin],
    data () {
        return {
            token: null,
            card: null,
            cardNumber: null,
            cardName: null,
            cardExpiry: null,
            cardCvc: null,
            validation: {
                cardNumber: false,
                expiration: false,
                cvc: false
            },
            icons: {
                warning: faExclamationTriangle
            },
            fontSize: this.$vuetify.breakpoint.mobile ? '14px' : '18px'
        };
    },
    computed: {
        stripeElements () {
            return this.$stripe.elements({
                locale: this.$i18n.locale
            });
        },
        allStripeElementsAreValid() {
            return this.validation.cardNumber && this.validation.expiration && this.validation.cvc;
        }
    },
    mounted() {
        // Style Object documentation here: https://stripe.com/docs/js/appendix/style
        const style = {
            base: {
                color: 'black',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: this.fontSize,
                '::placeholder': {
                color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        this.cardNumber = this.stripeElements.create('cardNumber', { style, showIcon: true });
        this.cardNumber.mount('#card-number');
        this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.stripeElements.create('cardCvc', { style });
        this.cardCvc.mount('#card-cvc');

        this.cardNumber.on('change', (event) => {
            if (event.error) {
              // show validation to customer
            }
            else if (event.complete) {
                this.validation.cardNumber = true;
            }
        });
        this.cardExpiry.on('change', (event) => {
            if (event.error) {
              // show validation to customer
            }
            else if (event.complete) {
                this.validation.expiration = true;
            }
        });
        this.cardCvc.on('change', (event) => {
            if (event.error) {
              // show validation to customer
            }
            else if (event.complete) {
                this.validation.cvc = true;
            }
        });
    },
    beforeDestroy () {
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
    },
    methods: {
        addNewPaymentMethod() {
            return new Promise((resolve, reject) => {
                SubscriptionService.createSetupIntent().then((response) => {
                    this.confirmCardSetup({
                        clientSecret: response.clientSecret,
                        cardNumber: this.cardNumber,
                        cardName: this.cardName
                    }).then(result => {
                        resolve(result)
                    }).catch(error => {
                        reject(error)
                    })
                })
            });
        },
        clearForm () {
            this.cardName = null
            this.cardNumber.clear();
            this.cardExpiry.clear();
            this.cardCvc.clear();
        },
        changeName (e) {
            this.cardName = e.target.value
        }
    },
    watch: {
        validation: {
            handler: function (val) {
                const valid = val.cardNumber && val.expiration && val.cvc;
                this.$emit('paymentInfosCompleted', { complete: valid });
            },
            deep: true
        }
    }
};
</script>

<style scoped>
.stripePanel {
    font-family: "Roboto", sans-serif;
    background-color: white;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	border-radius: 10px;
	padding: 25px;

}
.sp-title {
    font-size: larger;
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
.sp-info {
    font-size: 0.9em;
    padding: 6px 10px;
    border-radius: 5px;
    background-color: var(--agoraBG);
    background-color: #ffe6ff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: var(--agora);
    color: #660066;
}
.sp-cardname {
    margin-bottom: 16px;
}
.stripePanelInfos {
    display: flex;
    flex-direction: column;
}
.stripe-expcvc {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.stripe-exp {
    display: flex;
    flex-direction: column;
}
.stripe-cvc {
    display: flex;
    flex-direction: column;
}
.stripe-input {
    border: solid 1px #CCC;
    border-radius: 8px;
    padding: 10px;
}
.payment-actions {
    display: flex;
    justify-content: space-around;
}

#custom-button {
    height: 30px;
    outline: 1px solid grey;
    background-color: green;
    padding: 5px;
    color: white;
}

#card-error {
    color: red;
}

@media screen and (max-width: 750px) {
    .stripePanel {
        width: 100%;
        font-size: small;
        padding: 15px;
        margin-right: 0;
    }
    .sp-title {
        font-size: medium;
        padding-bottom: 0;
    }
    .sp-cardname {
        margin-bottom: 10px;
    }
    .stripe-expcvc {
        margin: 10px 0 0;
    }
}
</style>
