import { faCheck, faTimes, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import StockFullCard from '../../stocks/StockFullCard.vue'
import StockFullCardMobile from '../../stocks/StockFullCardMobile.vue'
import ConfirmDialog from '../../controls/ConfirmDialog.vue';
var EditStockDialogMixin = {
    data() {
        return {
            dialog: false,
            stock: {},
            listId: undefined,
            type: undefined,
            icons: {
                delete: faTrashAlt,
                ok: faCheck,
                close: faTimes
            }
        }
    },
    components: {
        StockFullCard,
        StockFullCardMobile,
        ConfirmDialog
    },
    methods: {
        editStock({ listId, stock, type }) {
            this.listId = listId;
            this.stock = stock;
            this.type = type;
            this.dialog = true;
        },
        close() {
            this.beforeClose()
        },
        closeDialog() {
            this.saveBtnEnabled = undefined;
            this.dialog = false;
            this.showStockInfos = false;
            this.$refs.stockFullCard.clear()
            this.stock = {};
        },
        scrollAndClose() {
            document.querySelector('#scrolling-techniques-6').scrollTop = 0;
            this.close();
        },
        remove() {
            this.$emit('removeSymbol', this.stock);
        },
        beforeClose() {
            if (this.$store.getters.hasDirtyThresholds) {
                this.$refs.dirtyConfirmation.open({
                    title: this.$t('confirmation.dirty.title'),
                    text: this.$t('confirmation.dirty.text')
                })
            }
            else {
                this.closeDialog()
            }
        },
        dirtyConfirmationAnswered({ response }) {
            if (response) {
                this.$refs.stockFullCard.saveThresholds().then(() => {
                    this.$emit('notify', { type: 'success', text: this.$t('confirmation.saved'), duration: 3000 })
                    this.closeDialog()
                })
            }
            else {
                this.closeDialog()
            }
        }
    }
}

export default EditStockDialogMixin
