<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.invitations.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.invitations.newInvitation.title')}}</div>
            <InviteRow @invitationSent="setInvitationLists"></InviteRow>
        </div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.invitations.invitationsAsked.title')}}</div>
            <InvitationAskedList :invitationList="invitationsAsked" :plans="plans" @invitationSent="setInvitationLists"></InvitationAskedList>
        </div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.invitations.invitationsSent.title')}}</div>
            <InvitationSentList :invitationList="invitationsSent" :plans="plans"></InvitationSentList>
        </div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.invitations.invitationsCompleted.title')}}</div>
            <InvitationList :invitationList="invitationsCompleted" :plans="plans"></InvitationList>
        </div>
    </div>
</template>

<script>
import InvitePanelMixin from './InvitePanelMixin'
export default {
    mixins: [InvitePanelMixin]
}
</script>

<style scoped>
.invitePanel{
    padding: 15px;
    display: flex;
    flex-direction: column;
}
.invitePanel>*:not(:last-child){
    margin-bottom: 15px;
}
.inviteForm{
    padding: 15px;
    background-color: #FFF;
    border-radius: 5px;
}
.inviteSent{
    padding: 15px;
    background-color: #FFF;
    border-radius: 5px;
}
@media screen and (max-width: 750px) {
    .invitePanel{
        padding: 5px;
    }
    .invitePanel>*:not(:last-child){
        margin-bottom: 5px;
    }
    .inviteForm{
        padding: 5px;
    }
    .inviteSent{
        padding: 5px;
    }
}
</style>
