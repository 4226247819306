import serverService from './ApiWrapper'

class InvitationService {
    send({ firstname, lastname, email, plan, twoLettersPreferredLanguage }) {
        return serverService.post('/api/Invitation/Send', { firstname, lastname, email, plan, twoLettersPreferredLanguage });
    }

    resend({ email }) {
        return serverService.post('/api/Invitation/Resend', { email })
    }

    acceptInvitationVisitorRequest({ email, planName }) {
        return serverService.post('/api/Invitation/AcceptInvitationVisitorRequest', { email, planName });
    }

    getPlans() {
        return serverService.post('/api/Invitation/GetPlans');
    }

    getList() {
        return serverService.post('/api/Invitation/GetList');
    }

    askForInvitation({ firstName, lastName, email, motivation, humanActionToken, twoLettersPreferredLanguage }) {
        return serverService.post('/api/Invitation/InvitationAsked', { firstName, lastName, email, motivation, humanActionToken, twoLettersPreferredLanguage });
    }
}

export function invitedVisitorHasVisitedWebsite({ invitationToken }) {
    return serverService.post('/api/Invitation/InvitedVisitorHasVisitedWebsite', { invitationToken });
}

export default InvitationService
