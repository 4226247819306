import Vue from 'vue'
import Vuex from 'vuex'
import analyticDataStore from './modules/analyticData.store';
import listDataStore from './modules/listData.store';
import planStore from './modules/plan.store';
import stockStore from './modules/stock.store';
import userStore from './modules/user.store';
import routerStore from './modules/router.store';
import preferenceStore from './modules/preference.store';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user: userStore,
        list: listDataStore,
        analytic: analyticDataStore,
        plan: planStore,
        stock: stockStore,
        router: routerStore,
        preference: preferenceStore
    }
})
