<template>
    <div :class="actionButtonsClasses">
        <v-btn
            color="primary"
            fab
            small
            :dark="enableSave"
            :disabled="!enableSave"
            @click="save"
        >
            <font-awesome-icon :icon="icons.save" class="fa-fw fa-lg"></font-awesome-icon>
        </v-btn>
        <v-btn
            color="secondary"
            fab
            small
            dark
            @click="cancel"
            class="ml-3"
        >
            <font-awesome-icon :icon="icons.cancel" class="fa-fw fa-lg" color="primary"></font-awesome-icon>
        </v-btn>
    </div>
</template>

<script>
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
export default {
    props: { align: String, enableSave: { type: Boolean, default: true } },
    data () {
        return {
            //saveEnabled: true,
            icons: {
                save: faCheck,
                cancel: faTimes
            }
        }
    },
    computed: {
        actionButtonsClasses() {
            return `actionButtons${this.align ? ` ${this.align}` : ''}`;
        }
    },
    methods: {
        save() {
            this.$emit('saveAsked');
        },
        cancel() {
            this.$emit('cancelAsked');
        // },
        // enableSave(enable) {
        //     this.saveEnabled = !!enable;
        }
    }
}
</script>

<style>
.actionButtons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.actionButtons.end {
    justify-content: flex-end;
}
</style>
