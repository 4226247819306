<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.comment.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">
                <div class="ap-subtitle-lpart">{{$t('admin.body.comment.title')}}</div>
                <div class="ap-subtitle-rpart">
                    <div class="globalScore">
                        <span class="gscoreLabel">{{$t('admin.body.comment.globalRating')}}</span>
                        <span class="gscore">{{globalRating}}</span>
                    </div>
                </div>
            </div>
            <div class="ap-table">
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="comments"
                        show-expand
                        item-key="id"
                        :expanded.sync="expanded"
                        :item-class="commentRowClass"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :search="search">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="primary"
                                    @click="refreshFeedbackList()"
                                >
                                    <font-awesome-icon :icon="icons.refresh" class="fa-fw fa-lg"></font-awesome-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div class="commentDetails">
                                    <div class="cd-name">{{ item.name }}</div>
                                    <div class="cd-email">{{ item.email }}</div>
                                    <div class="cd-date">{{ new Date(item.date).toLocaleString('en-CA') }}</div>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import CommentSectionMixin from './CommentSectionMixin'
export default {
    mixins: [CommentSectionMixin]
}
</script>

<style scoped src="@/assets/css/adminSection.css">
</style>
<style scoped>
.globalScore .gscoreLabel {
    margin-right: 10px;
}
.globalScore .gscore {
    font-weight: bold;
    font-size: larger;
}
.commentDetails {
    display: flex;
    justify-content: space-between;
}
</style>
<style>
@media screen and (max-width: 750px)  {
    .v-data-table .v-data-table__mobile-table-row.commentRow > *  {
        height: unset !important;
    }
}
</style>
