import EditorPanel from './edition/EditorPanel.vue'
import UserListEntry from '../../models/UserListEntry'
import StockHtmlHelper from '../stocks/StockHtmlHelper'
import StockPriceChange from '../stocks/StockPriceChange'
import StockPriceDate from '@/components/stocks/StockPriceDate'
import ConfirmDialog from '../controls/ConfirmDialog'
import UserSubscriptionHelper from '@/components/plan/UserSubscriptionHelper'
import Alert from '@/components/controls/Alert.vue'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import { listComputed } from '@/store/helpers/ListHelper'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { faPlus, faPen } from '@fortawesome/pro-solid-svg-icons'
import ListRegularIconMixin from '../help/icons/ListRegularIconMixin'
import Portfolio from '../../models/Portfolio'
var userStockListMixin = {
    mixins: [ListRegularIconMixin],
    data() {
        return {
            userStockListLoaded: false,
            canAddEntry: false,
            search: '',
            listSelected: this.$route.params.id,
            stockIdSelected: this.$route.params.stockId,
            stockHtmlHelper: undefined,
            listType: undefined,
            userSubscriptionHelper: undefined,
            allLists: [],
            icons: {
                warning: faExclamationTriangle,
                add: faPlus,
            edit: faPen
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();

        if (this.userSubscriptionInfo.usage !== undefined) {
            this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: this.userSubscriptionInfo });
            this.canAddEntry = this.userSubscriptionHelper.canAddEntry();
        }

        if (this.listLoaded) {
            this.loadUserStockList();
        }
    },
    components: {
        EditorPanel,
        StockPriceChange,
        ConfirmDialog,
        StockPriceDate,
        Alert
    },
    computed: {
        ...authComputed,
        ...listComputed,
        getUserSubscriptionInfo() {
            return this.userSubscriptionInfo;
        },
        getUserLists() {
            return this.userLists;
        },
        userDataLoaded() {
            return this.buyListsLoaded && this.sellListsLoaded;
        },
        stockSelected() {
            return this.stockIdSelected;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        stockList() {
            return this.userStockListLoaded ? this.allLists.find(list => list.id === this.listSelected).entries : [];
        },
        orderedStockList() {
            return this.stockList.slice().sort((a, b) => this.sortableScore(b.score) - this.sortableScore(a.score));
        },
        listNames() {
            return this.allLists.map(list => { return { id: list.id, name: list.name, type: list.type } });
        },
        headers() {
            return this.userStockListLoaded ? [
                { text: this.$t('UserStockWatchingList.watchTable.headers.symbol'), value: 'symbol', class: 'colHeader', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.companyName'), value: 'companyName', class: 'colHeader', width: '20%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.score'), value: 'score', class: 'colHeader', align: 'center', width: '6%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.marketRegularPrice'), value: 'marketRegularPrice', class: "lastprice colHeader", align: 'right', width: '8%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.change'), value: 'change', class: 'colHeader', align: 'right', width: '10%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.priceSince'), value: 'marketRegularDateTime', class: 'colHeader', align: 'right', width: '10%' },
                { text: this.$t(`thresholds.${this.listType}.t200`), value: 'threashold200', class: `${this.listType === 'buy' ? '' : 't200-sell'} colHeader`, align: 'right', width: '7%' },
                { text: this.$t(`thresholds.${this.listType}.t400`), value: 'threashold400', class: `${this.listType === 'buy' ? '' : 't400-sell'} colHeader`, align: 'right', width: '7%' },
                { text: this.$t(`thresholds.${this.listType}.t600`), value: 'threashold600', class: 't600 colHeader', align: 'right', width: '7%' },
                { text: this.$t(`thresholds.${this.listType}.t800`), value: 'threashold800', class: 't800 colHeader', align: 'right', width: '7%' },
                { text: this.$t('UserStockWatchingList.watchTable.headers.marketCap'), value: 'marketCap', class: 'colHeader', align: 'right', width: '8%' }
                // { text: 'Actions', value: 'actions', sortable: false, class: 'colHeader', align: 'right', width: '8%' }
            ] : [];
        },
        listLoaded() {
            return this.buyListsLoaded && this.sellListsLoaded
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        userDataLoaded(udLoaded) {
            if (udLoaded && !this.userStockListLoaded) {
                this.loadUserStockList();
            }
        },
        getUserSubscriptionInfo: {
            handler: function (val) {
                this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: this.userSubscriptionInfo });
                this.canAddEntry = this.userSubscriptionHelper.canAddEntry();
            },
            deep: true
        },
        getUserLists: {
            handler: function (val) {
                this.loadUserStockList();
            },
            deep: true
        },
        loaded(val) {
            if (val) {
                const item = this.stockList.find(s => s.id === this.$route.params.stockId);
                if (item) {
                    this.editItem(item);
                }
            }
        }
    },

    methods: {
        showNotification({ type, text, duration }) {
            this.$refs.iSySnackbar.show({ type, text, duration })
        },

        loadUserStockList() {
            this.allLists = Object.keys(this.$store.state.list.lists).reduce((acc, cur) => acc.concat(this.$store.state.list.lists[cur]), []);
            const list = this.allLists.find(list => list.id === this.listSelected);
            if (list) {
                this.listType = list.type;
                this.userStockListLoaded = true;
            }
        },

        stockChangeInfo(item) {
            return {
                price: item.marketRegularPrice,
                changeAmount: item.change,
                changePercent: item.changePercent
            }
        },

        changeList() {
            ///this.listType = this.allLists.find(l => l.id === this.listSelected).type;
            const list = this.allLists.find(l => l.id === this.listSelected)
            if (list instanceof Portfolio) {
                this.$router.push({ name: 'Portfolio', params: { id: this.listSelected } });
            } else {
                this.$router.push({ name: 'List', params: { id: this.listSelected } });
            }
        },

        editItem(item) {
            this.editedItem = item;
            //this.$refs.editStockPanel.editStock({ listId: this.$route.params.id, stock: this.editedItem, type: this.listType })
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: this.$route.params.id });
            this.$router.push({ name: 'Stock', params: { id: this.editedItem.stockId } });
        },

        deleteItemDialogAnswered(answer) {
            if (answer.response === true) {
                this.$store.dispatch('deleteStockFromWatchList', { listId: this.listSelected, entryId: answer.data.item.id, listType: this.listType }).then(() => {
                    this.$refs.editStockPanel.close();
                }).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                });
            }
        },

        deleteItem(item) {
            this.$refs.deleteItemConfirmDialog.open({
                data: { item: item },
                title: this.$t('EditorPanel.actions.deleteItem.title'),
                text: this.$t('EditorPanel.actions.deleteItem.text', [item.symbol, '\xa0'])
            })
        },

        newItemBtn_Clicked() {
            this.$refs.editorPanel.open({ listId: this.$route.params.id, item: new UserListEntry({}), type: this.listType })
        },

        search_Clicked() {
            this.$refs.searchBar.$el.classList.remove('d-none', 'd-sm-flex');
        },

        sort_Clicked() {
            this.$refs.searchBar.$el.classList.remove('d-none', 'd-sm-flex');
        },

        getColorForScore(score) {
            return this.stockHtmlHelper.getBadgeBGColor(this.stockHtmlHelper.getReleventBadge(score), this.listType);
        },

        simplifyUnit(labelValue) {
            if (labelValue === undefined) return ''
            else if (labelValue === -9999) return '...'
            else {
                // 12 Zeroes for Trillions
                return Math.abs(Number(labelValue)) >= 1.0e+12

                    ? (Math.abs(Number(labelValue)) / 1.0e+12).toFixed(2) + this.$t('units.T')
                    // 9 Zeroes for Billions
                    : Math.abs(Number(labelValue)) >= 1.0e+9

                        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + this.$t('units.B')
                        // 6 Zeroes for Millions
                        : Math.abs(Number(labelValue)) >= 1.0e+6

                            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + this.$t('units.M')

                            : (Math.abs(Number(labelValue))).toFixed(2)
            }
        },

        AdaptIfNull(value) {
            return value > 0.0 ? this.simplifyUnit(value) : this.$t('dictio.na')
        },

        companyNameTagClass(name) {
            return name ? '' : 'unknown';
        },

        displayCompanyName(name) {
            return name || this.$t('entry.comingSoon');
        },

        cleanScore(score) {
            return score === -9999 ? '...' : Math.abs(Math.round(score))
        },

        sortableScore(score) {
            return score === -9999 ? -1 : Math.abs(Math.round(score));
        },

        formatPrice(price) {
            let formattedPrice = '';
            if (price && price !== 0) {
                const p = price.toFixed(price < 5 ? 4 : 2);
                const ps = p.split('.');
                formattedPrice = Number(ps[0]).toLocaleString() + '.' + ps[1];
            }
            return formattedPrice;
        },

        formatMarketPrice(price) {
            let formattedPrice = this.formatPrice(price);
            if (formattedPrice === '') {
                formattedPrice = '...';
            }
            return formattedPrice;
        },

        marketTime(dt) {
            if (dt === '0001-01-01T00:00:00') return '...';
            const d = new Date(dt)
            return d.toLocaleTimeString('fr-CA', { hour: '2-digit', minute: '2-digit' })
        },

        marketDate(dt) {
            if (dt === '0001-01-01T00:00:00') return '';
            const d = new Date(dt)
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return d.toLocaleDateString('fr-CA', options)
        },

        getCountry(entry) {
            if (entry.currency) {
                return entry.currency.substring(0, 2);
            }
        },

        editList() {
            const list = this.allLists.find(n => n.id === this.listSelected)
            this.$refs.editCard.show({ list, type: this.listType })
        }
    }
}

export default userStockListMixin
