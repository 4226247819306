<template>
    <div class="resetPasswordForm">
        <v-card outlined class="resetPasswordCard">
            <div class="rpf-header">
                <div class="rpf-header-icon">
                    <font-awesome-layers class="fa-2x">
                        <font-awesome-icon :icon="icon.lock" style="--fa-primary-color: gold; --fa-secondary-color: gray;" />
                        <font-awesome-icon :icon="icon.question" transform="shrink-10 down-3" />
                    </font-awesome-layers>
                </div>
                <div class="rpf-header-title">{{$t('resetPassword.request.title')}}</div>
            </div>
            <div class="rpf-desc">{{$t('resetPassword.request.text')}}</div>
            <v-form @submit="sendEmail">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="email"
                                type="email"
                                outlined
                                :rules="[rules.required, rules.email]"
                                :label="$t('resetPassword.request.emailLabel')"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" type="submit">{{$t('resetPassword.request.sendButton')}}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import validationMixin from '../../helpers/validationMixin';
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-duotone-svg-icons";
import { sendResetPasswordRequest } from '../../services/AuthenticationService'
export default {
    mixins: [validationMixin],
    data () {
        return {
            icon: {
                lock: faLock,
                question: faQuestion
            },
            email: ''
        }
    },
    methods: {
        sendEmail() {
            sendResetPasswordRequest({ email: this.email }).then((response) => {
                this.$router.push({ name: 'ResetPasswordRequestSent', params: { email: this.email } });
            }).catch(error => {
                console.log('ERROR sendResetPasswordRequest' + error);
            });
        }
    }
}
</script>

<style scoped src="@/assets/css/password.css">
</style>
