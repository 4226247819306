<template>
    <div class="StockThresholdsPanel">
        <v-skeleton-loader v-if="!stock" type="image"></v-skeleton-loader>
        <div class="threshold" v-for="(e, i) in stockThresholds" :key="i">
            <StockThresholdPanel :stock="e"></StockThresholdPanel>
        </div>
    </div>
</template>

<script>
import StockMixin from '../stocks/StockMixin';
import StockThresholdPanel from './StockThresholdPanel.vue';
export default {
    components: { StockThresholdPanel },
    mixins: [StockMixin],
    computed: {
        stockThresholds() {
            if (!this.$store.getters.allListAreLoaded) return []
            const x = Object.keys(this.$store.getters.userLists).reduce((acc, cur) => {
                if (cur !== 'portfolios') {
                    const lists = this.$store.getters.userLists[cur]
                    lists.forEach(list => {
                        list.entries.forEach(entry => {
                            if (entry.stockId === this.stockId) {
                                entry.listName = list.name
                                entry.listType = list.type
                                acc.push(entry)
                            }
                        })
                    })
                }
                return acc
            }, [])
            return x
        }
    }
}
</script>

<style lang="scss" scoped>
.StockThresholdsPanel {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>