class Plan {
    constructor({ name, price, reccuringInterval, priceId, currency, limit, displayOrder }) {
        this._name = name;
        this._price = price || '0.00';
        this._reccuringInterval = reccuringInterval;
        this._priceId = priceId;
        this._currency = currency;
        this._limit = limit;
        this._displayOrder = displayOrder;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get price() {
        return this._price;
    }

    set price(value) {
        this._price = value;
    }

    get reccuringInterval() {
        return this._reccuringInterval;
    }

    set reccuringInterval(value) {
        this._reccuringInterval = value;
    }

    get priceId() {
        return this._priceId;
    }

    set priceId(value) {
        this._priceId = value;
    }

    get currency() {
        return this._currency;
    }

    set currency(value) {
        this._currency = value;
    }

    get limit() {
        return this._limit;
    }

    set limit(value) {
        this._limit = value;
    }

    get displayOrder() {
        return this._displayOrder;
    }

    set displayOrder(value) {
        this._displayOrder = value;
    }

    get isAiPlan() {
        return Object.values(this._limit.stockContext.aiData).includes(true);
    }

    get aiDataActiveKeys() {
        return Object.entries(this._limit.stockContext.aiData)
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
    }

    get newsCount() {
        return this._limit.stockContext.newsCount || 0;
    }

    get cost() {
        const p = this._price.toString().split('.');
        return {
            d: p[0],
            c: p[1]
        };
    }
}

export default Plan;