<template>
    <div v-if="showPlansButton">
        <v-btn v-if="loggedIn && $vuetify.breakpoint.mobile"
            color="secondary"
            text
            class="mr-2"
            @click="goToPlans">
            <font-awesome-layers class="fa-2x">
                <font-awesome-icon :icon="icon.sync" />
                <font-awesome-icon :icon="icon.dollar" transform="shrink-10" />
            </font-awesome-layers>
        </v-btn>
        <v-btn
            v-else-if="!loggedIn"
            text small color="primary"
            @click="goToPlans">
            {{$t('subscriptions.mainMenuButton')}}
        </v-btn>
        <v-btn
            v-else
            tile
            @click="goToPlans"
            class="mr-5">
            {{$t('subscriptions.mainMenuButton')}}
        </v-btn>
    </div>
</template>

<script>
import { faDollarSign, faSyncAlt } from '@fortawesome/pro-duotone-svg-icons';
import { authComputed } from '../../store/helpers/AuthenticationHelper';
export default {
    data() {
        return {
            icon: {
                dollar: faDollarSign,
                sync: faSyncAlt
            }
        }
    },
    computed: {
        ...authComputed,
        showPlansButton() {
            return this.userSubscriptionInfo.planDefinition ? this.userSubscriptionInfo.planDefinition.price === 0 : true;
        }
    },
    methods: {
        goToPlans() {
            this.$router.push({ name: 'Subscription' }).catch(() => { });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>