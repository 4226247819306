<template>
    <div class="invitationList">

        <v-data-table
            :headers="headers"
            :items="invitationList"
            hide-default-footer>
                <template v-slot:item.fullname="{ item }">
                    <span>{{`${item.lastname}, ${item.firstname}`}}</span>
                </template>
                <template v-slot:item.action="{ item }">
                    <InviteActionButtons :plans="plans" :guest="item" @invitationAccepted="invitationAccepted"></InviteActionButtons>
                </template>
        </v-data-table>
    </div>
</template>

<script>
import { faPaperPlane, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import InviteActionButtons from './InviteActionButtons.vue'
export default {
    props: ['invitationList', 'plans'],
    data() {
        return {
            invitationService: undefined,
            icons: {
                send: faPaperPlane,
                caret: faCaretDown
            },
            headers: [
                { text: this.$t('admin.body.invitations.invitationsAsked.fields.fullname'), value: 'fullname' },
                { text: this.$t('admin.body.invitations.invitationsAsked.fields.email'), value: 'email' },
                { text: this.$t('admin.body.invitations.invitationsAsked.fields.motivation'), value: 'motivation', width: '40%' },
                { value: 'action', width: '250' }
            ]
        }
    },
    components: {
        InviteActionButtons
    },
    methods: {
        invitationAccepted({ invitations }) {
            this.$emit('invitationSent', { invitations });
        }
    }
}
</script>

<style scoped>
.invitationList {}
.invitationList>*:not(:last-child) {
    margin-bottom: 5px;
}
</style>
