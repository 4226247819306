<template>
    <div class="overview">
        <div class="oTop">
            <div class="oName">{{ listInfos.name }}</div>
            <v-btn @click="editList" icon x-small color="white" class="editBtn">
                <font-awesome-icon :icon="icons.edit" class="fa-fw fa-lg"></font-awesome-icon>
            </v-btn>
        </div>
        <font-awesome-icon v-if="listInfos.type" :icon="icons[listInfos.type]" class="fa-fw bgIcon" color="#DDD"></font-awesome-icon>
        <EditListCard ref="editCard"></EditListCard>
    </div>
</template>

<script>
import { faShoppingCart, faUsdCircle } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import StockHtmlHelper from '../stocks/StockHtmlHelper'
import userStockListMixin from './UserStockListMixin'
import EditListCard from '../home/dashboard/boards/EditListCard.vue'
export default {
    mixins: [userStockListMixin],
    data() {
        return {
            icons: {
                buy: faShoppingCart,
                sell: faUsdCircle,
                edit: faPen
            },
            stockHTMLHelper: undefined
        };
    },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper();
    },
    computed: {
        portfolioCurrency() {
            return this.portfolio ? this.portfolio.currency.toUpperCase() : '';
        },
        listInfos() {
            const li = this.allLists.find(l => l.id === this.listSelected);
            return li || {};
        }
    },
    components: { EditListCard }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.overview {
    width: 350px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: var(--darkBG);
    border-radius: 10px;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    position: relative;
    overflow: hidden;
    .oTop {
        display: flex;
        gap: 15px;
        flex-flow: wrap;
        align-items: center;
        .oName {
            font-size: 1.5em;
        }
        .editBtn {

        }
    }

    .poChanges {
        display: flex;
        flex-direction: column;
        .pchange {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            font-size: 1.2em;
            .pclabel {
                color: #AAA;
                width: 150px;
            }
            .pcvalue {
                font-size: 1.2em;
                font-weight: bold;
            }
        }
    }
    .pgraph {}
    .bgIcon {
        position: absolute;
        top: 5px;
        right: -10px;
        font-size: 90px;
        rotate: -15deg;
        opacity: 0.2;
    }
    @media screen and (max-width: $phone) {
        & {
            font-size: 0.8em;
            gap: 5px;
            .pvalue {
                text-align: end;
            }
            .pchange {
                justify-content: space-between;
                .pclabel {
                    width: unset;
                }
            }
        }
    }
}
</style>