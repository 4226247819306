<template>
    <div class="changePasswordDialog">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue-grey"
                    fab
                    small
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <font-awesome-icon :icon="icons.edit" class="fa-lg"></font-awesome-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    color="blue darken-2"
                    dark
                >{{$t('settings.body.changePasswordDialog.title')}}</v-toolbar>
                <v-form ref="form" @submit.prevent="confirmNewPassword">
                    <v-card-text class="py-2">
                        <v-container class="px-0">
                            <v-text-field name="oldPassword"
                                v-model="input.oldPassword"
                                :label="$t('settings.body.changePasswordDialog.oldPassword')"
                                :type="showOldPassword ? 'text' : 'password'"
                                outlined
                                :rules="[rules.required]"
                                :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showOldPassword_clicked"
                                autocomplete="new-password">
                            </v-text-field>
                            <v-text-field name="newPassword"
                                v-model="input.newPassword"
                                :label="$t('settings.body.changePasswordDialog.newPassword')"
                                :type="showNewPassword ? 'text' : 'password'"
                                outlined
                                :rules="[rules.required]"
                                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showNewPassword_clicked"
                                autocomplete="new-password">
                            </v-text-field>
                            <v-text-field name="confirmPassword"
                                v-model="input.confirmPassword"
                                :label="$t('settings.body.changePasswordDialog.confirmPassword')"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                outlined
                                :rules="[rules.required]"
                                :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showConfirmPassword_clicked"
                                hide-details="auto"
                                autocomplete="new-password">
                            </v-text-field>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            fab
                            small
                            dark
                            type="submit"
                        >
                            <font-awesome-icon :icon="icons.check" class="fa-lg"></font-awesome-icon>
                        </v-btn>
                        <v-btn
                            color="secondary"
                            fab
                            small
                            dark
                            @click="dialog=false"
                        >
                            <font-awesome-icon :icon="icons.times" class="fa-lg"></font-awesome-icon>
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import Alert from '@/components/controls/Alert.vue'
import { changePassword } from '@/services/AuthenticationService'
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import validationMixin from '../../../helpers/validationMixin';
export default {
    name: 'ChangePassword',
    mixins: [validationMixin],
    data() {
        return {
            dialog: false,
            input: {
                oldPassword: undefined,
                newPassword: undefined,
                confirmPassword: undefined
            },
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            icons: {
                check: faCheck,
                times: faTimes,
                edit: faPen
            }
        }
    },
    components: { Alert },
    methods: {
        confirmNewPassword() {
            changePassword({ actualPassword: this.input.oldPassword, newPassword: this.input.newPassword, newPasswordConfirmation: this.input.confirmPassword }).then((data) => {
                this.dialog = false;
                this.$emit('passwordChanged', { success: true });
                this.reset()
            }).catch((error) => {
                this.$refs.iAlert.error(error[0]);
            });
        },
        reset() {
            this.$refs.form.reset()
        },
        showOldPassword_clicked() {
            this.showOldPassword = !this.showOldPassword
        },
        showNewPassword_clicked() {
            this.showNewPassword = !this.showNewPassword
        },
        showConfirmPassword_clicked() {
            this.showConfirmPassword = !this.showConfirmPassword
        }
    }
}
</script>

<style scoped>
.changePasswordDialog {
    margin: auto;
}
@media screen and (max-width: 768px) {
    .changePasswordDialog {
        width: 100%;
    }
}
</style>
