<template>
    <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="primary">
                <font-awesome-icon :icon="icons.import" class="fa-fw fa-lg mr-2"></font-awesome-icon> {{ $t('UserStockWatchingList.portfolio.importInPortfolio') }}
            </v-btn>
        </template>
        <div class="importCsvFile">
            <div class="dialogHeader">{{ $t('UserStockWatchingList.portfolio.actions.import.title') }}</div>
            <div class="dialogBody">
                <div class="bodyDesc">{{ $t('UserStockWatchingList.portfolio.actions.import.text') }}</div>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-file-input
                        v-model="file"
                        :rules="[rules.required]"
                        accept=".csv"
                        :label="$t('UserStockWatchingList.portfolio.actions.import.file')"
                        outlined
                        dense
                        prepend-icon="mdi-arrow-collapse-up"
                    ></v-file-input>
                </v-form>
            </div>
            <div class="dialogFooter">
                <v-btn fab small @click="confirm" color="primary" :disabled="confirmImportDisabled">
                    <font-awesome-icon :icon="icons.ok" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
                <v-btn fab small @click="dialog = false" color="secondary">
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { faArrowAltToTop, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

export default {
    props: ['portfolio'],
    data() {
        return {
            dialog: false,
            file: null,
            valid: false,
            rules: {
                required: value => !!value || this.$t('rules.required')
            },
            icons: {
                ok: faCheck,
                close: faTimes,
                import: faArrowAltToTop
            }
        }
    },
    computed: {
        confirmImportDisabled() {
            return !this.valid;
        }
    },
    methods: {
        confirm() {
            if (this.$refs.form.validate()) {
                this.importCsvFile();
            }
        },
        importCsvFile() {
            this.$store.dispatch('importInPortfolio', { portfolioId: this.portfolio.id, fileInput: this.file }).then(importPortfolioResult => {
                console.log('import execptions:', importPortfolioResult.exceptions);
                this.$emit('imported');
                this.dialog = false;
            }).catch(err => {
                console.error(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.importCsvFile {
    background-color: #FFF;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .dialogHeader {
        font-size: 1.5rem;
    }
    .dialogBody {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .dialogFooter {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }
    @media screen and (max-width: 750px) {
        .dialogHeader {
            padding: 12px;
        }
        .dialogBody {
            padding: 0 12px;
        }
        .dialogFooter {
            padding: 12px;
        }
    }
}
</style>