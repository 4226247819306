<template>
    <div class="aSection">
        <div class="aSectionTitle">
            <slot name="title"></slot>
        </div>
        <div class="aSectionBody">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.aSection{
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border: solid 1px #8299b0;
    border-radius: 5px;
}
.aSectionTitle{
    font-weight: 500;
    color: #FFF;
    background-color: #8299b0;
    padding: 8px 12px;
}
.aSectionBody{
    padding: 15px;
    flex: 1;
}
</style>
