class ServerHelper {
    static getStockListInfos(listType) {
        switch (listType) {
            case 'buy':
                return 'UserBuyStockList';
            case 'sell':
                return 'UserSellStockList';
            default:
                return 'UserPortfolioStockList';
        }
    }
}

export default ServerHelper;
