class StorageService {
    static get AUTH_USER_KEY() {
        return 'AUTH_USER';
    }

    static get ACCESS_KEY() {
        return 'app-preproduction-key';
    }

    static get USER_ACCESS() {
        return 'user-access';
    }

    static get INVITE_TOKEN() {
        return 'invite-token';
    }

    static get ALLOW_COOKIES() {
        return 'allowCookies';
    }

    static get DISMISS_APP_MESSAGE() {
        return 'dismissAppMessage';
    }

    static get ACCEPTED_TERMS_VERSION() {
        return 'acceptedTermsVersion';
    }

    static get USER_CUURENCY() {
        return 'userCurrency';
    }

    set AuthUser(authenticatedUser) {
        const authenticatedUserString = typeof authenticatedUser === 'string' || authenticatedUser instanceof String ? authenticatedUser : JSON.stringify(authenticatedUser);
        localStorage.setItem(StorageService.AUTH_USER_KEY, authenticatedUserString);
    }

    get AuthUser() {
        let item = localStorage.getItem(StorageService.AUTH_USER_KEY);
        if (item !== null) {
            item = JSON.parse(item);
        }
        return item;
    }

    deleteAuthUser() {
        localStorage.removeItem(StorageService.AUTH_USER_KEY);
    }

    get accessKey() {
        return localStorage.getItem(StorageService.USER_ACCESS)
    }

    set accessKey(key) {
        localStorage.setItem(StorageService.ACCESS_KEY, key);
    }

    set UserAccess(info) {
        localStorage.setItem(StorageService.USER_ACCESS, info)
    }

    set acceptedTermsVersion(info) {
        localStorage.setItem(StorageService.ACCEPTED_TERMS_VERSION, info)
    }

    get UserAccess() {
        let item = this.accessKey
        if (item !== null) {
            item = JSON.parse(item);
        }
        return item;
    }

    set inviteToken(token) {
        localStorage.setItem(StorageService.INVITE_TOKEN, token)
    }

    get inviteToken() {
        return localStorage.getItem(StorageService.INVITE_TOKEN);
    }

    get acceptedTermsVersion() {
        return localStorage.getItem(StorageService.ACCEPTED_TERMS_VERSION);
    }

    set dismissAppMessage(dismissed) {
        localStorage.setItem(StorageService.DISMISS_APP_MESSAGE, JSON.stringify(dismissed))
    }

    get dismissAppMessage() {
        return JSON.parse(localStorage.getItem(StorageService.DISMISS_APP_MESSAGE)) || {};
    }

    addDismissAppMessageKey({ key, info }) {
        const dismissed = this.dismissAppMessage
        dismissed[key] = info
        this.dismissAppMessage = dismissed
    }

    deleteDismissAppMessageKey({ key }) {
        const dismissed = this.dismissAppMessage
        delete dismissed[key]
        this.dismissAppMessage = dismissed
    }

    deleteInviteToken() {
        localStorage.removeItem(StorageService.INVITE_TOKEN);
    }

    setCookie({ name, value, hours }) {
        const d = new Date();
        d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    getCookie({ name }) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    set allowCookies(value) {
        this.setCookie({ name: StorageService.ALLOW_COOKIES, value, hours: 365 * 24 });
    }

    get allowCookies() {
        return this.getCookie({ name: StorageService.ALLOW_COOKIES });
    }

    set userCurrency(currency) {
        localStorage.setItem(StorageService.USER_CUURENCY, currency);
    }

    get userCurrency() {
        return localStorage.getItem(StorageService.USER_CUURENCY);
    }
}
var storageService = new StorageService()
export default storageService
