<template>
    <div class="langSwitcher">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :dark="isDark"
                    :color="btnColor"
                    :small="$vuetify.breakpoint.xs"
                    :large="large !== undefined ? large : false"
                >
                    <font-awesome-icon :icon="icon.lang" style="--fa-secondary-color: #000;" class="fa-2x mr-3"/>
                    {{label}}
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="key in availableLocales"
                    :key="`lang${key}`"
                    @click="setLocale(key)"
                >
                    <v-list-item-title>{{ locales[key] }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { faLanguage } from "@fortawesome/pro-duotone-svg-icons";
import PreferencesService from '../../services/PreferencesService'
import { authComputed } from "../../store/helpers/AuthenticationHelper";
export default {
    props: ['dark', 'large', 'color'],
    data() {
        return {
            valid: true,
            locale: "",
            locales: {
                fr: "Français",
                en: "English"
            },
            icon: {
                lang: faLanguage
            },
            preferencesService: undefined
        }
    },
    computed: {
        ...authComputed,
        availableLocales() {
            return Object.keys(this.locales);
        },
        label() {
            return this.$vuetify.breakpoint.mobile ? this.locale : this.locales[this.locale];
        },
        btnColor() {
            let col = this.dark !== undefined ? 'secondary' : '';
            if (this.color) {
                col = this.color;
            }
            return col;
        },
        isDark() {
            return this.dark;
        }
    },
    created() {
        this.preferencesService = new PreferencesService();
        this.initLocale(this.preferencesService.UserLang);
    },
    methods: {
        initLocale(lang) {
            this.locale = lang;
            this.$i18n.locale = lang;
            this.preferencesService.UserLang = lang
        },
        setLocale(lang) {
            this.locale = lang;
            this.$i18n.locale = lang;
            this.preferencesService.UserLang = lang
            if (this.loggedIn) {
                this.preferencesService.setPreferredLanguage({ twoLettersLanguage: lang })
            }
        }
    }
}
</script>

<style scoped>
.langSwitcher {
    display: flex;
    align-items: center;
}
.langSwitcherSelect{
    margin-left: 10px;
}
</style>
