import storageService from "../services/StorageService";
import { faDollarSign, faEuroSign } from '@fortawesome/pro-regular-svg-icons';

var CurrencyHelperMixin = {
    data() {
        return {
            icons: {
                dollar: faDollarSign,
                euro: faEuroSign
            },
            baseCurrency: {},
            currencies: [
                { text: 'CAD', icon: 'dollar' },
                { text: 'USD', icon: 'dollar' }
            ]
        }
    },
    created() {
        const currency = storageService.userCurrency || 'CAD'
        this.baseCurrency = this.currencies.find(c => c.text === currency) || this.currencies[0];
        this.$store.dispatch('setUserCurrency', { currency });
    },
    computed: {
        baseCurrencyText() {
            return this.baseCurrency.text;
        },
        baseCurrencyIcon() {
            return this.getCurrencyIcon(this.baseCurrency.text);
        }
    },
    methods: {
        getCurrencyIcon(currencyText) {
            const currencyObj = this.currencies.find(c => c.text === currencyText);
            return currencyObj ? currencyObj.icon : 'dollar';
        },
        setBaseCurrency(currency) {
            this.baseCurrency = currency;
            this.$store.dispatch('setUserCurrency', { currency: currency.text })
        }
    }
}

export default CurrencyHelperMixin;