class PriceHelper {
    convertCentsToDollars(cents, locale, currency) {
        const dol = cents / 100 // cent to dollar
        return dol.toLocaleString(locale, { style: "currency", currency }).replace(/CA|US/, '')
    }

    convertToDollars(price, locale, currency) {
        const cents = Number.isInteger(price) ? price : price * 100
        return this.convertCentsToDollars(cents, locale, currency)
    }
}

export default PriceHelper