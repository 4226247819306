<template>
    <div class="sySnackbar">
        <v-snackbar
            v-model="model"
            :color="color"
            :timeout="timeout"
            top
        >
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="close">
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export default {
    data() {
        return {
            model: false,
            type: undefined,
            text: undefined,
            duration: undefined,
            icons: {
                close: faTimes
            }
        };
    },
    computed: {
        timeout() {
            return this.duration || -1
        },
        color() {
            switch (this.type) {
                case 'success':
                    return this.$vuetify.theme.themes.light.success
                case 'error':
                    return this.$vuetify.theme.themes.light.error
                default:
                    return this.$vuetify.theme.themes.light.agora
            }
        }
    },
    methods: {
        show({ type, text, duration }) {
            this.type = type
            this.text = text
            this.duration = duration
            this.model = true
        },
        success({ text, duration }) {
            this.show({ type: 'success', text, duration })
        },
        error({ text, duration }) {
            this.show({ type: 'error', text, duration })
        },
        close() {
            this.model = false;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>