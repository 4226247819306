import { VuePaycard } from 'vue-paycard'
import SubscriptionService from '../../services/SubscriptionService'
var CreditCardPaymentPanelMixin = {
    props: ['subscriptionInfo'],
    data() {
        return {
            cardNumber: null,
            valueFieldsValid: {
                cardNumber: true,
                cardMonth: true,
                cardYear: true,
                cardCvv: false
            },
            minCardYear: new Date().getFullYear(),
            mainCardNumber: '',
            cardNumberMaxLength: 19,
            valueFields: {
                cardName: '',
                cardNumber: '',
                cardNumber2: '',
                cardMonth: '',
                cardYear: null,
                cardCvv: null
            },
            inputFields: {
                cardNumber: 'v-card-number',
                cardName: 'v-card-name',
                cardMonth: 'v-card-month',
                cardYear: 'v-card-year',
                cardCvv: 'v-card-cvv'
            },
            labels: {
                cardNumber: this.$t('subscriptions.payment.cardNumber'),
                cardName: this.$t('subscriptions.payment.cardName'),
                cardHolder: this.$t('subscriptions.payment.cardHolder'),
                cardMonth: this.$t('subscriptions.payment.cardExpirationMonth'),
                cardYear: this.$t('subscriptions.payment.cardExpirationYear'),
                cardExpires: this.$t('subscriptions.payment.cardExpires'),
                cardCvv: this.$t('subscriptions.payment.cardCVV'),
                formExpirationMonth: this.$t('subscriptions.payment.formExpirationMonth'),
                formExpirationYear: this.$t('subscriptions.payment.formExpirationYear'),
                formMonth: this.$t('subscriptions.payment.formMonth'),
                formYear: this.$t('subscriptions.payment.formYear')
            }
        }
    },
    directives: {
        'number-only': {
            bind(el) {
                function checkValue(event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind(el) {
                function checkValue(event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    components: {
        VuePaycard
    },
    computed: {
        minCardMonth() {
            if (this.valueFields.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
        stripeElements() {
            return this.$stripe.elements();
        }
    },
    watch: {
        cardNumber(event) {
            console.log(event);
        },
        cardYear() {
            if (this.valueFields.cardMonth < this.minCardMonth) {
                this.valueFields.cardMonth = ''
            }
        },
        'valueFields.cardNumber': function (val) {
            const ccNumber = val.replace(/\s/g, '');
            this.valueFieldsValid.cardNumber = ccNumber.length >= 14 && !!ccNumber.match(/^\d+$/);
        },
        'valueFields.cardMonth': function (val) {
            this.valueFieldsValid.cardMonth = !!val.match(/^[01]\d$/);
        },
        'valueFields.cardYear': function (val) {
            this.valueFieldsValid.cardYear = !!val.match(/^\d{4}$/) && this.valueFields.cardYear >= new Date().getFullYear();
        },
        'valueFields.cardCvv': function (val) {
            this.valueFieldsValid.cardCvv = !!val.match(/^\d{3,4}$/);
        },
        valueFieldsValid: {
            handler: function (val) {
                if (Object.keys(val).every(key => val[key])) {
                    this.$emit('paymentFormChanged', { completed: true, creditCardInfos: this.valueFields });
                }
                else {
                    this.$emit('paymentFormChanged', { completed: false });
                }
            },
            deep: true
        }
    },
    methods: {
        changeName(e) {
            this.valueFields.cardName = e.target.value
            this.$emit('input-card-name', this.valueFields.cardName)
        },
        changeNumber(e) {
            this.valueFields.cardNumber = e.target.value
            const value = this.valueFields.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.valueFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.valueFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.valueFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line
            if (e.inputType == 'deleteContentBackward') {
                const lastChar = this.valueFields.cardNumber.substring(this.valueFields.cardNumber.length, this.valueFields.cardNumber.length - 1)
                // eslint-disable-next-line
                if (lastChar == ' ') { this.valueFields.cardNumber = this.valueFields.cardNumber.substring(0, this.valueFields.cardNumber.length - 1) }
            }
            this.$emit('input-card-number', this.valueFields.cardNumber)
        },
        changeMonth() {
            this.$emit('input-card-month', this.valueFields.cardMonth)
        },
        changeYear() {
            this.$emit('input-card-year', this.valueFields.cardYear)
        },
        changeCvv(e) {
            this.valueFields.cardCvv = e.target.value
            this.$emit('input-card-cvv', this.valueFields.cardCvv)
        },
        generateMonthValue(n) {
            return n < 10 ? `0${n}` : n
        },
        toggleMask() {
            this.isCardNumberMasked = !this.isCardNumberMasked
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            } else {
                this.unMaskCardNumber()
            }
        },
        maskCardNumber() {
            this.valueFields.cardNumberNotMask = this.valueFields.cardNumber
            this.mainCardNumber = this.valueFields.cardNumber
            const arr = this.valueFields.cardNumber.split('')
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== '') {
                    arr[index] = '*'
                }
            })
            this.valueFields.cardNumber = arr.join('')
        },
        unMaskCardNumber() {
            this.valueFields.cardNumber = this.mainCardNumber
        },
        isFormFilled() {
            if (SubscriptionService.isFormFilled({ creditCardInfos: this.valueFields })) {
                this.$emit('paymentFormCompleted', { creditCardInfos: this.valueFields });
            }
        }
    },
    mounted() {
        // Style Object documentation here: https://stripe.com/docs/js/appendix/style
        // const style = {
        //     base: {
        //         color: 'black',
        //         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        //         fontSmoothing: 'antialiased',
        //         fontSize: '14px',
        //         '::placeholder': {
        //         color: '#aab7c4'
        //         }
        //     },
        //     invalid: {
        //         color: '#fa755a',
        //         iconColor: '#fa755a'
        //     }
        // };
        // this.cardNumber = this.stripeElements.create('cardNumber', { style });
        // this.cardNumber.mount('#sCardNumber');
        // this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
        // this.cardExpiry.mount('#sCardExpDate');
        // this.cardCvc = this.stripeElements.create('cardCvc', { style });
        // this.cardCvc.mount('#sCardCVV');

        // this.$el.querySelector('#sCardNumber')

        // this.cardNumber.on('change', function(event) {
        //     if (event.error) {
        //       // show validation to customer
        //     }
        // });
        // this.card = this.stripeElements.create('card', { style });
        // this.card.mount('#card-element');
    },
    beforeDestroy() {
        this.cardNumber.destroy();
        //this.cardExpiry.destroy();
        //this.cardCvc.destroy();
    }
}

export default CreditCardPaymentPanelMixin
