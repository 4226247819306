const PaymentServiceMixin = {
    methods: {
        confirmCardPayment({ clientSecret, paymentMethod }) {
            return new Promise((resolve, reject) => {
                const data = { payment_method: paymentMethod }
                this.$stripe.confirmCardPayment(clientSecret, data).then((result) => {
                    if (result.error) {
                        reject(result.error);
                    }
                    else {
                        // Successful subscription payment
                        resolve(result);
                    }
                });
            });
        },
        confirmPaymentWithExistingPaymentMethod({ clientSecret, existingPaymentMethodId }) {
            return this.confirmCardPayment({ clientSecret, paymentMethod: existingPaymentMethodId })
        },
        confirmPaymentWithNewPaymentMethod({ clientSecret, cardNumber, cardName }) {
            const paymentMethod = {
                card: cardNumber,
                billing_details: {
                    name: cardName
                }
            }
            return this.confirmCardPayment({ clientSecret, paymentMethod })
        },
        confirmCardSetup({ clientSecret, cardNumber, cardName }) {
            return new Promise((resolve, reject) => {
                const paymentMethod = {
                    card: cardNumber,
                    billing_details: {
                        name: cardName
                    }
                }
                this.$stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod }).then((result) => {
                    if (result.error) {
                        reject(result.error);
                    }
                    else {
                        resolve(result);
                    }
                });
            })
        }
    }
}

export default PaymentServiceMixin