<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        content-class="iAlert"
    >
        <v-card :class="cls">
            <div class="alertIcon">
                <font-awesome-icon :icon="icons[type]" class="fa-fw fa-3x"></font-awesome-icon>
            </div>
            <v-card-title class="text-h5">
                {{title}}
            </v-card-title>
            <v-card-text class="alertText">{{msg}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="close"
                >
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AlertAdaptor from './AlertAdaptor'
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { faExclamationTriangle, faTimesCircle, faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
export default {
    data () {
        return {
            icons: {
                close: faCheck,
                error: faTimesCircle,
                warning: faExclamationTriangle,
                info: faInfoCircle
            },
            dialog: false,
            alertAdaptor: undefined,
            alert: {}
        }
    },
    created() {
        this.alertAdaptor = new AlertAdaptor();
    },
    computed: {
        type() {
            return this.alert.type || 'error';
        },
        cls() {
            return `alert-${this.type}`;
        },
        title() {
            return this.$t(`alerts.title.${this.type}`);
        },
        msg() {
            return this.alert.errorMsg || this.$t('alerts.error');
        }
    },
    methods: {
        show({ type, code, data }) {
            this.alert = this.alertAdaptor.adapt({ type, code, data }).getInfos();
            this.dialog = true;
        },
        error({ errorCode, errorMsg, innerData }) {
            console.log(errorMsg);
            this.alert = this.alertAdaptor.adapt({ type: 'error', code: errorCode, data: innerData }).getInfos();
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
>>>.iAlert {
    overflow-y: unset;
}
.alert-error {
    border-left: solid 15px #e60000;
}
.alert-warning {
    border-left: solid 10px var(--v-synaxy-base);
}
.alert-info {
    border-left: solid 10px var(--v-primary-base);
}
.alertText {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: medium;
}
.alertIcon {
    display: flex;
    justify-content: center;
    font-size: x-large;
    position: relative;
    top: -36px;
}
.alert-error>.alertIcon svg {
    --fa-primary-color: #FFF;
    --fa-secondary-color: #e60000;
    --fa-secondary-opacity: 1.0;

}
.alert-warning>.alertIcon svg {
    --fa-primary-color: #FFF;
    --fa-secondary-color: var(--v-synaxy-base);
    --fa-secondary-opacity: 1.0;
}
.alert-info>.alertIcon svg {
    --fa-primary-color: #FFF;
    --fa-secondary-color: var(--v-primary-base);;
    --fa-secondary-opacity: 1.0;
}
</style>
