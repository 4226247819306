<template>
    <div :id="swivleCardId" class="swivelAddCard2">
        <div class="flip-card-inner2">
            <div class="flip-card-front2">
                <ListCard v-if="!isPortfolio" :list="list" @askEditBoardInfos="askEditBoardInfos" :listType="listType"></ListCard>
                <PortfolioCard v-else :portfolio="list" @askEditBoardInfos="askEditBoardInfos"></PortfolioCard>
            </div>
            <div class="flip-card-back2">
                <div class="createListForm">
                    <v-form ref="addListForm" v-model="valid">
                        <v-text-field
                            name="listName"
                            v-model="input.listName"
                            hide-details="auto"
                            :label="$t('Home.dashboard.lists.listNamePlaceholder')"
                            counter="50"
                            required
                            :rules="listNameRules"
                        ></v-text-field>
                        <v-text-field
                            name="listDescription"
                            v-model="input.listDescription"
                            :label="$t('Home.dashboard.lists.listDescPlaceholder')"
                        ></v-text-field>
                        <div class="cardFooter">
                            <div v-if="isPortfolio" class="currencySelector">
                                <div class="currencyLabel">{{$t('Home.dashboard.lists.currency')}}</div>
                                <v-select
                                    v-model="currencySelected"
                                    :items="currencyList"
                                    label=""
                                    rounded
                                    dense
                                    outlined
                                    :hide-details="true"
                                    class="currencyDrop"
                                ></v-select>
                            </div>
                            <SaveCancelButtons align="end" @saveAsked="update" @cancelAsked="cancel" :enableSave="valid"></SaveCancelButtons>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import SaveCancelButtons from '../../../controls/SaveCancelButtons.vue'
import ListCard from './ListCard'
import Alert from '@/components/controls/Alert.vue'
import PortfolioCard from './PortfolioCard.vue'
export default {
    props: ['list', 'listType'],
    data() {
        return {
            valid: false,
            currencyList: ['CAD', 'USD'],
            currencySelected: 'CAD',
            input: {
                listName: this.list.name,
                listDescription: this.list.description
            },
            listNameRules: [
                (v) => !!v || this.$t('rules.required'),
                (v) => (v && v.length <= 50) || this.$t('rules.maxXCharacters', 50, { nb: 50 })
            ]
        }
    },
    components: {
        ListCard,
        SaveCancelButtons,
        Alert,
        PortfolioCard
    },
    computed: {
        swivleCardId() {
            return `swivle-${this.list.id}`;
        },
        isPortfolio() {
            return this.listType === 'portfolio'
        }
    },
    methods: {
        askEditBoardInfos() {
            document.querySelector(`#${this.swivleCardId} .flip-card-inner2`).style.transform = 'rotateY(180deg)';
            document.querySelector(`#${this.swivleCardId} .flip-card-front2`).style.display = 'none';
        },
        update() {
            if (this.listType === 'portfolio') {
                this.updatePortfolioInfos()
            } else {
                this.updateListInfos()
            }
        },
        updatePortfolioInfos() {
            const data = {
                portfolioId: this.list.id,
                portfolioName: this.input.listName,
                portfolioDescription: this.input.listDescription,
                currency: this.currencySelected
            }
            this.$store.dispatch('updatePortfolioInfos', data).then(result => {
                this.cancel()
            }).catch(error => {
                this.$refs.iAlert.error(error[0])
            });
        },
        updateListInfos() {
            const data = {
                listId: this.list.id,
                listName: this.input.listName,
                listDescription: this.input.listDescription,
                listType: this.listType
            }
            this.$store.dispatch('updateListInfos', data).then(result => {
                this.cancel();
            }).catch(error => {
                this.$refs.iAlert.error(error[0]);
            });
        },
        cancel() {
            document.querySelector(`#${this.swivleCardId} .flip-card-front2`).style.display = 'block';
            document.querySelector(`#${this.swivleCardId} .flip-card-inner2`).style.transform = '';
        }
    }
}
</script>

<style scoped>
.swivelAddCard2 {
    height: 200px;
    background-color: transparent;
    perspective: 1000px;
    cursor: pointer;
}

.swivelAddCard2.focused .listCard {
    background-color: var(--cardHighlight);
}

.flip-card-inner2 {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.swivelAddCard2.focused .flip-card-inner2 {
    box-shadow: none;
}

.flip-card-front2, .flip-card-back2 {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front2 {
    transition: 0.3s;
}

.flip-card-back2 {
    background-color: #FFF;
    color: dodgerblue;
    text-align: center;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    padding: 30px;
}

.createListForm{
    width: 100%;
}
.createListForm>*:not(:last-child){
    margin-bottom: 10px;
}
.cardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.currencySelector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.currencyDrop {
    max-width: min-content;
}
@media only screen and (device-width: 768px) {
/* For general iPad layouts */

}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
    .swivelAddCard2 {
        height: 190px;
    }
}

@media screen and (max-width: 750px) {
    .swivelAddCard2 {
        height: 190px;
    }
}
</style>
