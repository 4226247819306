<template>
    <div class="stockSearcher">
        <v-autocomplete
            v-model="model"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            auto-select-first
            clearable
            rounded
            solo
            :hide-details="true"
            item-text="description"
            :placeholder="$t('entry.searchPlaceholder')"
            :prepend-icon="showIcon"
            return-object
            :rules="[rules.required]"
            autofocus
            @click:clear="symbolCleared"
        >
            <template v-slot:item="{ item }">
                <div class="searchResult">
                    <div :class="`sr-symbol${setTickerSize(item.normalizedTicker)}`">{{formatTicker(item.normalizedTicker)}}</div>
                    <StockCountry :country="item.country"></StockCountry>
                    <div class="sr-name">{{item.name}}</div>
                    <div class="sr-exchange">{{item.exchange}}</div>
                </div>
            </template>
        </v-autocomplete>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import StockCountry from '../stocks/StockCountry.vue'
import SearchResult from "../../models/SearchResult";
import StockService from "../../services/StockService"
import Alert from './Alert.vue'
export default {
    components: { StockCountry, Alert },
    data() {
        return {
            model: null,
            isLoading: false,
            search: null,
            entries: [],
            searchValue: undefined,
            typingTimer: {
                timeoutId: undefined,
                delay: undefined // a definir si on veut afficher un loading apres un délai
            },
            searchTimer: {
                timeoutId: undefined,
                delay: 750
            },
            rules: {
                required: value => !!value || this.$t('EditorPanel.errors.required')
            }
        }
    },
    computed: {
        items() {
            return this.entries;
        },
        showIcon() {
            return this.$vuetify.breakpoint.mobile ? undefined : 'mdi-magnify'
        }
    },
    methods: {
        symbolCleared() {
            this.entries = []
            this.$emit('symbolCleared')
        },
        remoteSearch({ query }) {
            StockService.search({ query }).then((searchResult) => {
                if (searchResult.originalQuery === this.searchValue) {
                    this.entries = searchResult.entries;
                }
            }).catch((err) => {
                this.$refs.iAlert.error(err);
            }).finally(() => {
                this.isLoading = false;
            });
        },
        setTickerSize(ticker) {
            return ticker.length > 8 ? ' t-sm' : '';
        },
        formatTicker(ticker) {
            if (ticker.length > 10) {
                ticker = `${ticker.slice(0, 6)} ${ticker.slice(6)}`;
            }
            return ticker;
        }
    },
    watch: {
        search(searchValue) {
            if (!searchValue || searchValue.length === 0) {
                this.searchValue = undefined;
                this.isLoading = false;
                return
            }
            if (this.model instanceof SearchResult) {
                return
            }
            this.searchValue = searchValue;
            // Si on a un configurer un timer pour le délai de saisie, on affiche le loading apres le délai, sinon on affiche le loading immédiatement.
            if (this.typingTimer.delay) {
                clearTimeout(this.typingTimer.timeoutId);
                this.typingTimer.timeoutId = setTimeout(() => { this.isLoading = true; }, this.typingTimer.delay);
            }
            else {
                this.isLoading = true;
            }
            clearTimeout(this.searchTimer.timeoutId);
            this.searchTimer.timeoutId = setTimeout(() => { this.remoteSearch({ query: searchValue }); }, this.searchTimer.delay);
        },
        model(val) {
            if (val !== undefined) {
                this.$emit('symbolSelected', val);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.searchResult {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    .sr-symbol {
        width: 100px;
        background-color: var(--agora);
        color: white;
        padding: 3px 8px;
        border-radius: 3px;
        text-align: center;
        .sr-symbol.t-sm {
            font-size: smaller;
        }
    }
    .sr-name {
        flex: 1;
        font-weight: 500;
    }
    .sr-exchange {
        color: #999;
    }
    @media screen and (max-width: $small) {
        & {
            font-size: 0.75em;
            .sr-symbol {
                width: 70px;
            }
        }
    }
}
</style>
