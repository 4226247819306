import { faAnalytics, faCloudDownload, faSearch, faSearchPlus, faSignInAlt, faTags, faTelescope, faUserPlus } from "@fortawesome/pro-duotone-svg-icons";
import { faRedoAlt, faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import UsageService from "./UsageService";

var UsageSectionMixin = {
    data() {
        return {
            icons: {
                sync: faSyncAlt,
                refresh: faRedoAlt,
                login: faSignInAlt,
                TickerSearch: faSearch,
                TriggerStockLocalImportation: faSearchPlus,
                "remote-basic-fundamental-fetching": faAnalytics,
                GetAllTags: faTags,
                "ticker-remote-search": faTelescope,
                "trigger-stock-local-importation": faCloudDownload,
                register: faUserPlus
            },
            timespan: [
                { key: 0, since: '24h', delta: 1 },
                { key: 1, since: '48h', delta: 2 },
                { key: 2, since: '7d', delta: 7 },
                { key: 3, since: '30d', delta: 30 }
            ],
            since: 1,
            stats: {
                login: {},
                search: {},
                stockImport: {}
            }
        }
    },
    methods: {
        fetchApplicationStatistics({ since }) {
            const d = new Date();
            d.setDate(d.getDate() - this.since);
            since = since || d;
            UsageService.getApplicationStatistics({ since }).then(result => {
                console.log(result);
                this.stats = result;
            }).catch((err) => {
                console.error(err.message);
            });
        },

        timeSpanChanged(delta) {
            const since = new Date();
            since.setDate(since.getDate() - delta);
            this.fetchApplicationStatistics({ since });
        }
    },
    created() {
        this.fetchApplicationStatistics({});
    }
}

export default UsageSectionMixin
