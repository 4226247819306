<template>
    <div
        class="swivelAddCard"
        width="400"
    >
        <div class="flip-card-inner">
            <div class="flip-card-front" @click="addAsked">
                <font-awesome-icon :icon="createNewListIcon" class="addIcon" size="4x" />
                <div class="sac-msg">{{newListText}}</div>
            </div>
            <div class="flip-card-back">
                <div class="createListForm">
                    <v-form ref="addListForm" v-model="valid">
                        <v-text-field
                            name="listName"
                            v-model="input.listName"
                            hide-details="auto"
                            :label="listName"
                            counter="50"
                            required
                            :rules="listNameRules"
                        ></v-text-field>
                        <v-text-field
                            name="listDescription"
                            v-model="input.listDescription"
                            :label="listDesc"
                            value=""
                        ></v-text-field>
                        <div class="cardFooter">
                            <div v-if="isPortfolio" class="currencySelector">
                                <div class="currencyLabel">{{$t('Home.dashboard.lists.currency')}}</div>
                                <v-select
                                    v-model="currencySelected"
                                    :items="currencyList"
                                    label=""
                                    rounded
                                    dense
                                    outlined
                                    :hide-details="true"
                                    class="currencyDrop"
                                ></v-select>
                            </div>
                            <SaveCancelButtons align="end" @saveAsked="create" @cancelAsked="cancel" :enableSave="valid"></SaveCancelButtons>
                        </div>
                    </v-form>
                </div>
            </div>
        </div>
        <Alert ref="iAlert"></Alert>
        <UpgradeDialog ref="upgradeDialog"></UpgradeDialog>
    </div>
</template>

<script>
import { faLayerPlus } from "@fortawesome/pro-duotone-svg-icons"
import UpgradeDialog from '../../../controls/UpgradeDialog.vue'
import SaveCancelButtons from '../../../controls/SaveCancelButtons.vue'
import Alert from "../../../controls/Alert.vue"
import UserSubscriptionHelper from "../../../plan/UserSubscriptionHelper"
export default {
    props: ['listType'],
    name: 'SwivelAddCard',
    data () {
        return {
            valid: false,
            createNewListIcon: faLayerPlus,
            input: {
                listName: "",
                listDescription: ""
            },
            listNameRules: [
                (v) => !!v || this.$t('rules.required'),
                (v) => (v && v.length <= 50) || this.$t('rules.maxXCharacters', 50, { nb: 50 })
            ],
            currencyList: ['CAD', 'USD'],
            currencySelected: 'CAD'
        }
    },
    components: {
        SaveCancelButtons,
        UpgradeDialog,
        Alert
    },
    computed: {
        listName() {
            const name = this.listType === 'portfolio' ? 'portfolio' : 'list'
            return this.$t(`Home.dashboard.lists.${name}NamePlaceholder`)
        },
        listDesc() {
            const name = this.listType === 'portfolio' ? 'portfolio' : 'list'
            return this.$t(`Home.dashboard.lists.${name}DescPlaceholder`)
        },
        newListText() {
            const type = this.listType === 'portfolio' ? 'Portfolio' : 'List'
            return this.$t(`Home.dashboard.lists.addNew${type}`)
        },
        isPortfolio() {
            return this.listType === 'portfolio'
        },
        userSubscriptionInfo() {
            return this.$store.getters.userSubscriptionInfo;
        }
    },
    methods: {
        addAsked() {
            if (Object.prototype.hasOwnProperty.call(this.userSubscriptionInfo, "usage")) {
                let canAddAList;
                this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: this.userSubscriptionInfo });
                switch (this.listType) {
                    case "buy":
                        canAddAList = this.userSubscriptionHelper.canAddBuyList();
                        break;
                    case "sell":
                        canAddAList = this.userSubscriptionHelper.canAddSellList();
                        break;
                    case "portfolio":
                        canAddAList = this.userSubscriptionHelper.canAddPortfolio();
                        break;
                    default:
                        canAddAList = false;
                }
                if (canAddAList) {
                    this.flipBoard()
                }
                else {
                    this.showUpgradeDialog()
                }
            }
            else {
                this.showUpgradeDialog()
            }
        },
        flipBoard() {
            this.$el.classList.add('flipped')
        },
        showUpgradeDialog() {
            this.$refs.upgradeDialog.show({ type: this.listType })
        },
        createPortfolio() {
            const data = {
                portfolioName: this.input.listName,
                portfolioDescription: this.input.listDescription,
                currency: this.currencySelected
            }
            this.$store.dispatch('createNewPortfolio', data).then(result => {
                this.cancel();
            }).catch(error => {
                const code = error.response ? error.response.status : error
                const errorCode = code === 401 ? 'portfolioLimitReached' : code
                this.$refs.iAlert.error({ errorCode })
            })
        },
        createList() {
            const data = {
                userId: this.$store.state.user.authenticatedUser.userId,
                listName: this.input.listName,
                listDescription: this.input.listDescription,
                listType: this.listType
            }
            this.$store.dispatch('createNewList', data).then(result => {
                this.cancel();
            }).catch(error => {
                const code = error.response.status
                const errorCode = code === 401 ? 'listLimitReached' : code
                this.$refs.iAlert.error({ errorCode })
            });
        },
        create() {
            if (this.listType === 'portfolio') {
                this.createPortfolio()
            } else {
                this.createList()
            }
        },
        cancel() {
            this.$el.classList.remove('flipped')
            this.$refs.addListForm.reset();
            this.currencySelected = 'CAD'
        }
    }

}
</script>

<style scoped>
.swivelAddCard {
    background-color: transparent;
    height: 200px;
    perspective: 1000px;
    border-radius: 4px;
}

.swivelAddCard .headline {
    padding-bottom: 5px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #FFF;
    opacity: 0.3;
    color: dodgerblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    transition: 0.3s;
}

.flip-card-front:hover {
    opacity: 1;
    background-color: var(--hoverBG);
}

.flip-card-back {
    background-color: #FFF;
    color: dodgerblue;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    padding: 30px;
}
.sac-msg {
    text-align: center;
}
.createListForm{
    width: 100%;
}
.cardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.currencySelector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.currencyDrop {
    max-width: min-content;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .swivelAddCard {
        max-width: 100%;
        margin-bottom: 15px;
        height: 100px;
    }
    .flip-card-front {
        padding: 5px;
        opacity: 1;
        background-color: var(--hoverBG);
    }
    .addIcon {
        font-size: 2.5em;
    }
    .sac-msg {
        font-size: smaller;
    }

    .flipped {
        height: 200px;
    }
}
@media screen and (max-width: 750px) {
    .swivelAddCard {
        max-width: 100%;
        margin-bottom: 15px;
        height: 100px;
    }
    .flip-card-front {
        padding: 5px;
        opacity: 1;
        background-color: var(--hoverBG);
    }
    .addIcon {
        font-size: 2.5em;
    }
    .sac-msg {
        font-size: smaller;
    }

    .flipped {
        height: 200px;
    }

}

</style>
