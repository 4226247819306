<template>
    <v-timeline
    align-top
    dense
    >
            <v-timeline-item
          color="red"
          small
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>5pm</strong>
            </v-col>
            <v-col>
              <strong>New Icon</strong>
              <div class="text-caption">
                Mobile App
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
        >
            <v-card
            :color="item.color"
            dark
            >
            <v-card-title class="text-h6">
                Threshold {{i}}
            </v-card-title>
            <v-card-text class="white text--primary">
                <p>Threshold {{i}}</p>
            </v-card-text>
            </v-card>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
export default {
    name: 'ThresholdLine',
    data: () => ({
        items: [
            {
            color: 'red lighten-2',
            icon: 'mdi-star'
            },
            {
            color: 'purple darken-1',
            icon: 'mdi-book-variant'
            },
            {
            color: 'green lighten-1',
            icon: 'mdi-airballoon'
            },
            {
            color: 'indigo',
            icon: 'mdi-buffer'
            }
        ]
    })
}
</script>

<style scoped>
</style>
