<template>
    <div class="stockQuote">
        <div class="body">
            <div class="part size2">
                <div class="chart">
                    <!-- <StockChart v-if="false" ref="stockChart" :stock="stock"></StockChart> -->
                    <StockChart v-if="stock" ref="stockChart" :stock="stock"></StockChart>
                    <v-skeleton-loader v-else type="image" height="300"></v-skeleton-loader>
                </div>
                <div class="metas">
                    <StockMetas :stock="stock"></StockMetas>
                </div>
                <div v-if="isMobile" class="stockLists">
                    <StockThresholdsPanel></StockThresholdsPanel>
                    <StockHoldingsPanel></StockHoldingsPanel>
                </div>
                <div class="stockNews">
                    <StockNewsPanel :news="news"></StockNewsPanel>
                </div>
            </div>
            <div v-if="!isMobile" :class="`part size3 stockLists ${sectionsOrder}`">
                <StockThresholdsPanel></StockThresholdsPanel>
                <StockHoldingsPanel></StockHoldingsPanel>
            </div>
        </div>
    </div>
</template>

<script>
import StockChart from '../controls/StockChart.vue'
import StockHtmlHelper from './StockHtmlHelper'
import StockMetas from './StockMetas.vue'
import ExchangeHelper from '../../store/helpers/ExchangeHelper';
import StockThresholdsPanel from '../userStockWatchingList/StockThresholdsPanel.vue'
import StockHoldingsPanel from '../portfolio/StockHoldingsPanel.vue'
import StockNewsPanel from './StockNewsPanel.vue'
import StockMixin from './StockMixin'
export default {
    components: { StockChart, StockMetas, StockThresholdsPanel, StockHoldingsPanel, StockNewsPanel },
    mixins: [StockMixin],
    data() {
        return {
            stockHtmlHelper: null,
            extraStockHeaderCss: ''
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper()
    },
    computed: {
        logo() {
            return this.stock ? this.stockHtmlHelper.getStockImageSrc(this.stock.companyInfo.logoUrl) : this.$t('dictio.na');
        },
        exchange() {
            const name = this.stock ? ExchangeHelper.getShortName(this.stock.exchange) || this.stock.exchange : null
            return name || this.$t('dictio.na');
        },
        stockThresholds() {
            return this.$store.getters.userLists
        },
        news() {
            return this.$store.getters.news;
        },
        sectionsOrder() {
            const obj = this.$store.getters.getListFromId(this.origin)
            if (!obj) return 'LP'
            return obj.constructor.name === 'Portfolio' ? 'PL' : 'LP'
        },
        origin() {
            return this.$store.getters.stockPageOrigin
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.stockQuote {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .body {
        display: flex;
        gap: 20px;
        .part {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            &.size2 {
                flex: 2;
            }
            &.size3 {
                flex: 3;
            }
            .stockHeader {
                background-color: #FFF;
                position: relative;
                .mainSectionCont {
                    padding: 15px;
                    display: flex;
                    gap: 10px;
                    .mainSection {
                        flex: 2;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        position: relative;
                        .nameAndSymbol {
                            font-size: 1.5em;
                            font-weight: bold;
                            background-color: var(--v-lightBG-base);
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            .v-chip {
                                font-size: large;
                            }
                        }
                        .stockFrom {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            .exchange {
                                font-size: 0.8em;
                            }
                        }
                    }
                    .priceSection {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 5px;
                        font-size: 1.2em;
                        .price {
                            font-size: 2.2em;
                            font-weight: bold;
                            line-height: 1.2;
                        }
                        .priceChange {
                            font-size: 1.2em;
                            font-weight: bold;
                        }
                        .timeElapsed {
                            font-size: 0.8em;
                        }
                    }
                }
            }
            .chart {
                z-index: 10;
            }
            .metas {
                background-color: #FFF;
            }
            .stockNews {
                padding: 15px;
                background-color: #FFF;
            }
            .stockLists {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
            &.PL {
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        }
    }
    @media screen and (max-width: $small) {
        & {
            .body {
                flex-direction: column;
                .part{
                    .stockHeader {
                        .mainSectionCont {
                            .mainSection {
                                .nameAndSymbol {
                                    font-size: 1.1em;
                                    gap: 0;
                                    align-items: flex-start;
                                    .v-chip {
                                        font-size: 0.8em;
                                    }
                                }
                            }
                            .priceSection {
                                font-size: 1em;
                                .price {
                                    font-size: 1.5em;
                                }
                            }
                        }
                        .logo {
                            top: -15px;
                            width: 150px;
                            height: 150px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: $phone) {
        .body {
            .part {
                gap: 10px;
                .stockHeader {
                    .mainSection {
                        .nameAndSymbol {
                            font-size: 1em;
                            flex-direction: column;
                            gap: 5px;
                            align-items: flex-start;
                            .v-chip {
                                font-size: 0.8em;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>