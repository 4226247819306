<template>
    <v-menu
        v-model="displayMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="userListSelector">
                <span class="userListSelectorLabel" v-show="allUnselected">{{$t('EditorPanel.actions.addToList', [symbol])}}</span>
                <v-badge
                    :content="selectedListsCount"
                    :value="selectedListsCount"
                    color="synaxy"
                >
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon :icon="icons[iconsFilter]" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                </v-badge>
            </div>
        </template>
        <v-card>
            <div class="header">{{$t('EditorPanel.actions.inList', { sym: symbol })}}</div>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item-group
                    v-model="selectedLists"
                    multiple
                    color="primary"
                >
                    <template v-for="(list, i) in listItems">
                        <v-divider
                            v-if="!list"
                            :key="`divider-${i}`"
                        ></v-divider>

                        <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :value="list.id"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action class="mr-4">
                                    <v-checkbox
                                    :input-value="active"
                                    ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <!-- <v-list-item-title v-text="list.type"></v-list-item-title> -->
                                    <v-list-item-subtitle>
                                        <font-awesome-icon :icon="getListIconFromType(list.type)" class="fa-lg fa-fw mr-2" />
                                        {{list.name}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <div class="footer">
                <v-btn block :disabled="selectionChanged" color="primary" @click="applyAsked">{{$t('EditorPanel.actions.apply')}}</v-btn>
            </div>
            <ConfirmDialog ref="deleteItemConfirmDialog" @answered="deleteItemDialogAnswered"></ConfirmDialog>
            <Alert ref="iAlert"></Alert>
            <SimpleThresholdPanelDialog ref="thresholdPanelDialog" @saveThresholds="saveThresholds" @cancelThresholds="cancelThresholds"></SimpleThresholdPanelDialog>
        </v-card>
    </v-menu>
</template>

<script>
import { faListUl, faTasks } from '@fortawesome/pro-duotone-svg-icons'
import ConfirmDialog from '../../controls/ConfirmDialog.vue';
import Alert from '../../controls/Alert.vue';
import { listComputed } from '../../../store/helpers/ListHelper';
import SimpleThresholdPanelDialog from './SimpleThresholdPanelDialog.vue';
import Portfolio from '../../../models/Portfolio';
import ListDuoIconMixin from '../../help/icons/ListDuoIconMixin';
export default {
    props: ['symbol', 'thresholds', 'stock'],
    mixins: [ListDuoIconMixin],
    data () {
        return {
            displayMenu: false,
            icons: {
                unsetFilter: faListUl,
                filterSetted: faTasks
            },
            messages: 0,
            selectedLists: [],
            selections: [],
            initialSelectedLists: [],
            extractedUserLists: [],
            listsToAskThreshols: []
        }
    },
    components: { ConfirmDialog, Alert, SimpleThresholdPanelDialog },
    created() {
        this.extractLists();
        this.getSelectedLists();
    },
    methods: {
        extractLists() {
            this.extractedUserLists = Object.keys(this.userLists).reduce((acc, cur) => acc.concat(this.userLists[cur]), []);
        },
        getSelectedLists() {
            this.selectedLists = [];
            this.extractedUserLists.forEach(l => {
                let entry = null
                if (l instanceof Portfolio) {
                    entry = l.stocks.find(s => s.stockDateTimeSnapshot && s.stockDateTimeSnapshot.symbol === this.symbol)
                } else {
                    entry = l.entries.find(e => e.symbol === this.symbol)
                }
                if (entry) {
                    this.selectedLists.push(l.id);
                    this.selections.push({ listId: l.id, entryId: entry.id });
                }
            });
        },
        deleteItemDialogAnswered(answer) {
            if (answer.response === true) {
                this.initialSelectedLists.forEach(l => {
                    const list = this.getListFromId(l);
                    this.deleteItemFromList({ list, stock: this.stock });
                })
                this.displayMenu = false;
            }
        },
        deleteItemFromList({ list, stock }) {
            const entryId = this.selections.find(s => s.listId === list.id).entryId;
            if (entryId) {
                this.$store.dispatch('deleteStockFromWatchList', { listId: list.id, entryId, listType: list.type }).then(() => {
                    this.displayMenu = false;
                    this.selections = this.selections.filter(s => s.entryId !== entryId);
                }).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                });
            }
        },
        getListTypeFromId(listId) {
            return this.getListFromId(listId).type;
        },
        getListFromId(listId) {
            return this.listItems.find(l => l.id === listId);
        },
        askForThresholdsIfNeeded() {
            if (this.listsToAskThreshols.length > 0) {
                const list = this.listsToAskThreshols.shift();
                this.askForThresholds({ list })
            }
        },
        askForThresholds({ list }) {
            this.$refs.thresholdPanelDialog.show({ list });
        },
        applyAsked() {
            if (this.selectedListsCount === 0) {
                this.$refs.deleteItemConfirmDialog.open({
                    title: this.$t('EditorPanel.actions.deleteItem.title'),
                    text: this.$t('EditorPanel.actions.deleteItem.fromAllList', [this.symbol])
                });
            }
            else {
                const allLists = Object.keys(this.userLists).reduce((acc, cur) => acc.concat(this.userLists[cur]), []);
                allLists.forEach(l => {
                    if (this.selectedLists.includes(l.id) && !this.initialSelectedLists.includes(l.id)) {
                        //add
                        this.listsToAskThreshols.push(l);
                    }
                    else if (!this.selectedLists.includes(l.id) && this.initialSelectedLists.includes(l.id)) {
                        //remove
                        this.deleteItemFromList({ list: l, stock: this.stock });
                    }
                });
                this.askForThresholdsIfNeeded()
                this.displayMenu = false;
            }
        },
        addStockInWatchList({ list, newEntry }) {
            this.$store.dispatch('addStockInWatchList', { listId: list.id, newEntry, listType: list.type }).then((addedUserListEntry) => {
                this.selections.push({ listId: list.id, entryId: addedUserListEntry.id });
            }).catch(error => {
                this.$refs.iAlert.error(error[0]);
            });
        },
        saveThresholds({ list, thresholds }) {
            const newEntry = this.stock.rawData;
            newEntry.id = '';
            const threasholds = {
                T200: Number(thresholds.t200),
                T400: Number(thresholds.t400),
                T600: Number(thresholds.t600),
                T800: Number(thresholds.t800)
            }
            newEntry.threasholds = threasholds;
            this.addStockInWatchList({ list, newEntry });
            this.askForThresholdsIfNeeded()
        },
        cancelThresholds({ list }) {
            this.selectedLists = this.selectedLists.filter(l => l.id !== list.id);
            this.askForThresholdsIfNeeded()
        }
    },
    computed: {
        ...listComputed,
        listItems() {
            const lists = Object.keys(this.userLists).reduce((acc, cur) => acc.concat(this.userLists[cur]), []);
            return lists;
        },
        selectedListsCount() {
            return this.selectedLists.length;
        },
        iconsFilter() {
            return this.selectedLists.length === 0 ? 'unsetFilter' : 'filterSetted';
        },
        allUnselected() {
            return this.selectedLists.length === 0;
        },
        allSelected() {
            return this.selectedLists.length === this.tags.length;
        },
        someSelected() {
            return this.selectedLists.length > 0 && this.selectedLists.length < this.tags.length;
        },
        selectionChanged() {
            const o1 = this.selectedLists;
            const o2 = this.initialSelectedLists;
            return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(k => o1[k] === o2[k]);
        }
    },
    watch: {
        displayMenu(val) {
            if (val) {
                this.initialSelectedLists = this.selectedLists;
            }
        },
        symbol(val) {
            this.getSelectedLists();
        }
    }
}
</script>

<style scoped>
.userListSelector {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.userListSelectorLabel {
    font-size: small;
    margin-right: 5px;
}
.header {
    padding: 16px;
    font-size: small;
}
.footer {
    padding: 10px;
}
>>>.v-badge__badge.synaxy {
    inset: -3px -5px auto auto !important;
}
</style>
