<template>
<div :class="`subscriptionCard${loggedIn && isSelected ? ' selected' : ''}`">
    <div class="sc-header">
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <div v-if="plan.name === myCurrentPlan()" v-bind="attrs" v-on="on" class="sc-header-userSelected-icon">
                    <font-awesome-icon :icon="icons.userSelection" style="color:dodgerblue;margin-right:5px"></font-awesome-icon>
                </div>
            </template>
            <span>{{$t(`subscriptions.plans.current`)}}</span>
        </v-tooltip>
        <div class="sc-header-icon">
            <font-awesome-icon :icon="icons[plan.name]"></font-awesome-icon>
        </div>
        <div class="sc-header-title">
            {{$t(`subscriptions.plans.${plan.name}.title`)}}
            <div v-if="isCurrentPlanCancelled" class="sc-header-subtitle">{{$t('subscriptions.cancellation.cancelled')}}*</div>
        </div>
        <SubscriptionEnd v-if="isCurrentPlan" class="sc-end"></SubscriptionEnd>
    </div>
    <div class="sc-body">
        <div class="sc-pricing">
            <div v-if="isFreePlan" class="sc-price"><span class="dollars">{{$t('subscriptions.plans.price.free')}}</span></div>
            <div v-else class="sc-price"><span class="dollars">{{plan.cost.d}}</span><span class="cents">{{$t(`subscriptions.plans.price.separator`)}}{{plan.cost.c}} {{$t(`subscriptions.plans.price.currency`)}}</span></div>
            <div v-if="isFreePlan" class="sc-reccuring">{{$t(`subscriptions.plans.reccuring.always`)}}</div>
            <div v-else class="sc-reccuring">{{$t(`subscriptions.plans.reccuring.perMonth`)}}</div>
        </div>
        <div v-if="!isReadOnly" class="sc-action">
            <v-btn v-if="isCurrentPlan" outlined class="sc-action-btn current-plan">
                <font-awesome-icon :icon="icons.current" class="sc-action-icon"></font-awesome-icon>
                {{$t(`subscriptions.plans.buttons.current`)}}
            </v-btn>
            <div v-else>
                <v-btn v-if="isSelected" disabled class="sc-action-btn">{{$t(`subscriptions.plans.buttons.selected`)}}</v-btn>
                <v-btn v-else-if="canceledSubscription" disabled class="sc-action-btn">{{actionButtonText}}</v-btn>
                <v-btn v-else color="primary" @click="selectPlan()" class="sc-action-btn">{{actionButtonText}}</v-btn>
            </div>
        </div>
        <div class="sc-desc">
            <div class="sc-desc-items" v-for="(item, i) in descriptionKeys" :key="i">
                <div class="sc-desc-item" v-if="isKeyActive(item)">
                    <div class="sc-desc-part"><font-awesome-icon :icon="icons.check" class="sc-desc-item-check"></font-awesome-icon></div>
                    <div class="sc-desc-part">{{$tc(`subscriptions.plans.description.${item}`, plural(item), { nb: plan.limit[item] })}}</div>
                </div>
            </div>
            <div class="sc-desc-items" v-if="newsCount">
                <div class="sc-desc-item">
                    <div class="sc-desc-part"><font-awesome-icon :icon="icons.check" class="sc-desc-item-check"></font-awesome-icon></div>
                    <div class="sc-desc-part">{{$t(`subscriptions.plans.description.news`, { nb: newsCount })}}</div>
                </div>
            </div>
            <div v-if="isAiPlan" class="sc-desc-items aiSection">
                <font-awesome-layers class="iconSection fa-4x">
                    <font-awesome-icon :icon="icons.ai" transform="rotate-45"></font-awesome-icon>
                    <font-awesome-layers-text transform="shrink-9 rotate--45" class="fa-inverse" :value="$t(`subscriptions.plans.description.ai.label`)"></font-awesome-layers-text>
                </font-awesome-layers>
                <div class="sc-desc-item" v-for="(key, i) in iaKeysByPlan" :key="i">
                    <div class="sc-desc-part"><font-awesome-icon :icon="icons.check" class="sc-desc-item-check"></font-awesome-icon></div>
                    <div class="sc-desc-part">{{$t(`subscriptions.plans.description.ai.${key}`)}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="sc-footer"></div>
</div>
</template>

<script>
import SubscriptionCardMixin from './SubscriptionCardMixin'
import SubscriptionEnd from './SubscriptionEnd.vue'
export default {
    mixins: [SubscriptionCardMixin],
    components: { SubscriptionEnd }
}
</script>

<style lang="scss" scoped>
.subscriptionCard {
    width: 375px;
    background-color: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    .sc-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .sc-pricing {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding-top: 10px;
            font-size: xx-large;
            border-top: solid 10px var(--darkBG);
            transition: ease-out 0.5s;
            .sc-reccuring {
                font-size: 0.7rem;
                color: #AAA;
                text-transform: uppercase;
            }
        }
        .sc-action {
            display: flex;
            justify-content: center;
            align-items: center;
            .sc-action-btn {
                color: #FFF;
                &.current-plan{
                    color: dodgerblue;
                    .sc-action-icon {
                        color: dodgerblue;
                    }
                }
                .sc-action-icon {
                    color: #FFF;
                    margin-right: 10px
                }
            }
        }
        .sc-desc {
            padding: 20px;
            font-size: 0.8em;
            .aiSection {
                margin: 0 -10px;
                border: solid 1px var(--v-agora-base);
                border-radius: 5px;
                padding: 10px;
                position: relative;
                .iconSection {
                    position: absolute;
                    top: -28px;
                    right: 12px;
                    color: var(--v-agora-base);
                }
            }
        }
    }
    &.selected {
        padding: 20px 0;
        margin-top: -20px;
        margin-bottom: -20px;
        background-color: var(--v-agorabg-base);
        .sc-header {
            margin-top: -20px;
            padding-top: 60px;
            background-color: var(--v-agora-base);
            color: #FFF;
        }
    }

    &:not(.selected):hover {
        background-color: var(--hoverBG);
        .sc-header .sc-header-icon {
            transform: scale(1.4);
        }

        .sc-pricing {
            border-top: solid 10px dodgerblue;
        }
    }
}

.sc-header {
    background-color: var(--sectionTitle);
    text-transform: uppercase;
    color: var(--darkBG);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 40px 0;
    transition: 0.5s;
    position: relative;
    overflow: hidden;
    .sc-end {
        width: 100%;
        position: absolute;
        bottom: 0;
        font-size: 0.7rem;
    }
}

.sc-header .sc-header-icon {
    color: var(--v-agora-base);
    font-size: xx-large;
    margin-right: 20px;
    transition: 0.3s;
}

.sc-header .sc-header-userSelected-icon {
    position: absolute;
    top: -10px;
    right: 20px;
    font-size: 36px;
}

.sc-header .sc-header-title {}

.sc-header-subtitle {
    font-size: medium;
    color: #e60000;
    font-weight: bold;
}

// .sc-body-msg {
//     padding: 0 10px;
// }

// .subscriptionCard.selected .sc-pricing {
//     border-top: solid 10px dodgerblue;
// }

.subscriptionCard.selected .sc-header .sc-header-icon {
    transform: scale(1.4);
    svg{
        color: #FFF;
    }
}

.sc-pricing .sc-price {
    display: flex;
}

.sc-pricing .sc-price .dollars {
    font-size: larger;
    font-weight: bold;
}

.sc-pricing .sc-price .cents {
    font-size: smaller;
    padding-top: 6px;
    padding-left: 3px;
}

.sc-desc-item {
    padding: 5px 0;
    display: flex;
    gap: 5px;
    .sc-desc-item-check {
        color: dodgerblue;
        margin-right: 5px
    }
}

.sc-footer {
    height: 20px;
}

@media screen and (max-width: 750px) {
    .subscriptionCard {
        margin-bottom: 15px;
    }

    .subscriptionCard.selected {
        padding: 0;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .subscriptionCard.selected .sc-header {
        padding-top: 40px;
        margin-top: 0;
    }

}
</style>
