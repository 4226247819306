<template>
    <div class="questionCard">
        {{ question.question }}
    </div>
</template>

<script>
import QuestionBeforeInvesting from '../../../models/QuestionBeforeInvesting'
export default {
    props: {
        question: {
            type: QuestionBeforeInvesting
        }
    }
}
</script>

<style lang="scss" scoped>
.questionCard {
    font-size: 1.1em;
}
</style>