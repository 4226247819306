import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { listComputed } from "../../store/helpers/ListHelper";
import StockHtmlHelper from './StockHtmlHelper'
var StockScoreInfosListMixin = {
    name: 'StockScoreInfosList',
    props: ['entry', 'type'],
    data() {
        return {
            icon: faCircle,
            stockHtmlHelper: undefined,
            scoreInfo: this.entry.getScoreInfos()[0]
        }
    },
    computed: {
        ...listComputed,
        getUserLists() {
            return this.userLists;
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        this.setScore();
    },
    methods: {
        getCleanScore(score) {
            return this.stockHtmlHelper.cleanScore(score);
        },
        isSelling() {
            return this.type === 'sell';
        },
        setScore() {
            this.scoreInfo = this.entry.getScoreInfos()[0];
        },
        getScoreColor(score) {
            return this.stockHtmlHelper.getBadgeColor(this.stockHtmlHelper.getBadge(score));
        }
    },
    watch: {
        getUserLists: {
            handler: function (val) {
                this.setScore();
            },
            deep: true
        }
    }
}

export default StockScoreInfosListMixin;
