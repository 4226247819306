<template>
    <div class="simplePage">
        <v-card outlined class="simplePageCard">
            <v-list-item>
                <v-list-item-avatar>
                <v-icon x-large color="orange darken-4">mdi-alert-octagon</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title class="headline mb-1">{{$t('unlockAccess.title')}}</v-list-item-title>
                </v-list-item-content>

            </v-list-item>

            <v-container>
                <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="key"
                        dense
                        :label="$t('unlockAccess.enterKey')"
                        :name="Math.random()"
                        @keydown="keydown"
                    ></v-text-field>
                </v-col>
                </v-row>
            </v-container>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="validateKey">{{$t('unlockAccess.validateButton')}}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import storageService from '../../services/StorageService'
export default {
    data: () => ({
        key: ''
    }),
    methods: {
        keydown(e) {
            if (e.keyCode === 13) {
                this.validateKey();
            }
        },
        validateKey: function () {
            storageService.accessKey = this.key;
            this.$router.push({ path: this.$route.query.go });
        }
    }
}
</script>

<style scoped>
.simplePage {
    padding-top: 100px;
}
.v-sheet.v-card.simplePageCard {
    max-width: 500px;
    margin: auto;
    border: none;
}
@media screen and (max-width: 750px) {
    .simplePage {
        width: 100%;
        padding: 50px 15px;
    }

}
</style>
