import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loggedIn)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VAvatar,{attrs:{"color":"#2d353c","size":"40"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.user.nickname))])])],1)]}}],null,false,1991804618)},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"350","tile":""}},[_c(VList,[_c('div',{staticClass:"userInfos"},[_c('div',{staticClass:"ui-name"},[_vm._v(_vm._s(_vm.user.fullname()))]),_c('div',{staticClass:"ui-email"},[_vm._v(_vm._s(_vm.user.email))])]),_c(VListItem,{attrs:{"link":""},on:{"click":_vm.goToMyAccount}},[_c(VListItemIcon,[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.myAccount}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('avatar.myAccount')))])],1)],1)],1),(_vm.isNotDemo)?_c('div',[_c(VDivider),_c('UsagePanel',{on:{"editSubscriptionAsked":_vm.editSubscriptionAsked}})],1):_vm._e(),_c(VDivider),_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._l((_vm.mainNavigationListItem),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.go(item.route)}}},[_c(VListItemIcon,[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons[item.icon]}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.text)))])],1)],1)}),_c(VDivider),_c(VListItem,{on:{"click":_vm.logoff}},[_c(VListItemIcon,[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.logoff}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('avatar.logoff')))])],1)],1)],2)],1)],1)],1):_c(VBtn,{attrs:{"dark":"","icon":""}},[_c(VIcon,[_vm._v("mdi-account-circle-outline")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }