<template>
    <div :class="`stockDayChange ${getCardClass}`">{{ dayChange }}</div>
</template>

<script>
import StockHtmlHelper from './StockHtmlHelper';

export default {
    props: ['stock'],
    data() {
        return {
            stockHtmlHelper: null
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        getCardClass() {
            return this.stock.statistics.dayChange < 0 ? "down" : this.stock.statistics.dayChange > 0 ? 'up' : '';
        },
        dayChange() {
            const symbol = this.stock.statistics.dayChange > 0 ? '+' : ''
            return `${symbol}${this.stockHtmlHelper.formatChange(this.stock.statistics.dayChange, this.stock.stockDateTimeSnapshot.price)}`
        }
    }
}
</script>

<style lang="scss" scoped>
.stockDayChange {
    font-weight: bold;
    &.up{
        color: var(--v-greenUp-base);
    }
    &.down{
        color: var(--v-redDown-base);
    }
}
</style>