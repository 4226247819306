import { faHandshake, faHeart, faIndustryAlt, faLightbulbDollar, faNewspaper, faShoppingCart, faSuitcase, faUsdCircle } from '@fortawesome/pro-regular-svg-icons'
import ListIconMixin from "./ListIconMixin"

var ListRegularIconMixin = {
    mixins: [ListIconMixin],
    data() {
        return {
            icons: {
                buy: faShoppingCart,
                sell: faUsdCircle,
                portfolio: faSuitcase,
                news: faNewspaper,
                partnership: faHandshake,
                company: faIndustryAlt,
                sentiments: faHeart,
                questions: faLightbulbDollar
            }
        }
    }
}

export default ListRegularIconMixin