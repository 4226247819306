<template>
    <div class="settingsSection">
        <div class="settings">
            <div class="sPart">
                <div class="sInfo">{{$t('settings.body.language')}}</div>
                <div class="sAction">
                    <LanguageSwitcher dark color="blue-grey"></LanguageSwitcher>
                </div>
            </div>
            <div class="sPart">
                <div class="sInfo">{{$t('settings.body.currency')}}</div>
                <div class="sAction">
                    <CurrencySelector color="blue-grey" :dark="true"></CurrencySelector>
                </div>
            </div>
        </div>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import {
    faCheck,
    faTimes
} from "@fortawesome/pro-light-svg-icons";
import {
    faUserCog
} from "@fortawesome/pro-duotone-svg-icons";
import LanguageSwitcher from '../../../components/controls/LanguageSwitcher'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import Alert from '../../../components/controls/Alert.vue'
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import CurrencySelector from '../../../components/controls/CurrencySelector.vue';
export default {
        data() {
        return {
            editName: false,
            changePswSnackbar: false,
            icons: {
                cog: faUserCog,
                check: faCheck,
                times: faTimes,
                pencil: faPen
            },
            rules: {
                required: value => !!value || this.$t('settings.errors.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('settings.errors.invalidEmail')
                }
            },
            links: [
                {
                    name: 'termsAndConditions',
                    route: 'TermsAndConditions'
                },
                {
                    name: 'cookiePolicy',
                    route: 'CookiePolicy'
                }
            ]
        }
    },
    computed: {
        ...authComputed,
        firstname() {
            return this.user.firstname;
        },
        lastname() {
            return this.user.lastname;
        }
    },
    components: { LanguageSwitcher, Alert, CurrencySelector },
    methods: {
        openFullnameEdition() {
            this.editName = true;
        },
        confirmChangeFullname() {
            this.$store.dispatch('setUserFullname', { firstname: this.input.firstname, lastname: this.input.lastname }).then((response) => {
                this.closeFullnameEdition();
            }).catch((error) => {
                this.$refs.iAlert.error(error[0]);
            });
        },
        closeFullnameEdition() {
            this.editName = false;
        },
        passwordChanged() {
            this.changePswSnackbar = true;
        }
    }
}
</script>

<style scoped>
.settingsPage {
    height: 100%;
    background-color: var(--lightBG);
}
.settings {
    display: flex;
    flex-direction: column;
    font-size: large;
}
/* .s-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    font-size: xx-large;
}
.s-title {
    margin-left: 30px;
    text-transform: uppercase;
} */
/* .s-body {
    padding: 10px;
} */
.s-part {
    padding: 0 20px;
    margin-bottom: 30px;
    display: flex;
}
.s-part.s-hover {
    width: 100%;
    transition: 0.2s;
}
.s-part.s-hover:hover {
    background-color: var(--hoverBG);
}
.s-part:last-of-type {
    margin-bottom: 0;
}
.s-namePart {
    padding: 20px;
    border-radius: 5px;
}
.s-namePart.s-readonly {
    width: 100%;
    transition: 0.2s;
}
.s-namePart-inputs {
    flex: 1;
    display: flex;
    align-items: center;
}
.s-name {
    flex: 1;
}
.s-label {
    font-weight: bold;
}
.s-fullname {
    color: #555;
    font-weight: bold;
}
.s-namePart-actions {
    display: flex;
    opacity: 1;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s;
}
.s-namePart-actions>*:not(:first-child) {
    margin-left: 10px;
}
.s-readonly:hover {
    background-color: var(--hoverBG);
}
.s-readonly:hover .s-namePart-actions, .s-hover:hover .s-namePart-actions {
    opacity: 1;
}
.s-namePart.s-editable {
    flex-direction: column;
    background-color: #FFF;
}
.s-namePart.s-editable .s-namePart-actions {
    opacity: 1;
    justify-content: flex-end;
}
.s-subscrptionInfos {
    display: flex;
    padding: 20px;
    border-radius: 5px;
}
.s-passwordPart, .s-langPart {
    padding: 0 20px;
}
.s-langPart .v-btn {
    color: #FFF;
    background-color: #607D8B !important;
}
.versionInfos{
    /* position: absolute;
    bottom: 0; */
    padding: 5px;
}
.cie {
    margin: 8px 0;
    padding: 0 16px;
    display: flex;
    color: #FFF;
}

.sPart {
    padding: 10px;
    display: flex;
    align-items: center;
    transition: 0.2s;
}
.sPart:hover {
    background-color: var(--hoverBG);
}
.sInfo{
    font-weight: bold;
    flex: 1;
}
.sAction{

}
@media screen and (max-width: 768px) {
    .s-header {
        padding: 20px;
        font-size: large;
    }
    .s-body {
        padding: 15px;
        width: 100%;
    }
    .s-part {
        padding: 0 10px;
    }
    .s-namePart-actions {
        opacity: 1;
    }
}

</style>
