<template>
    <v-dialog v-model="dialog" fullscreen scrollable hide-overlay @click:outside="close">
        <v-card tile class="overflow-hidden">
            <v-app-bar
                absolute
                dark
                dense
                elevate-on-scroll
                scroll-target="#scrolling-techniques-6"
                class="dialog-toolbar-color"
            >
                <v-toolbar-title class="stockHeader">{{ stock.companyName }} ({{ stock.symbol }})</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="scrollAndClose"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-sheet
                id="scrolling-techniques-6"
                class="overflow-y-auto px-2 pb-6"
                max-height="100vh"
            >
                <div class="stockFullCardCont">
                    <div class="dialogBody">
                        <StockFullCardMobile ref="stockFullCard" :stock="stock"></StockFullCardMobile>
                    </div>
                    <div class="dialogFooter">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="secondary"
                            fab
                            small
                            dark
                            @click="scrollAndClose"
                            class="ml-3"
                        >
                            <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg" color="primary"></font-awesome-icon>
                        </v-btn>
                    </div>
                </div>
            </v-sheet>
            <ConfirmDialog ref="dirtyConfirmation" @answered="dirtyConfirmationAnswered"></ConfirmDialog>
        </v-card>
    </v-dialog>
</template>

<script>
import ViewStockDialogMixin from './ViewStockDialogMixin'
export default {
    mixins: [ViewStockDialogMixin]
}
</script>

<style scoped>
.stockFullCardCont {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
}
.stockHeader {
    font-size: medium;
}
.dialogFooter {
    border-top: solid 1px #DDD;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    background-color: #FFF;
}

</style>
