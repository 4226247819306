import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simplePage"},[_c(VCard,{staticClass:"simplePageCard",attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":"","color":"orange darken-4"}},[_vm._v("mdi-alert-octagon")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(_vm.$t('unlockAccess.title')))])],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"dense":"","label":_vm.$t('unlockAccess.enterKey'),"name":Math.random()},on:{"keydown":_vm.keydown},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.validateKey}},[_vm._v(_vm._s(_vm.$t('unlockAccess.validateButton')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }