import serverService from './ApiWrapper'

var BasicFeedbackService = {
    submit: ({ userId, rating, comment, token }) => {
        return serverService.post('/api/Feedbacks/Basic/Submit', { userId, rating, comment, token })
    },

    getUserFeedbackList: () => {
        return serverService.post('/api/Feedbacks/GetUserFeedbackList')
    }
}

export default BasicFeedbackService
