<template>
    <div class="registerPage">
        <SimplePage>
            <RegisterForm v-if="goToRegister"></RegisterForm>
            <RegisterNotInvited v-else></RegisterNotInvited>
            <template v-slot:widgetFooterLeftPart>
                <LoginAsGuest></LoginAsGuest>
            </template>
        </SimplePage>
    </div>
</template>

<script>
import RegisterNotInvited from './RegisterNotInvited.vue'
import RegisterForm from './RegisterForm.vue'
import SimplePage from '../SimplePage.vue'
import LoginAsGuest from '../../components/controls/LoginAsGuest.vue';
import storageService from '../../services/StorageService';
export default {
    data() {
        return {
            valid: true
        }
    },
    components: { RegisterNotInvited, RegisterForm, SimplePage, LoginAsGuest },
    computed: {
        isInvited() {
            return storageService.inviteToken;
        },
        goToRegister() {
            return this.isInvited || !this.$store.getters.isInvitationRequired
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.registerPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
