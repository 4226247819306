import serverService from '@/services/ApiWrapper'

var MaintenanceService = {
    syncronize: ({ scope = 'all' }) => {
        return serverService.post('/api/devops/Synchronization/Synchronize', { scope, authKey: '' });
    },

    getLastSynchronization: () => {
        return serverService.post('/api/devops/Synchronization/GetLastSynchronization', {});
    },

    getStatus: () => {
        return serverService.post('/api/devops/Synchronization/Status', {});
    },

    getNextScheduledSynchronization: () => {
        return serverService.post('/api/devops/Synchronization/GetNextScheduledSynchronization', {});
    }
}

export default MaintenanceService
