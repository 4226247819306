class StockHtmlHelper {
    getBadge(score) {
        if (score >= 250 && score < 500) return 4 // follow
        else if (score >= 500 && score < 750) return 3 // consider
        else if (score >= 750 && score < 1000) return 2 // buy
        else if (score >= 1000) return 1 // deal
        else if (score <= -1000) return 21 // sell-loss
        else if (score <= -750 && score > -1000) return 22 // sell-trigger
        else if (score === -1) return 11 // coming soon
        else return 5
    }

    getReleventBadge(score) {
        if (score === -9999) return 3 // not to consider
        if (score >= 0 && score < 750) return 3 // follow & consider
        else if (score >= 750 && score < 1000) return 2 // buy
        else if (score >= 1000) return 1 // deal
        else if (score <= -1000) return 5 // sell-loss
        else if (score <= -750 && score > -1000) return 4 // sell-trigger
        else if (score > -750 && score < 0) return 3 // consider sell
        else return -1 // not to consider
    }

    getSymbolRessource(count) {
        return `entry.symbol${count > 1 ? 's' : ''}`;
    }

    getBadgeColor(badge) {
        const val = parseInt(badge);
        switch (val) {
            case 5:
                return 't000';
            case 4:
                return 't200';
            case 3:
                return 't400';
            case 2:
                return 't600';
            case 1:
                return 't800';
            case 10:
                return '#FFF';
            case 21:
                return 't200-sell'
            case 22:
                return 't400-sell'
            default:
                return 'transparent';
        }
    }

    getBadgeSellColor(badge) {
        const val = parseInt(badge);
        switch (val) {
            case 4:
            case 21:
                return 't200-sell';
            case 3:
            case 22:
                return 't400-sell';
            default:
                return this.getBadgeColor(badge);
        }
    }

    getBadgeBGColor(badge, listType) {
        const val = parseInt(badge);
        switch (val) {
            case 5:
                return 'bg-t200-sell';
            case 4:
                return 'bg-t400-sell';
            case 3:
                return 'bg-t400';
            case 2:
                return 'bg-t600';
            case 1:
                return 'bg-t800';
            case 10:
                return '#FFF';
            case 21:
                return 'bg-t200-sell';
            case 22:
                return 'bg-t400-sell';
            default:
                return 'transparent';
        }
    }

    cleanScore(score) {
        return score === 2000 ? '!' : score === -1 ? '...' : Math.abs(Math.round(score));
    }

    getBadgeCssClass(badge) {
        const val = parseInt(badge);
        switch (val) {
            case 4:
                return 'follow';
            case 3:
                return 'consider';
            case 2:
                return 'buy';
            case 1:
                return 'deal';
            case 10:
                return 'invalid';
            case 21:
                return 'sell-loss';
            case 22:
                return 'sell-trigger';
            default:
                return '';
        }
    }

    normalizePrice(price) {
        return Number(price.toFixed(price < 5 ? 4 : 2));
    }

    formatPrice(price) {
        let formattedPrice = '';
        if (price) {
            if (price === 0) {
                formattedPrice = '...';
            }
            else {
                const p = price.toFixed(price < 5 ? 4 : 2);
                const ps = p.split('.');
                formattedPrice = Number(ps[0]).toLocaleString() + '.' + ps[1];
            }
        }
        return formattedPrice;
    }

    getChangeColor(change) {
        return change > 0.0 ? 'up' : change < 0.0 ? 'down' : 'unch';
    }

    getStockImageSrc(relativePath) {
        return relativePath ? `https://eodhistoricaldata.com${relativePath}` : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }

    simplifyUnit(value) {
        if (value === undefined) return ''
        else if (value === -9999) return '...'
        else {
            // 12 Zeroes for Trillions
            return Math.abs(Number(value)) >= 1.0e+12

                ? { val: (Math.abs(Number(value)) / 1.0e+12).toFixed(2), unit: 'T' }
                // 9 Zeroes for Billions
                : Math.abs(Number(value)) >= 1.0e+9

                    ? { val: (Math.abs(Number(value)) / 1.0e+9).toFixed(2), unit: 'B' }
                    // 6 Zeroes for Millions
                    : Math.abs(Number(value)) >= 1.0e+6

                        ? { val: (Math.abs(Number(value)) / 1.0e+6).toFixed(2), unit: 'M' }

                        : (Math.abs(Number(value))).toFixed(2)
        }
    }

    getStockChangeInfo(entry) {
        return {
            price: entry.marketRegularPrice,
            changeAmount: entry.change,
            changePercent: entry.changePercent
        }
    }

    formatChange(change, price) {
        let formattedPrice = '';
        if (price && price !== 0) {
            const p = change.toFixed(2);
            //const p = change.toFixed(Math.abs(price) < 5 ? 4 : 2);
            const ps = p.split('.');
            formattedPrice = Number(ps[0]).toLocaleString() + '.' + ps[1];
        }
        return formattedPrice;
    }

    formatPriceWithDigit(price, digit) {
        const ps = Number(price).toFixed(digit).split('.')
        return Number(ps[0]).toLocaleString() + '.' + ps[1];
    }

    formatPriceWithLocaleCurrency(price, currency, locale) {
        const num = Intl.NumberFormat(locale, { style: 'currency', currency }).format(price)
        return num.replace(/CA|US/, '')
    }

    priceChange({ lastPrice, averageTransactionPrice }) {
        return ((lastPrice / averageTransactionPrice) * 100) - 100
    }

    gainPrct({ lastPrice, averageTransactionPrice }) {
        const change = this.priceChange({ lastPrice, averageTransactionPrice })
        return `${change.toFixed(2)}%`
    }

    gain({ quantity, lastPrice, averageTransactionPrice }) {
        const gain = (lastPrice - averageTransactionPrice) * quantity
        return this.formatPriceWithDigit(gain, 2)
    }

    gainClass({ lastPrice, averageTransactionPrice }) {
        const change = this.priceChange({ lastPrice, averageTransactionPrice })
        return `${change < 0 ? ' negative' : change > 0 ? ' positive' : ''}`
    }
}

export default StockHtmlHelper
