<template>
    <div class="accountSections">
        <AccountSection>
            <template v-slot:title>{{$t('account.sections.details.title')}}</template>
            <template v-slot:body>
                <DetailsSection></DetailsSection>
            </template>
        </AccountSection>
        <AccountSection v-if="!isDemo">
            <template v-slot:title>{{$t('account.sections.subscriptionBilling.title')}}</template>
            <template v-slot:body>
                <SubscriptionAndBillingSection></SubscriptionAndBillingSection>
            </template>
        </AccountSection>
        <AccountSection>
            <template v-slot:title>{{$t('account.sections.settings.title')}}</template>
            <template v-slot:body>
                <SettingsSection></SettingsSection>
            </template>
        </AccountSection>
    </div>
</template>

<script>
import AccountSection from "./AccountSection.vue";
import SubscriptionAndBillingSection from "./Subscription/SubscriptionAndBillingSection.vue";
import SettingsSection from "./Settings/SettingsSection.vue";
import DetailsSection from "./Details/DetailsSection.vue";
export default {
    components: { AccountSection, SubscriptionAndBillingSection, SettingsSection, DetailsSection },
    computed: {
        isDemo() {
            return this.$store.getters.hasRole('demonstration');
        }
    }
}
</script>

<style scoped>
.accountSections>*:not(:last-child){
    margin-bottom: 20px;
}
</style>
