import PortfolioStockConverter from "../infra/converters/PortfolioStockConverter"

class Portfolio {
    constructor({ id, name, userId, description, stocks, statistics, creationDate, currency }) {
        this._id = id
        this._name = name
        this._userId = userId
        this._description = description
        this._stocks = this.convertStocks(stocks)
        this._statistics = statistics
        this._creationDate = creationDate
        this._currency = currency
    }

    convertStocks(stocks) {
        return stocks.map(s => PortfolioStockConverter.convert({ ...s }))
    }

    addStock(stock) {
        this._stocks.push(PortfolioStockConverter.convert({ ...stock }))
    }

    get id() { return this._id }
    get userId() { return this._userId }
    get creationDate() { return this._creationDate }

    get name() { return this._name }
    set name(value) { this._name = value }

    get description() { return this._description }
    set description(value) { this._description = value }

    get stocks() { return this._stocks }
    set stocks(value) { this._stocks = value }

    get statistics() { return this._statistics }
    set statistics(value) { this._statistics = value }

    get currency() { return this._currency }
    set currency(value) { this._currency = value }
}

export default Portfolio