import UserListEntry from '../../models/UserListEntry'
import PropertyMetasConverter from './PropertyMetasConverter'

class UserListEntryConverter {
    static convert({ id, stockId, symbol, companyName, score, threasholds, isPriceIntraDay, marketCap, oneYearLow, oneYearHigh, propertiesMetas, currency, plateformCustomExchange }) {
        const propertyMetasList = PropertyMetasConverter.convert({ propertiesMetas });
        return new UserListEntry({ id, stockId, symbol, companyName, score, threasholds, isPriceIntraDay, marketRegularPrice: propertyMetasList.price, marketCap, oneYearLow, oneYearHigh, change: propertyMetasList.change, changePercent: propertyMetasList.change_percent, currency, plateformCustomExchange });
    }
}

export default UserListEntryConverter
