import StockHtmlHelper from './StockHtmlHelper'
import StockScoreInfosList from './StockScoreInfosList'
var StockCardMixin = {
    props: ['entry'],
    data() {
        return {
            stockHtmlHelper: undefined,
            color: "",
            badge: "",
            country: undefined
        }
    },
    components: {
        StockScoreInfosList
        // StockPriceInfos,
        // StockPriceChange
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        this.badge = this.stockHtmlHelper.getBadge(this.entry.highScore);
        this.color = this.stockHtmlHelper.getBadgeColor(this.badge);
    },
    methods: {
        getChangeColor(changePrct) {
            return this.stockHtmlHelper.getChangeColor(changePrct);
        },
        formatChange(change, price) {
            return change !== undefined ? change.toFixed(price < 5 ? 4 : 2) : 'N/A';
        },
        getChangeIcon(change) {
            return change > 0 ? this.up : change < 0 ? this.down : this.null;
        },
        formatPrice(price) {
            return this.stockHtmlHelper.formatPrice(price);
        },
        formatChangePrct(changePrct) {
            return changePrct !== undefined ? changePrct.toFixed(2) : 'N/A';
        },
        getSuffix() {
            return this.entry.symbolSuffix ? `.${this.entry.symbolSuffix}` : '';
        },
        getCountry() {
            if (this.entry.currency) {
                return this.entry.currency.substring(0, 2);
            }
        }
    }
}

export default StockCardMixin;
