<template>
    <v-dialog v-model="dialog" scrollable max-width="600" transition="dialog-bottom-transition">
        <div class="thresholdsContainer">
            <div class="dialogBody">
                <div class="title">{{list.name}}</div>
                <BuyEditionPanel v-if="list.type === 'buy'" :thresholds="thresholds" ref="buyEditionPanel" @isFormValid="thresholdsValid" @inputChanged="thresholdsValid"></BuyEditionPanel>
                <SellEditionPanel v-else :thresholds="thresholds" ref="sellEditionPanel" @isFormValid="thresholdsValid" @inputChanged="thresholdsValid"></SellEditionPanel>
            </div>
            <div class="dialogFooter">
                <v-spacer></v-spacer>
                <SaveCancelButtons ref="buttons" align="end" :enableSave="saveCancelButtonsEnableSave"  @saveAsked="save" @cancelAsked="cancel"></SaveCancelButtons>
            </div>
        </div>
    </v-dialog>
</template>

<script>import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons"
import SaveCancelButtons from '../../controls/SaveCancelButtons.vue'
import BuyEditionPanel from './BuyEditionPanel.vue'
import SellEditionPanel from './SellEditionPanel.vue'

export default {
    components: { SaveCancelButtons, BuyEditionPanel, SellEditionPanel },
    data() {
        return {
            dialog: false,
            list: {},
            thresholds: { t200: '', t400: '', t600: '', t800: '' },
            icons: {
                ok: faCheck,
                close: faTimes
            },
            saveCancelButtonsEnableSave: false
        }
    },
    methods: {
        show({ list }) {
            this.list = list;
            this.dialog = true;
        },
        hide() {
            return new Promise((resolve) => {
                this.dialog = false;
                setTimeout(() => {
                    resolve();
                }, 300);
            })
        },
        thresholdsValid(status) {
            this.saveCancelButtonsEnableSave = status.valid;
        },
        save() {
            this.hide().then(() => {
                this.$emit('saveThresholds', { list: this.list, thresholds: this.thresholds });
            });
        },
        cancel() {
            this.hide().then(() => {
                this.$emit('cancelThresholds', { list: this.list });
            });
        }
    }
}
</script>

<style scoped>
.thresholdsContainer {
    background-color: #FFF;
    padding: 20px;
}
</style>
