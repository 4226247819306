<template>
    <div>
        <div :class="['pl-4', 'level-' + depth]"  v-for="(subItem, index) in item.subItems" :key="index">
            <div v-if="subItem.bullet !== undefined" class="with-bullet">
                <div class="bullet"><div class="mr-2"v-html="subItem.bullet"></div></div>
                <div v-html="subItem.text"></div>
            </div>
            <span v-else v-html="subItem.text"></span>
            <div v-if="subItem.subItems && subItem.subItems.length > 0">
                <term-section
                    :item="subItem"
                    :depth="depth+1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TermSection from "./TermSection"
export default {
    name: "term-section",
    props: ["item", "depth"],
    data () {
        return {

        }
    },
    components: {
        "term-section": TermSection
    }
}
</script>
<style scoped>
.level-1{
    padding-top:30px
}
>>> .level-3 .bullet{
    font-size: 18px;
    font-weight:bold;
}

>>> .level-4 .bullet{
    font-weight:bold;
}

>>> .level-5 .bullet{
    font-style:italic;
}

.with-bullet{
    display:flex;
}
</style>