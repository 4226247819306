<template>
    <v-btn color="synaxy" dark :small="isSmall" :large="isLarge" class="guestBtn" @click="continueAsGuestAsked">{{text}}</v-btn>
</template>

<script>
export default {
    props: ['large'],
    data() {
        return {
        }
    },
    computed: {
        isSmall() {
            return this.$vuetify.breakpoint.xs
        },
        isLarge() {
            return this.$vuetify.breakpoint.lgAndUp
        },
        text() {
            return this.$vuetify.breakpoint.smAndDown ? this.$t('Home.welcome.buttons.continueAsGuestShort') : this.$t('Home.welcome.buttons.continueAsGuest');
        }
    },
    methods: {
        continueAsGuestAsked() {
            this.$store.dispatch('loginAsGuest').then(response => {
                this.$router.push({ name: 'Home' }).catch(() => { });
            }).catch(errors => {
                let errMsg = this.$t('login.errors.default');
                if (errors !== undefined) {
                    if (typeof errors === 'string') {
                        errMsg = errors;
                    }
                    else if (Array.isArray(errors)) {
                        errMsg = errors.map(e => e.errorMsg).join(".<br>");
                    }
                }
                this.showError(errMsg);
            });
        }
    }
}
</script>

<style scoped>
.guestBtn {}
</style>
