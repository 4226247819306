<template>
    <div class="cardSelector">
        <div v-if="yourCreditCards.length" class="ccTitles">
            <div>{{$t('subscriptions.stepper.2.panel.yourCreditCards')}}</div>
        </div>
        <v-skeleton-loader v-if="!loaded" type="article"></v-skeleton-loader>
        <div v-else class="allPaymentCards">
            <CreditCard v-for="(cc, i) in yourCreditCards" :card="cc" :selected="isSelectedCard(cc.id)" :key="i" @clicked="changeCardSelection" @deleted="deleteCard" @isDefault="makeDefault" @updated="cardUpdated"></CreditCard>
            <div :class="`newCard${newCardAsked ? ' selected' : ''}`" @click="addNewCard()">
                <div class="cardLogo">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon :icon="icons.blankCard"></font-awesome-icon>
                        <font-awesome-icon :icon="icons.add" transform="shrink-10 right-5 up-2" />
                    </font-awesome-layers>
                </div>
                <div class="cardDescription">
                    <div class="newCard">{{$t('account.sections.subscriptionBilling.managePaymentMethods.addNewCard')}}</div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import PreferencesService from '../../services/PreferencesService';
import { faCreditCardBlank, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import CreditCard from './CreditCard.vue';
import Alert from '../controls/Alert.vue';

export default {
    props: { editable: { type: Boolean, default: false } },
    data() {
        return {
            loaded: false,
            overlay: false,
            yourCreditCards: [],
            defaultPaymentMethodId: undefined,
            creditCardSelection: undefined,
            icons: {
                blankCard: faCreditCardBlank,
                add: faPlusCircle
            },
            preferencesService: undefined
        };
    },
    created() {
        this.preferencesService = new PreferencesService();
    },
    computed: {
        newCardAsked() {
            return this.creditCardSelection === 'new'
        },
        defaultPaymentMethod() {
            return this.$store.getters.getDefaultPaymentMethod
        }
    },
    mounted() {
        this.loadPaymentMethodsInfo()
    },
    methods: {
        loadPaymentMethodsInfo() {
            if (this.yourCreditCards.length === 0) {
                this.preferencesService.getPaymentMethodsInfo().then(response => {
                    const paymentMethods = response.paymentMethods.methods;
                    this.defaultPaymentMethodId = response.defaultPaymentMethodId;
                    this.yourCreditCards = paymentMethods.map(pm => {
                        return {
                            id: pm.Id,
                            brand: pm.Brand,
                            last4: pm.Last4,
                            expiration: {
                                year: pm.ExpYear,
                                month: pm.ExpMonth
                            },
                            isDefault: pm.Id === this.defaultPaymentMethodId
                        };
                    });
                    this.creditCardSelection = this.defaultPaymentMethodId;
                    this.loaded = true
                });
            }
        },
        changeCardSelection(cardId) {
            this.creditCardSelection = cardId
        },
        addNewCard() {
            this.creditCardSelection = 'new'
            this.$emit('askForNewCard')
        },
        isSelectedCard(cardId) {
            return this.creditCardSelection === cardId
        },
        isRemovable(card) {
            return this.editable && card.id !== this.defaultPaymentMethodId
        },
        isDefaultPaymentMethod(cardId) {
            return this.defaultPaymentMethodId === cardId
        },
        cardUpdated({ paymentMethodId, expirationMonth, expirationYear }) {
            const card = this.yourCreditCards.find(cc => cc.id === paymentMethodId)
            if (card) {
                card.expiration.month = expirationMonth
                card.expiration.year = expirationYear
            }
        },
        makeDefault(defaultCardId) {
            this.overlay = true
            this.defaultPaymentMethodId = defaultCardId
            this.preferencesService.setDefaultPayementMethod({ paymentMethodId: defaultCardId }).then(() => {
                this.yourCreditCards.forEach(cc => {
                    cc.isDefault = cc.id === defaultCardId
                });
                this.$emit('defaultPayementMethodChanged', { defaultCardId })
            }).catch(error => {
                this.$refs.iAlert.error({ errorCode: error[0].errorCode })
            }).finally(() => {
                this.overlay = false
            })
        },
        deleteCard(creditCardIdToRemove) {
            this.overlay = true
            this.preferencesService.deletePaymentMethod({ paymentMethodId: creditCardIdToRemove }).then(() => {
                this.yourCreditCards = this.yourCreditCards.filter(cc => cc.id !== creditCardIdToRemove);
            }).catch(error => {
                this.$refs.iAlert.error({ errorCode: error[0].errorCode })
            }).finally(() => {
                this.overlay = false
            })
        }
    },
    watch: {
        creditCardSelection(value) {
            let card = this.yourCreditCards.find(cc => cc.id === value)
            card = card || { id: value }
            this.$emit("changed", { card });
        },
        defaultPaymentMethod(card) {
            if (this.yourCreditCards.length === 0) {
                this.loadPaymentMethodsInfo()
            } else {
                const refCard = this.yourCreditCards.find(cc => cc.id === card.Id)
                if (refCard) {
                    refCard.isDefault = true
                }
                else {
                    this.yourCreditCards.forEach(cc => {
                        cc.isDefault = false
                    })
                    this.yourCreditCards.push({
                        id: card.Id,
                        brand: card.Brand,
                        last4: card.Last4,
                        expiration: {
                            year: card.ExpYear,
                            month: card.ExpMonth
                        },
                        isDefault: true
                    })
                }
            }
        }
    },
    components: { CreditCard, Alert }
}
</script>

<style lang="scss" scoped>
.cardSelector {
    padding: 20px;
    background-color: #FFF;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	border-radius: 10px;
    .ccTitles {
        display: flex;
        justify-content: space-between;
        font-size: 0.9em;
        padding: 5px;
    }
    .allPaymentCards {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .newCard {
            flex: auto;
            display: flex;
            gap: 15px;
            padding: 5px 10px;
            align-items: center;
            cursor: pointer;
            border-left: solid 6px transparent;
            &.selected {
                border-left: solid 6px var(--agora);
                background-color: var(--agoraBG);
            }
            &:hover {
                background-color: var(--hoverBG);
            }
            .cardLogo {
                width: 55px;
            }
            .cardDescription {
                flex: 1;
            }
        }
    }
    .ccInactive {
        border: solid 1px transparent;
        padding: 5px;
    }
    .ccActive {
        border: solid 1px var(--v-agora-base);
        border-radius: 5px;
        padding: 5px;
        background-color: var(--hoverBG);
    }
}
</style>