import serverService from './ApiWrapper'

export function login({ email, password, token }) {
    return serverService.post('/api/authentication/login', { email, password, token });
}

export function loginAsGuest() {
    return serverService.post('/api/authentication/loginAsGuest', {});
}

export function register({ email, firstname, lastname, password, passwordConfirmation, twoLettersPreferredLanguage, token, inviteToken, termsAndConditionsAnswer }) {
    return serverService.post('/api/authentication/Register', { email, firstname, lastname, password, passwordConfirmation, twoLettersPreferredLanguage, token, inviteToken, termsAndConditionsAnswer })
}

export function confirmEmail({ token }) {
    return serverService.post('/api/authentication/accountActivation/emailConfirmation', { token })
}

export function resendActivationToken({ oldToken }) {
    return serverService.post('/api/authentication/accountActivation/resendActivationToken', { oldToken })
}

export function sendResetPasswordRequest({ email }) {
    return serverService.post('/api/authentication/SendResetPasswordRequest', { email });
}

export function resetPasswordRequest({ token, password, confirmPassword }) {
    return serverService.post('/api/authentication/ResetPassword', { token, password, confirmPassword });
}

export function testToken() {
    return serverService.post('/api/authentication/TestToken');
}

export function changePassword({ actualPassword, newPassword, newPasswordConfirmation }) {
    return serverService.post('/api/authentication/ChangePassword', { actualPassword, newPassword, newPasswordConfirmation });
}

export function changeFullname({ firstname, lastname }) {
    return serverService.post('/api/authentication/ChangeFullname', { firstname, lastname });
}