import Plan from "../../models/Plan";
class PlanConverter {
    static convert(plan) {
        return new Plan({
            name: plan.planName,
            price: plan.price,
            reccuringInterval: plan.reccurringInterval,
            priceId: plan.paymentSystemPriceId,
            currency: plan.currency,
            limit: plan.limit,
            displayOrder: plan.displayOrder
        });
    }
}

export default PlanConverter;