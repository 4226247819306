<template>
    <div class="tagsDisplayer">
        <div class="tagSection" v-for="(cTags, name, i) in classifiedTags" :key="i">
            <div class="sectionName">{{name}}</div>
            <Tags :tags="cTags" @tagClicked="tagClicked"></Tags>
        </div>
    </div>
</template>

<script>
import Tags from '../../controls/Tags.vue';
export default {
    components: { Tags },
    props: ['tags'],
    data() {
        return {
            classifiedTags: {}
        }
    },
    methods: {
        tagClicked({ tag }) {
            this.$emit('tagClicked', { tag });
        }
    },
    watch: {
        tags: {
            handler: function (val) {
                const ct = {};
                val.forEach(t => {
                    if (ct[t.name]) {
                        ct[t.name].push(t)
                    }
                    else {
                        ct[t.name] = [t];
                    }
                });
                this.classifiedTags = ct;
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.tagsDisplayer{
    display: flex;
    flex-direction: column;
}
.tagSection{}
.sectionName{
    font-weight: bold;
    text-transform: capitalize;
}
.vtag{}
</style>
