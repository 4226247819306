<template>
    <div :class="`overviewListCard ${getCardClass}`" @click="viewStock">
        <div class="olcSymbol">{{stock.symbol}}</div>
        <div :class="`olcScore ${scoreCls}`">{{score}}</div>
        <font-awesome-icon :icon="getListIconFromType(listType)" class="fa-fw olcIcon" color="#777"></font-awesome-icon>
    </div>
</template>

<script>
import ListLightIconMixin from '../../help/icons/ListLightIconMixin'
import StockHtmlHelper from '../../stocks/StockHtmlHelper'

export default {
    props: ['stock', 'type'],
    mixins: [ListLightIconMixin],
    data() {
        return {
            stockHtmlHelper: null
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper()
    },
    computed: {
        listType() {
            return this.type
        },
        score() {
            return this.stockHtmlHelper.cleanScore(this.stock.highScore)
        },
        getCardClass() {
            const badge = this.stockHtmlHelper.getBadge(this.stock.highScore)
            return this.stockHtmlHelper.getBadgeCssClass(badge)
        },
        scoreCls() {
            return this.stockHtmlHelper.getBadgeColor(this.stockHtmlHelper.getBadge(this.stock.highScore))
        }
    },
    methods: {
        viewStock() {
            this.$emit('viewStock', { stock: this.stock });
        }
    }
}
</script>

<style lang="scss" scoped>
.overviewListCard {
    background-color: #FFF;
    padding: 10px;
    border: solid 1px;
    position: relative;
    overflow: hidden;
    &.deal{
        border-color: var(--t800);
        background-color: #dcefdc;
    }
    &.buy{
        border-color: var(--t600);
        background-color: #dcefdc;
    }
    &.sell-trigger{
        border-color: var(--t400-sell);
        background-color: #ffe6e6;
    }
    &.sell-loss{
        border-color: var(--t200-sell);
        background-color: #ffe6e6;
    }
    .olcSymbol {
        color: #000;
        font-weight: bold;
    }
    .olcScore {
        font-weight: bold;
    }
    .olcIcon {
        position: absolute;
        bottom: 3px;
        right: 3px;
        font-size: 18px;
        rotate: -15deg;
    }
}
</style>