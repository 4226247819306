import {
    faCheck,
    faCheckCircle,
    faBookmark,
    faCircle,
    faMicrochip
} from "@fortawesome/pro-solid-svg-icons";
import planHelperMixin from "../../helpers/planHelperMixin";
import { authComputed } from '../../store/helpers/AuthenticationHelper'
var SubscriptionCardMixin = {
    props: ['plan', 'selectedPlan'],
    mixins: [planHelperMixin],
    name: 'SubscriptionCard',
    data: () => ({
        dialog: undefined,
        icons: {
            check: faCheck,
            dot: faCircle,
            current: faCheckCircle,
            userSelection: faBookmark,
            ai: faMicrochip
        },
        descriptionKeys: ['combinedListCount', 'buyListCount', 'sellListCount', 'combinedListCombinedEntriesCount', 'portfolioListCount', 'combinedPortfolioStockCount'],
        iaKeys: ['partnerships', 'sentiments', 'similarCompanies', 'questions']
    }),
    computed: {
        ...authComputed,
        isReadOnly() {
            return !this.loggedIn || this.$store.getters.hasRole('demonstration');
        },
        isSelected() {
            return this.selectedPlan ? this.plan.name === this.selectedPlan.name : false
        },
        isFreePlan() {
            return ['base', 'pioneer', 'vip'].includes(this.plan.name);
        },
        isCurrentPlan() {
            return this.plan.name === this.myCurrentPlan();
        },
        isCurrentPlanCancelled() {
            return this.plan.name === this.myCurrentPlan() && this.canceledSubscription;
        },
        isNewOrder() {
            const plan = this.$store.getters.getMyPlan
            return plan ? plan.price === 0 : true
        },
        actionButtonText() {
            return this.isNewOrder ? this.$t('subscriptions.plans.buttons.select') : this.$t('subscriptions.plans.buttons.change')
        },
        newsCount() {
            return this.plan.limit.stockContext.newsCount;
        },
        isAiPlan() {
            return this.plan.isAiPlan;
        },
        iaKeysByPlan() {
            return this.plan.aiDataActiveKeys;
        }
    },
    methods: {
        selectPlan() {
            const userPlanName = this.userSubscriptionInfo.planDefinition ? this.userSubscriptionInfo.planDefinition.planName : 'ND'
            if (this.plan.name !== userPlanName) {
                this.$emit('newPlanSelected', { plan: this.plan });
            }
        },
        myCurrentPlan() {
            return this.loggedIn ? this.planName : '';
        },
        isKeyActive(key) {
            return this.plan.limit[key] && this.plan.limit[key] > 0
        },
        plural(key) {
            return key === 'combinedPortfolioStockCount' ? this.plan.limit.portfolioListCount : this.plan.limit[key]
        }
    }
}

export default SubscriptionCardMixin
