import CompanyInfos from './CompanyInfos';
import Partnership from './Partnership';
import QuestionBeforeInvesting from './QuestionBeforeInvesting';
import StockSentiments from './StockSentiments';

class StockAiContextualInfo {
    constructor({ additionalData }) {
        this._additionalData = additionalData;
        this._sentiments = [];
        this._partnerships = [];
        this._questionsBeforeInvesting = [];
        this._similarCompanies = [];
    }

    get sentiments() { return this._sentiments; }
    get partnerships() { return this._partnerships; }
    get questionsBeforeInvesting() { return this._questionsBeforeInvesting; }
    get similarCompanies() { return this._similarCompanies; }
    get additionalData() { return this._additionalData; }

    set sentiments(values) {
        if (!values) return;
        this._sentiments = this._sentiments.concat(values.map(n => new StockSentiments({ ...n })));
    }

    set partnerships(values) {
        if (!values) return;
        this._partnerships = this._partnerships.concat(values.map(p => new Partnership({ ...p })));
    }

    set questionsBeforeInvesting(values) {
        if (!values) return;
        this._questionsBeforeInvesting = this._questionsBeforeInvesting.concat(values.map(q => new QuestionBeforeInvesting({ ...q })));
    }

    set similarCompanies(values) {
        if (!values) return;
        this._similarCompanies = this._similarCompanies.concat(values.map(c => new CompanyInfos({ ...c, description: c.reason })));
    }
}

export default StockAiContextualInfo;
