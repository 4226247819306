import serverService from './ApiWrapper'
import UserListEntryConverter from '../infra/converters/UserListEntryConverter'
import ServerHelper from './ServerHelper'

export function addStockInUserWatchList({ listId, newEntry, listType }) {
    return new Promise((resolve, reject) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        serverService.post(`/api/${ctrl}/V2/AddEntry`, { listId, newEntry }, true).then((response) => {
            const userListEntry = UserListEntryConverter.convert(response.data);
            userListEntry.listId = listId;
            const convertedResponse = {
                addedUserListEntry: userListEntry,
                usage: response.additionalData.subscriptionInfo.usage
            };
            resolve(convertedResponse);
        }).catch(error => {
            reject(error);
        });
    });
}

export function updateStockInUserWatchList({ listId, updatedListEntry, listType }) {
    return new Promise((resolve, reject) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        updatedListEntry.listId = listId;
        serverService.post(`/api/${ctrl}/V2/UpdateEntry`, { updatedListEntry }).then((response) => {
            const userListEntry = UserListEntryConverter.convert(response);
            userListEntry.listId = listId;
            resolve(userListEntry);
        }).catch(error => {
            reject(error);
        });
    });
}

export function deleteStockFromWatchList({ entryId, listId, listType }) {
    return new Promise((resolve, reject) => {
        const ctrl = ServerHelper.getStockListInfos(listType);
        return serverService.post(`/api/${ctrl}/V2/DeleteEntry`, { entryId, listId }, true).then((response) => {
            const convertedResponse = {
                usage: response.additionalData.subscriptionInfo.usage
            };
            resolve(convertedResponse);
        }).catch(error => {
            reject(error);
        });
    });
}
