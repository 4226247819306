<template>
	<div class="usagePanel">
        <v-list>
            <v-list-item
                link
                @mouseover="hoverUser=true"
                @mouseleave="hoverUser=false"
                @click="editUserSubscription"
            >
            <v-list-item-content>
                <div class="up-header">
                    <div class="up-plan-img">
                        <font-awesome-icon :icon="icons[planName]" style="color:dodgerblue;margin-right:5px"></font-awesome-icon>
                    </div>
                    <div class="up-plan-name">{{$t(`subscriptions.plans.${planName}.fullTitle`)}}</div>
                </div>
            </v-list-item-content>
            <v-list-item-action v-if="hoverUser || $vuetify.breakpoint.mobile">
                <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            </v-list-item>
        </v-list>

        <div class="up-body">
            <UsageProgress v-if="!combinedListCount" :label="$t('subscriptions.usage.buyListLabel')" :max="planLimit.buyListCount" :value="getBuyListCount"></UsageProgress>
            <UsageProgress v-if="!combinedListCount" :label="$t('subscriptions.usage.sellListLabel')" :max="planLimit.sellListCount" :secondValue="getSellListCount"></UsageProgress>
            <UsageProgress v-if="combinedListCount" :label="$t('subscriptions.usage.listsLabel')" :max="combinedListCount" :value="planUsage.buyListCount" :secondValue="planUsage.sellListCount"></UsageProgress>
            <UsageProgress :label="$t('subscriptions.usage.entriesLabel')" :max="planLimit.combinedListCombinedEntriesCount" :value="buyEntries" :secondValue="sellEntries"></UsageProgress>
            <div>
                <div class="up-legend">
                    <div class="up-legend-item">
                        <font-awesome-icon :icon="icons.circle" class="buyColor" size="xs"></font-awesome-icon>
                        <div class="up-legend-item-label">{{$t('subscriptions.usage.legend.buy')}}</div>
                    </div>
                    <div class="up-legend-item">
                        <font-awesome-icon :icon="icons.circle" class="sellColor" size="xs"></font-awesome-icon>
                        <div class="up-legend-item-label">{{$t('subscriptions.usage.legend.sell')}}</div>
                    </div>
                    <div class="up-legend-item">
                        <font-awesome-icon :icon="icons.circle" class="unusedColor" size="xs"></font-awesome-icon>
                        <div class="up-legend-item-label">{{$t('subscriptions.usage.legend.available')}}</div>
                    </div>
                </div>
            </div>
            <UsageProgress v-if="planLimit.portfolioListCount" :label="$t('subscriptions.usage.portfolioLabel')" :max="planLimit.portfolioListCount" :value="planUsage.portfolioCount"></UsageProgress>
            <UsageProgress v-if="planLimit.portfolioListCount" :label="$t('subscriptions.usage.portfolioEntriesLabel')" :max="planLimit.combinedPortfolioStockCount" :value="planUsage.portfolioCombineEntrieCount"></UsageProgress>
        </div>
	</div>
</template>

<script>
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import planHelperMixin from '../../helpers/planHelperMixin';
import UsageProgress from './UsageProgress.vue';
import UserSubscriptionHelper from "./UserSubscriptionHelper";
import { authComputed } from "@/store/helpers/AuthenticationHelper"
export default {
    components: { UsageProgress },
    mixins: [planHelperMixin],
    data() {
        return {
            hoverUser: false,
            usage: {
                lists: {
                    buys: 0,
                    sells: 0
                }
            },
            icons: {
                circle: faCircle
            },
            userSubscriptionHelper: undefined
        }
    },
    created() {
        this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: this.userSubscriptionInfo });
        this.usage.lists.buys = this.userSubscriptionHelper.buyListCountPercentage();
        this.usage.lists.sells = this.userSubscriptionHelper.buyListCountPercentage() + this.userSubscriptionHelper.sellListCountPercentage();
    },
    computed: {
        ...authComputed,
        getUserSubscriptionInfo() {
            return this.userSubscriptionInfo;
        },
        combinedListCount() {
            return this.$store.getters.planDefinition.limit.combinedListCount
        },
        planLimit() {
            return this.$store.getters.planDefinition.limit
        },
        planUsage() {
            return this.$store.getters.planUsage
        },
        planName() {
            return this.userSubscriptionInfo.planDefinition.planName
        },
        renewalDate() {
            return this.userSubscriptionHelper.renewalDate.toLocaleDateString(this.$t('local'));
        },
        getBuyListCount() {
            return this.userSubscriptionHelper.getBuyListCount()
        },
        getSellListCount() {
            return this.userSubscriptionHelper.getSellListCount()
        },
        listCount() {
            return this.userSubscriptionHelper.getListCount();
        },
        entryCount() {
            return this.userSubscriptionHelper.getEntryCount();
        },
        buyEntries() {
            return this.userSubscriptionHelper.buyEntryCount();
        },
        sellEntries() {
            return this.userSubscriptionHelper.sellEntryCount();
        }
    },
    watch: {
        getUserSubscriptionInfo: {
            handler: function (val) {
                this.userSubscriptionHelper.userSubscriptionInfo = val;
                this.usage.lists.buys = this.userSubscriptionHelper.buyListCountPercentage();
                this.usage.lists.sells = this.userSubscriptionHelper.buyListCountPercentage() + this.userSubscriptionHelper.sellListCountPercentage();
            },
            deep: true
        }
    },
    methods: {
        editUserSubscription() {
            this.$emit('editSubscriptionAsked');
        }
    }
}
</script>

<style scoped src="@/assets/css/usagePanel.css">
</style>
