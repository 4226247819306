<template>
    <div class="card-form">
        <div class="card-list">
            <VuePaycard :valueFields="valueFields" :labels="labels" :is-card-number-masked="false" />
            <div class="card-form__inner">
                <div class="card-input">
                    <label for="cardNumber" class="card-input__label">{{labels.cardNumber}}</label>
                    <input
                        type="tel"
                        :id="inputFields.cardNumber"
                        title="Number"
                        class="card-input__input"
                        :value="valueFields.cardNumber2"
                        @input="changeNumber"
                        data-card-field
                        autocomplete="off"
                        :maxlength="cardNumberMaxLength"
                    />
                </div>
                <div class="card-input">
                    <label for="cardName" class="card-input__label">{{labels.cardHolder}}</label>
                    <input
                        type="text"
                        :id="inputFields.cardName"
                        title="Name"
                        v-letter-only
                        class="card-input__input"
                        :value="valueFields.cardName"
                        @input="changeName"
                        data-card-field
                        autocomplete="off"
                    />
                </div>
                <div class="card-form__row">
                    <div class="card-form__col">
                        <div class="card-input">
                        <label for="cardMonth" class="card-input__label" aria-label="Expiration Date">{{labels.formExpirationMonth}}</label>
                        <select
                            class="card-input__input -select"
                            :id="inputFields.cardMonth"
                            aria-label="Card Month"
                            title="Month"
                            v-model="valueFields.cardMonth"
                            data-card-field
                        >
                            <option value disabled selected>{{labels.formMonth}}</option>
                            <option
                            v-bind:value="n < 10 ? '0' + n : n"
                            v-for="n in 12"
                            v-bind:disabled="n < minCardMonth"
                            v-bind:key="n"
                            >{{generateMonthValue(n)}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="card-form__col">
                        <div class="card-input">
                        <label for="cardYear" class="card-input__label" aria-label="Expiration Date">{{labels.formExpirationYear}}</label>
                        <select
                            class="card-input__input -select"
                            :id="inputFields.cardYear"
                            aria-label="Card year"
                            title="Year"
                            v-model="valueFields.cardYear"
                            data-card-field
                        >
                            <option value disabled selected>{{labels.formYear}}</option>
                            <option
                            v-bind:value="$index + minCardYear"
                            v-for="(n, $index) in 12"
                            v-bind:key="n"
                            >{{$index + minCardYear}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="card-form__col -cvv">
                        <div class="card-input">
                        <label for="cardCvv" class="card-input__label" aria-label="Card CVV">{{labels.cardCvv}}</label>
                        <input
                            type="tel"
                            title="CVV"
                            class="card-input__input"
                            v-number-only
                            :id="inputFields.cardCvv"
                            maxlength="4"
                            :value="valueFields.cardCvv"
                            @input="changeCvv"
                            data-card-field
                            autocomplete="off"
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CreditCardPaymentPanelMixin from './CreditCardPaymentPanelMixin'
export default {
    mixins: [CreditCardPaymentPanelMixin]
}
</script>

<style src="@/assets/css/creditCardPaymentPanel.css">
</style>
