class StockInfo {
    constructor({ stockId, symbol, companyName, lastPrice, lastPriceDateTime, isPriceIntraDay, fundamentals = {}, changeInfo = {}, currency, exchange, tags, dayStockEvolution, dividendInfo, companyInfo }) {
        changeInfo = changeInfo || {}
        fundamentals = fundamentals || {}
        this._stockId = stockId;
        this._symbol = symbol;
        this._companyName = companyName;
        this._isPriceIntraDay = isPriceIntraDay;
        this._lastPrice = lastPrice || 0.0;
        this._lastPriceDateTime = lastPriceDateTime || Date.now;
        this._marketCap = fundamentals.mainFundamentals ? fundamentals.mainFundamentals.marketCapitalization : undefined
        this._change = changeInfo.changeAmount || 0.0;
        this._changePercent = changeInfo.changePercent || 0.0;
        this._currency = currency;
        this._exchange = exchange;
        this._tags = tags || [];
        this._dayStockEvolution = dayStockEvolution;
        this._dividendInfo = dividendInfo;
        this._companyInfo = companyInfo;
        this._mainFundamentals = fundamentals.mainFundamentals;
    }

    get stockId() { return this._stockId; }
    get symbol() { return this._symbol; }
    get companyName() { return this._companyName; }
    get isPriceIntraDay() { return this._isPriceIntraDay; }
    get lastPrice() { return this._lastPrice; }
    get lastPriceDateTime() { return this._lastPriceDateTime; }
    get marketCap() { return this._marketCap; }
    get change() { return this._change; }
    get changePercent() { return this._changePercent; }
    get currency() { return this._currency; }
    get exchange() { return this._exchange; }
    get tags() { return this._tags; }
    get dayStockEvolution() { return this._dayStockEvolution; }
    get dividendInfo() { return this._dividendInfo; }
    get companyInfo() { return this._companyInfo; }
    get mainFundamentals() { return this._mainFundamentals; }

    set stockId(value) { this._stockId = value; }
    set symbol(value) { this._symbol = value; }
    set companyName(value) { this._companyName = value; }
    set isPriceIntraDay(value) { this._isPriceIntraDay = value; }
    set lastPriceDateTime(value) { this._lastPriceDateTime = value; }
    set lastPrice(value) { this._lastPrice = value; }
    set marketCap(value) { this._marketCap = value; }
    set change(value) { this._change = value; }
    set changePercent(value) { this._changePercent = value; }
    set currency(value) { this._currency = value; }
    set exchange(value) { this._exchange = value; }
    set tags(value) { this._tags = value; }

    get changeInfo() {
        return {
            price: this._lastPrice,
            changeAmount: this._change,
            changePercent: this._changePercent
        }
    }

    get rawData() {
        return {
            stockId: this._stockId,
            symbol: this._symbol,
            companyName: this._companyName,
            isPriceIntraDay: this._isPriceIntraDay,
            marketRegularPrice: parseFloat(this._lastPrice || 0),
            marketRegularDateTime: this._lastPriceDateTime,
            marketCap: parseFloat(this._marketCap || 0),
            change: parseFloat(this._change.value || 0),
            changePercent: parseFloat(this._changePercent.value || 0),
            currency: this._currency,
            exchange: this._exchange
        };
    }
}

export default StockInfo
