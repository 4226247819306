import News from './News';

class StockContextualInfo {
    constructor() {
        this._news = [];
    }

    get news() { return this._news; }

    set news(values) {
        this._news = this._news.concat(values.map(c => new News({ ...c })));
    }
}

export default StockContextualInfo;