import { faCommentAltLines } from "@fortawesome/pro-solid-svg-icons";
import BasicFeedbackService from '../../services/FeedbackService'
import AutomatedAbuseService from '../../services/AutomatedAbuseService'
import RecaptchaText from '../../components/controls/RecaptchaText'
import Alert from '@/components/controls/Alert.vue'
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
var BasicFeedbackMixin = {
    data() {
        return {
            feedbackDialog: false,
            input: {
                rating: 4.5,
                comment: ''
            },
            icons: {
                comment: faCommentAltLines,
                save: faCheck,
                cancel: faTimes
            },
            snackbar: {
                color: 'primary',
                text: '',
                timeout: 2000,
                show: false
            }
        }
    },
    components: {
        RecaptchaText,
        Alert
    },
    methods: {
        slidePanel() {
            if (this.$vuetify.breakpoint.mobile) {
                this.feedbackDialog = !this.feedbackDialog;
            }
            else {
                this.$el.classList.toggle('open');
            }
        },
        showNotification({ type, text }) {
            this.slidePanel();
            this.$emit('feedbackCallback', { type, text });
        },
        submitComment() {
            AutomatedAbuseService.validate({ vueComp: this, action: 'comment' }).then(token => {
                BasicFeedbackService.submit({ userId: this.$store.state.user.authenticatedUser.userId, rating: this.input.rating, comment: this.input.comment, token }).then((response) => {
                    this.showNotification({ type: 'success', text: this.$t('feedback.messages.success') });
                }).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                })
            }).catch(error => {
                error = error || 'default';
                this.showNotification({ type: 'error', text: this.$t(`feedback.messages.fail.${error}`) });
            }).finally(() => {
                this.input.comment = '';
                this.input.rating = 4.5;
            });
        }
    }
}

export default BasicFeedbackMixin
