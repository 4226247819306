<template>
    <div class="questionsBeforeInvestingPanel">
        <div v-if="isActive">
            <div v-if="questions.length > 0" class="questionCards">
                <QuestionCard v-for="(q, index) in questions" :question="q" :key="index"></QuestionCard>
            </div>
            <div v-else>
                <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
            </div>
        </div>
        <div v-else>
            <LockedControl :minPlan="minPlan"></LockedControl>
        </div>
    </div>
</template>

<script>
import LockedControl from '../LockedControl.vue';
import QuestionCard from './QuestionCard.vue';
export default {
    components: { LockedControl, QuestionCard },
    computed: {
        isActive() {
            const myPlan = this.$store.getters.getMyPlan;
            return myPlan.aiDataActiveKeys.includes('questions');
        },
        questions() {
            return this.$store.getters.questionsBeforeInvesting
        },
        minPlan() {
            const plans = this.$store.getters.getPlans;
            return plans.find(p => {
                return p.limit.stockContext.aiData.questions;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.questionsBeforeInvestingPanel {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    .questionCards {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
</style>