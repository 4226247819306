class UserSubscriptionHelper {
    constructor({ userSubscriptionInfo }) {
        this._userSubscriptionInfo = userSubscriptionInfo;
    }

    set userSubscriptionInfo(userSubscriptionInfo) {
        this._userSubscriptionInfo = userSubscriptionInfo;
    }

    get userSubscriptionInfo() {
        return this._userSubscriptionInfo
    }

    _getListTotal() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.combinedListCount : 0;
    }

    _getCountOfListAvailable() {
        return this._getListLimit() - this._getListTotal();
    }

    _getListLimit() {
        const listCount = this._userSubscriptionInfo.planDefinition.limit.combinedListCount + this._userSubscriptionInfo.planDefinition.limit.buyListCount + this._userSubscriptionInfo.planDefinition.limit.sellListCount
        return listCount || this._getListTotal();
    }

    _getListCountClass() {
        const listCountClasses = ['limit'];
        if (this._getListTotal() >= this._userSubscriptionInfo.planDefinition.limit.combinedListCount) {
            listCountClasses.push('limitReached');
        }
        return listCountClasses.join(' ');
    }

    _getEntryTotal() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.combinedListCombinedEntriesCount : 0
    }

    _getEntryLimit() {
        return this._userSubscriptionInfo.planDefinition.limit.combinedListCombinedEntriesCount || this._getEntryTotal();
    }

    _getEntryCountClass() {
        const listCountClasses = ['limit'];
        if (this._getEntryTotal() === this._userSubscriptionInfo.planDefinition.limit.combinedListCombinedEntriesCount) {
            listCountClasses.push('limitReached');
        }
        return listCountClasses.join(' ');
    }

    get renewalDate() {
        return new Date(this._userSubscriptionInfo.RenewalInfo.renewalDate);
    }

    getIconFromPlan() {
        if (this._userSubscriptionInfo && this._userSubscriptionInfo.planDefinition) {
            return this.getIconFromPlanName(this._userSubscriptionInfo.planDefinition.planName)
        }
        return 'unknown';
    }

    getIconFromPlanName(planName) {
        switch (planName) {
            case 'pioneer':
                return 'hike';
            case 'base':
                return 'walk';
            case 'standard':
                return 'bike';
            case 'pro':
                return 'car';
            case 'vip':
                return 'helicopter';
            case 'owner':
                return 'millenniumFalcon';
            default:
                return 'unknown';
        }
    }

    getListCount() {
        const total = this._userSubscriptionInfo.planDefinition.limit.combinedListCount || `∞`;
        return `<span class="${this._getListCountClass()}">${this._getListTotal()}</span> / <span class="limitTotal">${total}</span>`;
    }

    getBuyListCount() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.buyListCount : 0
    }

    getSellListCount() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.sellListCount : 0
    }

    buyListCountPercentage() {
        return this._userSubscriptionInfo.usage ? (this._userSubscriptionInfo.usage.buyListCount / this._getListLimit()) * 100 : 0
    }

    sellListCountPercentage() {
        return this._userSubscriptionInfo.usage ? (this._userSubscriptionInfo.usage.sellListCount / this._getListLimit()) * 100 : 0
    }

    getEntryCount() {
        const total = this._userSubscriptionInfo.planDefinition.limit.combinedListCombinedEntriesCount || `∞`;
        return `<span class="${this._getEntryCountClass()}">${this._getEntryTotal()}</span> / <span class="limitTotal">${total}</span>`;
    }

    buyEntryCount() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.buyListCombineEntriesCount : 0
    }

    sellEntryCount() {
        return this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.sellListCombineEntriesCount : 0
    }

    canAddBuyList() {
        if (!this._userSubscriptionInfo.planDefinition) return false
        const limitBuyListCount = this._userSubscriptionInfo.planDefinition.limit.buyListCount + this._userSubscriptionInfo.planDefinition.limit.combinedListCount
        const usageBuyListCount = this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.buyListCount : 0
        const limitBuyListCountLeft = (limitBuyListCount - usageBuyListCount) > 0;
        //const limitBuyListCountLeft = ((this._userSubscriptionInfo.planDefinition.limit.buyListCount || this._getListLimit()) - usageBuyListCount) > 0;
        const totalListCountLeft = this._getCountOfListAvailable() > 0;
        return limitBuyListCountLeft && totalListCountLeft;
    }

    canAddSellList() {
        if (!this._userSubscriptionInfo.planDefinition) return false
        const limitSellListCount = this._userSubscriptionInfo.planDefinition.limit.sellListCount + this._userSubscriptionInfo.planDefinition.limit.combinedListCount
        const usageSellListCount = this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.sellListCount : 0
        const limitSellListCountLeft = (limitSellListCount - usageSellListCount) > 0;
        //const limitSellListCountLeft = ((this._userSubscriptionInfo.planDefinition.limit.sellListCount || this._getListLimit()) - usageSellListCount) > 0;
        const totalListCountLeft = this._getCountOfListAvailable() > 0;
        return limitSellListCountLeft && totalListCountLeft;
    }

    canAddPortfolio() {
        if (!this._userSubscriptionInfo.planDefinition) return false
        const limit = this._userSubscriptionInfo.planDefinition.limit.portfolioListCount
        const usage = this._userSubscriptionInfo.usage ? this._userSubscriptionInfo.usage.portfolioCount : 0
        return (limit - usage) > 0;
    }

    canAddEntry() {
        return (this._userSubscriptionInfo.planDefinition.limit.combinedListCombinedEntriesCount - this._getEntryTotal()) > 0;
    }
}

export default UserSubscriptionHelper
