import axios from "axios"
class ServiceOptions {
    static addToken({ token }) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    static addAppPreproductionKey({ appPreproductionKey }) {
        axios.defaults.headers.common['App-PreProduction-Key'] = appPreproductionKey;
    }

    static addAppLanguage({ lang }) {
        axios.defaults.headers.common['App-Language'] = lang;
    }
}

export default ServiceOptions;
