import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resetPasswordForm"},[_c(VCard,{staticClass:"resetPasswordCard",attrs:{"outlined":""}},[_c('div',{staticClass:"rpf-header"},[_c('div',{staticClass:"rpf-header-icon"},[_c('font-awesome-layers',{staticClass:"fa-2x"},[_c('font-awesome-icon',{staticStyle:{"--fa-primary-color":"gold","--fa-secondary-color":"gray"},attrs:{"icon":_vm.icon.lock}}),_c('font-awesome-icon',{attrs:{"icon":_vm.icon.question,"transform":"shrink-10 down-3"}})],1)],1),_c('div',{staticClass:"rpf-header-title"},[_vm._v(_vm._s(_vm.$t('resetPassword.request.title')))])]),_c('div',{staticClass:"rpf-desc"},[_vm._v(_vm._s(_vm.$t('resetPassword.request.text')))]),_c(VForm,{on:{"submit":_vm.sendEmail}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"type":"email","outlined":"","rules":[_vm.rules.required, _vm.rules.email],"label":_vm.$t('resetPassword.request.emailLabel'),"hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t('resetPassword.request.sendButton')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }