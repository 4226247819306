import JWT from "./JWT";

class AuthUserJWT extends JWT {
    static get roleClaimKey() { return "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"; }
    static get subscriptionPlanIdKey() { return "subscriptionPlanId"; }
    static get subscriptionPlanNameKey() { return "subscriptionPlanName"; }

    constructor(token) {
        super(token);
        this._userRoles = this._extractRoleClaim();
        this._subscriptionInfos = this._extractSubscriptionInfos();
    }

    _extractRoleClaim() {
        const roleClaim = JSON.parse(this._payload[AuthUserJWT.roleClaimKey]);
        return roleClaim.roles || {};
    }

    _extractSubscriptionInfos() {
        return {
            subscriptionPlanId: this._payload[AuthUserJWT.subscriptionPlanIdKey],
            subscriptionPlanName: this._payload[AuthUserJWT.subscriptionPlanNameKey]
        }
    }

    get userRoles() {
        return this._userRoles;
    }

    get subscriptionInfos() {
        return this._subscriptionInfos;
    }

    hasRole(role) {
        if (role in this._userRoles) {
            return this._userRoles[role].active;
        }
        return false;
    }
}

export default AuthUserJWT
