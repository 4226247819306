<template>
    <div class="badges">
        <Badge v-for="(badge, index) in badgesToShow" :key="index" :badge="badge" :size="size" @clicked="clicked"></Badge>
    </div>
</template>

<script>
import Badge from './Badge.vue';
export default {
    props: ['badges', 'size'],
    methods: {
        clicked({ badge }) {
            this.$emit('badgeClicked', { badge })
        }
    },
    components: { Badge },
    computed: {
        badgesCount() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 3;
                case 'sm':
                    return 4;
                case 'md':
                    return 5;
                case 'lg':
                    return 8;
                case 'xl':
                    return 12;
                default:
                    return 3;
            }
        },
        badgesToShow() {
            return this.badges.slice(0, this.badgesCount);
            // if (this.badges && this.badges.length > 0) {
            //     return this.badges.filter(t => t.value).sort((badge1, badge2) => {
            //         if (badge1.value < badge2.value) {
            //             return -1;
            //         }
            //         else {
            //             return 1;
            //         }
            //     });
            // }
            // return this.badges || [];
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/breakpoints.scss';
.badges {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .noBadges {
        font-style: italic;
        font-size: smaller;
        color: var(--agora);
    }
    @media screen and (max-width: $phone)  {
        justify-content: center;
    }
}
</style>
