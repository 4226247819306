<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-toolbar
                color="blue darken-2"
                dark
            >{{$t('account.sections.subscriptionBilling.managePaymentMethods.editPaymentMethod.title')}}</v-toolbar>
            <div class="paymentMethods">
                <CreditCardInfos :card="card" class="mb-4"></CreditCardInfos>
                <v-text-field v-model="nameOnCard" label="Nom sur la carte" outlined></v-text-field>
                <div class="expirationDate">
                    <v-autocomplete v-model="expirationMonth" label="Mois" :items="months" outlined></v-autocomplete>
                    <v-autocomplete v-model="expirationYear" label="Année" :items="years" outlined></v-autocomplete>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    fab
                    small
                    dark
                    @click="save"
                >
                    <font-awesome-icon :icon="icons.check" class="fa-lg"></font-awesome-icon>
                </v-btn>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="cancel"
                >
                    <font-awesome-icon :icon="icons.times" class="fa-lg"></font-awesome-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <Alert ref="iAlert"></Alert>
    </v-dialog>
</template>

<script>
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'
import PreferencesService from '../../services/PreferencesService'
import Alert from '../controls/Alert.vue'
import CreditCardInfos from './CreditCardInfos.vue';
export default {
    props: ["card"],
    data() {
        return {
            dialog: false,
            overlay: false,
            icons: {
                check: faCheck,
                times: faTimes
            },
            nameOnCard: undefined,
            expirationMonth: undefined,
            expirationYear: undefined,
            months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            years: []
        };
    },
    created() {
        const y = new Date().getUTCFullYear()
        this.years = Array.from({ length: 15 }, (v, i) => y + i)
    },
    methods: {
        show() {
            this.nameOnCard = ''
            this.expirationMonth = this.card.expiration.month.toString().padStart(2, '0')
            this.expirationYear = this.card.expiration.year
            this.dialog = true;
        },
        save() {
            this.overlay = true;
            const service = new PreferencesService();
            const data = {
                paymentMethodId: this.card.id,
                nameOnCard: this.nameOnCard,
                expirationMonth: parseInt(this.expirationMonth),
                expirationYear: this.expirationYear
            };
            service.updatePaymentMethodDetails(data).then(() => {
                this.$emit('updated', data)
                this.close();
            }).catch(error => {
                this.$refs.iAlert.error({ errorCode: error[0].errorCode });
            }).finally(() => {
                this.overlay = false;
            });
        },
        cancel() {
            this.close();
        },
        close() {
            this.dialog = false;
        }
    },
    components: { Alert, CreditCardInfos }
}
</script>

<style lang="scss" scoped>
.paymentMethods {
    padding: 15px;;
    .expirationDate {
        display: flex;
        gap: 15px;
    }
}
</style>