<template>
    <div :class="`sCountry${size ? ` ${size}` : ''} ${getCountry()}`">{{getCountry()}}</div>
</template>

<script>
export default {
    name: 'SotkcCountry',
    props: ['entry', 'size', 'country'],
    data() {
        return { }
    },
    methods: {
        getCountry() {
            if (this.entry && this.entry.currency) {
                return this.entry.currency.substring(0, 2);
            }
            if (this.country) {
                switch (this.country) {
                    case 'USA':
                        return 'US'
                    case 'Canada':
                        return 'CA'
                    default:
                        return 'UNK'
                }
            }
        }
    }
}
</script>

<style scoped src="@/assets/css/stockInfos.css">
</style>
