<template>
    <v-chip :small="$vuetify.breakpoint.mobile" @click="clicked" outlined color="var(--agora)" :close="closable" :close-icon="closeIcon">{{tag.value}}</v-chip>
</template>

<script>
export default {
    props: ['tag', 'closable', 'closeIcon'],
    methods: {
        clicked() {
            this.$emit('clicked', { tag: this.tag })
        }
    }
}
</script>

<style scoped>

</style>
