import { render, staticRenderFns } from "./CompleteStockCardMobile.vue?vue&type=template&id=dfa8eda6&scoped=true&"
import script from "./CompleteStockCardMobile.vue?vue&type=script&lang=js&"
export * from "./CompleteStockCardMobile.vue?vue&type=script&lang=js&"
import style0 from "./CompleteStockCardMobile.vue?vue&type=style&index=0&id=dfa8eda6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa8eda6",
  null
  
)

export default component.exports