<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.usage.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">
                <div class="ap-subtitle-lpart">{{$t('admin.body.usage.title')}}</div>
                <div class="ap-subtitle-rpart">
                </div>
            </div>
            <div class="ap-row">
                <div class="ap-section">
                    <div class="ap-subSectionTitle">{{$t('admin.body.usage.timespan.title')}}</div>
                    <div class="ap-body-part">
                        <v-radio-group v-model="since">
                            <v-radio
                                v-for="n in timespan"
                                :key="n.key"
                                :label="$t(`admin.body.usage.timespan.${n.since}`)"
                                :value="n.delta"
                                @change="timeSpanChanged(n.delta)"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <v-divider vertical></v-divider>
                <v-list class="px-5 flex-grow-1">
                    <v-list-item>
                        <v-list-item-avatar>
                        </v-list-item-avatar>

                        <div class="li-title"></div>
                        <div class="li-stats">
                            <div class="li-stat">Total</div>
                            <div class="li-stat">Unique</div>
                        </div>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                        v-for="s in stats"
                        :key="s.statisticName"
                    >
                        <v-list-item-avatar>
                            <font-awesome-icon v-if="s.statisticName" :icon="icons[s.statisticName]" class="fa-lg"></font-awesome-icon>
                        </v-list-item-avatar>

                        <div class="li-title" v-if="s.statisticName">{{$t(`admin.body.usage.keys.${s.statisticName}`)}}</div>
                        <div class="li-stats">
                            <div class="li-stat">{{s.totalCount}}</div>
                            <div class="li-stat">{{s.uniqueUserCount}}</div>
                        </div>

                    </v-list-item>
                </v-list>
            </div>
        </div>
    </div>
</template>

<script>
import UsageSectionMixin from './UsageSectionMixin'
export default {
    mixins: [UsageSectionMixin]
}
</script>

<style scoped src="@/assets/css/adminSection.css">
</style>

<style scoped>
.li-title {
    width: 60%;
}
.li-stats {
    flex: 1;
    display: flex;
    align-items: center;
}
.li-stat {
    width: 100px;
    text-align: center;
}
.li-stat:not(:last-of-type) {
    margin-right: 15px;
}
</style>
