<template>
    <div class="portfolioWidget">
        <div class="portfolioHeader">
            <div class="portfolioTitle">{{$t("Home.dashboard.summary.portfolios")}}</div>
            <div v-if="showLimitReached">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <font-awesome-icon v-bind="attrs" v-on="on" :icon="icons.warning"/>
                    </template>
                    <span>{{$t("Home.dashboard.summary.listLimitReached.portfolio")}}</span>
                </v-tooltip>
            </div>
        </div>
        <v-skeleton-loader v-if="!portfolioLoaded" type="card, list-item-two-line" width="500" height="320"></v-skeleton-loader>
        <div v-else>
            <div v-if="myPlanHasPortfolio" class="cardList">
                <SwivleCard v-for="portfolio in portfolioList" :key="portfolio.id" :list="portfolio" listType="portfolio"></SwivleCard>
                <SwivelAddCard listType="portfolio"></SwivelAddCard>
            </div>
            <div v-else class="locked">
                <LockedControl :minPlan="minPlanWithPortfolio"></LockedControl>
            </div>
        </div>
    </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import SwivleCard from '../home/dashboard/boards/SwivleCard.vue'
import UserSubscriptionHelper from '../plan/UserSubscriptionHelper'
import SwivelAddCard from '../home/dashboard/boards/SwivelAddCard.vue'
import { listComputed } from '../../store/helpers/ListHelper'
import LockedControl from '../controls/LockedControl.vue'
export default {
    components: { SwivleCard, SwivelAddCard, LockedControl },
    data() {
        return {
            icons: {
                warning: faExclamationTriangle
            },
            portfolioLoaded: true,
            userSubscriptionHelper: undefined
        };
    },
    created() {
        this.userSubscriptionHelper = new UserSubscriptionHelper({})
    },
    computed: {
        ...listComputed,
        portfolioList() {
            return this.userLists.portfolios
        },
        userSubscriptionInfo() {
            return this.$store.getters.userSubscriptionInfo
        },
        showLimitReached() {
            if (!this.userSubscriptionInfo.planDefinition) return false
            const limit = this.userSubscriptionInfo.planDefinition.limit.portfolioListCount
            const usage = this.userSubscriptionInfo.usage ? this.userSubscriptionInfo.usage.portfolioCount : 0
            return this.myPlanHasPortfolio && (limit - usage) <= 0;
        },
        myPlanHasPortfolio() {
            return this.$store.getters.myPlanHasPortfolio
        },
        minPlanWithPortfolio() {
            return this.$store.getters.minPlanWithPortfolio
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.portfolioWidget {
    .portfolioHeader {
        display: flex;
        justify-content: space-between;
        background-color: var(--darkBG);
        padding: 5px 10px;
        margin-bottom: 10px;
        color: white;
        align-items: center;
        .portfolioTitle {
            font-size: large;
            display: flex;
            align-items: center;
        }
    }
    .cardList {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .locked {
        background-color: #FFF;
    }
    @media screen and (max-width: $tablet) {
        .portfolioHeader {
            background-color: var(--lightBG);
            color: black;
            .portfolioTitle {
                font-size: medium;
            }
        }
    }
}
</style>