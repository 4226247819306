<template>
    <div class="invitationList">
        <v-data-table
            :headers="headers"
            :items="invitationList"
            :items-per-page=-1
            hide-default-footer>
                <template v-slot:item.fullname="{ item }">
                    <span>{{`${item.lastname}, ${item.firstname}`}}</span>
                </template>
                <template v-slot:item.plan="{ item }">
                    <span>{{$t(`subscriptions.plans.${item.plan}.title`)}}</span>
                </template>
                <template v-slot:item.status="{ item }">
                    <span>{{$t(`admin.body.invitations.invitationsSent.status.${item.status}`)}}</span>
                </template>
                <template v-slot:item.invitedBy="{ item }">
                    <span>{{item.invitedBy ? item.invitedBy.fullName : 'ND'}}</span>
                </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ['invitationList', 'plans'],
    data() {
        return {
            headers: [
                { text: this.$t('admin.body.invitations.invitationsSent.fields.fullname'), value: 'fullname' },
                { text: this.$t('admin.body.invitations.invitationsSent.fields.email'), value: 'email' },
                { text: this.$t('admin.body.invitations.invitationsSent.fields.plan'), value: 'plan' },
                { text: this.$t('admin.body.invitations.invitationsSent.fields.status'), value: 'status' },
                { text: this.$t('admin.body.invitations.invitationsSent.fields.invitedBy'), value: 'invitedBy' }
            ]
        }
    }
}
</script>

<style scoped>
.invitationList {}
.invitationList>*:not(:last-child) {
    margin-bottom: 5px;
}
</style>
