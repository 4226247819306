import ValueAndChange from '../../controls/ValueAndChange.vue';
import OverviewPortfolioCard from './OverviewPortfolioCard.vue';
import PortfolioEditStockDialog from '../../portfolio/PortfolioEditStockDialog.vue';
import { listComputed, getTopList, getTopMovers } from '../../../store/helpers/ListHelper';
import StockHtmlHelper from "../../stocks/StockHtmlHelper"
import { authorizationComputed } from '../../../store/helpers/AuthorizationHelper';

var DashboardOverviewMixin = {
    data() {
        return {
            stockHTMLHelper: null,
            totalValue: null,
            selectedPortfolioId: null,
            selectedPortfolioStock: null
        }
    },
    components: { OverviewPortfolioCard, ValueAndChange, PortfolioEditStockDialog },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper()
    },
    computed: {
        ...listComputed,
        ...authorizationComputed,
        allPortfoliosTotalValue() {
            return this.$store.getters.portfolioList.reduce((acc, cur) => acc + this.convertAmountIfNeeded(cur, cur.statistics.totalRemainingStockValue), 0)
        },
        allPortfoliosTotal() {
            return this.stockHTMLHelper.formatPriceWithLocaleCurrency(this.allPortfoliosTotalValue, this.preferredCurrency, this.$i18n.locale)
        },
        allPortfoliosTotalConverted() {
            const rate = this.selectedCurrency === 'CAD' ? this.exchangeRate('cadusd') : this.exchangeRate('usdcad')
            const convertedTotal = this.allPortfoliosTotalValue * rate
            return this.stockHTMLHelper.formatPriceWithLocaleCurrency(convertedTotal, this.conversionCurrency, this.$i18n.locale)
        },
        preferredCurrency() {
            return this.$store.getters.userCurrency.toUpperCase()
        },
        conversionCurrency() {
            return this.$store.getters.conversionCurrency.toUpperCase()
        },
        getTopBuys() {
            const bestLists = this.$store.getters.bestLists.buys
            const buyList = this.$store.getters.userLists.buyLists.filter(list => bestLists.length === 0 || bestLists.includes(list.id))
            return getTopList(buyList, 2)
        },
        getTopSells() {
            const bestLists = this.$store.getters.bestLists.sells
            const sellList = this.$store.getters.userLists.sellLists.filter(list => bestLists.length === 0 || bestLists.includes(list.id))
            return getTopList(sellList, 2)
        },
        topPortfolioMovers() {
            const lists = this.$store.getters.portfolioList
            return lists ? getTopMovers(lists, 4) : []
        },
        totalChange() {
            const total = this.$store.getters.portfolioList.reduce((acc, cur) => acc + this.convertAmountIfNeeded(cur, cur.statistics.totalRemainingStockValue), 0)
            const totalDayChange = this.$store.getters.portfolioList.reduce((acc, cur) => acc + this.convertAmountIfNeeded(cur, cur.statistics.dayChange), 0)
            const totalDayPrctChange = totalDayChange / total
            return {
                val: totalDayChange,
                chg: totalDayPrctChange,
                chgSymbol: "%",
                inverted: true
            }
        },
        hasBestSuggestions() {
            return this.$store.getters.userLists.buyLists.length > 0 || this.$store.getters.userLists.sellLists.length > 0
        }
    },
    methods: {
        viewStock({ stock }) {
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: stock.stock.listId });
            this.$router.push({ name: 'Stock', params: { id: stock.stockId } });
        },
        viewStockLot({ stock }) {
            this.selectedPortfolioId = stock.portfolioId
            this.selectedPortfolioStock = this.$store.getters.portfolioStock({ portfolioId: stock.portfolioId, stockId: stock.stockId })
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: stock.portfolioId });
            this.$router.push({ name: 'Stock', params: { id: stock.stockId } });
        },
        showNotification({ type, text, duration }) {
            this.$refs.iSySnackbar.show({ type, text, duration })
        },
        getExchangeRate(portfolio) {
            const rateKey = `${portfolio.currency}${this.preferredCurrency}`.toUpperCase()
            return this.$store.getters.getCurrencyExchangeRates ? this.$store.getters.getCurrencyExchangeRates[rateKey] : 1
        },
        convertRateIfNeeded(portfolio) {
            return portfolio.statistics.totalRemainingStockValue * this.getExchangeRate(portfolio)
        },
        convertAmountIfNeeded(portfolio, total) {
            return total * this.getExchangeRate(portfolio)
        },
        exchangeRate(forexKey) {
            if (forexKey) {
                const key = forexKey.toUpperCase()
                return this.$store.getters.getCurrencyExchangeRates ? this.$store.getters.getCurrencyExchangeRates[key] : this.$t('dictio.na')
            }
            return this.$t('dictio.na')
        }
    }
}

export default DashboardOverviewMixin