class User {
  constructor({ userId, email, firstname, lastname }) {
    this._userId = userId;
    this._email = email;
    this._firstname = firstname;
    this._lastname = lastname;
  }

  get userId() {
    return this._userId;
  }

  get email() {
    return this._email;
  }

  get firstname() {
    return this._firstname;
  }

  get lastname() {
    return this._lastname;
  }

  set userId(value) {
    this._userId = value;
  }

  set email(value) {
    this._email = value;
  }

  set firstname(value) {
    this._firstname = value;
  }

  set lastname(value) {
    this._lastname = value;
  }

  get nickname() {
    return `${this._firstname.charAt(0)}${this._lastname.charAt(0)}`;
  }

  fullname() {
    return `${this._firstname} ${this._lastname}`;
  }

  serialize() {
    const serialized = {
      userId: this._userId,
      email: this._email,
      firstname: this._firstname,
      lastname: this._lastname
    }
    return JSON.stringify(serialized);
  }
}

export default User
