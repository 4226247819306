import { faUsdSquare } from '@fortawesome/pro-solid-svg-icons';
import StockPriceInfos from './StockPriceInfos.vue';
import StockPriceChange from './StockPriceChange.vue';
import StockCountry from './StockCountry.vue';
import StockPriceDate from './StockPriceDate.vue'
import StockHtmlHelper from './StockHtmlHelper'
import ThresholdPanel from '../userStockWatchingList/edition/ThresholdPanel.vue'
import ThresholdLine from './ThresholdLine.vue'
// import FundamentalsService from '../../services/FundamentalsService';
// import StockEvolutionService from '../../services/StockEvolutionService';
import StockChart from '../controls/StockChart.vue'
import Tags from '../controls/Tags.vue';
import SearchDialog from '../search/SearchDialog.vue'
import TagsNavigator from '../search/tags/TagsNavigator.vue'
import StockService from '@/services/StockService';
import UserListSelector from '../userStockWatchingList/edition/UserListSelector.vue'
import StockInfo from '../../models/StockInfo';
import ExchangeHelper from '../../store/helpers/ExchangeHelper';
var CompleteStockCardMixin = {
    props: ['stockId'],
    data() {
        return {
            icons: {
                currency: faUsdSquare
            },
            loaded: false,
            stock: {}
        }
    },
    computed: {
        companyName() {
            return this.isStock() ? this.stock.companyName : this.$t('dictio.na');
        },
        symbol() {
            return this.isStock() ? this.stock.symbol : this.$t('dictio.na');
        },
        exchange() {
            const name = this.isStock() ? ExchangeHelper.getShortName(this.stock.exchange) : this.$t('dictio.na');
            return name || this.$t('dictio.na');
        },
        currency() {
            return this.isStock() ? this.stock.currency : this.$t('dictio.na');
        },
        lastPrice() {
            return this.isStock() ? this.formatPrice(this.stock.lastPrice) : this.$t('dictio.na');
        },
        prevClose() {
            return this.isStock() && this.stock.dayStockEvolution.previousClose ? this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.previousClose) : this.$t('dictio.na');
        },
        openPrice() {
            return this.isStock() && this.stock.dayStockEvolution.dayOpen ? this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayOpen) : this.$t('dictio.na');
        },
        dayRange() {
            if (this.isStock() && this.stock.dayStockEvolution.dayLow && this.stock.dayStockEvolution.dayHigh) {
                return `${this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayLow)} - ${this.stockHtmlHelper.formatPrice(this.stock.dayStockEvolution.dayHigh)}`;
            }
            else {
                return this.$t('dictio.na');
            }
        },
        oneYearRange() {
            if (this.isStock() && this.stock.mainFundamentals.the52WeekLow && this.stock.mainFundamentals.the52WeekHigh) {
                return `${this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.the52WeekLow)} - ${this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.the52WeekHigh)}`;
            }
            else {
                return this.$t('dictio.na');
            }
        },
        marketCap() {
            if (this.isStock()) {
                const mcValUnit = this.stockHtmlHelper.simplifyUnit(this.stock.marketCap);
                return `${mcValUnit.val}${mcValUnit.unit ? this.$t(`units.${mcValUnit.unit}`) : ''}`;
            } else {
                return this.$t('dictio.na');
            }
        },
        oneYearTargetEst() {
            return this.isStock() ? this.stockHtmlHelper.formatPrice(this.stock.mainFundamentals.wallStreetTargetPrice) : this.$t('dictio.na');
        },
        logo() {
            return this.isStock() ? this.stockHtmlHelper.getStockImageSrc(this.stock.companyInfo.logoUrl) : this.$t('dictio.na');
        },
        dividend() {
            return this.isStock() ? this.stock.dividendInfo.dividendYield ? this.stock.dividendInfo.dividendYield : this.$t('dictio.na') : this.$t('dictio.na');
        },
        tags() {
            return this.isStock() ? this.stock.tags : [];
        },
        stockChangeInfo() {
            if (this.isStock()) {
                return this.stock.changeInfo;
            } else {
                return {};
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    mounted() {
        this.showCompleteStockCard();
    },
    components: {
        StockPriceInfos,
        StockPriceChange,
        StockCountry,
        StockPriceDate,
        ThresholdPanel,
        StockChart,
        ThresholdLine,
        Tags,
        SearchDialog,
        TagsNavigator,
        UserListSelector
    },
    methods: {
        isStock() {
            return this.stock && this.stock instanceof StockInfo;
        },
        showCompleteStockCard() {
            if (this.stockId && Object.keys(this.stock).length === 0) {
                StockService.getCompleteInfo({ stockId: this.stockId }).then(result => {
                    this.stock = result;
                    this.loaded = true;
                    if (this.$refs.stockChart) {
                        this.fillStockEvolutionData();
                    }
                });
            }
            // const stockId = this.stock.stockId;
            // if (stockId) {
            //     this.userListEntry = this.stock;
            //     this.stockListId = this.userListEntry.listId;
            //     FundamentalsService.getBasics({ stockId }).then(result => {
            //         this.fillStockInfos(result);
            //     }).catch(err => {
            //         console.log(err);
            //     });
            //     StockEvolutionService.getLast({ stockId }).then(result => {
            //         this.fillStockEvolutionData(result);
            //     }).catch(err => {
            //         console.log(err);
            //     });
            // }
        },

        // fillStockInfos(result) {
        //     this.fundamentals = result;
        //     if (result.technicals.the52WeekHigh && result.technicals.the52WeekLow) {
        //         this.stockInfos.oneYearRange = `${this.stockHtmlHelper.formatPrice(result.technicals.the52WeekLow)} - ${this.stockHtmlHelper.formatPrice(result.technicals.the52WeekHigh)}`;
        //     }
        //     else {
        //         this.stockInfos.oneYearRange = '-'
        //     }
        //     const mcValUnit = this.stockHtmlHelper.simplifyUnit(result.highlights.marketCapitalization);
        //     const marketCap = `${mcValUnit.val}${mcValUnit.unit ? this.$t(`units.${mcValUnit.unit}`) : ''}`;
        //     this.stockInfos.logo = this.stockHtmlHelper.getStockImageSrc(result.general.logoUrl);
        //     this.stockInfos.marketCap = marketCap;
        //     this.stockInfos.dividend = result.highlights.dividendYield ? result.highlights.dividendYield : this.$t('dictio.na');
        //     this.stockInfos.oneYearTargetEst = this.stockHtmlHelper.formatPrice(result.highlights.wallStreetTargetPrice);
        // },

        fillStockEvolutionData() {
            const evo = this.stock.dayStockEvolution;
            if (evo) {
                const data = evo.stockEvolutionPITList.map(s => { return [Date.parse(`${s.dateTime}Z`), s.price]; })
                this.$refs.stockChart.setData({
                    name: this.stock.symbol,
                    data,
                    change: this.stock.change,
                    previousClose: evo.previousClose
                });
            }
        },
        // editStockThresholds() {
        //     this.$router.push({ name: 'List', params: { id: this.stock.getScoreInfos()[0].list.id, stockId: this.stock.id } });
        // },

        cancel() {
        },

        formatPrice(price) {
            return this.stockHtmlHelper.formatPrice(price);
        },

        tagClicked({ tag }) {
            this.$emit('tagClicked', { tag });
        }
    },
    watch: {
        stockId(val) {
            this.showCompleteStockCard();
        }
    }
}

export default CompleteStockCardMixin
