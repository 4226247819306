<template>
    <div class="orderDetailsPanel">
        <OrderDetails :order="order"></OrderDetails>
        <div class="disclaimer">{{$t('subscriptions.plans.disclaimer')}}</div>
    </div>
</template>

<script>
import OrderDetails from './OrderDetails.vue'
export default {
    props: ['order'],
    components: { OrderDetails }
}
</script>

<style scoped>
.orderDetailsPanel {
    width: 800px;
    display: flex;
    flex-direction: column;
}
.disclaimer {
    padding: 8px;
    font-size: small;
}
@media screen and (max-width: 750px) {
    .orderDetailsPanel {
        width: 100%;
    }
}
</style>
