<template>
    <div class="subscrptionInfosPanel">
        <v-skeleton-loader v-if="!isPlanDefinitionLoaded()" type="article"></v-skeleton-loader>
        <div v-else class="subscrptionInfos">
            <div class="sip-img">
                <font-awesome-icon :icon="icons[planName]" class="fa-lg"></font-awesome-icon>
            </div>
            <div class="sip-Infos">
                <div class="sip-name">
                    {{$t(`subscriptions.plans.${planName}.fullTitle`)}}
                    <span v-if="canceledSubscription" class="sip-note">{{$t('subscriptions.cancellation.cancelled')}}</span>
                </div>
                <div class="sip-Price">{{formattedPrice}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { faQuestionSquare } from "@fortawesome/pro-light-svg-icons";
import { authComputed } from '@/store/helpers/AuthenticationHelper';
import UserSubscriptionHelper from "../../../components/plan/UserSubscriptionHelper";
import planHelperMixin from "../../../helpers/planHelperMixin";
export default {
    name: 'SubscrptionInfos',
    mixins: [planHelperMixin],
    data() {
        return {
            icons: {
                plan: undefined,
                unknown: faQuestionSquare
            },
            userSubscriptionHelper: undefined
        }
    },
    created() {
        this.userSubscriptionHelper = new UserSubscriptionHelper({ userSubscriptionInfo: this.userSubscriptionInfo });
    },
    computed: {
        ...authComputed,
        formattedPrice() {
            let fprice = '';
            if (this.isPlanDefinitionLoaded() && this.userSubscriptionInfo.planDefinition.reccurringInterval === 'month') {
                fprice = this.$t('subscriptions.confirmation.monthlyPrice', [this.userSubscriptionInfo.planDefinition.price.toFixed(2)]);
            }
            return fprice;
        },
        planName() {
            return this.isPlanDefinitionLoaded() ? this.userSubscriptionInfo.planDefinition.planName : '';
        },
        planIcon() {
            const icon = this.isPlanDefinitionLoaded() ? this.userSubscriptionHelper.getIconFromPlanName(this.userSubscriptionInfo.planDefinition.planName) : 'unknown'
            return this.icons[icon]
        }
    },
    methods: {
        isPlanDefinitionLoaded() {
            return this.userSubscriptionInfo && this.userSubscriptionInfo.planDefinition;
        }
    },
    watch: {
        userSubscriptionInfo: {
            handler: function (val) {
                this.userSubscriptionHelper.getIconFromPlan();
            },
            deep: true
        }
    }
}
</script>

<style>
.subscrptionInfos {
    display: flex;
}
.sip-img {
    color: dodgerblue;
    margin-right: 15px;
}
.sip-Infos {
    display: flex;
    flex-direction: column;
}
.sip-name {
    display: flex;
    align-items: center;
    font-weight: bold;
}
.sip-Price {
    font-size: small;
}
.sip-note {
    font-size: small;
    color: #e60000;
    margin-left: 8px;
}
.sip-note::after {
    content: '*';
}
</style>
