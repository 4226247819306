import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":"","scrollable":"","hide-overlay":""},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"overflow-hidden",attrs:{"tile":""}},[_c(VAppBar,{staticClass:"dialog-toolbar-color",attrs:{"absolute":"","dark":"","dense":"","elevate-on-scroll":"","scroll-target":"#scrolling-techniques-6"}},[_c(VToolbarTitle,{staticClass:"stockHeader"},[_vm._v(_vm._s(_vm.stock.companyName)+" ("+_vm._s(_vm.stock.symbol)+")")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.scrollAndClose}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VSheet,{staticClass:"overflow-y-auto px-2 pb-6",attrs:{"id":"scrolling-techniques-6","max-height":"100vh"}},[_c('div',{staticClass:"stockFullCardCont"},[_c('div',{staticClass:"dialogBody"},[_c('StockFullCardMobile',{ref:"stockFullCard",attrs:{"stock":_vm.stock}})],1),_c('div',{staticClass:"dialogFooter"},[_c(VBtn,{attrs:{"color":"red","fab":"","small":"","dark":""},on:{"click":_vm.remove}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.delete}})],1),_c(VSpacer),_c(VBtn,{staticClass:"ml-3",attrs:{"color":"secondary","fab":"","small":"","dark":""},on:{"click":_vm.scrollAndClose}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.close,"color":"primary"}})],1)],1)])]),_c('ConfirmDialog',{ref:"dirtyConfirmation",on:{"answered":_vm.dirtyConfirmationAnswered}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }