<template>
    <div class="vtags">
        <div class="tagSection" v-for="(cat, i) in categoryOrder" :key="i">
            <div class="sectionName">{{$t(`search.tags.category.${cat}`)}}</div>
            <SelectableTag v-for="(tag, i) in classifiedTags[cat]" :key="i" :tag="tag" @clicked="tagClicked" class="vtag"></SelectableTag>
        </div>
    </div>
</template>

<script>
import SelectableTag from './SelectableTag.vue'
export default {
    props: ['tags', 'selectedTags'],
    data () {
        return {
            classifiedTags: {},
            categoryOrder: ['industry', 'subIndustry']
        }
    },
    methods: {
        tagClicked({ tag, selected }) {
            this.$emit('tagClicked', { tag, selected })
        }
    },
    components: { SelectableTag },
    watch: {
        tags: {
            handler: function (val) {
                const ct = {};
                val.forEach(t => {
                    if (ct[t.name]) {
                        ct[t.name].push(t)
                    }
                    else {
                        ct[t.name] = [t];
                    }
                });
                this.classifiedTags = ct;
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.vtags {
    display: flex;
    flex-direction: column;
}
.tagSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tagSection:not(:last-of-type){
    margin-bottom: 15px;
}
.sectionName {
    font-weight: bold;
    text-transform: capitalize;
}
.vtag:not(:last-of-type) {
    margin-bottom: 5px;
}
</style>
