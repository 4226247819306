class StockSentiments {
    constructor({ date, count, normalized }) {
        this._date = date;
        this._count = count;
        this._normalized = normalized;
    }

    get date() { return this._date; }
    get count() { return this._count; }
    get normalized() { return this._normalized; }

    set date(value) { this._date = value; }
    set count(value) { this._count = value; }
    set normalized(value) { this._normalized = value; }
}

export default StockSentiments;