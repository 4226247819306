<template>
    <div class="companyPanel">
        <div v-if="companies.length > 0" class="companies">
            <CompanyCard v-for="(c, index) in companies" :company="c" :key="index"></CompanyCard>
        </div>
        <div v-else>
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
    </div>
</template>

<script>
import CompanyCard from './CompanyCard.vue'
export default {
    components: { CompanyCard },
    computed: {
        companies() {
            return this.$store.getters.similarCompanies
        }
    }
}
</script>

<style lang="scss" scoped>
.companyPanel {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    .companies {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
</style>