<template>
    <div class="portfolioOverview">
        <div class="poTop">
            <div class="pname">{{ portfolioName }}</div>
            <v-btn @click="editPF" icon x-small color="white" class="editBtn">
                <font-awesome-icon :icon="icons.edit" class="fa-fw fa-lg"></font-awesome-icon>
            </v-btn>
        </div>
        <div class="pvalueInfos">
            <div class="poPriceInfo">
                <div class="poValue">{{ totalValue }}</div>
                <div class="poCurrency">{{ portfolioCurrency }}</div>
            </div>
        </div>
        <div class="poChanges">
            <div class="pchange">
                <div class="pclabel">{{ $t('UserStockWatchingList.portfolio.dayGain') }}</div>
                <ValueAndChange v-bind="dayGain"></ValueAndChange>
            </div>
            <div class="pchange">
                <div class="pclabel">{{ $t('UserStockWatchingList.portfolio.totalGain') }}</div>
                <ValueAndChange v-bind="totalGain"></ValueAndChange>
            </div>
        </div>
        <div class="pgraph" v-if="false"></div>
        <font-awesome-icon :icon="icons.portfolio" class="fa-fw bgIcon" color="#DDD"></font-awesome-icon>
        <EditListCard ref="editCard"></EditListCard>
    </div>
</template>

<script>
import { faSuitcase } from '@fortawesome/pro-regular-svg-icons'
import ValueAndChange from '../controls/ValueAndChange.vue'
import StockHtmlHelper from '../stocks/StockHtmlHelper'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import EditListCard from '../home/dashboard/boards/EditListCard.vue'
export default {
    components: { ValueAndChange, EditListCard },
    props: ['portfolio'],
    data() {
        return {
            icons: {
                portfolio: faSuitcase,
                edit: faPen
            },
            stockHTMLHelper: undefined
        }
    },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper()
    },
    computed: {
        portfolioCurrency() {
            return this.portfolio ? this.portfolio.currency.toUpperCase() : ''
        },
        portfolioName() {
            return this.portfolio ? this.portfolio.name : ''
        },
        totalValue() {
            if (this.portfolio) {
                const val = this.portfolio.statistics.totalRemainingStockValue || 0
                return this.stockHTMLHelper.formatPriceWithLocaleCurrency(val, this.portfolio.currency, this.$i18n.locale)
            }
            return 0
        },
        dayGain() {
            return this.portfolio ? {
                val: this.portfolio.statistics.dayChange,
                chg: this.portfolio.statistics.dayPercentChange,
                chgSymbol: "%",
                dark: true
            } : { val: 0, chg: 0, chgSymbol: "%" }
        },
        totalGain() {
            return this.portfolio ? {
                val: this.portfolio.statistics.totalProfitIncludingUnrealized,
                chg: this.portfolio.statistics.percentageOfProfitIncludingUnrealized,
                chgSymbol: "%",
                dark: true
            } : { val: 0, chg: 0, chgSymbol: "%" }
        }
    },
    methods: {
        editPF() {
            this.$refs.editCard.show({ list: this.portfolio, type: 'portfolio' })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.portfolioOverview {
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: var(--darkBG);
    border-radius: 10px;
    color: #FFF;
    font-family: "Roboto", sans-serif;
    position: relative;
    overflow: hidden;
    .poPriceInfo {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        .poCurrency {
            font-size: 1.3em;
            color: #AAA;
        }
        .poValue {
            font-size: 3em;
            font-weight: bold;
        }
    }
    .poTop {
        display: flex;
        gap: 15px;
        flex-flow: wrap;
        align-items: center;
        .pname {
            font-size: 1.5em;
        }
        .editBtn {
            //opacity: 0.7;
        }
    }
    .poChanges {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .pchange {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 5px;
            font-size: 1.2em;
            .pclabel {
                color: #AAA;
            }
            .pcvalue {
                font-size: 1.2em;
                font-weight: bold;
            }
        }
    }
    .pgraph {}
    .bgIcon {
        position: absolute;
        top: 5px;
        right: -10px;
        font-size: 90px;
        rotate: -15deg;
        opacity: 0.2;
    }
    @media screen and (max-width: $tablet){
        & {
            gap: 15px;
            .poChanges {
                .pchange {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
    @media screen and (max-width: $phone) {
        & {
            font-size: 0.8em;
            gap: 5px;
            .pvalue {
                text-align: end;
            }
            .poChanges {
                .pchange {
                    flex-direction: row;
                    justify-content: space-between;
                    .pclabel {
                        width: unset;
                    }
                }
            }
        }
    }
}
</style>