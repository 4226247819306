<template>
    <div class="inviteActionButtons">
        <div class="d-flex align-center">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="blue-grey"
                        :disabled="!pending"
                        :dark="pending"
                        v-bind="attrs"
                        small
                        v-on="on"
                    >
                        <font-awesome-icon :icon="icons[selectedPlan]" class="mr-2"></font-awesome-icon>
                        <span>{{$t(`subscriptions.plans.${selectedPlan}.title`)}}</span>
                        <font-awesome-icon :icon="icons.down" class="ml-2"></font-awesome-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(plan, index) in plans"
                        :key="index"
                        @click="setPlan(plan)">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="icons[plan]"></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t(`subscriptions.plans.${plan}.title`) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-btn
            v-if="pending"
            color="primary"
            fab
            x-small
            :disabled="loading"
            :loading="loading"
            @click="send()"
        >
            <font-awesome-icon :icon="icons.send" class="fa-lg"></font-awesome-icon>
        </v-btn>
        <font-awesome-icon v-else :icon="icons.sent" class="fa-2x" style="color:green"></font-awesome-icon>
    </div>
</template>

<script>
import InvitationService from '@/services/InvitationService'
import {
    faPaperPlane,
    faCaretDown
} from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import planHelperMixin from '../../helpers/planHelperMixin';
export default {
    props: ['plans', 'guest'],
    mixins: [planHelperMixin],
    data() {
        return {
            input: {
                firstname: '',
                lastname: '',
                email: '',
                plan: this.$t('admin.body.invitations.newInvitation.plan')
            },
            icons: {
                send: faPaperPlane,
                down: faCaretDown,
                sent: faCheckCircle
            },
            selectedPlan: 'base',
            invitationService: undefined,
            loading: false,
            pending: true
        }
    },
    created() {
        this.invitationService = new InvitationService();
        this.selectedPlan = this.plans[0]
    },
    methods: {
        setPlan(plan) {
            this.selectedPlan = plan;
            this.input.plan = plan;
        },
        send() {
            this.loading = true;
            this.invitationService.acceptInvitationVisitorRequest({ email: this.guest.email, planName: this.selectedPlan }).then(invitations => {
                this.loading = false;
                this.pending = false;
            });
        }
    },
    watch: {
        plans(val) {
            this.selectedPlan = val[0]
        }
    }
}
</script>

<style scoped>
.inviteActionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
</style>
