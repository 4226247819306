<template>
    <div class="dashboardOverview">
        <div class="doTotal">
            <div class="doValueCont">
                <div class="doValueInfos">
                    <div class="doValueCurrency">{{ preferredCurrency }}</div>
                    <div class="doValue">{{ allPortfoliosTotal }}</div>
                </div>
                <ValueAndChange v-bind="totalChange"></ValueAndChange>
            </div>
        </div>
        <div class="doMovers" v-if="topPortfolioMovers.length > 0">
            <div class="domTop">
                <div class="doTitle">{{ $t('Home.dashboard.overview.topMovers') }}</div>
            </div>
            <div class="doPortfolios">
                <OverviewPortfolioCard v-for="(s, i) in topPortfolioMovers" :stock="s" type="portfolio" :key="`tp${i}`" @viewStockLot="viewStockLot"></OverviewPortfolioCard>
            </div>
        </div>
        <PortfolioEditStockDialog ref="portfolioEditStockDialog" :portfolioId="selectedPortfolioId" :stock="selectedPortfolioStock"></PortfolioEditStockDialog>
    </div>
</template>

<script>
import DashboardOverviewMixin from './DashboardOverviewMixin'
export default {
    mixins: [DashboardOverviewMixin],
    computed: {
        convertedText() {
            return this.$t('Home.dashboard.overview.convertedValue', { currency: this.currencyToConvert })
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboardOverview {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: solid 1px #333;
    background-color: #FFF;
    font-family: "Roboto", sans-serif;
    z-index: 5;
    .doTotal {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .doTitle {
            font-size: 1.2em;
        }
        .doValueCont {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .doValueInfos {
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 10px;
                .doValueCurrency {
                    font-size: 1.5rem;
                    color: #777;
                }
                .doValue {
                    font-weight: bold;
                    font-size: 2.5rem;
                }
            }
            .doRates {
                font-size: 0.8em;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .doRate {
                    padding: 8px 0;
                    color: var(--v-lightBorder-base);
                    &.selected{
                        font-weight: bold;
                        color: var(--v-agora-base);
                    }
                }
            }
        }
    }
    .doMovers {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &>* {
            flex: 1;
        }
        .domTop {
            background-color: var(--lightBG);
            padding: 10px;
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            align-items: center;
        }
        .doLists {
            display: flex;
            gap: 10px;
            &>* {
                flex: 1;
            }
        }
        .doPortfolios {
            display: flex;
            gap: 10px;
            &>* {
                flex: 1;
            }
        }
    }
}
</style>