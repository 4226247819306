import ApiWrapper from "./ApiWrapper"

var StockEvolutionService = {
    getLast({ stockId, numberOfDays = 1 }) {
        return ApiWrapper.post('/api/StockEvolution/GetLastDays', { stockId, numberOfDays });
    },

    getAvailableIntervals() {
        return ApiWrapper.post('/api/StockEvolution/GetAvailableIntervals');
    },

    getInterval({ stockId, interval }) {
        return ApiWrapper.post('/api/StockEvolution/GetInterval', { stockId, interval });
    }
}

export default StockEvolutionService
