class TopEntry {
    constructor({ stock }) {
        this._stock = stock;
        this._id = stock.id;
        this._stockId = stock.stockId;
        this._symbol = stock.symbol;
        this._companyName = stock.companyName;
        this._lastPrice = stock.lastPrice;
        this._change = stock.change || 0.0;
        this._changePercent = stock.changePercent || 0.0;
        this._scoreInfos = [];
        this._currency = stock.currency;
        this._isPriceIntraDay = stock.isPriceIntraDay;
        this._lastPriceDateTime = stock.lastPriceDateTime;
        this._thresholds = {}
    }

    addScoreInfos({ score, list }) {
        this._scoreInfos.push({ score, list });
    }

    get id() {
        return this._id;
    }

    get stockId() {
        return this._stockId;
    }

    get symbol() {
        return this._symbol;
    }

    get companyName() {
        return this._companyName;
    }

    get lastPrice() {
        return this._lastPrice;
    }

    get change() {
        return this._change;
    }

    get changePercent() {
        return this._changePercent;
    }

    get currency() {
        return this._currency;
    }

    get highScore() {
        return this.getScoreInfos()[0].score;
    }

    get lastPriceDateTime() {
        return this._lastPriceDateTime;
    }

    get isPriceIntraDay() {
        return this._isPriceIntraDay;
    }

    get stock() {
        return this._stock;
    }

    getThresholds() {
        return this._stock.getThresholds();
    }

    setThresholds({ t200, t400, t600, t800 }) {
        this._stock.setThresholds({ t200, t400, t600, t800 });
    }

    getScoreInfos() {
        return this._scoreInfos.map(s => s).sort((a, b) => b.score - a.score);
    }
}

export default TopEntry
