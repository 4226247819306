<template>
    <div class="home">
        <DashboardDisplayer v-if="loggedIn"></DashboardDisplayer>
        <Welcome v-else></Welcome>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import Welcome from './WelcomePage.vue'
import { authComputed } from "../store/helpers/AuthenticationHelper"
import Alert from '../components/controls/Alert.vue';
import DashboardDisplayer from './Dashboard/DashboardDisplayer.vue';

export default {
    name: 'Home',
    components: { Welcome, DashboardDisplayer, Alert },
    computed: {
        ...authComputed
    },
    mounted() {
        const e = this.$route.query.e
        if (e) {
            this.$refs.iAlert.error({ errorCode: e })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/breakpoints.scss";
.home{
    height: 100%;
    position: relative;
    width: 100%;
}
.home.anonymous {
    padding-top: 10vh;
    background-color: var(--lightBG);
}

@media screen and (max-width: 1050px) {
    .home{
        width: 100%;
        &.anonymous {
            padding: 50px;
        }
    }
}

@media screen and (max-width: 750px) {
    .home{
        &.anonymous {
            padding: 0;
        }
    }
}
</style>
