var ListIconMixin = {
    methods: {
        getListIconFromType(type) {
            switch (type) {
                case 'buy':
                    return this.icons.buy
                case 'sell':
                    return this.icons.sell
                default:
                    return this.icons.portfolio
            }
        }
    }
}

export default ListIconMixin