<template>
    <div class="simplePage">
        <v-card class="simplePageCard" outlined>
            <div class="sp-header">
                <div class="sp-header-icon">
                    <font-awesome-icon :icon="icons.invitation" class="fa-2x" style="--fa-primary-color: #03A9F4; --fa-secondary-color: #0277BD;" />
                </div>
                <div class="sp-header-title">{{$t('register.invitationRequestReceived.title')}}</div>
            </div>
            <div class="sp-desc" v-html="$t('register.invitationRequestReceived.text', [this.email])"></div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="goHome">{{$t('register.invitationRequestReceived.goHomeButton')}}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { faFileUser } from '@fortawesome/pro-duotone-svg-icons';
export default {
    props: ['email'],
    data() {
        return {
            icons: {
                invitation: faFileUser
            }
        }
    },
    methods: {
        goHome() {
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>
<style scoped src="@/assets/css/simplePage.css">
</style>
<style scoped>
>>>.email {
    font-weight: bold;
}
</style>
