<template>
    <div class="adminBoard">
        <div class="ab-header">
            <font-awesome-icon :icon="icons.admin" class="fa-lg"></font-awesome-icon>
            <div class="ab-header-title">{{$t('admin.header.title')}}</div>
        </div>
        <div class="ab-body">
            <v-tabs fixed-tabs>
                <v-tab><font-awesome-icon :icon="icons.users"></font-awesome-icon></v-tab>
                <v-tab><font-awesome-icon :icon="icons.invite"></font-awesome-icon></v-tab>
                <v-tab><font-awesome-icon :icon="icons.comment"></font-awesome-icon></v-tab>
                <v-tab><font-awesome-icon :icon="icons.usage"></font-awesome-icon></v-tab>
                <v-tab><font-awesome-icon :icon="icons.maintenance"></font-awesome-icon></v-tab>
                <v-tab><font-awesome-icon :icon="icons.tests"></font-awesome-icon></v-tab>
                <v-tab-item>
                    <div class="ab-tab">
                        <UsersSection></UsersSection>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="ab-tab">
                        <InvitePanel></InvitePanel>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="ab-tab">
                        <CommentSection></CommentSection>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="ab-tab">
                        <UsageSection></UsageSection>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="ab-tab">
                        <MaintenanceSection ref="maintenance"></MaintenanceSection>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="ab-tab">
                        <TestsSection></TestsSection>
                    </div>
                </v-tab-item>
            </v-tabs>
        </div>
    </div>
</template>

<script>
import AdministrationPageMixin from './AdministrationPageMixin'
import UserDataHelperMixin from '../../infra/UserDataHelperMixin'
export default {
    mixins: [AdministrationPageMixin, UserDataHelperMixin]
}
</script>

<style scoped>
.adminPage {
    height: 100%;
    background-color: var(--lightBG);
}
.adminBoard {
    display: flex;
    flex-direction: column;
    font-size: large;
    padding: 0;
}
.ab-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: large;
}
.ab-header-title {
    margin-left: 20px;
}
.ab-body {
    width: 100%;
    padding: 0;
    margin: auto;
}
.ab-invite {
    border-radius: 0;
}
>>>.v-tab {
    min-width: 70px;
}
</style>
