<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.tests.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.tests.tests.clock.title')}}</div>
            <div class="clock">
                <v-text-field
                    v-model="clockId"
                    label="ClockId"
                    outlined
                    dense
                    hide-details=""
                    class="mr-3"
                ></v-text-field>
                <v-btn color="primary" @click="setClockId">
                    {{$t('admin.body.tests.tests.clock.setClock')}}
                </v-btn>
                <v-btn color="primary" @click="test" class="ml-3">
                    TEST
                </v-btn>
            </div>
        </div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">{{$t('admin.body.tests.tests.invoice.title')}}</div>
            <div class="clock">
                <v-text-field
                    v-model="email"
                    label="Email"
                    outlined
                    dense
                    hide-details=""
                    class="mr-3"
                ></v-text-field>
                <v-btn color="primary" @click="sendInvoice" class="ml-3">
                    TEST
                </v-btn>
            </div>
        </div>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>import TestsService from "./TestsService"
import Alert from "../../../components/controls/Alert.vue"

export default {
    data() {
        return {
            clockId: "",
            email: ""
        };
    },
    methods: {
        setClockId() {
            TestsService.setClockId({ clockId: this.clockId }).then(() => {
                this.$refs.iAlert.error('Done');
            });
        },
        test() {
            this.$refs.iAlert.error({ code: 'Done', data: '' });
        },
        sendInvoice() {
            TestsService.sendInvoice({ email: this.email }).then(response => {
                console.log(response)
                this.$refs.iAlert.error('Done')
            })
        }
    },
    components: { Alert }
}
</script>
<style scoped src="@/assets/css/adminSection.css">
</style>

<style scoped>
.clock {
    display: flex;
    align-items: center;
}
</style>
