<template>
    <div class="stockOverview">
        <div class="soTop">
            <div class="soStockSection">
                <v-img v-if="logo" :src="logo" contain max-height="100" max-width="100" :key="logo"></v-img>
                <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    :height="logoSize"
                    :width="logoSize"
                    type="image"
                ></v-skeleton-loader>
            </div>
            <div class="soPriceSection">
                <div class="soStockPricePart" v-if="stock">
                    <div class="soStockCurrency">{{currency}}</div>
                    <div class="soStockPrice">{{lastPrice}}</div>
                </div>
                <v-skeleton-loader v-else width="200" height="50" type="image"></v-skeleton-loader>
                <div class="soPriceChange" v-if="stock">
                    <StockPriceChange :stockChangeInfo="stock.changeInfo"></StockPriceChange>
                </div>
                <v-skeleton-loader v-else width="150" height="30" type="image"></v-skeleton-loader>
            </div>
        </div>
        <div class="soBottom" v-if="stock">
            <div class="soStockFrom">
                <StockCountry :entry="stock" size="lg" class="mr-2"></StockCountry>
                <div class="soStockExchange">{{exchange}}</div>
            </div>
            <div class="soPriceDelay">
                <StockPriceDate :entry="stock" out="end"></StockPriceDate>
            </div>
        </div>
    </div>
</template>

<script>
import ExchangeHelper from '../../store/helpers/ExchangeHelper';
import StockCountry from './StockCountry.vue';
import StockHtmlHelper from './StockHtmlHelper';
import StockPriceChange from './StockPriceChange.vue';
import StockPriceDate from './StockPriceDate.vue';
export default {
    props: ['stock'],
    components: { StockPriceChange, StockPriceDate, StockCountry },
    data() {
        return {
            loaded: true,
            icons: {

            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        logoSize() {
            return this.$vuetify.breakpoint.smAndDown ? 50 : 100
        },
        currency() {
            return this.stock ? this.stock.currency : ''
        },
        lastPrice() {
            const price = this.stock ? this.stock.lastPrice || this.stock.marketRegularPrice : 0
            return this.stockHtmlHelper.formatPrice(price)
        },
        logo() {
            return this.stock ? this.stockHtmlHelper.getStockImageSrc(this.stock.companyInfo.logoUrl) : ''
        },
        exchange() {
            const name = this.stock ? ExchangeHelper.getShortName(this.stock.exchange) || this.stock.exchange : null
            return name || this.$t('dictio.na');
        }
    }
}
</script>

<style lang="scss" scoped>
.stockOverview {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .soTop {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        .soStockSection {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .soStockName {
                font-weight: bold;
                font-size: 1.3em;
            }
        }
        .soPriceSection {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 10px;
            .soStockPricePart {
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 3em;
                .soStockCurrency {
                    color: var(--midGray);
                    font-size: 0.5em;
                }
                .soStockPrice {
                    font-weight: bold;
                }
            }
            .soPriceChange {
                font-size: 1.2em;
            }

        }
    }
    .soBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .soStockFrom {
            display: flex;
            gap: 5px;
            .soStockExchange {
            }
        }
        .soPriceDelay {
            color: var(--midGray);
        }
    }
}
</style>