<template>
    <div class="bestSuggestionsWidget">
        <div class="bswTitle">{{ $t(title) }}</div>
        <div class="bswCardList">
            <v-skeleton-loader v-if="!listLoaded" type="image"></v-skeleton-loader>
            <EmptySummaryList v-else-if="listLoaded && getTopSuggestions.length === 0"></EmptySummaryList>
            <SuggestionCard v-for="stock in getTopSuggestions" :key="stock.id" :stock="stock" @viewStock="viewStock"></SuggestionCard>
        </div>
    </div>
</template>

<script>
import { getTopList, listComputed } from '../../store/helpers/ListHelper';
import EmptySummaryList from '../stocks/EmptySummaryList.vue';
import SuggestionCard from './SuggestionCard.vue';

export default {
    props: ['type'],
	components: { EmptySummaryList, SuggestionCard },
    computed: {
        ...listComputed,
        listLoaded() {
            return this.$store.getters.allListAreLoaded
        },
        title() {
            return `Home.dashboard.summary.best${this.type}s`
        },
        getLists() {
            const listType = `${this.type}Lists`
            return this.$store.getters.userLists[listType]
        },
        getTopSuggestions() {
            const bestListType = `${this.type}s`
            const bestLists = this.$store.getters.bestLists[bestListType];
            const lists = this.getLists.filter(list => bestLists.length === 0 || bestLists.includes(list.id));
            return getTopList(lists, 4);
        }
    },
    methods: {
        viewStock({ stock }) {
            this.$store.dispatch('setStockPageOrigin', { stockPageOrigin: stock.listId });
            this.$router.push({ name: 'Stock', params: { id: stock.stockId } });
        }
    }
}
</script>

<style lang="scss" scoped>
.bestSuggestionsWidget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #FFF;
    padding: 15px;
    z-index: 5;
    .bswTitle {
        padding: 10px;
        text-transform: uppercase;
        background-color: var(--darkBG);
        color: #FFF;
    }
    .bswCardList {
        display: flex;
        gap: 10px;
        * {
            flex: 1;
        }

    }
}
</style>