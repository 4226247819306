<template>
    <div class="StockNewsPanel">
        <StockNews v-for="(n, index) in news" :news="n" :key="index"></StockNews>
    </div>
</template>

<script>
import StockNews from './StockNews.vue';

export default {
    props: ['news'],
    components: { StockNews }
}
</script>

<style lang="scss" scoped>
    .StockNewsPanel {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
</style>