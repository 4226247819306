import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRating } from 'vuetify/lib/components/VRating';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inv-commentBox"},[_c('a',{staticClass:"comment-expend-btn",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.slidePanel()}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":_vm.icons.comment}})],1),_c(VCard,{staticClass:"commentBoxCard elevation-16"},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('feedback.basic.title'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('feedback.basic.description'))+" "),_c('div',{staticClass:"text-center my-3"},[_c(VRating,{attrs:{"color":"yellow darken-3","background-color":"grey darken-1","empty-icon":"$ratingEmpty","half-icon":"$ratingHalf","half-increments":"","hover":"","large":""},model:{value:(_vm.input.rating),callback:function ($$v) {_vm.$set(_vm.input, "rating", $$v)},expression:"input.rating"}})],1),_c(VTextarea,{attrs:{"outlined":"","hide-details":true,"name":"input-7-4","label":_vm.$t('feedback.basic.comment')},model:{value:(_vm.input.comment),callback:function ($$v) {_vm.$set(_vm.input, "comment", $$v)},expression:"input.comment"}}),_c('RecaptchaText')],1),_c(VCardActions,[_c('SaveCancelButtons',{attrs:{"align":"end"},on:{"saveAsked":_vm.submitComment,"cancelAsked":_vm.slidePanel}})],1)],1),_c('Alert',{ref:"iAlert"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }