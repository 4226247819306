<template>
    <div class="tags">
        <Tag v-for="(tag, i) in tagsToShow" :key="i" :tag="tag"  @clicked="tagClicked"></Tag>
        <div v-show="tagsToShow.length === 0" class="noTags">{{$t('tags.msg.noTags')}}</div>
    </div>
</template>

<script>
import Tag from './Tag.vue'
export default {
    props: ['tags'],
    methods: {
        tagClicked({ tag }) {
            this.$emit('tagClicked', { tag })
        }
    },
    components: { Tag },
    computed: {
        tagsToShow() {
            if (this.tags && this.tags.length > 0) {
                return this.tags.filter(t => t.value).sort((tag1, tag2) => {
                    if (tag1.value < tag2.value) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                });
            }
            return this.tags || [];
        }
    }
}
</script>

<style scoped>
.tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.tags > * {
    margin: 3px;
}
.noTags {
    font-style: italic;
    font-size: smaller;
    color: var(--agora);
}
@media screen and (max-width: 750px)  {
    .tags {
        justify-content: center;
    }
}
</style>
