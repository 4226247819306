import StockAiContextualInfo from "../../models/StockAiContextualInfo";

class StockAiContextualInfoConverter {
    static convert({ additionalData, partnerships, sentiments, similarCompanies, questionsBeforeInvesting }) {
        const stockContextualInfo = new StockAiContextualInfo({ additionalData });
        stockContextualInfo.sentiments = sentiments;
        stockContextualInfo.partnerships = partnerships;
        stockContextualInfo.questionsBeforeInvesting = questionsBeforeInvesting;
        stockContextualInfo.similarCompanies = similarCompanies;
        return stockContextualInfo;
    }
}

export default StockAiContextualInfoConverter;