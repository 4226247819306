<template>
    <v-card elevation="0">
        <v-card-title class="justify-space-between">
            <span class="headline">{{ stock.companyName }} ({{ stock.symbol }})</span>
            <UserListSelector v-if="isStock" :symbol="stock.symbol" :thresholds="stock.getThresholds()" :stock="stock"></UserListSelector>
        </v-card-title>
        <v-card-text style="color:#444">
            <div class="pricePanel">
                <div class="ppInfos">
                    <StockOverview :stock="stockOverview"></StockOverview>
                    <div class="ppiRow">
                        <div class="mt-5">
                            <v-skeleton-loader v-if="loading" width="300" type="card-heading"></v-skeleton-loader>
                            <Tags v-else :tags="fundamentals.tags" @tagClicked="tagClicked"></Tags>
                        </div>
                        <SearchDialog ref="searchDialog">
                            <TagsNavigator ref="tagsNavigator" :refTag="selectedTag" @selectedTagChanged="selectedTagChanged"></TagsNavigator>
                        </SearchDialog>
                    </div>
                </div>
                <!-- <div class="ppInfos">
                    <div class="ppiRow">
                        <div class="ppInfosPart">
                            <v-img v-if="stockInfos.logo" :src="stockInfos.logo" contain max-height="100" max-width="100"></v-img>
                            <v-skeleton-loader
                                v-else
                                class="mx-auto"
                                height="100"
                                width="100"
                                type="image"
                            ></v-skeleton-loader>
                        </div>
                        <div class="ppInfosPart ppFlex">
                            <div class="pp-top">
                                <div class="pp-price">{{ formatPrice(stock.lastPrice) }}</div>
                            </div>
                            <div class="pp-priceChange">
                                <StockPriceChange :stockChangeInfo="stockChangeInfo" :key="stock.id + '-spc'"></StockPriceChange>
                            </div>
                            <div class="pp-delay">
                                <StockPriceDate :entry="stock" :key="stock.id + '-spd'"></StockPriceDate>
                            </div>
                        </div>
                    </div>
                    <div class="ppiRow">
                        <div class="pp-subInfos">
                            <div class="d-row">
                                <StockCountry :entry="stock" size="lg" class="mr-2"></StockCountry>
                                <div class="ppsi-exchange">{{ fundamentals.general.exchange }}</div>
                            </div>
                            <div class="ppsi-currency">
                                <font-awesome-icon :icon="icons.currency" class="fa-fw fa-lg"></font-awesome-icon>
                                {{ stock.currency }}
                            </div>
                        </div>
                    </div>
                    <div class="ppiRow">
                        <div class="mt-5">
                            <v-skeleton-loader v-if="loading" width="300" type="card-heading"></v-skeleton-loader>
                            <Tags v-else :tags="fundamentals.tags" @tagClicked="tagClicked"></Tags>
                        </div>
                        <SearchDialog ref="searchDialog">
                            <TagsNavigator ref="tagsNavigator" :refTag="selectedTag" @selectedTagChanged="selectedTagChanged"></TagsNavigator>
                        </SearchDialog>
                    </div>
                </div> -->
                <div class="pp-priceChart">
                    <StockChart ref="stockChart" :stock="stock"></StockChart>
                </div>
                <div class="keyInfoTab">
                    <div class="metas">
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.previousClose') }}</div>
                            <div class="metaValue">{{stockInfos.close}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.open') }}</div>
                            <div class="metaValue">{{stockInfos.open}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneDayRange') }}</div>
                            <div class="metaValue">{{stockInfos.oneDayRange}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneYearRange') }}</div>
                            <div class="metaValue">{{stockInfos.oneYearRange}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.marketCap') }}</div>
                            <div class="metaValue">{{stockInfos.marketCap}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.dividend') }}</div>
                            <div class="metaValue">{{stockInfos.dividend}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneYearTargetEst') }}</div>
                            <div class="metaValue">{{stockInfos.oneYearTargetEst}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stockTabs">
                <div class="thresholdCont">
                    <ThresholdPanel ref="thresholdPanel" :stock="userListEntry" :stockListId="stockListId"></ThresholdPanel>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import StockFullCardMixin from './StockFullCardMixin'
import StockOverview from './StockOverview.vue';
export default {
    mixins: [StockFullCardMixin],
    components: { StockOverview }
}
</script>

<style scoped>
.pricePanel {
    font-size: larger;
    display: flex;
    padding-top: 15px;
    gap: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 15px;
}
.ppInfos {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.ppiRow {
    display: flex;
}
.ppInfosPart {
    display: flex;
    flex-direction: column;
}

.ppInfosPart.ppFlex {
    flex: 1;
}
.d-row {
    display: flex;
    align-items: center;
}
.d-row-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.pp-top {
    display: flex;
    justify-content: flex-end;
}
.pp-price {
    font-size: 42px;
    line-height: 42px;
    font-weight: bold;
    margin-bottom: 10px;
    align-self: flex-end;
}
.pp-priceChange {
    font-size: x-large;
    margin-bottom: 10px;
}
.pp-delay {
    align-self: flex-end;
}
.pp-priceChart {
    width: 500px;
}
.pp-subInfos {
    flex: 1;
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid 1px var(--lightBG);
    display: flex;
    font-size: smaller;
    justify-content: space-between;
    align-items: center;
}
.ppsi-exchange {
    font-weight: 500;
}
.keyInfoTab {
    display: flex;
}
.metas {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #f5f5f5;
    gap: 10px;
    font-size: 0.8rem;
}
/* .metasCol {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.meta:not(:last-of-type) {
    margin-bottom: 10px;
} */
.metaName {
    text-transform: uppercase;
}
.metaValue {
    font-weight: bold;
}
.newsFeed {
    background-color: #f2f2f2;
    flex: 1;
    height: 100%;
    border-radius: 5px;
    padding: 15px;
}
.news:not(:last-of-type) {
    margin-bottom: 15px;
}
.newsTitle {
    font-weight: bold;
    color: #333;
}
.newsSummary {
    font-size: smaller;
    color: #333;
}
.newsSource {
    font-size: smaller;
    color: var(--midGray);;
}

</style>
