<template>
    <div class="appVersion">{{appVersion}}</div>
</template>

<script>
import InfosService from '@/services/InfosService'
export default {
    data() {
        return {
            appVersion: undefined
        }
    },
    created() {
        const infosService = new InfosService();
        infosService.getAppVersion().then((result) => {
            this.appVersion = `v.${result}`;
        }).catch(() => {
            this.appVersion = '';
        });
    }
}
</script>

<style>
.appVersion{
    color: var(--darkBG);
    font-size: small;
}
</style>
