class News {
    constructor({ content, date, link, sentiment, symbols, title }) {
        this._content = content;
        this._date = date;
        this._link = link;
        this._sentiment = sentiment;
        this._symbols = symbols;
        this._title = title;
    }

    get content() { return this._content; }
    get date() { return this._date; }
    get link() { return this._link; }
    get sentiment() { return this._sentiment; }
    get symbols() { return this._symbols; }
    get title() { return this._title; }
}

export default News;