<template>
    <div class="portfolioStockLotWidget">
        <div class="psLots">
            <div class="psLot" v-for="(lot, i) in stock.lots" :key="i">
                <PortfolioStockLotMobile :lastPrice="stock.stockDateTimeSnapshot.price" :lot="lot" @editLot="editLot"></PortfolioStockLotMobile>
            </div>
        </div>
        <div v-if="stock.lots" class="psSummary">
            <div class="psTotal">
                <div class="lab">{{ $tc('dictio.action', remainingStockShareCount()) }}</div>
                <div class="val">{{ remainingStockShareCount()}}</div>
            </div>
            <div class="psRealProfit">
                <div class="lab">{{ $t('UserStockWatchingList.portfolio.projectedGain') }}</div>
                <div class="val">{{ stockHtmlHelper.formatPriceWithDigit(stock.statistics.totalProfitIncludingUnrealized, 2) }}</div>
            </div>
            <div class="psAllProfit">
                <div class="lab">{{ $t('UserStockWatchingList.portfolio.profitIncludingUnrealized') }}</div>
                <div class="val">{{ stockHtmlHelper.formatPriceWithDigit(stock.statistics.percentageOfProfitIncludingUnrealized, 2) }}%</div>
            </div>
        </div>
        <div class="newLot">
            <PortfolioEditStockLotDialog ref="editStockLotDialog" :stock="stock" @saveLot="saveLotAsked" :mobile="true" @delete="deleteLotAnswered" @move="moveLotAsked"></PortfolioEditStockLotDialog>
        </div>
    </div>
</template>

<script>
import PortfolioEditStockLotDialog from './PortfolioEditStockLotDialog.vue';
import PortfolioStockLotMobile from './PortfolioStockLotMobile.vue';
import PortfolioStockLotWidgetMixin from './PortfolioStockLotWidgetMixin';

export default {
    props: ['portfolioId', 'stockId', 'stockinfo'],
    mixins: [PortfolioStockLotWidgetMixin],
    components: { PortfolioStockLotMobile, PortfolioEditStockLotDialog },
    computed: {
        stock() {
            const storedStock = this.stockId ? this.$store.getters.portfolioStock({ portfolioId: this.portfolioId, stockId: this.stockId }) : null
            return storedStock || this.stockinfo
        }
    },
    methods: {
        remainingStockShareCount() {
            return this.stock ? this.stock.statistics.remainingStockShareCount : 0
        }
    }
}
</script>

<style lang="scss" scoped>
.portfolioStockLotWidget {
    display: flex;
    flex-direction: column;
    z-index: 1;
    .psLots {
        background-color: #FFF;
        padding: 5px;
        .psLot {
            padding: 5px 0;
            &:not(:last-child) {
                border-bottom: solid 1px #EEE;
            }
        }
    }
    .psSummary {
        // font-size: 0.7rem;
        padding: 6px;
        border-top: solid 1px var(--agora);
        background-color: var(--agoraBG);
        display: flex;
        >*{
            flex: 2;
            display: flex;
            flex-direction: column;
        }
        .psTotal {
            flex: 1;
        }
        .psRealProfit {
            text-align: end;
        }
        .psAllProfit {
            text-align: end;
        }
        .lab {
            text-transform: capitalize;
        }
        .val {
            font-weight: bold;
        }
    }
    .newLot {
        margin-top: 10px;
    }
}
</style>