<template>
    <div class="thresholds">
        <v-card elevation="2">
            <div class="sectionTitle">
                <span>{{$t('thresholds.title')}}</span>
                <div class="t-infos">
                    <v-btn
                        color="primary"
                        icon
                        @click="toggleHelp">
                        <font-awesome-icon :icon="icons.help" class="fa-2x" />
                    </v-btn>
                </div>
            </div>
            <v-expand-transition>
                <v-card
                    v-show="showHelp"
                    width="100%"
                    class="sectionDesc"
                >{{$t('thresholds.description')}} {{$t(`thresholds.desc-${listType}`)}}</v-card>
            </v-expand-transition>
            <div class="editPanel">
                <div v-if="editMode" class="quickActions">
                    <div class="sectionSubTitle">{{$t('EditorPanel.actions.quickActions')}}</div>
                    <div class="ed-actions">
                        <div class="ed-action-part" v-for="changeValue in changeValues" :key="`${changeValue}-ed`">
                            <v-btn small outlined rounded color="teal darken-1" dark @click="addToAll(changeValue)"><font-awesome-icon :icon="icons.up" class="fa-fw mr-1"></font-awesome-icon>{{changeValue}}%</v-btn>
                            <v-btn small outlined rounded color="teal darken-1" dark @click="removeToAll(changeValue)"><font-awesome-icon :icon="icons.down" class="fa-fw mr-1"></font-awesome-icon>{{changeValue}}%</v-btn>
                        </div>
                    </div>
                </div>
                <BuyEditionPanel v-if="listType === 'buy'" :thresholds="thresholds" ref="buyEditionPanel" @isFormValid="subEditFormValid" @inputChanged="editInputChanged"></BuyEditionPanel>
                <SellEditionPanel v-else :thresholds="thresholds" ref="sellEditionPanel" @isFormValid="subEditFormValid" @inputChanged="editInputChanged"></SellEditionPanel>
                <div class="actions">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                small
                                :dark="saveBtnEnabled"
                                :disabled="!saveBtnEnabled"
                                color="blue darken-2"
                                @click="save"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <font-awesome-icon :icon="icons.save" class="fa-fw fa-lg"></font-awesome-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('EditorPanel.buttons.save')}}</span>
                    </v-tooltip>
                </div>
            </div>
        </v-card>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowDown, faArrowUp, faCircle, faSave } from "@fortawesome/pro-solid-svg-icons";
import StockHtmlHelper from "../../stocks/StockHtmlHelper";
import BuyEditionPanel from './BuyEditionPanel'
import SellEditionPanel from './SellEditionPanel'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import Alert from '@/components/controls/Alert.vue'
import UserListEntry from '../../../models/UserListEntry';
export default {
    props: ["stock", "stockListId", "score"],
    data() {
        return {
            icons: {
                up: faArrowUp,
                down: faArrowDown,
                help: faInfoCircle,
                dot: faCircle,
                save: faSave
            },
            changeValues: [1, 2, 5, 10],
            showHelp: false,
            dialog: false,
            dialogLoaded: false,
            itemListId: undefined,
            editMode: true,
            listType: 'buy',
            title: '',
            icon: faCircle,
            stockHtmlHelper: undefined,
            showStockInfos: false,
            showStockLoader: false,
            saveBtnEnabled: false,
            thresholds: [],
            rules: {
                required: value => !!value || this.$t('EditorPanel.errors.required'),
                isSymbol: value => {
                    const pattern = /^([a-zA-Z-0-9.\\-]+)$/;
                    return (
                        pattern.test(value) || !value || this.$t('EditorPanel.errors.invalidSymbol')
                    );
                }
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        this.init();
    },
    computed: {
        ...authComputed,
        isDemo() {
            return this.hasRole('demonstration');
        }
    },
    components: {
        BuyEditionPanel,
        SellEditionPanel,
        Alert
    },
    methods: {
        init() {
            this.thresholds = this.stock.getThresholds();
            this.setListTypeFromStock();
            this.reset();
        },

        addToAll(value) {
            for (const t in this.thresholds) {
                if (Object.hasOwnProperty.call(this.thresholds, t)) {
                    if (this.thresholds[t] !== '') {
                        const tmpT = this.thresholds[t] + (this.thresholds[t] * (value / 100));
                        this.thresholds[t] = this.stockHtmlHelper.normalizePrice(tmpT);
                        this.saveBtnEnabled = true;
                    }
                }
            }
        },

        removeToAll(value) {
            for (const t in this.thresholds) {
                if (Object.hasOwnProperty.call(this.thresholds, t)) {
                    if (this.thresholds[t] !== '') {
                        const tmpT = this.thresholds[t] - (this.thresholds[t] * (value / 100));
                        this.thresholds[t] = this.stockHtmlHelper.normalizePrice(tmpT);
                        this.saveBtnEnabled = true;
                    }
                }
            }
        },

        toggleHelp() {
            this.showHelp = !this.showHelp;
        },

        save() {
            return new Promise((resolve, reject) => {
                const panel = this.$refs[`${this.listType}EditionPanel`];
                if (panel.isFormValid()) {
                    const thresholds = panel.thresholds;
                    this.stock.setThresholds(thresholds);
                    this.$store.dispatch('updateStockInUserWatchList', { listId: this.stockListId, item: this.stock, listType: this.listType }).then(() => {
                        this.reset();
                        resolve()
                    }).catch(error => {
                        const errorCode = error[0].code || error[0].errorCode
                        this.$refs.iAlert.error({ errorCode });
                        reject(error)
                    });
                }
            })
        },

        reset() {
            this.saveBtnEnabled = false;
            this.$store.dispatch('setDirtyThresholds', { dirty: false })
        },

        subEditFormValid(status) {
            this.saveBtnEnabled = this.$store.getters.hasDirtyThresholds && status.valid;
        },

        editInputChanged() {
            this.saveBtnEnabled = true
        },

        setListTypeFromStock() {
            this.allLists = Object.keys(this.$store.state.list.lists).reduce((acc, cur) => acc.concat(this.$store.state.list.lists[cur]), []);
            this.listType = this.allLists.find(list => list.id === this.stockListId).type;
        }
    },
    watch: {
        stock(val) {
            if (val instanceof UserListEntry) {
                this.init()
            }
        },
        saveBtnEnabled(val) {
            this.$store.dispatch('setDirtyThresholds', { dirty: val })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/breakpoints.scss";
.editPanel {
    display: flex;
    align-items: center;
    padding: 0 15px 15px;
    .quickActions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .ed-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .ed-action-part {
                display: flex;
                gap: 10px;
            }
        }
    }
    .actions{
        margin-left: 10px;
    }
    @media screen and (max-width: $small){
        flex-direction: column;
        .quickActions {
            .ed-actions {
                flex-direction: row;
                flex-wrap: wrap;
                .ed-action-part {
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
