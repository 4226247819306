<template>
    <div class="appBarInfos">
        <InvTicker v-bind="currencyTicker"></InvTicker>
    </div>
</template>

<script>
import StockPriceHelperMixin from '../../../../helpers/StockPriceHelperMixin';
import InvTicker from './InvTicker.vue'
export default {
    mixins: [StockPriceHelperMixin],
	components: { InvTicker },
    computed: {
        currencyTicker() {
            const defaultConversion = this.$store.getters.getDefaultConversion;
            const value = this.exchangeRate(defaultConversion.split(',').join('')) || 0;
            return {
                name: defaultConversion.split(',').join(' > '),
                value,
                extraClasses: 'currency'
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>