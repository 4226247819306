<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.maintenance.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">
                <div class="ap-subtitle-lpart">{{$t('admin.body.maintenance.synchronization.title')}}</div>
                <div class="ap-subtitle-rpart">
                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="getSynchronizationDates()"
                    >
                        <font-awesome-icon :icon="icons.refresh" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
            <div class="ap-body">
                <div class="ap-body-part">
                    <span class="lastSyncLabel">{{getResx('lastSync')}} :</span>
                    <span v-if="lastSynchronization" style="font-weight:bold">{{lastSynchronization}}</span>
                    <v-skeleton-loader v-else type="text" width="200" class="ma-0"></v-skeleton-loader>
                </div>
                <div class="ap-body-part">
                    <span class="lastSyncLabel">{{getResx('nextSync')}} :</span>
                    <span v-if="nextSynchronization" style="font-weight:bold">{{nextSynchronization}}</span>
                    <v-skeleton-loader v-else type="text" width="200" class="ma-0"></v-skeleton-loader>
                </div>
            </div>
            <div class="ap-action">
                <div v-for="syncType in syncTypes" :key="syncType.action" class="ap-action-btn">
                    <span>{{syncType.label}}</span>
                    <v-btn
                        color="primary"
                        fab
                        small
                        dark
                        @click="syncronize(syncType.action)"
                    >
                        <font-awesome-icon :icon="icons.sync" class="fa-lg"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import {
    faSyncAlt,
    faRedoAlt
} from "@fortawesome/pro-regular-svg-icons";
import DateHelper from "../../../infra/DateHelper";
import MaintenanceService from './MaintenanceService'
export default {
    data() {
        return {
            icons: {
                sync: faSyncAlt,
                refresh: faRedoAlt
            },
            dateHelper: undefined,
            syncTypes: [
                { label: this.$t('admin.body.maintenance.synchronization.syncAll'), action: 'all' },
                { label: this.$t('admin.body.maintenance.synchronization.syncIntraDay'), action: 'intraday' },
                { label: this.$t('admin.body.maintenance.synchronization.syncEOD'), action: 'eod' },
                { label: this.$t('admin.body.maintenance.synchronization.syncDeamon'), action: 'deamon' },
                { label: this.$t('admin.body.maintenance.synchronization.fundamentals'), action: 'fundamental' }
            ],
            lastSynchronization: undefined,
            nextSynchronization: undefined,
            overlay: false,
            resxBase: 'admin.body.maintenance.synchronization.'
        }
    },
    methods: {
        syncronize(scope) {
            this.overlay = true;
            MaintenanceService.syncronize({ scope }).then((result) => {
                if (Object.keys(result).length > 0) {
                    const syncDates = result.map(r => r.synchronizationDateTime);
                    const syncDate = syncDates.reduce((a, c) => { return c > a ? c : a; });
                    this.setLastSynchronizationDate(syncDate);
                }
            }).catch((err) => {
                alert(err.message);
            }).finally(() => {
                this.overlay = false;
            });
        },
        getSynchronizationDates() {
            MaintenanceService.getStatus().then(result => {
                console.log(result);
                this.setLastSynchronizationDate(result.lastExecutionDateTime);
                this.setNextSynchronizationDate(result.nextExecutionDateTime);
            }).catch((err) => {
                this.setLastSynchronizationDate();
                this.setNextSynchronizationDate();
                console.error(err.message);
            });
        },
        setLastSynchronizationDate(syncDate) {
            this.lastSynchronization = syncDate ? DateHelper.toSimpleDateTime(syncDate) : this.$t('admin.body.maintenance.synchronization.unknown');
        },
        setNextSynchronizationDate(nextSyncDate) {
            this.nextSynchronization = nextSyncDate ? DateHelper.toSimpleDateTime(nextSyncDate) : this.$t('admin.body.maintenance.synchronization.unknown');
        },
        getResx(key) {
            return this.$t(`${this.resxBase}${key}${this.$vuetify.breakpoint.mobile ? 'Abbrev' : ''}`);
        }
    },
    created() {
        this.getSynchronizationDates();
    }
}
</script>

<style scoped src="@/assets/css/adminSection.css">
</style>
<style scoped>
.lastSyncLabel {
    margin-right: 5px;
}
</style>
