<template>
    <SimplePage>
        <v-card class="accountCreated" outlined>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon x-large color="green darken-1">mdi-account-check</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="headline mb-1">{{$t('title')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-card-subtitle>{{$t('text')}}</v-card-subtitle>
            <v-card-actions>
                <v-btn color="primary" class="loginBtn" @click="loginAsked">{{$t('loginButton')}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="goHome">{{$t('goHomeButton')}}</v-btn>
            </v-card-actions>
        </v-card>
        <template v-slot:widgetFooterLeftPart>
            <SubscriptionButtons></SubscriptionButtons>
        </template>
    </SimplePage>
</template>

<script>
import SimplePage from "../SimplePage.vue";
import SubscriptionButtons from "../../components/subscriptions/SubscriptionButtons.vue";
export default {
    methods: {
        loginAsked() {
            this.$router.push({ name: "Login" });
        },
        goHome() {
            this.$router.push({ name: "Home" });
        }
    },
    i18n: {
        messages: {
            fr: {
                title: "Votre compte a été créé avec succès !",
                text: "Veuillez vous connecter pour commencer à prendre le contrôle de vos investissements.",
                goHomeButton: "Retour à l'accueil",
                loginButton: "Connexion"
            },
            en: {
                title: "Your account has been created with success!",
                text: "Please log in to start taking control of your investments.",
                goHomeButton: "Back to home page",
                loginButton: "Login"
            }
        }
    },
    components: { SimplePage, SubscriptionButtons }
}
</script>

<style lang="scss" scoped>
.accountCreated{
    width: 100%;
    max-width: 600px;
    margin: auto;
    border: none;
    .headline {
        white-space: normal;
    }
}
</style>
