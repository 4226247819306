import TopEntry from './TopEntry'
class TopEntries {
    constructor() {
        this._entries = [];
    }

    exists(symbol) {
        return this._entries.some(e => e.symbol === symbol);
    }

    findEntry(symbol) {
        return this._entries.find(e => e.symbol === symbol);
    }

    addEntry({ stock, list }) {
        if (stock.lastPrice > 0) {
            const entry = this.findEntry(stock.symbol);
            if (entry !== undefined) {
                entry.addScoreInfos({ score: stock.score, list: list });
            }
            else {
                const topEntry = new TopEntry({ stock });
                topEntry.addScoreInfos({ score: stock.score, list: list });
                topEntry.thresholds = stock.getThresholds();
                this._entries.push(topEntry);
            }
        }
    }

    getTop(number) {
        if (number <= 0) { throw new Error('TopEntry.getTop number must be greater than zero.') }
        return this._entries.sort((a, b) => Math.abs(b.highScore) - Math.abs(a.highScore)).slice(0, number);
    }
}

export default TopEntries
