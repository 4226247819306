<template>
    <div class="adminPanel">
        <div class="adminPanelTitle">{{$t('admin.body.users.title')}}</div>
        <div class="adminPanelPart">
            <div class="ap-subtitle">
                <div class="ap-subtitle-lpart">{{userListCount}} {{$t('admin.body.users.title')}}</div>
            </div>
            <div class="ap-body">
                <v-data-table
                    :headers="headers"
                    :items="userList"
                    :items-per-page=-1
                    hide-default-footer>
                </v-data-table>
            </div>
            <div class="ap-action">
            </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import UsersSectionMixin from './UsersSectionMixin';

export default {
    mixins: [UsersSectionMixin]

}
</script>
<style scoped src="@/assets/css/adminSection.css">
</style>
<style lang="scss" scoped>

</style>