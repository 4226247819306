<template>
    <div class="tagsNavigator">
        <v-toolbar class="flex-grow-0">
            <v-toolbar-title class="d-flex align-center">
                <div v-if="$vuetify.breakpoint.mobile">
                    <font-awesome-icon :icon="icons.tags" class="fa-fw"></font-awesome-icon>
                </div>
                <div v-else>{{$t('search.tags.header.title')}}</div>
                <Tag :tag="refTag" class="ml-2"></Tag>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <TagFilter v-if="$vuetify.breakpoint.mobile" :tags="facetedSearchTags" @tagFilterRequested="tagFilterRequested"></TagFilter>
            <!-- <v-btn v-if="$vuetify.breakpoint.mobile" icon @click="showFilter">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
        </v-toolbar>
        <div class="navigationBody">
            <div v-if="!$vuetify.breakpoint.mobile" icon class="navigationFilter">
                <VerticalTags :tags="facetedSearchTags" :selectedTags="searchedTags" @tagClicked="filterTagClicked" selectable></VerticalTags>
            </div>
            <div class="taggedStockCardList">
                <div v-if="stockIds.length === 0">
                    <div class="emptyTagList">{{$t('search.tags.results.empty')}}</div>
                    <TagsDisplayer :tags="tagsToDisplay" @tagClicked="searchNewTag"></TagsDisplayer>
                </div>
                <v-carousel v-else light :height="$vuetify.breakpoint.mobile ? '100%' : 700" :hide-delimiters="$vuetify.breakpoint.mobile">
                    <v-carousel-item
                        v-for="(stockId, i) in stockIds"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                        <v-sheet tile class="carouselSheet">
                            <CompleteStockCardMobile v-if="$vuetify.breakpoint.mobile" :stockId="stockId" @tagClicked="searchNewTag"></CompleteStockCardMobile>
                            <CompleteStockCard v-else :stockId="stockId" @tagClicked="searchNewTag" class="mx-auto"></CompleteStockCard>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </div>
    </div>
</template>

<script>
import StockService from '@/services/StockService';
import Tag from '@/components/controls/Tag';
import VerticalTags from '../../controls/VerticalTags';
import CompleteStockCard from '../../stocks/CompleteStockCard'
import CompleteStockCardMobile from '../../stocks/CompleteStockCardMobile'
import TagFilter from './TagFilter.vue'
import { faTag } from '@fortawesome/pro-light-svg-icons';
import TagsDisplayer from './TagsDisplayer.vue';
export default {
    props: ['refTag'],
    data() {
        return {
            icons: {
                tags: faTag
            },
            stockIds: [],
            stocksInfos: {},
            searchedTags: [],
            facetedSearchTags: [],
            tagsToDisplay: []
        }
    },
    components: { Tag, CompleteStockCard, VerticalTags, TagFilter, CompleteStockCardMobile, TagsDisplayer },
    mounted() {
        this.searchedTags = [this.refTag];
        this.searchTags();
    },
    methods: {
        searchTags() {
            StockService.searchTagsLocally({ tags: this.searchedTags }).then(result => {
                if (result.length > 0) {
                    const tags = result[0].tags;
                    this.stockIds = result.map(r => r.stockId);
                    this.stocks = this.stockIds.map(stockId => {
                        this.stocksInfos[stockId] = {};
                        return {
                            stockId,
                            tags
                        };
                    });
                    this.buildFacetedSearchTags();
                    return this.stocks[0].stockId;
                }
                else {
                    this.stockIds = [];
                    StockService.getAllTags().then(result => {
                        console.log(result);
                        this.searchedTags = [];
                        this.tagsToDisplay = result;
                    }).finally(() => {
                        return null;
                    });
                }
            }).then(stockId => {
                if (stockId) {
                    StockService.getCompleteInfo({ stockId }).then(result => {
                        console.log(result);
                        this.stocksInfos[stockId] = result;
                    })
                }
                else {
                    console.log('No tag found');
                }
            });
        },
        buildFacetedSearchTags() {
                this.facetedSearchTags = [];
                let tmp = [];
                const seen = Object.create(null);
                // this.searchedTags.forEach(t => {
                //     seen[`${t.name}|${t.value}`] = true;
                // });
                this.stocks.forEach(element => {
                    const t = element.tags.filter(o => {
                        var key = ['name', 'value'].map(k => o[k]).join('|');
                        if (!seen[key]) {
                            seen[key] = true;
                            return true;
                        }
                    });
                    tmp = tmp.concat(t);
                });
                this.facetedSearchTags = tmp.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        },
        searchNewTag({ tag }) {
            this.$emit('selectedTagChanged', { tag });
            //this.refTag = tag;
            this.searchedTags = [tag];
            this.searchTags();
        },
        filterTagClicked({ tag, selected }) {
            if (selected) {
                this.searchedTags.push(tag);
            }
            else {
                const index = this.searchedTags.findIndex(t => t.name === tag.name && t.value === tag.value);
                this.searchedTags.splice(index, 1);
            }
            this.searchTags();
        },
        tagFilterRequested({ tagsToFilter }) {
            this.searchedTags.push(tagsToFilter);
            this.searchTags();
        },
        reset() {
            this.facetedSearchTags = [];
        }
    },
    watch: {
        refTag(val) {
            if (val !== undefined) {
                this.searchedTags = [val];
                this.searchTags();
            }
        }
    }
}
</script>

<style scoped>
.tagsNavigator {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.navigationBody{
    flex: 1;
    display: flex;
}
.navigationFilter {
    display: flex;
    flex-direction: column;
    background-color: #e6f2ff;
    padding: 20px;
    width: 400px;
}
.taggedStockCardList {
    flex: 1;
    display: flex;
    padding: 20px;
}
.emptyTagList {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 50px;
}
.carouselSheet {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 750px) {
    .taggedStockCardList {
        padding: 5px;
    }
    .carouselSheet {
        align-items: flex-start;
    }
    >>>.v-window__prev, >>>.v-window__next {
        top: 200px;
        margin: 0 0;
    }
}
</style>
