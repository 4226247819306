import { faShoppingCart, faSuitcase, faUsdCircle } from "@fortawesome/pro-duotone-svg-icons"
import ListIconMixin from "./ListIconMixin"

var ListDuoIconMixin = {
    mixins: [ListIconMixin],
    data() {
        return {
            icons: {
                buy: faShoppingCart,
                sell: faUsdCircle,
                portfolio: faSuitcase
            }
        }
    }
}

export default ListDuoIconMixin