<template>
    <div :class="`stockPriceDate ${out ? ` ${out}` : ''}`">
        <div class="spd-timepsan">{{getTime}}</div>
        <font-awesome-icon :icon="entry.isPriceIntraDay ? icons.intraday : icons.eod" ></font-awesome-icon>
    </div>
</template>

<script>
import { faMoon, faSun } from "@fortawesome/pro-light-svg-icons";
import { listComputed } from '../../store/helpers/ListHelper';
export default {
    props: ['entry', 'out'],
    data () {
        return {
            icons: {
                intraday: faSun,
                eod: faMoon
            }
        }
    },
    computed: {
        ...listComputed,
        getUserLists() {
            return this.userLists;
        },
        getTime() {
            const dt = this.entry.priceDateTime || this.entry.marketRegularDateTime || this.entry.lastPriceDateTime;
            if (dt) {
                const ddt = new Date(dt);
                const localDdt = Date.UTC(ddt.getFullYear(), ddt.getMonth(), ddt.getDate(), ddt.getHours(), ddt.getMinutes(), ddt.getSeconds());
                const diffTime = new Date() - new Date(localDdt);
                let days;
                let hours;
                let minutes = Math.floor(diffTime / (1000 * 60));
                if (minutes > 60) {
                    hours = Math.floor(minutes / 60);
                    minutes = minutes - (hours * 60);
                }
                if (hours > 24) {
                    days = Math.floor(hours / 24);
                    hours = hours - (days * 24);
                }
                if (days > 3) {
                    hours = null;
                    minutes = null;
                }
                const dayLabel = this.$vuetify.breakpoint.mobile ? this.$t('units.dayAbbrev') : this.$t('units.day') + (days && days > 1 ? 's' : '');
                const minLabel = this.$vuetify.breakpoint.mobile ? this.$t('units.minuteAbbrev') : this.$t('units.minute');
                const separator = this.$vuetify.breakpoint.mobile ? '' : ' ';
                const delta = (days ? `${days}${separator}${dayLabel} ` : '') + (hours ? `${hours}${separator}${this.$t('units.hour')} ` : '') + (minutes ? `${minutes}${separator}${minLabel}` : '');
                return delta;
            }
            return this.$t('dictio.na');
        }
    },
    methods: {

    },
    watch: {
        getUserLists: {
            handler: function (val) {
                this.timespan = this.getTime;
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.stockPriceDate{
    font-size: smaller;
    display: flex;
    align-items: center;
}
.stockPriceDate.reverse {
    flex-direction: row-reverse;
}
.stockPriceDate.end {
    justify-content: flex-end;
}
.spd-timepsan {
    margin-right: 8px;
}
.stockPriceDate.reverse .spd-timepsan {
    margin-left: 8px;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .spd-timepsan {
        font-size: small;
    }
}
</style>
