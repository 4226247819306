import { faUsdSquare } from '@fortawesome/pro-solid-svg-icons';
import StockPriceInfos from './StockPriceInfos.vue';
import StockPriceChange from './StockPriceChange.vue';
import StockCountry from './StockCountry.vue';
import StockPriceDate from './StockPriceDate.vue'
import StockHtmlHelper from './StockHtmlHelper'
import ThresholdPanel from '../userStockWatchingList/edition/ThresholdPanel.vue'
import ThresholdPanelMobile from '../userStockWatchingList/edition/ThresholdPanelMobile.vue'
import ThresholdLine from './ThresholdLine.vue'
import FundamentalsService from '../../services/FundamentalsService';
import StockEvolutionService from '../../services/StockEvolutionService';
import StockChart from '../controls/StockChart.vue'
import Tags from '../controls/Tags.vue';
import SearchDialog from '../search/SearchDialog.vue'
import TagsNavigator from '../search/tags/TagsNavigator.vue'
import UserListSelector from '../userStockWatchingList/edition/UserListSelector.vue'
import { listComputed } from '../../store/helpers/ListHelper';
import UserListEntry from '../../models/UserListEntry';
var StockFullCardMixin = {
    props: ['stock'],
    data() {
        return {
            tab: 0,
            icons: {
                currency: faUsdSquare
            },
            stockListId: undefined,
            userListEntry: {},
            selectedTag: undefined,
            loading: false,
            stockInfos: {
                logo: undefined,
                marketCap: undefined,
                oneYearRange: undefined,
                oneDayRange: undefined,
                close: undefined,
                open: undefined,
                dividend: undefined,
                oneYearTargetEst: undefined
            },
            fundamentals: {
                general: {},
                highlights: {},
                tags: []
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        this.showStockFullCard();
    },
    computed: {
        ...listComputed,
        stockChangeInfo() {
            return {
                price: this.stock.marketRegularPrice,
                changeAmount: this.stock.change,
                changePercent: this.stock.changePercent
            }
        },
        isStock() {
            return this.stock && this.stock instanceof UserListEntry;
        },
        stockOverview() {
            const stock = this.stock instanceof UserListEntry ? this.stock.rawData : {}
            stock.companyInfo = {
                logoUrl: this.fundamentals.general.logoUrl
            }
            stock.exchange = this.fundamentals.general.exchange
            stock.changeInfo = {
                changePercent: this.stock.changePercent,
                changeAmount: this.stock.change,
                price: this.stock.lastPrice
            }
            return stock
        }
    },
    components: {
        StockPriceInfos,
        StockPriceChange,
        StockCountry,
        StockPriceDate,
        ThresholdPanel,
        ThresholdPanelMobile,
        StockChart,
        ThresholdLine,
        Tags,
        SearchDialog,
        TagsNavigator,
        UserListSelector
    },
    methods: {
        showStockFullCard() {
            this.loading = true;
            this.stockInfos = {};
            this.resetStock();
            this.userListEntry = this.stock;
            this.stockListId = this.userListEntry.listId;
            const fundamentalsPromise = FundamentalsService.getBasics({ stockId: this.stock.stockId });
            const stockEvolutionPromise = StockEvolutionService.getLast({ stockId: this.stock.stockId });
            Promise.all([fundamentalsPromise, stockEvolutionPromise]).then(results => {
                this.fillStockInfos(results[0]);
                this.fillStockEvolutionData(results[1]);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },

        resetStock() {
            if (this.$refs.stockChart) {
                this.$refs.stockChart.reset();
            }
            this.fundamentals = {
                general: {},
                highlights: {},
                tags: []
            };
        },

        fillStockInfos(result) {
            this.fundamentals = result;
            if (result.technicals.the52WeekHigh && result.technicals.the52WeekLow) {
                this.stockInfos.oneYearRange = `${this.stockHtmlHelper.formatPrice(result.technicals.the52WeekLow)} - ${this.stockHtmlHelper.formatPrice(result.technicals.the52WeekHigh)}`;
            }
            else {
                this.stockInfos.oneYearRange = '-'
            }
            if (result.highlights.marketCapitalization) {
                const mcValUnit = this.stockHtmlHelper.simplifyUnit(result.highlights.marketCapitalization);
                this.stockInfos.marketCap = `${mcValUnit.val}${mcValUnit.unit ? this.$t(`units.${mcValUnit.unit}`) : ''}`;
            }
            else {
                this.stockInfos.marketCap = this.$t('dictio.na');
            }
            this.stockInfos.logo = this.stockHtmlHelper.getStockImageSrc(result.general.logoUrl);
            this.stockInfos.dividend = result.highlights.dividendYield ? result.highlights.dividendYield : this.$t('dictio.na');
            this.stockInfos.oneYearTargetEst = result.highlights.wallStreetTargetPrice ? this.stockHtmlHelper.formatPrice(result.highlights.wallStreetTargetPrice) : this.$t('dictio.na');
        },

        fillStockEvolutionData(result) {
            this.stockInfos.close = result.previousClose ? this.stockHtmlHelper.formatPrice(result.previousClose) : this.$t('dictio.na');
            this.stockInfos.open = result.dayOpen ? this.stockHtmlHelper.formatPrice(result.dayOpen) : this.$t('dictio.na');
            if (result.dayHigh && result.dayLow) {
                this.stockInfos.oneDayRange = `${this.stockHtmlHelper.formatPrice(result.dayLow)} - ${this.stockHtmlHelper.formatPrice(result.dayHigh)}`;
            }
            else {
                this.stockInfos.oneDayRange = '-';
            }
            if (result.dayStockEvolutionList.length > 0) {
                const data = result.dayStockEvolutionList[0].stockEvolutionPITList.map(s => { return [Date.parse(`${s.dateTime}Z`), s.price]; })
                this.$refs.stockChart.setData({
                    name: this.stock.symbol,
                    data,
                    change: this.stock.change,
                    previousClose: result.previousClose
                });
            }
        },

        selectedTagChanged({ tag }) {
            this.selectedTag = tag;
        },

        cancel() {
            this.tab = 0;
            this.logo = undefined;
        },

        clear() {
            this.userListEntry = {}
        },

        formatPrice(price) {
            return this.stockHtmlHelper.formatPrice(price);
        },

        tagClicked({ tag }) {
            console.log(tag.value);
            if (this.$refs.tagsNavigator) {
                this.$refs.tagsNavigator.reset();
            }
            this.selectedTag = tag;
            this.$refs.searchDialog.show();
        },

        saveThresholds() {
            return this.$refs.thresholdPanel.save()
        }
    },
    watch: {
        stock(val) {
            if (val instanceof UserListEntry) {
                this.showStockFullCard();
            }
        }
    }
}

export default StockFullCardMixin
