class PropertyMetas {
    constructor({ name, value, dateTime, metas }) {
        this._name = name;
        this._value = value;
        this._dateTime = dateTime;
        this._metas = metas;
    }

    get name() {
        return this._name;
    }

    get value() {
        return this._value;
    }

    get dateTime() {
        return this._dateTime;
    }

    get metas() {
        return this._metas;
    }

    set name(value) {
        this._name = value;
    }

    set value(value) {
        this._value = value;
    }

    set dateTime(value) {
        this._dateTime = value;
    }

    set metas(value) {
        this._metas = value;
    }
}

export default PropertyMetas
