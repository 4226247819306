<template>
    <v-card outlined class="simplePageCard">
        <div class="sp-header">
            <div class="sp-header-icon">
                <font-awesome-icon :icon="icons.addUser" class="fa-2x" style="--fa-primary-color: green; --fa-secondary-color: darkgreen;" />
            </div>
            <div class="sp-header-title">{{$t('register.form.header.title')}}</div>
        </div>
        <div class="sp-desc">{{$t('register.form.header.text')}}</div>

        <div class="container">
            <div>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field name="email"
                        v-model="input.email"
                        clearable
                        :label="$t('register.form.fields.email')"
                        type="email"
                        outlined
                        :rules="[rules.required, rules.email, uniqueEmail]"
                        prepend-inner-icon="mdi-email"
                        @input="emailInput"
                        autocomplete="new-password">
                    </v-text-field>
                    <v-text-field name="firstname"
                        v-model="input.firstname"
                        clearable
                        :label="$t('register.form.fields.firstname')"
                        type="text"
                        outlined
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account"
                        autocomplete="new-password">
                    </v-text-field>
                    <v-text-field name="lastname"
                        v-model="input.lastname"
                        clearable
                        :label="$t('register.form.fields.lastname')"
                        type="text"
                        outlined
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account"
                        autocomplete="new-password">
                    </v-text-field>
                    <v-text-field v-model="input.password"
                        clearable
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[rules.required, rules.pswLength, rules.pswNumber, rules.pswLowercase, rules.pswCapital, rules.pswPonct, passwordValidation]"
                        prepend-inner-icon="mdi-key"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :label="$t('register.form.fields.password')"
                        @click:append="showPasswordIcon_clicked"
                        autocomplete="new-password">
                    </v-text-field>
                    <v-text-field v-model="input.passwordConfirmation"
                        clearable
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[rules.required, passwordConfirmationRule]"
                        prepend-inner-icon="mdi-key"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPasswordIcon_clicked"
                        :label="$t('register.form.fields.passwordConfirmation')"
                        autocomplete="new-password">
                    </v-text-field>
                    <RecaptchaText></RecaptchaText>
                    <div class="d-flex justify-content align-center mt-3 mb-5">
                        <v-checkbox class="mt-0"
                            v-model="isTermsAndConditionAccepted"
                            hide-details
                        >
                            <template v-slot:label>
                                <div class="d-flex justify-content align-center">
                                    <span>
                                        {{$t('register.form.termsAndConditions.part1')}}
                                        <a href="#" @click="termConditionLink_clicked">{{$t('register.form.termsAndConditions.terms')}}</a>
                                        {{$t('register.form.termsAndConditions.part2')}}
                                    </span>
                                </div>
                            </template>
                        </v-checkbox>
                    </div>
                    <v-btn :disabled="createDisabled" block color="primary" x-large :dark="!createDisabled" @click="createAccountAsked" class="mt-3">{{$t('register.form.footer.buttons.create')}}</v-btn>
                </v-form>
            </div>
            <div class="signin">{{$t('register.form.footer.alreadyHaveAccount.text')}} <router-link to="/login">{{$t('register.form.footer.alreadyHaveAccount.signIn')}}</router-link></div>
            <Alert ref="iAlert"></Alert>
            <SySnackbar ref="sySnackbar"></SySnackbar>
        </div>
        <v-dialog
            v-model="termsAndConditionDialog"
            fullscreen
            style="overflow-y:hidden"
            transition="dialog-bottom-transition"
        >
            <v-card style="height:100%;overflow:scroll" >
                <v-toolbar
                    dark
                    dense
                    color="primary"
                >
                    <v-toolbar-title>Settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon
                            dark
                            @click="termsAndConditionDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <TermsAndConditionsPanel style="width:100%" />
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Alert from '../../components/controls/Alert.vue';
import RegisterFormMixin from './RegisterFormMixin';
import TermsAndConditionsPanel from '@/views/Policies/TermsAndConditionsPanel';
import SySnackbar from '../../components/controls/SySnackbar.vue';
export default {
    mixins: [RegisterFormMixin],
    methods: {
        termConditionLink_clicked() {
            this.termsAndConditionDialog = true;
        }
    },
    components: {
    TermsAndConditionsPanel,
    Alert,
    SySnackbar
}
}
</script>
<style scoped src="@/assets/css/simplePage.css">
</style>

<style scoped>
.signin {
    margin-top: 25px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px 0;
}
</style>
