import apiWrapper from './ApiWrapper'
class PreferencesService {
    static get USER_LANG_KEY() {
        return 'USER_LANG';
    }

    set UserLang(lang) {
        localStorage.setItem(PreferencesService.USER_LANG_KEY, lang);
    }

    get UserLang() {
        let lang = localStorage.getItem(PreferencesService.USER_LANG_KEY);
        if (lang === null) {
            lang = navigator.language.split('-')[0];
            this.UserLang = lang;
        }
        return lang;
    }

    getPaymentMethodsInfo() {
        return apiWrapper.post('/api/UserPreference/getPaymentMethodsInfo');
    }

    setPreferredLanguage({ twoLettersLanguage }) {
        return apiWrapper.post('/api/UserPreference/setPreferredLanguage', { twoLettersLanguage })
    }

    deletePaymentMethod({ paymentMethodId }) {
        return apiWrapper.post('/api/UserPreference/deletePaymentMethod', { paymentMethodId })
    }

    setDefaultPayementMethod({ paymentMethodId }) {
        return apiWrapper.post('/api/UserPreference/setDefaultPayementMethod', { paymentMethodId })
    }

    updatePaymentMethodDetails({ paymentMethodId, nameOnCard, expirationMonth, expirationYear }) {
        return apiWrapper.post('/api/UserPreference/updatePaymentMethodDetails', { paymentMethodId, nameOnCard, expirationMonth, expirationYear })
    }
}

export default PreferencesService
