import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accountPage"},[_c('div',{staticClass:"apHeader"},[_c('font-awesome-icon',{class:`fa-${_vm.iconSize}`,attrs:{"icon":_vm.icons.cog}}),_c('div',{staticClass:"apTitle"},[_vm._v(_vm._s(_vm.$t('account.header.title')))])],1),_c('div',{staticClass:"apBody"},[_c('AccountSections')],1),_c(VFooter,{attrs:{"color":"secondary lighten-1","padless":""}},[_c(VRow,{attrs:{"justify":"center","no-gutters":"","align":"center"}},[_vm._l((_vm.links),function(link){return _c(VBtn,{key:link.name,staticClass:"my-2",attrs:{"color":"white","text":"","rounded":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: link.route })}}},[_vm._v(" "+_vm._s(_vm.$t(`settings.links.${link.name}`))+" ")])}),_c(VSpacer),_c('div',{staticClass:"program"},[_c('span',[_vm._v(_vm._s(_vm.$t('program.stripeClimatePartner')))]),_c(VImg,{staticClass:"flex-grow-0",attrs:{"src":require('@/assets/Stripe Climate Badge - Small.png'),"position":"center center","contain":"","width":"25","height":"25"}})],1),_c('div',{staticClass:"cie"},[_c('span',[_vm._v(_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Invagora")])])])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }