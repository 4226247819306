class SearchResult {
    constructor({ ticker, name, exchange, previousClose, country }) {
        this._ticker = ticker
        this._name = name
        this._exchange = exchange
        this._previousClose = previousClose
        this._country = country
    }

    get ticker() { return this._ticker }
    get normalizedTicker() { return this._ticker.normalizedTicker }
    get name() { return this._name }
    get exchange() { return this._exchange }
    get previousClose() { return this._previousClose }
    get country() { return this._country }
    get description() { return `${this._name} (${this.normalizedTicker}) [${this._exchange}]` }
}

export default SearchResult
