<template>
    <div class="resetPasswordForm">
        <v-card class="resetPasswordCard" outlined>
            <div class="rpf-header">
                <div class="rpf-header-icon">
                    <v-icon x-large color="blue lighten-1">mdi-email-arrow-right</v-icon>
                </div>
                <div class="rpf-header-title">{{ $t('resetPassword.requestSent.title') }}</div>
            </div>
            <div class="rpf-desc" v-html="$t('resetPassword.requestSent.text', [this.email])"></div>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'ResetPasswordRequestSent',
    props: ['email']
}
</script>

<style scoped src="@/assets/css/password.css">
</style>

<style scoped>
.rpf-desc >>> .email {
    font-weight: bold;
}
</style>
