<template>
    <div class="userStockList">
        <UserStockListOverview></UserStockListOverview>
        <div v-if="!listLoaded" class="loading">
            <v-data-table
            item-key="name"
            class="elevation-1"
            loading
            loading-text="Loading... Please wait"
            ></v-data-table>
        </div>
        <div v-else class="uslTable">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="canAddEntry"
                        color="blue darken-4"
                        fab
                        dark
                        fixed
                        top
                        right
                        style="top:75px; right:40px;"
                        class="d-none d-sm-flex"
                        @click="newItemBtn_Clicked"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        color="blue darken-4"
                        depressed
                        dark
                        fab
                        fixed
                        top
                        right
                        style="top:75px; right:40px; cursor:default;opacity:0.5"
                        class="d-none d-sm-flex"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon :icon="icons.add" class="fa-fw fa-2x"></font-awesome-icon>
                    </v-btn>
                </template>
                <span v-if="canAddEntry">{{$t('UserStockWatchingList.tableHeader.addTooltip')}}</span>
                <span v-else>{{$t("UserStockWatchingList.tableHeader.entryLimitReachedTooltip")}}</span>
            </v-tooltip>
            <v-data-table
                :headers="headers"
                :items="stockList"
                :item-class="itemRowBackground"
                item-key="name"
                :search="search"
                :custom-filter="filter"
                sort-by="absoluteScore"
                :sort-desc=true
                :items-per-page=-1
                locale="fr-CA"
                :header-props="{ sortIcon: null }"
                @click:row="editItem"
                class="elevation-1 stockTable">
                <template v-slot:top>
                    <v-toolbar flat height="72" color="white">
                        <div class="listName">
                            <v-select
                                v-model="listSelected"
                                :items="listNames"
                                item-text="name"
                                item-value="id"
                                rounded
                                dense
                                outlined
                                hide-details="true"
                                @change="changeList"
                            >
                                <template slot="item" slot-scope="data">
                                    <font-awesome-icon :icon="getListIconFromType(data.item.type)" class="fa-fw me-3" />
                                    <div class="cb-item">{{data.item.name}}</div>
                                </template>
                            </v-select>
                        </div>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-if="stockListLength > 0"
                            ref="searchBar"
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('UserStockWatchingList.tableHeader.searchLabel')"
                            single-line
                            hide-details
                            clearable
                            dense
                            rounded
                            outlined
                            class="d-none d-sm-flex"
                        ></v-text-field>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </template>
                <template v-slot:item.symbol="{ item }">
                    <div class="entrySymbollInfo">
                        <div class="entrySymbol">{{item.symbol}}</div>
                        <div :class="`sCountry ${getCountry(item)}`">{{getCountry(item)}}</div>
                    </div>
                </template>
                <template v-slot:item.companyName="{ item }">
                    <span :class="companyNameTagClass(item.companyName)">{{displayCompanyName(item.companyName)}}</span>
                </template>
                <template v-slot:item.marketRegularPrice="{ item }">
                    <stock-price-infos :entry="item" :key="item.id + '-espi'"></stock-price-infos>
                    <!-- <span class="price">{{formatMarketPrice(item.marketRegularPrice)}}</span> -->
                </template>
                <template v-slot:item.change="{ item }">
                    <StockPriceChange :stockChanglistLoadedInfo="stockChangeInfo(item)"></StockPriceChange>
                </template>
                <template v-slot:item.score="{ item }">
                    <v-chip :class="getColorForScore(item.score)" dark>{{ cleanScore(item.score) }}</v-chip>
                </template>
                <template v-slot:item.marketRegularDateTime="{ item }">
                    <!-- <span>{{marketTime(item.marketRegularDateTime)}}</span><span class="pl-2 marketDate">{{marketDate(item.marketRegularDateTime)}}</span> -->
                    <div class="timeSpan">
                        <stock-price-date :entry="item"></stock-price-date>
                    </div>
                </template>
                <template v-slot:item.marketCap="{ item }">
                    <span>{{AdaptIfNull(item.marketCap)}}</span>
                </template>
                <template v-slot:item.threashold200="{ item }">
                    <span>{{formatPrice(item.threashold200)}}</span>
                </template>
                <template v-slot:item.threashold400="{ item }">
                    <span>{{formatPrice(item.threashold400)}}</span>
                </template>
                <template v-slot:item.threashold600="{ item }">
                    <span>{{formatPrice(item.threashold600)}}</span>
                </template>
                <template v-slot:item.threashold800="{ item }">
                    <span>{{formatPrice(item.threashold800)}}</span>
                </template>
                <!-- <template v-slot:item.actions="{ item }">
                    <v-icon class="actionBtn" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon class="actionBtn" @click="deleteItem(item)">mdi-delete</v-icon>
                </template> -->
            </v-data-table>
            <ConfirmDialog ref="deleteItemConfirmDialog" @answered="deleteItemDialogAnswered"></ConfirmDialog>
            <!-- <v-card style="border-top-left-radius: 0; border-top-right-radius: 0; box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2), 0px 5px 2px 0px rgba(0, 0, 0, 0.14), 0px 5px 5px 0px rgba(0, 0, 0, 0.12);">
                    <v-card-text>
                        <div style="position: absolute; top: -42px" class="body-1 font-weight-bold">
                        <v-icon class="mdi-18px roundedIcon success" style="color: white">mdi-check</v-icon> Selected domains:
                        </div>
                        <template>
                        <v-chip class="mr-2 mb-2" close>blb</v-chip>
                        </template>
                        <template>
                        <v-chip class="mb-2" close>and bla more</v-chip>
                        </template>
                    </v-card-text>
                    <v-card-actions class="actionsDetails" style="background: rgb(249, 249, 249); border-top-left-radius: 0; border-top-right-radius: 0">
                    <v-spacer></v-spacer>
                    <v-btn text normal >Export All</v-btn>
                    </v-card-actions>
                </v-card> -->
        </div>
        <EditorPanel ref="editorPanel" @removeSymbol="deleteItem"></EditorPanel>
        <EditStockDialog ref="editStockPanel" @removeSymbol="deleteItem" @notify="showNotification"></EditStockDialog>
        <Alert ref="iAlert"></Alert>
        <SySnackbar ref="iSySnackbar"></SySnackbar>
    </div>
</template>

<script>
import userStockListMixin from './UserStockListMixin'
import UserDataHelperMixin from '../../infra/UserDataHelperMixin'
import listCardMixin from '../home/dashboard/boards/ListCardMixin'
import StockPriceInfos from '../stocks/StockPriceInfos.vue'
import StockPriceDate from '../stocks/StockPriceDate.vue'
import EditStockDialog from './edition/EditStockDialog.vue'
import SySnackbar from '../controls/SySnackbar.vue'
import UserStockListOverview from './UserStockListOverview.vue'
import DateHelper from '../../infra/DateHelper'
export default {
    components: { StockPriceInfos, StockPriceDate, EditStockDialog, SySnackbar, UserStockListOverview },
    data: () => ({
        expanded: [],
        singleExpand: false,
        dialog: false,
        stocks: [],
        editedIndex: -1,
        editedItem: {
            symbol: '',
            name: '',
            threashold200: 0,
            threashold400: 0,
            threashold600: 0,
            threashold800: 0
        },
        defaultItem: {
            symbol: '',
            name: '',
            threashold200: 0,
            threashold400: 0,
            threashold600: 0,
            threashold800: 0
        }
    }),
    computed: {
        stockListLength() {
            return this.stockList ? this.stockList.length : 0;
        }
    },
    methods: {
        filter: function(value, search, item) {
            if (!search || search === "") return true;
            if (value === undefined) return false;
            /*let type = "";
            if (value === item.symbol) {
                type = "symbol";
            }
            if (value === item.symbol) {
                type = "symbol";
            }*/
            if (value === item.marketCap) {
                //type = "marketCap";
                return this.AdaptIfNull(value).toLowerCase().includes(search.toLowerCase());
            }
            else if (value === item.threashold200 || value === item.threashold400 || value === item.threashold600 || value === item.threashold800) {
                //type = "threashold"
                let valueAsString = value.toString();
                const transformedSearch = search.includes(".") ? search.replace(".", ",") : search.replace(",", ".");
                if (!isNaN(search) || !isNaN(transformedSearch)) {
                    if (!valueAsString.includes(".")) {
                        valueAsString += ".00";
                    }
                    return valueAsString.toLowerCase().includes(search.toLowerCase()) || valueAsString.toLowerCase().includes(transformedSearch.toLowerCase())
                }
            }
            if (isNaN(value)) {
                return value.toLowerCase().includes(search.toLowerCase());
            }
            else {
                /*console.log(this.formatPrice(value) + " " + search.toLowerCase());
                var searchFormated = this.formatPrice(search);
                return this.formatPrice(value).toLowerCase().includes(searchFormated);*/
            }
            //
        },
        itemRowBackground: function (item) {
            const dt = item.priceDateTime || item.marketRegularDateTime || item.lastPriceDateTime;
            if (dt) {
                const diff = DateHelper.getDiffBetweenUtcDateAndNow({ fromUTCDate: new Date(dt) });
                if (diff.days && diff.days > 10) {
                    return 'problemItemBackgroundColor';
                }
            }
            return '';
        }
    },
    mixins: [userStockListMixin, listCardMixin, UserDataHelperMixin]
}
</script>

<style scoped>
.listName{
    width: 400px;
}
th.colHeader {
    background-color: #F5F5F5;
}
.t200 span {
    border-bottom: solid 2px var(--t200);
}
.t200-sell span {
    border-bottom: solid 2px var(--t200-sell);
}
.t400 span {
    border-bottom: solid 2px var(--t400);
}
.t400-sell span {
    border-bottom: solid 2px var(--t400-sell);
}
.t600 span {
    border-bottom: solid 2px var(--t600);
}
.t800 span {
    border-bottom: solid 2px var(--t800);
}
.priceInfos{
    display: flex;
    justify-content: flex-end;
}
.entrySymbollInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrySymbol{
    font-weight: bold;
}
span.price {
    font-weight: bold;
}
span.marketDate {
    color: #AAA;
    font-style: italic;
    font-size: small;
}
span.unknown {
    font-style: italic;
    color: #AAA;
    font-size: small;
}
.actionBtn{
    font-size: 16px;
    margin-left: 20px;
}
.stockTable .timeSpan {
    display: flex;
    justify-content: flex-end;
}
.customitem {
    display: flex;
    justify-content: space-between;
}
.listNameType{
    margin-left: 10px;
}
.stockTable >>> tbody tr :hover {
    cursor: pointer;
}

>>> .problemItemBackgroundColor{
    background-color: rgba(215,215,44,.5)
}

@media screen and (max-width: 750px) {
    .listName{
        width: 100%;
        padding-top: 5px;
    }
    .stockTable .v-toolbar__content {
        flex-direction: column;
    }
    .stockTable .v-data-table-header-mobile__wrapper .v-select {
        padding-top: 0;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.v-data-table__mobile-row .v-chip.v-size--default {
        height: 28px;
        padding: 0 10px;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.v-data-table__mobile-row .v-chip.transparent {
        padding: 0;
    }
    .actionBtn {
        font-size: 24px;
        margin-left: 25px;
        margin-right: 0;
    }
    .v-data-table-header-mobile{
        display: none;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.v-data-table__mobile-row {
        height: 36px;
        min-height: 36px;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.v-data-table__mobile-row:nth-child(even) {
        background-color: #EEE;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.v-data-table__mobile-row:first-child {
        background-color: #555;
        color: #FFF;
    }
}
</style>
<style scoped src="@/assets/css/stockInfos.css">
</style>
<style lang="scss" scoped>
.userStockList {
    display: flex;
    gap: 10px;
    .uslTable {
        flex: 1;
    }
}
</style>
