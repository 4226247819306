class ExchangeHelper {
    static get ExchangeMapping() {
        return {
            CSE: {
                shortName: 'CSE',
                fullName: 'Canadian Securities Exchange'
            },
            OTCQX: {
                shortName: 'OTC',
                fullName: 'OTC Markets Group'
            },
            XLON: {
                shortName: 'LSE',
                fullName: 'LONDON STOCK EXCHANGE'
            },
            XNAS: {
                shortName: 'Nasdaq',
                fullName: 'Nasdaq'
            },
            XNYS: {
                shortName: 'NYSE',
                fullName: 'New York Stock Exchange'
            },
            XTSE: {
                shortName: 'TSX',
                fullName: 'Toronto Stock Exchange'
            },
            XTSX: {
                shortName: 'TSXV',
                fullName: 'TSX VENTURE EXCHANGE - DRK'
            },
            _OTHER_: {
                shortName: 'Other',
                fullName: 'Other'
            },
            _UNKNOWN_: {
                shortName: 'Unknown',
                fullName: 'Unknown'
            }
        }
    }

    static getExchangeMap(mic) {
        return ExchangeHelper.ExchangeMapping[mic];
    }

    static getShortName(mic) {
        const exch = ExchangeHelper.getExchangeMap(mic);
        return exch ? exch.shortName : null;
    }

    static getFullName(mic) {
        const exch = ExchangeHelper.getExchangeMap(mic);
        return exch ? exch.fullName : null;
    }
}

export default ExchangeHelper
