import serverService from './ApiWrapper'
import StockInfoConverter from '../infra/converters/StockInfoConverter'
import SearchResultConverter from '../infra/converters/SearchResultConverter';
import StockContextualInfoConverter from '../infra/converters/StockContextualInfoConverter';
import StockAiContextualInfoConverter from '../infra/converters/StockAiContextualInfoConverter';

var StockService = {
    getStock: function ({ symbol, plateformCustomExchange }) {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/Get', { symbol, plateformCustomExchange }).then((response) => {
                var stock;
                if (response) {
                    stock = StockInfoConverter.convert(response);
                    // Temporaire
                    stock.plateformCustomExchange = plateformCustomExchange;
                }
                resolve(stock);
            });
        });
    },

    searchTagsLocally: function ({ query, tags }) {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/SearchLocally', { tags }).then((response) => {
                resolve(response);
            });
        });
    },

    search: function ({ query }) {
        return new Promise((resolve, reject) => {
            serverService.post('/api/Stocks/Search', { query, tags: [] }).then((response) => {
                const searchResult = {
                    originalQuery: query,
                    entries: response.entries.map(res => SearchResultConverter.convert(res))
                };
                resolve(searchResult);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getCompleteInfo: function ({ stockId }) {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/GetCompleteInfo', { stockId }).then((response) => {
                const stock = StockInfoConverter.convert(response);
                resolve(stock);
            });
        });
    },

    getAllTags: function () {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/GetAllTags').then((response) => {
                resolve(response);
            });
        });
    },

    getQuoteContextualInfo: function ({ stockId, locale }) {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/GetQuoteContextualInfo', { stockId, twoLettersLanguage: locale }).then((response) => {
                const stockContextualInfo = StockContextualInfoConverter.convert({ ...response });
                resolve(stockContextualInfo);
            });
        });
    },

    getAiContextualInfo: function ({ stockId, locale }) {
        return new Promise((resolve) => {
            serverService.post('/api/Stocks/GetAiContextualInfo', { stockId, twoLettersLanguage: locale }, true).then((response) => {
                const stockContextualInfo = StockAiContextualInfoConverter.convert({ ...response, ...response.data });
                resolve(stockContextualInfo);
            });
        });
    }
}

export default StockService
