import StockService from "../../services/StockService"

var stockStore = {
    state: () => ({
        dirtyThresholds: false,
        currencyExchangeRates: undefined,
        stock: undefined,
        quoteContextualInfo: undefined,
        aiContextualInfo: undefined,
        aiContextualInfoLoaded: false,
        stockPageOrigin: undefined
    }),

    mutations: {
        setDirtyThresholds(state, { dirty }) {
            state.dirtyThresholds = dirty
        },
        setCurrencyExchangeRates(state, { currencyExchangeRates }) {
            state.currencyExchangeRates = {
                CADCAD: 1,
                USDUSD: 1
            }
            Object.entries(currencyExchangeRates).forEach(([key, value]) => {
                Object.entries(value).forEach(([skey, svalue]) => {
                    state.currencyExchangeRates[`${key}${skey}`.toUpperCase()] = svalue
                })
            })
        },
        setStock(state, { stock }) {
            state.stock = stock
        },
        setQuoteContextualInfo(state, { contextualInfo }) {
            state.quoteContextualInfo = contextualInfo
        },
        setAiContextualInfo(state, { contextualInfo }) {
            state.aiContextualInfo = contextualInfo
            state.aiContextualInfoLoaded = true
        },
        unsetAiContextualInfo(state) {
            state.aiContextualInfo = undefined
            state.aiContextualInfoLoaded = false
        },
        setStockPageOrigin(state, { stockPageOrigin }) {
            state.stockPageOrigin = stockPageOrigin
        }
    },
    actions: {
        setDirtyThresholds(context, { dirty }) {
            context.commit('setDirtyThresholds', { dirty })
        },
        setCurrencyExchangeRates(context, { currencyExchangeRates }) {
            context.commit('setCurrencyExchangeRates', { currencyExchangeRates })
        },
        setStockPageOrigin(context, { stockPageOrigin }) {
            context.commit('setStockPageOrigin', { stockPageOrigin })
        },
        getStockCompleteInfo({ commit }, { stockId }) {
            return new Promise((resolve) => {
                StockService.getCompleteInfo({ stockId }).then((stock) => {
                    commit('setStock', { stock })
                    resolve(stock)
                })
            })
        },
        getQuoteContextualInfo({ commit, state }, { stockId }) {
            return new Promise((resolve) => {
                const stateStockId = state.quoteContextualInfo ? state.quoteContextualInfo.stockId : undefined
                if (stockId !== stateStockId) {
                    commit('setQuoteContextualInfo', { contextualInfo: undefined })
                    StockService.getQuoteContextualInfo({ stockId }).then((response) => {
                        const contextualInfo = response
                        contextualInfo.stockId = stockId
                        commit('setQuoteContextualInfo', { contextualInfo })
                        resolve(contextualInfo)
                    })
                }
            })
        },
        getAiContextualInfo({ commit, state }, { stockId, locale }) {
            return new Promise((resolve) => {
                const stateStockId = state.aiContextualInfo ? state.aiContextualInfo.stockId : undefined
                if (stockId !== stateStockId) {
                    commit('unsetAiContextualInfo')
                    StockService.getAiContextualInfo({ stockId, locale }).then((response) => {
                        const contextualInfo = response
                        contextualInfo.stockId = stockId
                        commit('setAiContextualInfo', { contextualInfo })
                        resolve(contextualInfo)
                    })
                }
            })
        },
        resetQuoteContextualInfo(context) {
            context.commit('setQuoteContextualInfo', { contextualInfo: undefined })
        },
        resetAiContextualInfo(context) {
            context.commit('unsetAiContextualInfo')
        },
        clearStockInfo(context) {
            context.commit('setStock', { stock: undefined })
            context.commit('setQuoteContextualInfo', { contextualInfo: undefined })
            context.commit('unsetAiContextualInfo')
        }
    },
    getters: {
        hasDirtyThresholds(state) {
            return state.dirtyThresholds
        },
        getCurrencyExchangeRates(state) {
            return state.currencyExchangeRates
        },
        completeStockInfo(state) {
            return state.stock
        },
        stockPageOrigin(state) {
            return state.stockPageOrigin
        },
        aiContextualInfoLoaded(state) {
            return state.aiContextualInfoLoaded
        },
        additionalData(state) {
            return state.aiContextualInfo ? state.aiContextualInfo.additionalData : undefined
        },
        sentiments(state) {
            return state.aiContextualInfo ? state.aiContextualInfo.sentiments : []
        },
        partnerships(state) {
            return state.aiContextualInfo ? state.aiContextualInfo.partnerships : []
        },
        questionsBeforeInvesting(state) {
            return state.aiContextualInfo ? state.aiContextualInfo.questionsBeforeInvesting : []
        },
        similarCompanies(state) {
            return state.aiContextualInfo ? state.aiContextualInfo.similarCompanies : []
        },
        news(state) {
            return state.quoteContextualInfo ? state.quoteContextualInfo.news : []
        }
    }
}
export default stockStore