<template>
    <div class="stockScoreInfosList">
        <div class="stockScoreInfos" :key="scoreInfo.list.id + '-' + entry.symbol + '-' + scoreInfo.score">
            <div class="scoreForList">
                <!-- <font-awesome-icon v-if="isSelling()" :icon="icon"></font-awesome-icon> -->
                <span :class="`score ${getScoreColor(scoreInfo.score)}`">{{getCleanScore(scoreInfo.score)}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import StockScoreInfosListMixin from './StockScoreInfosListMixin'
export default {
    mixins: [StockScoreInfosListMixin]
}
</script>

<style>
.stockScoreInfosList{
    /* border-top: solid 1px #BBB; */
    /* font-size: small; */
    font-size: larger;
    display: flex;
    flex-direction: column;
    /* padding-top: 10px;
    margin-top: 10px; */
}
.stockScoreInfos{
    display: flex;
    align-items: center;
    /* color: var(--t000); */
}

.ssi_listName{
    flex: 1;
    /* flex-direction: column;
    justify-content: center; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    min-width: 0;
}
.scoreForList{
    white-space: nowrap;
    text-align: right;
    display: flex;
    align-items: center;
}
.scoreForList svg {
    font-size: larger;
    color: #FFB300;
}
.score{
    margin-left: 10px;
    font-size: xx-large;
    color: var(--t000);
}
.score.t600{
    color: var(--t600);
    font-weight: bold;
}
.score.t800{
    color: var(--t800);
    font-weight: bold;
}
.score.t400-sell {
    color: var(--t400-sell);
    font-weight: bold;
}
.score.t200-sell {
    color: var(--t200-sell);
    font-weight: bold;
}
</style>
