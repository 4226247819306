class AutomatedAbuseService {
    static async validate({ vueComp, action }) {
        // (optional) Wait until recaptcha has been loaded.
        await vueComp.$recaptchaLoaded()
        // Execute reCAPTCHA with action.
        const token = await vueComp.$recaptcha(action);
        return token;
    }
}

export default AutomatedAbuseService
