<template>
    <div class="sellThresholdsPanel">
        <v-form ref="sellEditForm" v-model="valid">
            <div class="stp-container">
                <div class="stp-part">
                    <div class="stp-icon">
                        <div class="stp-title-m" v-if="$vuetify.breakpoint.mobile">{{$t('thresholds.titleSellUp')}}</div>
                        <font-awesome-icon :icon="icons.top" :size="$vuetify.breakpoint.mobile ? '3x' : '5x'" color="green"></font-awesome-icon>
                    </div>
                    <div class="stp-thresholds">
                        <div class="stp-title" v-if="!$vuetify.breakpoint.mobile">{{$t('thresholds.titleSellUp')}}</div>
                        <div class="stp-threshold">
                            <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t800" @input="inputChanged()" @keyup="keyUp" :rules="[rules.required, rules.isPrice]" :label="$t(`thresholds.sell.t800`)">
                                <template slot="append"><font-awesome-icon :class="getThresholdColor(1)" :icon="icons.circle" size="xs"></font-awesome-icon></template>
                            </v-text-field>
                        </div>
                        <div class="stp-threshold">
                            <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t600" @input="inputChanged()" @keyup="keyUp" :rules="[rules.isPrice]" :label="$t(`thresholds.sell.t600`)">
                                <template slot="append"><font-awesome-icon :class="getThresholdColor(2)" :icon="icons.circle" size="xs"></font-awesome-icon></template>
                            </v-text-field>
                        </div>
                    </div>
                </div>
                <v-divider :vertical="!$vuetify.breakpoint.mobile"></v-divider>
                <div class="stp-part">
                    <div class="stp-icon">
                        <div class="stp-title-m" v-if="$vuetify.breakpoint.mobile">{{$t('thresholds.titleSellDown')}}</div>
                        <font-awesome-icon :icon="icons.bottom" :size="$vuetify.breakpoint.mobile ? '3x' : '5x'" color="#cc0000"></font-awesome-icon>
                    </div>
                    <div class="stp-thresholds">
                        <div class="stp-title" v-if="!$vuetify.breakpoint.mobile">{{$t('thresholds.titleSellDown')}}</div>
                        <div class="stp-threshold">
                            <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t400" @input="inputChanged()" @keyup="keyUp" :rules="[rules.isPrice]" :label="$t(`thresholds.sell.t400`)">
                                <template slot="append"><font-awesome-icon :class="getThresholdColor(3)" :icon="icons.circle" size="xs"></font-awesome-icon></template>
                            </v-text-field>
                        </div>
                        <div class="stp-threshold">
                            <v-text-field outlined :dense="isMobile" hide-details="auto" :type="thresholdFieldType" v-model="thresholds.t200" @input="inputChanged()" @keyup="keyUp" :rules="[rules.required, rules.isPrice]" :label="$t(`thresholds.sell.t200`)">
                                <template slot="append"><font-awesome-icon :class="getThresholdColor(4)" :icon="icons.circle" size="xs"></font-awesome-icon></template>
                            </v-text-field>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import SellEditionPanelMixin from './SellEditionPanelMixin'
export default {
    mixins: [SellEditionPanelMixin]
}
</script>

<style scoped>
.v-card__actions {
	padding: 0 24px 20px;
}
.symbolInput input{
    text-transform: uppercase;
}
.sellThresholdsPanel{
    padding: 15px 0;
}
.stp-container {
    display: flex;
}
.stp-part{
    flex: 1;
    display: flex;
}
.stp-part:not(:last-of-type){
    margin-right: 10px;
}
.stp-icon{
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stp-thresholds{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}
.stp-title{
    font-size: large;
    color: #333;
    margin-bottom: 8px;
}
.stp-threshold{
    padding: 10px;
}

@media screen and (max-width: 750px) {
    .sellThresholdsPanel{
        padding: 0;
    }
    .stp-container {
        flex-direction: column;
    }

    .stp-part {
        padding: 5px;
    }

    .stp-part:not(:last-of-type){
        margin-right: 0;
    }

    .stp-icon{
        flex-direction: column;
        justify-content: space-evenly;
    }

    .v-text-field__details {
        display: none;
    }
}
</style>
