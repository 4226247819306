<template>
    <div :class="`stockCard ${getCardClass}`" @click="goToCardList()" @mouseenter="stockCardEnter()" @mouseleave="stockCardLeave()">
        <StockCard :entry="entry" :key="entry.id + '-sc'"></StockCard>
        <div class="pricing">
            <div class="pricing-left">
                <StockPriceInfos :entry="entry" :key="entry.id + '-spi'"></StockPriceInfos>
                <StockPriceChange :stockChangeInfo="stockChangeInfo" :key="entry.id + '-spc'"></StockPriceChange>
            </div>
            <div class="pricing-right">
                <StockPriceDate :entry="entry" :key="entry.id + '-spd'"></StockPriceDate>
            </div>
        </div>
    </div>
</template>

<script>
import {
    faCircle,
    faArrowUp,
    faArrowDown,
    faArrowRight
} from "@fortawesome/pro-solid-svg-icons";
import StockHtmlHelper from './StockHtmlHelper'
import StockCard from './StockCard'
import StockPriceInfos from './StockPriceInfos'
import StockPriceChange from './StockPriceChange'
import StockPriceDate from './StockPriceDate'
export default {
    name: 'ScoredStockCard',
    props: ['entry', 'demo'],
    data() {
        return {
            icon: faCircle,
            up: faArrowUp,
            down: faArrowDown,
            null: faArrowRight,
            stockHtmlHelper: undefined
        }
    },
    components: {
        StockCard,
        StockPriceInfos,
        StockPriceChange,
        StockPriceDate
    },
    computed: {
        getCardClass() {
            const badge = this.stockHtmlHelper.getBadge(this.entry.highScore);
            let cls = this.stockHtmlHelper.getBadgeCssClass(badge);
            cls = this.demo ? `demo ${cls}` : cls;
            return cls;
        },
        stockChangeInfo() {
            return this.stockHtmlHelper.getStockChangeInfo(this.entry);
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    methods: {
        goToCardList() {
            this.$emit('viewStock', { stock: this.entry });
        },
        stockCardEnter() {
            if (!this.demo) {
                this.$store.dispatch('hoverScoredStockCard', { stock: this.entry });
            }
        },
        stockCardLeave() {
            if (!this.demo) {
                this.$store.dispatch('leaveScoredStockCard', {});
            }
        }
    }
}
</script>

<style scoped>
.stockCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    border-left: solid 10px var(--t000);
}
.stockCard:not(.demo) {
    cursor: pointer;
}
.stockCard:not(.demo):hover {
    opacity: 0.95;
}
.stockCard.deal{
    border-left: solid 10px var(--t800);
    background-color: #dcefdc;
}
.stockCard.buy{
    border-left: solid 10px var(--t600);
    background-color: #dcefdc;
}
.stockCard.sell-trigger{
    border-left: solid 10px var(--t400-sell);
    background-color: #ffe6e6;
}
.stockCard.sell-loss{
    border-left: solid 10px var(--t200-sell);
    background-color: #ffe6e6;
}
.pricing{
    border-top: solid 1px #CCC;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: 5px;
}
.stockCard.deal .pricing{
    border-color: var(--t800);
}
.stockCard.buy .pricing{
    border-color: var(--t600);
}
.stockCard.sell-trigger .pricing{
    border-color: var(--t400-sell);
}
.stockCard.sell-loss .pricing{
    border-color: var(--t200-sell);
}
.pricing-left, .pricing-right {
    display: flex;
    align-items: center;
    gap: 10px;
}
</style>
<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
@media screen and (max-width: $small) {
    .pricing-left {
        align-items: flex-start;
        flex-direction: column;
    }
}
</style>
