import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400","content-class":"iAlert"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{class:_vm.cls},[_c('div',{staticClass:"alertIcon"},[_c('font-awesome-icon',{staticClass:"fa-fw fa-3x",attrs:{"icon":_vm.icons[_vm.type]}})],1),_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"alertText"},[_vm._v(_vm._s(_vm.msg))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","fab":"","small":"","dark":""},on:{"click":_vm.close}},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.close}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }