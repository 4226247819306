import axios from 'axios'

class ServerService {
    addToken({ token }) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    removeToken() {
        delete axios.defaults.headers.common.Authorization;
    }

    postJSON(url, data, includeAdditionalData) {
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(data)
        }
        return new Promise((resolve, reject) => {
            axios.post(url, data, options).then((response) => {
                if (response.data.success) {
                    let data;
                    if (includeAdditionalData) {
                        data = {
                            data: response.data.data,
                            additionalData: response.data.additionalData
                        }
                    }
                    else {
                        data = response.data.data;
                    }
                    resolve(data);
                }
                else {
                    reject(response.data.errors || response.data.error);
                }
            }).catch((error) => {
                // const err = responseConverter.convert(error)
                reject(error);
            });
        })
    }

    post(url, data, includeAdditionalData) {
        return new Promise((resolve, reject) => {
            axios.post(url, data).then((response) => {
                if (response.data.success) {
                    let data;
                    if (includeAdditionalData) {
                        data = {
                            data: response.data.data,
                            additionalData: response.data.additionalData
                        }
                    }
                    else {
                        data = response.data.data;
                    }
                    resolve(data);
                }
                else {
                    reject(response.data.errors || response.data.error);
                }
            }).catch((error) => {
                // const err = responseConverter.convert(error)
                reject(error);
            });
        })
    }

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios.get(url, data).then((response) => {
                if (response.data.success) {
                    resolve(response.data.data)
                }
                else {
                    reject(response.data.errors || response.data.error);
                }
            }).catch((error) => {
                // const err = responseConverter.convert(error)
                reject(error);
            });
        })
    }
}

export default new ServerService();
