<template>
    <div class="recaptchaText" v-html="$t('recaptcha.branding')"></div>
</template>

<script>
export default {
}
</script>

<style>
.recaptchaText {
    padding: 5px;
    font-size: x-small;
    color: gray;
}
.grecaptcha-badge {
    visibility: hidden;
}
</style>
