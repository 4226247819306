<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="agora">
                <font-awesome-icon :icon="icons.move" class="fa-fw fa-lg mr-2"></font-awesome-icon> {{ $t('UserStockWatchingList.portfolio.actions.move.title') }}
            </v-btn>
        </template>
        <div class="portfolioMoveStockDialog">
            <div class="dialogHeader">{{ $t('UserStockWatchingList.portfolio.actions.move.text') }}</div>
            <div class="dialogBody">
                <div class="portfolios">
                    <v-list dense>
                        <v-subheader>{{ $t('UserStockWatchingList.portfolio.actions.move.actual') }}</v-subheader>
                        <div class="actualPortofolio">{{ actualProfolio.name }}</div>
                    </v-list>
                    <v-list dense>
                        <v-subheader>{{ $t('UserStockWatchingList.portfolio.actions.move.to') }}</v-subheader>
                        <v-list-item-group
                            v-model="selectedPortfolio"
                            color="primary"
                        >
                            <v-list-item v-for="(p, i) in portfoliosAvailable" :key="i">
                                <v-list-item-content>
                                    <v-list-item-title v-text="p.name"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </div>
            <div class="dialogFooter">
                <v-spacer></v-spacer>
                <v-btn fab small @click="confirm" color="primary" :disabled="confirmMoveDisabled">
                    <font-awesome-icon :icon="icons.ok" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
                <v-btn fab small dark @click="cancel">
                    <font-awesome-icon :icon="icons.cancel" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { faArrowRight, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

export default {
    props: ['portfolioId', 'stockId'],
    data() {
        return {
            dialog: false,
            icons: {
                ok: faCheck,
                cancel: faTimes,
                move: faArrowRight
            },
            selectedPortfolio: undefined,
            selectedActualPortfolio: 0
        }
    },
    computed: {
        actualProfolio() {
            return this.$store.getters.portfolioList.find(p => p.id === this.portfolioId);
        },
        portfoliosAvailable() {
            return this.$store.getters.portfolioList.filter(p => p.id !== this.portfolioId);
        },
        confirmMoveDisabled() {
            return this.selectedPortfolio === undefined;
        }
    },
    methods: {
        show() {
            this.dialog = true;
        },
        confirm() {
            const toPortfolioId = this.portfoliosAvailable[this.selectedPortfolio].id;
            this.$emit('move', { stockId: this.stockId, fromPortfolioId: this.portfolioId, toPortfolioId });
            this.dialog = false;
        },
        cancel() {
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.portfolioMoveStockDialog {
    background-color: #FFF;
    .dialogHeader {
        padding: 20px;
    }
    .dialogBody {
        padding: 0 20px;
        .portfolios {
            display: flex;
            flex-direction: column;
            .portfolio {
                padding: 10px;
                border: solid 1px #EEE;
                cursor: pointer;
                &:hover {
                    background-color: #DDD;
                }
            }
            .actualPortofolio {
                padding: 10px 16px;
                border: solid 1px #EEE;
                background-color: #EEE;
                font-size: 0.8125rem;
                font-weight: 500;
                line-height: 1rem;
            }
        }
    }
    .dialogFooter {
        display: flex;
        gap: 10px;
        padding: 20px;
    }
}
</style>