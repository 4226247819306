<template>
    <div class="usageProgress">
        <div class="up-usage-label">
            <div>{{ label }}</div>
            <div class="up-usage-limit">
                <div v-if="max==0">
                    <font-awesome-icon :icon="icons.lock" size="sm"></font-awesome-icon>
                </div>
                <div v-else>
                    <span :class="`${this.limitStyle}`">{{ totalUsed }}</span> / <span class="limitTotal">{{ max }}</span>
                </div>
            </div>
        </div>
        <div class="up-usage-bar">
            <v-progress-linear
                v-model="prctValue"
                :buffer-value="prctBuffer"
                height="8"
                :color="progressColor"
                :striped="max==0"
            ></v-progress-linear>
        </div>
    </div>
</template>

<script>
import { faLock } from '@fortawesome/pro-light-svg-icons'

export default {
    props: {
        label: String,
        value: { type: Number, default: 0 },
        secondValue: { type: Number, default: 0 },
        max: Number
    },
    data() {
        return {
            icons: {
                lock: faLock
            }
        }
    },
    computed: {
        totalUsed() {
            return this.value + this.secondValue
        },
        prctValue() {
            return (this.value / this.max) * 100
        },
        prctBuffer() {
            return ((this.value + this.secondValue) / this.max) * 100
        },
        limitStyle() {
            return this.totalUsed > this.max ? 'limitReached' : ''
        },
        progressColor() {
            return this.value > this.max ? 'error' : 'primary'
        }
    },
    methods: {
        getListCount() {
            //const total = this._userSubscriptionInfo.planDefinition.limit.combinedListCount || `∞`;
            //return `<span class="${this._getListCountClass()}">${this._getListTotal()}</span> / <span class="limitTotal">${total}</span>`;
            return 0
        }
    }
}
</script>

<style scoped src="@/assets/css/usagePanel.css">
</style>
<style lang="scss" scoped>
.usageProgress {
    .up-usage-limit {
        .limitReached {
            color: var(--v-error-base);
        }

    }
}
</style>