import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBadge,{attrs:{"content":_vm.selectedTagsCount,"value":_vm.selectedTagsCount,"color":"synaxy","overlap":""}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons[_vm.iconsFilter]}})],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c('div',{staticClass:"filterHeader"},[_vm._v(_vm._s(_vm.$t('search.tags.filters.filterSymbolsWithTags')))]),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"multiple":"","color":"primary"},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},[_vm._l((_vm.tags),function(tag,i){return [(!tag)?_c(VDivider,{key:`divider-${i}`}):_c(VListItem,{key:`item-${i}`,attrs:{"value":tag.value},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(tag.value)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(tag.name)}})],1)]}}],null,true)})]})],2)],1),_c('div',{staticClass:"footer"},[_c(VBtn,{attrs:{"block":"","disabled":_vm.selectionChanged,"color":"primary"},on:{"click":_vm.filterAsked}},[_vm._v("Filtrer")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }