<template>
    <div class="adminPage">
        <AdministrationPageMobile v-if="$vuetify.breakpoint.mobile"></AdministrationPageMobile>
        <AdministrationPageDesktop v-else></AdministrationPageDesktop>
    </div>
</template>

<script>
import AdministrationPageDesktop from './AdministrationPageDesktop.vue';
import AdministrationPageMobile from './AdministrationPageMobile.vue';
export default {
    components: {
        AdministrationPageDesktop,
        AdministrationPageMobile
    }
}
</script>

<style src="@/assets/css/adminSection.css">
</style>
<style>
.adminPage {
    height: 100%;
    background-color: var(--lightBG);
}
</style>
