<template>
    <div class="subscriptionStepperCont">
        <v-stepper v-model="step" class="subscriptionStepper">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" editable step="1">
                    {{stepTitle(1)}}
                    <!-- <small>{{$t(`subscriptions.plans.current`)}} : {{$t(`subscriptions.plans.${planName}.title`)}}</small>
                    <small>{{$t(`subscriptions.plans.selected`)}} : {{$t(`subscriptions.plans.${this.selectedPlanName}.title`)}}</small> -->
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">
                    {{stepTitle(2)}}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 3" step="3">
                    {{$t('subscriptions.stepper.3.title')}}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <SubscriptionPlanPanel ref="subscriptionPlanPanel"></SubscriptionPlanPanel>
                    <div class="stepper-actions">
                        <v-btn
                            color="primary"
                            :small="isMobile"
                            :disabled="!continueToCheckoutBtnEnabled"
                            @click="newPlanSelectionConfirm"
                        >
                            {{step1Action}}
                        </v-btn>
                        <v-btn text :small="isMobile" @click="cancel">
                            {{$t('subscriptions.plans.buttons.cancel')}}
                        </v-btn>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <div class="orderDetailsStep" v-if="isNewOrder">
                        <div class="cardSelector">
                            <div v-if="yourCreditCards.length" class="ccTitles">
                                <span>{{$t('subscriptions.stepper.2.panel.yourCreditCards')}}</span>
                                <span>{{$t('subscriptions.stepper.2.panel.expirationDate')}}</span>
                            </div>
                            <v-radio-group v-model="creditCardSelection" class="mt-0" hide-details>
                                <v-radio
                                    v-for="cc in yourCreditCards"
                                    :key="cc.id"
                                    :value="cc.id"
                                    active-class="ccActive"
                                    class="ccInactive"
                                >
                                    <template v-slot:label>
                                        <div class="card">
                                            <div class="cardId">
                                                <div class="brand"><font-awesome-icon :icon="icons.cc[cc.brand]" class="fa-lg mr-3"></font-awesome-icon>{{cc.brand}}</div>
                                                <div class="endsWith">{{$t('subscriptions.stepper.2.panel.endingWith')}} {{cc.last4}}</div>
                                            </div>
                                            <div class="expiration">
                                                <div class="cardExpired">{{ expirationDetail(cc.expiration) }}</div>
                                                <div class="expDate">{{cc.expiration.year}}/{{cc.expiration.month.toString().padStart(2, '0')}}</div>
                                            </div>
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio value="new" active-class="ccActive" class="ccInactive">
                                    <template v-slot:label>
                                        <font-awesome-icon :icon="icons.cc.unk" class="fa-lg mr-3"></font-awesome-icon> <span>{{$t('subscriptions.stepper.2.panel.addNewCard')}}</span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </div>
                        <StripeCheckoutPanel v-if="creditCardSelection==='new'" ref="stripeCheckoutPanel" :subscriptionInfo="subscriptionInfo" @paymentInfosCompleted="paymentInfosCompleted"></StripeCheckoutPanel>
                    </div>
                    <div class="orderDetailsStep" v-else>
                        <SubscriptionChangeValidation ref="subscriptionChangeValidation"></SubscriptionChangeValidation>
                    </div>
                    <div class="stepper-actions">
                        <v-btn
                            color="primary"
                            :disabled="!paymentInfosContinueBtnEnabled"
                            @click="confirmSubscription"
                        >
                            {{step2Action}}
                        </v-btn>
                        <v-btn text @click="cancel">
                            {{$t('subscriptions.plans.buttons.cancel')}}
                        </v-btn>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <SubscriptionConfirmationPanel v-if="isNewOrder" ref="subscriptionConfirmationPanel" :order="invoice"></SubscriptionConfirmationPanel>
                    <SubscriptionChangeConfirmation v-else></SubscriptionChangeConfirmation>
                    <div class="stepper-actions">
                        <v-btn
                            color="primary"
                            :small="isMobile"
                            @click="closeAsked"
                        >
                            {{$t('subscriptions.plans.buttons.close')}}
                        </v-btn>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import UserDataHelperMixin from '../../infra/UserDataHelperMixin';
import SubscriptionStepperMixin from './SubscriptionStepperMixin'
export default {
    mixins: [SubscriptionStepperMixin, UserDataHelperMixin]
}
</script>

<style lang="scss" scoped>
.subscriptionStepperCont {
    padding: 15px;
    display: flex;
    justify-content: center;
    .subscriptionStepper {
        width: clamp(70%, 100%, 1800px);
        &.theme--light.v-stepper{
            background: none;
            background-color: var(--v-lightGrayBG-base);
        }
    }
}

.orderDetailsStep {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.stepper-actions{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.stepper-actions>*{
    margin: 0 5px;
}
.cardSelector {
    padding: 20px;
    background-color: #FFF;
    width: 600px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	border-radius: 10px;
}
.card {
    flex: auto;
    display: flex;
    justify-content: space-between;
}
.cardId {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ccTitles {
    display: flex;
    justify-content: space-between;
    font-size: small;
    padding: 5px;
    border-bottom: solid 1px var(--v-lightBorder-base);
}
.expiration {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    gap: 8px;
    .cardExpired {
        font-size: 0.8em;
        color: var(--v-error-base);
    }
}
.brand {
    display: flex;
    font-weight: bold;
    text-transform: capitalize;
    width: 150px;
}
.endsWith {
    font-size: 0.9em;
}
.ccInactive {
    border: solid 1px transparent;
    padding: 5px;
}
.ccActive {
    border: solid 1px var(--v-agora-base);
    border-radius: 5px;
    padding: 5px;
    background-color: var(--hoverBG);
}
</style>
