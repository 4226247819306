import ApiWrapper from '../../../services/ApiWrapper';

var TestsService = {
    setClockId: ({ clockId }) => {
        return ApiWrapper.post('/api/devops/Test/SetClockId', { clockId });
    },
    sendInvoice: ({ email }) => {
        return ApiWrapper.post('/api/devops/Test/SendInvoiceTest', { email })
    }
}

export default TestsService
