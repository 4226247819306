import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"d-flex flex-column"},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('search.title')))]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"dialogBody"},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }