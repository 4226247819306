<template>
    <div :class="`valueAndChange ${cssCls}`">
        <div v-if="!hideValue" class="vacValue">{{value}}</div>
        <font-awesome-icon :icon="getChangeIcon" ></font-awesome-icon>
        <div class="vacChange">{{change}}</div>
    </div>
</template>

<script>
import { faArrowDown, faArrowRight, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import StockHtmlHelper from '../stocks/StockHtmlHelper';

export default {
    props: {
        digit: { type: Number, default: 2 },
        val: Number,
        chg: Number,
        chgSymbol: { type: String, default: '' },
        hideValue: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },
        inverted: { type: Boolean, default: false }
    },
    data() {
        return {
            stockHTMLHelper: null,
            icons: {
                up: faArrowUp,
                down: faArrowDown,
                null: faArrowRight
            }
        }
    },
    created() {
        this.stockHTMLHelper = new StockHtmlHelper()
    },
    computed: {
        value() {
            const v = this.val || 0
            return this.stockHTMLHelper.formatPriceWithDigit(v, this.digit)
        },
        change() {
            const chg = Number(this.chg || 0).toFixed(2)
            return `${chg}${this.chgSymbol}`
        },
        cssCls() {
            const classes = []
            classes.push(this.chg > 0 ? 'up' : this.chg < 0 ? 'down' : '')
            classes.push(this.dark ? 'dark' : '')
            classes.push(this.inverted ? 'inverted' : '')
            return classes.filter(c => c).join(' ')
        },
        getChangeIcon() {
            const d = this.chg > 0 ? 'up' : this.chg < 0 ? 'down' : 'null'
            return this.icons[d]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.valueAndChange {
    display: flex;
    font-size: 1.5em;
    align-items: center;
    gap: 6px;
    &.up {
        color: var(--v-greenUp-base);
        .vacValue::before {
            content: "+";
        }
    }
    &.down {
        color: var(--v-redDown-base);
    }
    &.dark {
        &.up {
            color: var(--v-lightGreenUp-base);
        }
        &.down {
            color: var(--v-lightRedDown-base);
        }
    }
    &.inverted {
        padding: 0 10px;
        border-radius: 5px;
        color: #FFF;
        &.up {
            background-color: var(--v-greenUp-base);
        }
        &.down {
            background-color: var(--v-redDown-base);
        }
    }
    @media screen and (max-width: $small) {
        font-size: 1em;
    }
}
</style>