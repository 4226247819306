<template>
    <div class="companyCard">
        <div class="cName">{{company.name}}</div>
        <div class="cDescription">{{company.description}}</div>
    </div>
</template>

<script>
export default {
    props: ['company']
}
</script>

<style lang="scss" scoped>
.companyCard {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .cName {
        font-size: 1.2em;
        font-weight: bold;
    }
    .cDescription {
        font-size: 1em;
    }
}
</style>