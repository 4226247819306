<template>
    <div class="creditCardInfos">
        <div class="cardLogo">
            <font-awesome-icon :icon="icons.cc[card.brand]" class="fa-3x"></font-awesome-icon>
        </div>
        <div class="cardDescription">
            <div class="cardId">{{card.brand}} •••• {{card.last4}}</div>
        </div>
    </div>
</template>

<script>
import { faCreditCardBlank } from '@fortawesome/pro-light-svg-icons'
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'
export default {
    props: ['card'],
    data() {
        return {
            icons: {
                cc: {
                    unk: faCreditCardBlank,
                    mastercard: faCcMastercard,
                    visa: faCcVisa,
                    amex: faCcAmex
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.creditCardInfos {
    display: flex;
    align-items: center;
    gap: 15px;
    .cardLogo {
        width: 55px;
    }
    .cardDescription {
        flex: 1;
        .cardId {
            display: flex;
            font-weight: bold;
            text-transform: capitalize;
            width: 150px;
        }
        .expiration {
            font-size: 0.9em;
        }
    }
}
</style>