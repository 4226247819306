<template>
    <div class="portfolioCard" @click="viewPortfolio">
        <div class="speedDialCont" @click.stop="">
            <v-speed-dial
                v-model="fab"
                direction="bottom"
                :open-on-hover=false
                transition="slide-y-transition"
            >
                <template v-slot:activator>
                    <v-btn
                        color="#1e90ff"
                        small
                        dark
                        fab
                        class="floatBtn"
                    >
                        <v-icon v-if="fab">mdi-close</v-icon>
                        <v-icon v-else>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-btn
                    fab
                    dark
                    small
                    color="blue darken-2"
                    @click="editPortfolioInfo"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    small
                    color="red darken-4"
                    @click="deletePortfolio"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-speed-dial>
            <ConfirmDialog ref="deletePortfolioConfirmDialog" @answered="deletePortfolioDialogAnswered"></ConfirmDialog>
        </div>
        <div :class="`portfolioHeader ${change}`">
            <div class="portfolioCardName">{{portfolio.name}}</div>
            <div class="portfolioCardDesc">{{portfolio.description}}</div>
        </div>
        <div class="portfolioCardBody">
            <div class="portfolioCardPart">
                <div class="pcp-value">
                    <div class="pcpPriceInfoCont">
                        <div class="pcpPriceInfo">
                            <div class="pcpCurrency">{{ portfolioCurrency }}</div>
                            <div class="pcpValue">{{ portfolioValue }}</div>
                        </div>
                        <div v-if="portfolio.stocks.length > 0" class="pcp-priceChange">
                            <ValueAndChange v-bind="portfolioValueChange"></ValueAndChange>
                        </div>
                    </div>
                </div>
            </div>
            <div class="portfolioCardPart">
                <StockChart v-if="false" ref="stockChart"></StockChart>
            </div>
        </div>
        <div v-if="portfolioCurrencyNotSameAsUserPref" class="portfolioCardFooter">
            <div class="pcpPriceInfoConverted">
                <div class="pcpPriceInfo">
                    <div class="pcpCurrency">{{ convertedText }}</div>
                    <div class="pcpValue">{{ portfolioValueConverted }}</div>
                </div>
                <div v-if="portfolio.stocks.length > 0" class="pcp-priceChange">
                    <ValueAndChange v-bind="portfolioValueChangeConverted"></ValueAndChange>
                </div>
            </div>
        </div>
        <font-awesome-icon :icon="icons.portfolio" class="fa-fw bgIcon" color="#EEE"></font-awesome-icon>
    </div>
</template>

<script>
import portfolioCardMixin from './PortfolioCardMixin';
export default {
    mixins: [portfolioCardMixin]
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/breakpoints.scss";
.portfolioCard {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .speedDialCont {
        z-index: 100;
    }
    .v-btn--fab.v-size--small.floatBtn {
        opacity: 0;
    }
    &:hover .v-btn--fab.v-size--small.floatBtn {
        opacity: 0.6;
    }

    &:hover .v-btn--fab.v-size--small.floatBtn:hover {
        opacity: 1;
    }
    .portfolioHeader {
        border-top: solid 20px #666;
        padding: 15px 15px 0;
        &.up {
            border-color: var(--v-greenUp-base);
        }
        &.down {
            border-color: var(--v-redDown-base);
        }
        .portfolioCardName {
            font-size: x-large;
            font-weight: 500;
        }
        .portfolioCardDesc {
            font-size: small;
            color: #757575;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .portfolioCardBody {
        padding: 0 15px 15px;
        z-index: 1;
        .portfolioCardPart {
            .pcp-value {
                display: flex;
                flex-direction: column;
                font-size: large;
                .pcpPriceInfoCont {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &.otherCur {
                        .pcpPriceInfo {
                            .pcpValue {
                                font-size: 1em;
                            }
                            .pcpCurrency {
                                font-weight: normal;
                                font-size: 0.9em;
                            }
                        }
                        .pcp-priceChange{
                            font-size: 0.7em;
                        }
                    }
                }
                .pcpPriceInfo {
                    font-family: "Roboto", sans-serif;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .pcpCurrency {
                        font-size: 1em;
                        color: #777;
                    }
                    .pcpValue {
                        font-size: 2em;
                    }
                }
                .pcp-priceChange{
                    font-size: 0.8em;
                    * {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
    .portfolioCardFooter{
        z-index: 10;
        border-top: solid 1px var(--v-lightBorder-base);
        padding: 5px 10px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: var(--v-superLightGrayBG-base);
        .pcpPriceInfoConverted {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .pcpPriceInfo {
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 10px;
                .pcpValue {
                    font-size: 1em;
                }
                .pcpCurrency {
                    font-weight: normal;
                    font-size: 0.9em;
                }
            }
            .pcp-priceChange{
                font-size: 0.7em;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .portfolioHeader {
            .portfolioCardName {
                font-size: large;
            }
        }
    }
}
</style>