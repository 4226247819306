import InviteRow from './InviteRow'
import InvitationList from './InvitationList'
import InvitationAskedList from './InvitationAskedList'
import InvitationService from '../../services/InvitationService'
import InvitationSentList from './InvitationSentList.vue';
var InvitePanelMixin = {
    data() {
        return {
            input: {
                fullname: '',
                email: '',
                plan: this.$t('admin.body.invitations.newInvitation.plan')
            },
            plans: [],
            invitationsSent: [],
            invitationsAsked: [],
            invitationsCompleted: []
        }
    },
    created() {
        this.invitationService = new InvitationService();
        this.invitationService.getList().then((invitations) => {
            this.setInvitationLists({ invitations });
        });
        this.invitationService.getPlans().then((response) => {
            this.plans = response;
        });
    },
    components: {
        InviteRow,
        InvitationList,
        InvitationSentList,
        InvitationAskedList
    },
    methods: {
        setInvitationLists({ invitations }) {
            this.invitationsAsked = [];
            this.invitationsSent = [];
            this.invitationsCompleted = [];
            invitations.forEach(inv => {
                if (inv.status === 'invitationAsked') {
                    this.invitationsAsked.push(inv);
                }
                else if (inv.status === 'accountCreated') {
                    this.invitationsCompleted.push(inv);
                }
                else {
                    this.invitationsSent.push(inv);
                }
            });
        }
    }
}

export default InvitePanelMixin
