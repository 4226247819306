<template>
    <div>
        <div class="pricePanel">
            <div class="ppInfos">
                <div class="ppiRow">
                    <div class="ppInfosPart">
                        <v-img v-if="stockInfos.logo" :src="stockInfos.logo" contain max-height="50" max-width="50"></v-img>
                        <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            height="50"
                            width="50"
                            type="image"
                        ></v-skeleton-loader>
                    </div>
                    <div class="ppInfosPart ppFlex">
                        <div class="pp-top">
                            <div class="pp-price">{{ formatPrice(stock.lastPrice) }}</div>
                        </div>
                        <div class="pp-priceChange">
                            <StockPriceChange :stockChangeInfo="stockChangeInfo" :key="stock.id + '-spc'"></StockPriceChange>
                        </div>
                    </div>
                </div>
                <div class="ppiRow">
                    <div class="pp-subInfos">
                        <div class="d-row">
                            <StockCountry :entry="stock" class="mr-2"></StockCountry>
                            <div class="ppsi-exchange">{{ fundamentals.general.exchange || '*NASDAQ' }}</div>
                        </div>
                        <div class="pp-delay">
                            <StockPriceDate :entry="stock" :key="stock.id + '-spd'"></StockPriceDate>
                        </div>
                        <div class="ppsi-currency">
                            <font-awesome-icon :icon="icons.currency" class="fa-fw fa-lg"></font-awesome-icon>
                            {{ stock.currency }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pp-priceChart">
            <StockChart ref="stockChart" :stock="stock"></StockChart>
        </div>
        <div class="ppiRow">
            <div class="my-2">
                <Tags :tags="fundamentals.tags" @tagClicked="tagClicked"></Tags>
            </div>
            <SearchDialog ref="searchDialog">
                <TagsNavigator :refTag="selectedTag" @selectedTagChanged="selectedTagChanged"></TagsNavigator>
            </SearchDialog>
        </div>
        <div class="stockTabs">
            <div class="keyInfoTab">
                <div class="metas">
                    <div class="metasCol">
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.previousClose') }}</div>
                            <div class="metaValue">{{stockInfos.close}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.open') }}</div>
                            <div class="metaValue">{{stockInfos.open}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneDayRange') }}</div>
                            <div class="metaValue">{{stockInfos.oneDayRange}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneYearRange') }}</div>
                            <div class="metaValue">{{stockInfos.oneYearRange}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.oneYearTargetEst') }}</div>
                            <div class="metaValue">{{fundamentals.highlights.wallStreetTargetPrice}}</div>
                        </div>
                        <div class="meta">
                            <div class="metaName">{{ $t('entry.marketCap') }}</div>
                            <div class="metaValue">{{stockInfos.marketCap}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="thresholdCont">
                <ThresholdPanelMobile ref="thresholdPanel" :stock="userListEntry" :stockListId="stockListId"></ThresholdPanelMobile>
            </div>
        </div>
    </div>
</template>

<script>
import StockFullCardMixin from './StockFullCardMixin'
export default {
    mixins: [StockFullCardMixin]
}
</script>

<style scoped>
.stockHeader {
    font-size: medium;
}
.pricePanel {
    margin-top: 45px;
    display: flex;
    padding: 5px;
}
.ppInfos {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.ppiRow {
    display: flex;
}
.ppInfosPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ppInfosPart.ppFlex {
    flex: 1;
}
.ppFlexBtw {
    justify-content: space-between;
}
.d-row {
    display: flex;
    align-items: center;
}
.d-row-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.pp-top {
    display: flex;
    justify-content: flex-end;
}
.pp-price {
    font-size: xx-large;
    font-weight: bold;
    align-self: flex-end;
}
.pp-delay {
    align-self: flex-end;
    font-size: small;
}
.pp-priceChart {
    width: 100%;
    margin-top: 15px;
}
.pp-subInfos {
    flex: 1;
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid 1px var(--lightBG);
    display: flex;
    font-size: smaller;
    justify-content: space-between;
    align-items: center;
}
.ppsi-exchange {
    font-weight: 500;
}
.keyInfoTab {
    display: flex;
}
.metas {
    flex: 1;
    display: flex;
    padding: 10px 15px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
}
.metasCol {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.meta {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: small;
}
.metaName {
    text-transform: uppercase;
}
.metaValue {
    font-weight: bold;
}
.newsFeed {
    background-color: #f2f2f2;
    flex: 1;
    height: 100%;
    border-radius: 5px;
    padding: 15px;
}
.news:not(:last-of-type) {
    margin-bottom: 15px;
}
.newsTitle {
    font-weight: bold;
    color: #333;
}
.newsSummary {
    font-size: smaller;
    color: #333;
}
.newsSource {
    font-size: smaller;
    color: var(--midGray);;
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {

}
</style>
