<template>
    <v-chip :x-small="chipXSmallSize" :small="chipSmallSize" @click="clicked" outlined color="var(--agoraLight)">{{badge.label}}</v-chip>
</template>

<script>
export default {
    props: ['badge', 'size'],
    computed: {
        chipXSmallSize() {
            return this.size === 'xs'
        },
        chipSmallSize() {
            return this.size === 'sm'
        }
    },
    methods: {
        clicked() {
            this.$emit('clicked', { badge: this.badge })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
