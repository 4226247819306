<template>
    <div class="stockInfos">
        <div class="stockProfile">
            <div class="symbollInfo">
                <div class="symbol">{{entry.symbol}}</div>
                <div :class="`sCountry ${getCountry()}`">{{getCountry()}}</div>
            </div>
            <div class="companyName">{{entry.companyName}}</div>
        </div>
        <div class="stockScore">
            <StockScoreInfosList :entry="entry" :key="entry.id + '-ssil'"></StockScoreInfosList>
        </div>
    </div>
</template>

<script>
import StockCardMixin from './StockCardMixin'
export default {
    mixins: [StockCardMixin]
}
</script>

<style scoped>
.stockInfos{
    flex: 1;
    display: flex;
}
.stockProfile{
    flex: 1;
}
.symbollInfo {
    display: flex;
    align-items: center;
}
.symbol{
    font-weight: bold;
    margin-right: 10px;
}
.companyName{
    font-size: smaller;
}
</style>
<style scoped src="@/assets/css/stockInfos.css">
</style>
