import { faShoppingCart, faSuitcase, faUsdCircle } from "@fortawesome/pro-light-svg-icons"
import ListIconMixin from "./ListIconMixin"

var ListLightIconMixin = {
    mixins: [ListIconMixin],
    data() {
        return {
            icons: {
                buy: faShoppingCart,
                sell: faUsdCircle,
                portfolio: faSuitcase
            }
        }
    }
}

export default ListLightIconMixin