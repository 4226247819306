<template>
    <div class="resetPasswordForm">
        <v-card class="resetPasswordCard" outlined>
            <div class="rpf-header">
                <div class="rpf-header-icon">
                    <font-awesome-icon :icon="icon.check" class="fa-2x" />
                </div>
                <div class="rpf-header-title">{{$t('resetPassword.done.title')}}</div>
            </div>
            <div class="rpf-desc">{{$t('resetPassword.done.text')}}</div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="goHome">{{$t('resetPassword.done.goHomeButton')}}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
export default {
    name: 'ResetPasswordDone',
    data () {
        return {
            icon: {
                check: faCheckCircle
            }
        }
    },
    methods: {
        goHome() {
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style scoped src="@/assets/css/password.css">
</style>

<style scoped>
.rpf-header-icon svg {
    color: green;
}
</style>
