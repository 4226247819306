import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTimeline,{attrs:{"align-top":"","dense":""}},[_c(VTimelineItem,{attrs:{"color":"red","small":""}},[_c(VRow,{staticClass:"pt-1"},[_c(VCol,{attrs:{"cols":"3"}},[_c('strong',[_vm._v("5pm")])]),_c(VCol,[_c('strong',[_vm._v("New Icon")]),_c('div',{staticClass:"text-caption"},[_vm._v(" Mobile App ")])])],1)],1),_vm._l((_vm.items),function(item,i){return _c(VTimelineItem,{key:i,attrs:{"color":item.color,"icon":item.icon,"fill-dot":""}},[_c(VCard,{attrs:{"color":item.color,"dark":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Threshold "+_vm._s(i)+" ")]),_c(VCardText,{staticClass:"white text--primary"},[_c('p',[_vm._v("Threshold "+_vm._s(i))])])],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }