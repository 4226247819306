var routerStore = {
    state: {
        history: []
    },
    mutations: {
        addToHistory(state, route) {
            state.history.push(route);
        },
        resetHistory(state) {
            state.history = [];
        }
    },
    actions: {
        addToHistory(context, route) {
            context.commit('addToHistory', route);
        },
        resetHistory(context) {
            context.commit('resetHistory');
        }
    },
    getters: {
        history: state => state.history
    }

}

export default routerStore;