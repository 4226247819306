<template>
    <div class="summary">
        <div class="sTotal">Total</div>
        <div class="s10"></div>
        <div class="s10">{{ statistics.remainingStockShareCount }}</div>
        <div class="s10"></div>
        <div class="s12"></div>
        <div class="s12">{{ stockHtmlHelper.formatPriceWithDigit(statistics.totalRemainingStockValue, 2) }}</div>
        <div :class="`s10 ${gainClass(statistics.actualAverageShareBuyPrice)}`">{{ stockHtmlHelper.formatPriceWithDigit(statistics.percentageOfProfitIncludingUnrealized, 2) }}%</div>
        <div :class="`s10 ${gainClass(statistics.actualAverageShareBuyPrice)}`">{{ stockHtmlHelper.formatPriceWithDigit(statistics.totalProfitIncludingUnrealized, 2) }}</div>
        <div class="s11"></div>
    </div>
</template>

<script>
import StockHtmlHelper from '../stocks/StockHtmlHelper';
export default {
    props: ['lastPrice', 'statistics'],
    data() {
        return {
            stockHtmlHelper: undefined
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    methods: {
        gainClass(averageShareCost) {
            const change = ((this.lastPrice / averageShareCost) * 100) - 100
            return change < 0 ? 'negative' : change > 0 ? 'positive' : ''
        }
    }
}
</script>

<style lang="scss" scoped>
.summary {
    display: flex;
    padding: 8px 0;
    border-top: solid 1px var(--agora);
    background-color: var(--agoraBG);
    text-align: right;
    font-weight: bold;
    border-radius: 0 0 5px 5px;
    .sTotal {
        width: 15%;
        text-align: left;
        padding: 0 16px;
    }
    .s10 {
        width: 10%;
        padding: 0 16px;
        &.positive {
            color: var(--v-greenUp-base);
        }
        &.negative {
            color: var(--v-redDown-base);
        }

    }
    .s11 {
        width: 11%;
        padding: 0 16px;
    }
    .s12 {
        width: 12%;
        padding: 0 16px;
    }
}
</style>