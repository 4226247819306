import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resetPasswordForm"},[_c(VCard,{staticClass:"resetPasswordCard",attrs:{"outlined":""}},[_c('div',{staticClass:"rpf-header"},[_c('div',{staticClass:"rpf-header-icon"},[_c('font-awesome-layers',{staticClass:"fa-2x"},[_c('font-awesome-icon',{staticStyle:{"color":"#F57C00"},attrs:{"icon":_vm.icon.sync}}),_c('font-awesome-icon',{staticStyle:{"color":"#E65100"},attrs:{"icon":_vm.icon.key,"transform":"shrink-8 flip-h"}})],1)],1),_c('div',{staticClass:"rpf-header-title"},[_vm._v(_vm._s(_vm.$t('resetPassword.form.title')))])]),_c('div',{staticClass:"rpf-desc"},[_vm._v(_vm._s(_vm.$t('resetPassword.form.text')))]),_c(VForm,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"name":"newPassowrd","autocomplete":"off","outlined":"","hide-details":"auto","type":"password","label":_vm.$t('resetPassword.form.passwordLabel')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"name":"confirmNewPassowrd","autocomplete":"new-password","outlined":"","hide-details":"auto","type":"password","label":_vm.$t('resetPassword.form.confirmPasswordLabel')},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.resetPassword}},[_vm._v(_vm._s(_vm.$t('resetPassword.form.resetButton')))])],1)],1),_c('Alert',{ref:"iAlert"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }