<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                :content="selectedTagsCount"
                :value="selectedTagsCount"
                color="synaxy"
                overlap
            >
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <font-awesome-icon :icon="icons[iconsFilter]" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
            </v-badge>
        </template>
        <v-card>
            <div class="filterHeader">{{$t('search.tags.filters.filterSymbolsWithTags')}}</div>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item-group
                    v-model="selectedTags"
                    multiple
                    color="primary"
                >
                    <template v-for="(tag, i) in tags">
                        <v-divider
                            v-if="!tag"
                            :key="`divider-${i}`"
                        ></v-divider>

                        <v-list-item
                            v-else
                            :key="`item-${i}`"
                            :value="tag.value"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox
                                    :input-value="active"
                                    ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="tag.value"></v-list-item-title>
                                    <v-list-item-subtitle v-text="tag.name"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <div class="footer">
                <v-btn block :disabled="selectionChanged" color="primary" @click="filterAsked">Filtrer</v-btn>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
import { faListUl, faTasks } from '@fortawesome/pro-duotone-svg-icons'
export default {
    props: ['tags'],
    data () {
        return {
            menu: false,
            icons: {
                unsetFilter: faListUl,
                filterSetted: faTasks
            },
            messages: 0,
            items: [
                'Dog Photos',
                'Cat Photos',
                '',
                'Potatoes',
                'Carrots'
            ],
            selectedTagsCount: 0,
            initialSelectedTags: [],
            selectedTags: []
        }
    },
    methods: {
        filterAsked() {
            this.$emit('tagFilterRequested', { tagsToFilter: this.selectedTags });
            this.selectedTagsCount = this.selectedTags.length;
            this.menu = false;
        }
    },
    computed: {
        iconsFilter() {
            return this.selectedTags.length === 0 ? 'unsetFilter' : 'filterSetted';
        },
        selectionChanged() {
            const o1 = this.selectedTags;
            const o2 = this.initialSelectedTags;
            return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(k => o1[k] === o2[k]);
        },
        allUnselected() {
            return this.selectedTags.length === 0;
        },
        allSelected() {
            return this.selectedTags.length === this.tags.length;
        },
        someSelected() {
            return this.selectedTags.length > 0 && this.selectedTags.length < this.tags.length;
        }
    },
    watch: {
        menu(val) {
            if (val) {
                this.initialSelectedTags = this.selectedTags;
            }
        }
    }
}
</script>

<style scoped>
.filterHeader {
    padding: 16px;
    font-size: small;
}
.footer {
    padding: 10px;
}
>>>.v-badge__badge.synaxy {
    inset: 4px 0 auto auto !important;
}
</style>
