<template>
    <div class="cookiePolicyContainer">
        <div class="cookiePolicy">
            <span>{{$t('policies.appUsesCookies')}} <a @click="viewCookiePolicyPage">{{$t('policies.cookies.title')}}</a>.</span>
            <v-btn small color="secondary" class="ml-3" @click="accept">
                {{$t('policies.acceptBtn')}}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import storageService from '../../services/StorageService';
export default {
    data () {
        return {
            dialog: false,
            icons: {
                ok: faCheck
            }
        }
    },
    methods: {
        viewCookiePolicyPage() {
            this.$router.push({ name: 'CookiePolicy' });
        },
        accept() {
            storageService.allowCookies = true;
            this.$store.dispatch('setAllowCookies', { allowCookies: true });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.cookiePolicyContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    bottom: 0;
    z-index: 10;
    .cookiePolicy {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 90%;
        background-color: rgba(255, 255, 255, 0.9);
        border: solid 1px #333;
        border-radius: 100em;
    }
    @media screen and (max-width: $small) {
        position: fixed;
        padding: 5px;
        .cookiePolicy {
            width: 100%;
            flex-direction: column;
            border-radius: unset;
        }
    }
}
</style>
