<template>
    <div class="partnershipsPanel">
        <div v-if="isActive">
            <div v-if="partnerships.length > 0" class="partnerships">
                <PartnershipCard v-for="(p, index) in partnerships" :partnership="p" :key="index"></PartnershipCard>
            </div>
            <div v-else-if="partnershipsLoaded">
                <div class="emptyList">{{ $t('aiAnalysis.partnerships.emptyList') }}</div>
            </div>
            <div v-else>
                <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
            </div>
        </div>
        <div v-else>
            <LockedControl :minPlan="minPlan"></LockedControl>
        </div>
    </div>
</template>

<script>
import Partnership from '../../models/Partnership';
import LockedControl from './LockedControl.vue';
import PartnershipCard from './PartnershipCard.vue';

export default {
    components: { PartnershipCard, LockedControl },
    computed: {
        isActive() {
            const myPlan = this.$store.getters.getMyPlan;
            return myPlan.aiDataActiveKeys.includes('partnerships');
        },
        partnershipsLoaded() {
            return this.$store.getters.aiContextualInfoLoaded;
        },
        partnerships() {
            return this.$store.getters.partnerships.map(p => {
                return new Partnership({
                    companyName: p.companyName,
                    dateTime: p.dateTime,
                    description: p.description,
                    extraLinks: p.extraLinks
                })
            });
        },
        minPlan() {
            const plans = this.$store.getters.getPlans;
            return plans.find(p => {
                return p.limit.stockContext.aiData.partnerships;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.partnershipsPanel {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    .partnerships {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
</style>