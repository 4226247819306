import StockContextualInfo from "../../models/StockContextualInfo";

class StockContextualInfoConverter {
    static convert({ news }) {
        const stockContextualInfo = new StockContextualInfo();
        stockContextualInfo.news = news;
        return stockContextualInfo;
    }
}

export default StockContextualInfoConverter;