<template>
    <div class="inv-commentBox">
        <a href="javascript:;" class="comment-expend-btn" @click="slidePanel()">
            <font-awesome-icon :icon="icons.comment" class="fa-lg"/>
        </a>
        <v-card class="commentBoxCard elevation-16">
            <v-card-title class="headline">
                {{$t('feedback.basic.title')}}
            </v-card-title>
            <v-card-text>
                {{$t('feedback.basic.description')}}
                <div class="text-center my-3">
                    <v-rating
                        v-model="input.rating"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingEmpty"
                        half-icon="$ratingHalf"
                        half-increments
                        hover
                        large
                    ></v-rating>
                </div>
                <v-textarea
                v-model="input.comment"
                outlined
                :hide-details=true
                name="input-7-4"
                :label="$t('feedback.basic.comment')"
                ></v-textarea>
                <RecaptchaText></RecaptchaText>
            </v-card-text>
            <v-card-actions>
                <SaveCancelButtons align="end" @saveAsked="submitComment" @cancelAsked="slidePanel"></SaveCancelButtons>
            </v-card-actions>
        </v-card>
        <Alert ref="iAlert"></Alert>
    </div>
</template>

<script>
import SaveCancelButtons from '../controls/SaveCancelButtons.vue'
import BasicFeedbackMixin from './BasicFeedbackMixin'
export default {
    components: { SaveCancelButtons },
    mixins: [BasicFeedbackMixin]
}
</script>

<style>
.inv-commentBox{
    position: fixed;
    width: 450px;
    bottom: 20px;
    right: -450px;
    transition: 1s;
    z-index: 100;
}
.inv-commentBox.open{
    right: 0;
}
.inv-commentBox:not(.open) .commentBoxCard {
    box-shadow: none !important;
}
.comment-expend-btn{
    position: fixed;
    bottom: 50px;
    right: 0;
    padding: 15px;
    background-color: #FFF;
    border-radius: 5px 0 0 5px;
    box-shadow: -3px 3px 3px 0 rgba(0,0,0,0.2);
}

.inv-commentBox .recaptchaText {
    font-size: smaller;
}

@media screen and (max-width: 750px) {
    .inv-commentBox{
        width: 100%;
    }
    .comment-expend-btn{
        padding: 8px;
        bottom: 10px;
    }
}
</style>
